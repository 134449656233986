import React from "react";
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import defaultStyles from '../base';

const additionalStyles = {
    screenshot: {
        width: '90%',
        merginLeft: '5%',
        border: '1px solid #FFFFFF',
        borderRadius: '5px'
    },
    execerisecontainer: {
        border: '1px solid #FFFFFF',
        marginLeft: '5%',
        width: '90%'
    },
    levelbox: {
        width: '30%',
        height: '28px',
        marginLeft: '5%',
    }
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class WorkoutHelp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            submenu: 1
        };
    }

    componentDidMount() {

    }

    previousMenu() {
        var newmenu = 0;
        if (this.state.submenu === 1) {
            newmenu = 11;
        }
        else {
            newmenu = this.state.submenu - 1;
        }
        this.setState({
            submenu: newmenu
        });
    }

    nextMenu() {
        var newmenu = 0;
        if (this.state.submenu === 11) {
            newmenu = 1;
        }
        else {
            newmenu = this.state.submenu + 1;
        }
        this.setState({
            submenu: newmenu
        });
    }

    render() {
        const { classes } = this.props;
        var that = this;
        var submenutext = "Schritt " + this.state.submenu;
        var backarea = (<div className={[classes.backmenu].join(' ')}>
            <NavigateBeforeIcon className={[classes.backbuttonHome, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.props.close()} />
            <div className={[classes.submenucaption].join(' ')} color="textPrimary">
                Anleitung zum Online Training
                </div>
        </div>);
        var subheader = (<div className={[classes.header1, classes.marginBottom20].join(' ')}>
            <ArrowBackIcon className={[classes.navigationbutton1, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.previousMenu()} />
            <div className={[classes.switchheader1].join(' ')}>{submenutext}</div>
            <ArrowForwardIcon className={[classes.navigationbutton1, classes.navigationButtonRight].join(' ')} fontSize="large" onClick={() => that.nextMenu()} />
        </div>);

        var info = this.createHelp(this.state.submenu);

        return (

            <div className={classes.fullscreen}>
                {backarea}
                <div className={classes.mainspacer} />
                {subheader}
                <div className={classes.mainspacer} />
                {info}
                <div className={classes.mainspacer} />
                <div className={classes.mainspacer} />
            </div>
        );
    }

    createHelp(step) {
        const { classes } = this.props;
        var introtext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Herzlich willkommen im NoLimitZ Online Training.<br /><br />
        Schön dass ihr mittrainiert. Dieses App Modul ist frisch programmiert und daher sehr wahrscheinlich nicht fehlerfrei.<br />
        Bitte gebt uns Bescheid, wenn ihr Fehler entdeckt, auch für Verbesserungsvorschläge sind wir jederzeit offen.<br /><br />
        Im folgenden will ich kurz erklären, wie ihr das Online Training nutzen könnt.<br /><br />
        Als erstes solltet in eueren Einstellungen festlegen, wie ihr euere Fitness einschätzt, um die Trainingsintensität optimal zu steuern.<br />
        Dafür öffnet ihr bitte euer Profil (rechts oben):<br /><br />
        </div>
        var settingstext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Im Profilbereich wechselt ihr zum Menü <b>Online Training</b> (Pfeil nach rechts klicken).<br />
        Hier könnt ihr mit dem Regler eueren Fitnesslevel einstellen von Einsteiger (1) bis Profi (7).<br /><br />
        Die Stufe könnt ihr jederzeit ändern, auch bei der Trainingsausführung könnt ihr noch Anpassungen vornehmen, das wird im folgenden noch erklärt.<br /><br />
        Außerdem könnt ihr noch festlegen, ob ihr per Email oder auch per Push Alert (direkt am Handy) über Aktivitäten in einer Fitnessgruppe benachrichtigt werden wollt.<br />
        (Apple Benutzer können leider keine Push Alerts erhalten).<br /><br />
        </div>
        var groupstext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Nun kommen wir zum eigentlichen Training.<br />
        Wenn ihr im Menü auf <i>Online Training</i> klickt seht ihr eine Übersicht über die Gruppen, in denen ihr trainieren könnt.<br />
        Klickt auf die gewünschte Gruppe um diese zu betreten.<br /><br />
        Um euch gegenseitig motivieren zu können gibt es verschiedene Trainingsgruppen für Mitglieder die sich kennen.<br />
        Ihr könnt da euere Trainingsergebnisse veröffentlichen (wenn ihr wollt) und euch Nachrichten schicken.<br /><br />
        </div>
        var workouttext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Klickt euere gewünschte Gruppe an, und ihr kommt auf die Übersichtsseite mit den aktuellen Trainingseinheiten.<br /><br />
        Hier könnt ihr über die Pfeiltasten auch in den Nachrichtenbereich wechseln, und euch die Gruppenmitglieder ansehen.<br /><br />
        </div>
        var previewtext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Um euch auf eine Einheit vorzubereiten könnt ihr auf <i>Vorschau</i> klicken.<br />
       Alle Einheiten sind in mehrere Durchgänge mit jeweils mehreren Übungen aufgeteilt.<br />
       Das bedeutet, dass in jedem Durchgang bestimmte Übungen in der selben Reihenfolge ausgeführt werden.<br />
       Die Übungen selbst können sich in den Durchgängen in verschiedenen Varianten unterscheiden.<br /><br />
       Beispiel: Eine Einheit besteht aus 2 Durchgängen mit 4 Übungen.<br />
       Die Übungen sind Unterarmstütz, Situps, Ausfallschritt und Squats.<br />
       Im ersten Durchgang macht ihr statischen Unterarmsütz, Full Situps, Ausfallschritte vorwärts und normale Squats.<br />
       Im zweiten Durchgang macht ihr Unterarmsütz mit Drehung, Full Situps, Ausfallschritte rückwärts und normale Squats.<br /><br />
       Dies könnt ihr in der Vorschau sehen.<br /><br />
        </div>
        var preparetext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Wenn ihr bereit seid für die Einheit könnt ihr mit <i>Workout starten</i> die Einheit beginnen.<br />
       Zunächst könnt ihr genau einstellen, wie intensiv ihr trainieren wollt.<br />
       Ihr könnt für jede Übung (Beispiel: Unterarmstütz, Situps, Ausfallschritt und Squats) einstellen, welches Level ihr trainieren wollt.<br />
       Voreingestellt ist für alle Übungen euer Fitnesslevel, das ihr im Profil eingestellt habt.<br />
       Je nach dem wie ihr euch fühlt und wie ihr die einzelnen Übungen beherrscht könnt ihr hier die Intensität anpassen.<br />
       (Zum Beispiel Unteramrmstütz Stufe 4, Squats aber nur Stufe 2)<br />
       Die Stufe wird dann "übersetzt" in eine Anzahl oder eine Zeit<br /><br />
       Ihr könnt wählen, ob ihr zwischen den einzelnen Übungen eine Pause haben wollt, in der ihr eine Vorschau auf die kommende Übung seht.<br />
       Außerdem könnt ihr entscheiden, ob ihr mit einem kleinen Aufwärmprogramm starten wollt, und ob ihr zum Ende hin eine Cool Down Phase durchführen wollt.<br />
       Beides ist unabhängig voneinander optional wählbar.<br /><br />
        </div>
        var prestarttext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Sobald ihr die passenden Einstellungen gefunden habt könnt ihr mit <i>Workout starten</i> die Level bestätigen,<br />
    und ihr könnt euch nochmals sammeln.<br /><br />
    Wenn ihr bereit seid geht es mit <i>Training starten</i> los!<br /><br />
        </div>
        var prestarttext2 = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Wenn ihr kein Warmup gewählt habt seht ihr nun die Vorschau zur ersten Übung!<br /><br />
        </div>
        var executetext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Die erste Übung erscheint nun, ihr könnt während der Ausführung zwischen den Details <i>Video</i>, <i>Beschribung</i> und <i>Bewertung</i> wechseln. Zudem wird euere Trainingszeit angezeigt.<br />
            Im oberen Bereich seht, wie weit ihr im Rahmen des Gesamtworkouts gekommen seid.<br /><br />
    Ihr führt die Übungen wie beschrieben durch, so oft oder so lange wie es gefordert ist.<br /><br />
    Bei Übungen, die nach Zeit duerchgeführt werden, erhaltet ihr ein akkustisches Signal, wenn ihr fertig seid.<br />
    Sollte zur Hälfte der Zeit ein Seitenwechsel nötig sein, so wird das ebenfalls per akkustischem Signal angekündigt!<br /><br />
    Wichtig: nicht die Geschwindigkeit ist entscheidend, sondern die saubere Ausführung!<br />
    Nur so ist das Training effektiv!<br /><br />
        </div>
        var pausetext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Wenn ihr mit der Übung fertig seid klickt ihr auf <i>Übung fertiggestellt</i>.<br /><br />
    Sofern ihr Pausen aktiviert habt, habt ihre nun eine Verschaufpause. Nehmt euch ruhig die Zeit, um für die nächste Übung bereit zu sein.<br />
    Wenn ihr wieder bereit seid, könnte ihr mit <i>Nächste Übung</i> mit der nächsten Übung fortfahren.<br /><br />
    Ihr könnt das Training hier auch mit <i>Abbrechen</i> jederzeit abbrechen.<br />
            <b>Achtung</b>: Die Ergebnisse werden dann nicht gespeichert!<br />
        </div>
        var finishtext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            In unserer Beispiel-Einheit wiederholen sich diese Schritte 8 mal, 4 Übungen in 2 Durchgängen.<br />
    Wenn ihr alle Übungen ausgeführt habt seht ihr den Trainingsabschluss, wo ihr euere Ergebnisse speichern könnt.<br /><br />
    Hier könnt ihr festlegen, ob die anderen Gruppenmitglieder euere Ergebnisse sehen können.<br /><br />
        </div>
        var summarytext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Wir wünschen euch jetzt viel Spass beim Trainieren. Gnaaaz und Falti sind in jeder Gruppe Mitglied, so dass ihr euch direkt über den Nachrichtenbereich an uns wenden könnt,<br />
    falls ihr Probleme habt oder es Verbesserungsvorschläge gibt. <br /><br />
    Wir freuen uns sehr dass ihr mitmacht!
    <br /><br />
        </div>

        if (step === 1) {
            return (<div>
                {introtext}
                <img alt="Start" src="./assets/help/startseite.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 2) {
            return (<div>
                {settingstext}
                <img alt="Start" src="./assets/help/profile_settings_onlinetraining.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 3) {
            return (<div>
                {groupstext}
                <img alt="Gruppen" src="./assets/help/online_training_gruppen.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 4) {
            return (<div>
                {workouttext}
                <img alt="Einheiten" src="./assets/help/online_training_workouts.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 5) {
            return (<div>
                {previewtext}
                <img alt="Vorschau" src="./assets/help/online_training_workouts_preview.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 6) {
            return (<div>
                {preparetext}
                <img alt="Vorbereitung" src="./assets/help/online_training_workouts_prepare.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 7) {
            return (<div>
                {prestarttext}
                <img alt="PreStart" src="./assets/help/online_training_workouts_prestart.png" className={classes.screenshot} />
                {prestarttext2}
                <img alt="PreStart2" src="./assets/help/online_training_workouts_prestart_nowarmup.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 8) {
            return (<div>
                {executetext}
                <img alt="Ausführung" src="./assets/help/online_training_workouts_execute.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 9) {
            return (<div>
                {pausetext}
                <img alt="Pause" src="./assets/help/online_training_workouts_pause.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 10) {
            return (<div>
                {finishtext}
                <img alt="Ende" src="./assets/help/online_training_workouts_finish.png" className={classes.screenshot} />
            </div>)
        }
        if (step === 11) {
            return (<div>
                {summarytext}
            </div>)
        }
        return <div></div>
    }
}

export default withStyles(useStyles)(WorkoutHelp);