import firebase from 'firebase';
import { FirebaseStorage } from "./database";
// import { FirebaseMessaging } from "./fcm";
import { RequestMethods } from "./request";

class FA {

  currentUser = null;
  profile = null;

  register(email, password, profile) {
    var that = this;
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(() => {
        that.currentUser = firebase.auth().currentUser;
        that.CreateProfile(profile);
        that.currentUser.sendEmailVerification().then(function () {
          console.log("Email sent");
          that.OnRegistrationCompleted({
            "success": true,
            "message": ""
          })
        }).catch(function (error) {
          that.OnRegistrationCompleted({
            "success": false,
            "message": error.message
          })
        });
      }).catch((_error) => {
        console.log("Login Failed!", _error);
        if (_error.code === 'auth/weak-password') {
          that.OnRegistrationCompleted({
            "success": false,
            "message": 'Passwort zu schwach. das Passwort muss aus mindestens 6 Zeichen bestehen!'
          });
        }
        else {
          that.OnRegistrationCompleted({
            "success": false,
            "message": _error.message
          });
        }
      });

  }

  OnRegistrationCompleted(info) {

  }

  OnPasswordResetCompleted(info) {

  }

  resetPassword(email) {
    var that = this;
    firebase.auth().sendPasswordResetEmail(email).then(function () {
      that.OnPasswordResetCompleted("Success");
    }).catch(function (error) {
      that.OnPasswordResetCompleted(error.code);
    });
  }

  login(email, password) {
    var that = this;
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((authData) => {
        that.currentUser = firebase.auth().currentUser;
        if (that.currentUser) {
          if (that.currentUser.emailVerified) {
            this.OnLoginCompleted({
              "success": true,
              "message": ""
            });
          }
          else {
            this.OnLoginCompleted({
              "success": true,
              "message": ""
            });
          }
        }
        else {
          this.OnLoginFailed({
            "success": false,
            "message": "Unbekannter Fehler beim Login"
          });
        }
      }).catch((error) => {
        if (error.code === 'auth/wrong-password') {
          this.OnLoginFailed({
            "success": false,
            "message": "Falsches Passwort"
          });
        }
        else {
          this.OnLoginFailed({
            "success": false,
            "message": "Anmeldung fehlgeschlagen... " + error.message
          });
        }
      });
  }

  OnLoginCompleted(info) {

  }

  OnLoginFailed(info) {

  }

  logoff() {
    var that = this;
    firebase.auth().signOut().then(function () {
      that.currentUser = firebase.auth().currentUser;
      that.OnLogoffCompleted({
        "success": true,
        "message": ""
      });
    }).catch(function (error) {
      that.currentUser = firebase.auth().currentUser;
      that.OnLogoffCompleted({
        "success": false,
        "message": error.message
      });
    });
  }

  OnLogoffCompleted(info) {

  }

  Init() {
    var that = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        that.currentUser = user;
        that.LoadProfile(() => {
          that.OnInitCompleted({
            "success": true,
            "message": ""
          });
        });
      } else {
        that.OnInitCompleted({
          "success": true,
          "message": ""
        });
      }
    });
  }

  OnInitCompleted(info) {

  }

  CreateProfile(profile) {
    var that = this;
    if (RequestMethods.getQueryVariable("_notifyToken") !== "") {
      if (!profile.hasOwnProperty('AppToken')) {
        profile.AppToken = [];
      }
      if (!profile.AppToken.includes(RequestMethods.getQueryVariable("_notifyToken"))) {
        profile.AppToken.push(RequestMethods.getQueryVariable("_notifyToken"));
      }
    }
    firebase.auth().currentUser.getIdToken().then((idToken) => {
      profile.Token = idToken;
      profile.Email = that.currentUser.email;
      FirebaseStorage.updateObject("users", that.currentUser.uid, profile);
      that.LoadProfile(() => { });
    }, (error) => {
      console.log('Error creating profile');
    });
  }

  UpdateProfile(p, fn) {
    var that = this;
    this.currentUser.getIdToken().then((idToken) => {
      p.Token = idToken;
      p.Email = that.currentUser.email;
      p.betauser = that.profile.betauser;
      FirebaseStorage.updateObject("users", that.currentUser.uid, p);
      that.profile = p;
      fn("Profil erfolgreich gespeichert", "");
    }, (error) => {
      console.log('Error creating profile');
      fn("", "Fehler beim Speichern des Profils");
    });
  }

  ReloadProfile() {
    var that = this;
    this.currentUser.getIdToken().then((idToken) => {
      that.profile.Token = idToken;
      that.profile.Email = that.currentUser.email;
      FirebaseStorage.updateObject("users", that.currentUser.uid, that.profile);
    }, (error) => {
      console.log('Error creating profile');
    });
  }

  LoadProfile(fn) {
    var that = this;
    FirebaseStorage.loadObject("users", that.currentUser.uid, (p) => {
      that.profile = p.val();

      if (that.profile !== null) {
        var doReload = false;
        // if (!that.profile.hasOwnProperty('pushObjects')) {
        //   that.profile.pushObjects = [];
        // }
        if (!that.profile.hasOwnProperty('AppToken')) {
          that.profile.AppToken = [];
        }
        if (!that.profile.hasOwnProperty('onlinetraining')) {
          that.profile.onlinetraining = {
            fitnesslevel: 3,
            pushalertongroupmessage: true,
            emailalertongroupmessage: false
          };
          doReload = true;
        }
        if (!(that.profile.Email)) {
          doReload = true;
        }
        if (RequestMethods.getQueryVariable("_notifyToken") !== "") {          
          if (!that.profile.AppToken.includes(RequestMethods.getQueryVariable("_notifyToken"))) {
            that.profile.AppToken.push(RequestMethods.getQueryVariable("_notifyToken"));
            doReload = true;
          }
        }
        //if (FirebaseMessaging.currentToken !== "") {
          // if (!that.profile.pushObjects.includes(FirebaseMessaging.currentToken)) {
          //   that.profile.pushObjects.push(FirebaseMessaging.currentToken);
          //   doReload = true;
          // }
        //}        
        
        if (doReload) {
          that.ReloadProfile();
        }
      }
      if (fn !== null) {
        fn(p.val());
      }
    });
  }
}

let FirebaseAuth = new FA();

export { FirebaseAuth };