import { FirebaseStorage } from '../../lib/database';

export class NewsData {
    loadAllNews(fn){
        FirebaseStorage.loadAllComplete("flashnews", fn);        
    }

    insertNews(news) {
        FirebaseStorage.pushObject("flashnews", news)
    }
} 