import { FirebaseStorage } from '../../lib/database';

export class CourseData {

    insertExerciseGroup(group) {
        return FirebaseStorage.pushObjectWithId("courses/exercisegroup", group);
    }

    updateExerciseGroup(group, key) {
        FirebaseStorage.updateObject("courses/exercisegroup", key, group);
    }

    loadExerciseGroups(fn) {
        FirebaseStorage.loadAllComplete("courses/exercisegroup", (groups) => {
            fn(groups);
        });
    }

    loadExerciseGroupByName(name, fn) {
        FirebaseStorage.filterList("courses/exercisegroup", "name", name, (groups) => {
            groups.forEach(function (g) {
                fn(g);
            });
        });
    }

    loadExerciseByNames(groupname, exercisename, fn) {
        FirebaseStorage.filterList("courses/exercisegroup", "name", groupname, (groups) => {
            groups.forEach(function (g) {
                FirebaseStorage.filterList("courses/exercisegroup/" + g.key + "/excercises", "name", exercisename, (exercise) => {
                    exercise.forEach(function (e) {
                        fn(e);
                    });
                });
            });
        });
    }

    loadExercisesById(groupid, fn) {
        var coll = "courses/exercisegroup/" + groupid + "/exercises";
        FirebaseStorage.loadAllComplete(coll, (exercises) => {
            fn(exercises, groupid);
        });
    }

    loadExerciseByIds(groupid, exerciseid, fn) {
        FirebaseStorage.loadObject("courses/exercisegroup/" + groupid + "/exercises", exerciseid, (exercise) => {
            fn(exercise);
        });
    }

    loadExerciseGroupById(groupid, fn) {
        FirebaseStorage.loadObject("courses/exercisegroup", groupid, (g) => {
            fn(g);
        });
    }

    insertWorkoutExecution(workoutexec, user, message, isWorkout) {
        for (var i = 0; i < workoutexec.drills.length; i++) {
            workoutexec.drills[i].info.excercises = {};
            workoutexec.drills[i].info.exercises = {};
            workoutexec.drills[i].info.info = {};
            workoutexec.drills[i].info.information = [];
            for (var j = 0; j < workoutexec.drills[i].exercises.length; j++) {
                workoutexec.drills[i].exercises[j].info.description = [];
                workoutexec.drills[i].exercises[j].info.images = [];
                workoutexec.drills[i].exercises[j].info.video = {};
                workoutexec.drills[i].exercises[j].info.info = {};
            }
        }
        if (workoutexec.warmup !== undefined && workoutexec.warmup !== null) {
            for (var i = 0; i < workoutexec.warmup.drills.length; i++) {
                workoutexec.warmup.drills[i].info.excercises = {};
                workoutexec.warmup.drills[i].info.exercises = {};
                workoutexec.warmup.drills[i].info.information = [];
                for (var j = 0; j < workoutexec.warmup.drills[i].exercises.length; j++) {
                    workoutexec.warmup.drills[i].exercises[j].info.description = [];
                    workoutexec.warmup.drills[i].exercises[j].info.images = [];
                    workoutexec.warmup.drills[i].exercises[j].info.video = {};
                    workoutexec.warmup.drills[i].exercises[j].info.info = {};
                }
            }
        }
        if (workoutexec.cooldown !== undefined && workoutexec.cooldown !== null) {
            for (var i = 0; i < workoutexec.cooldown.drills.length; i++) {
                workoutexec.cooldown.drills[i].info.excercises = {};
                workoutexec.cooldown.drills[i].info.exercises = {};
                workoutexec.cooldown.drills[i].info.information = [];
                for (var j = 0; j < workoutexec.cooldown.drills[i].exercises.length; j++) {
                    workoutexec.cooldown.drills[i].exercises[j].info.description = [];
                    workoutexec.cooldown.drills[i].exercises[j].info.images = [];
                    workoutexec.cooldown.drills[i].exercises[j].info.video = {};
                    workoutexec.cooldown.drills[i].exercises[j].info.info = {};
                }
            }
        }
        var execid = FirebaseStorage.pushObjectWithId("courses/workoutexecutions", workoutexec);
        var ts = Date.now();
        var messageitem = {
            groupid: workoutexec.groupid,
            userid: user,
            message: message,
            timestamp: ts,
            workout: isWorkout,
            executionid: execid
        };
        FirebaseStorage.pushObject("courses/groupmessages", messageitem);
    }

    insertGroupWorkout(groupworkout) {
        FirebaseStorage.pushObject("courses/groupworkouts", groupworkout);
    }

    updateGroupWorkout(key, groupworkout) {
        FirebaseStorage.updateObject("courses/groupworkouts", key, groupworkout);
    }

    insertExercise(exercise, groupid) {
        return FirebaseStorage.pushObjectWithId("courses/exercisegroup/" + groupid + "/exercises", exercise);
    }

    insertGroup(group) {
        return FirebaseStorage.pushObjectWithId("courses/groups", group);
    }

    updateGroup(group, key) {
        FirebaseStorage.updateObject("courses/groups", key, group);
    }

    insertChallenge(groupid, type, subtype, start, end, information) {
        var challenge = {
            groupid: groupid,
            type: type,
            subtype: subtype,
            start: start,
            end: end,
            information: information
        };
        FirebaseStorage.pushObject("courses/challenges", challenge);
    }

    loadGroup(name, fn) {
        FirebaseStorage.filterList("courses/groups", "name", name, (groups) => {
            groups.forEach(function (g) {
                fn(g);
            });
        });
    }

    loadChallenges(groupid, currentonly, fn) {
        FirebaseStorage.filterList("courses/challenges", "groupid", groupid, (ch) => {
            var challenges = {};
            var now = Date.now;
            ch.forEach(function (c) {
                var challenge = c.val();
                if (!currentonly || (challenge.start < now && challenge.end > now)) {
                    challenges[c.key] = challenge;
                }
            });
            fn(challenges);
        });
    }

    loadUserStatistics(userid, fn) {
        var ret = { };
        FirebaseStorage.filterList("courses/workoutexecutions", "userid", userid, (execs) => {
            
            execs.forEach(function (exec) {
                var execution = exec.val();
                var startdate = new Date(execution.drills[0].exercises[0].start);
                var ym = startdate.getFullYear() + "_" + startdate.getMonth();
                if (ret[ym] === undefined) {
                    ret[ym] = {
                        exercises: { },
                        workouttimes: [],
                        completetime: 0
                    };
                }                                
                var s = execution.drills[0].exercises[0].start;
                var e = execution.drills[execution.drills.length - 1].exercises[execution.exercises - 1].end;
                ret[ym].workouttimes.push({
                    name: execution.name,
                    start: s,
                    end: e
                });
                ret[ym].completetime += (e - s);
            

                for (var i = 0; i < execution.drills.length; i++) {
                    var level = execution.drills[i].executionlevel;
                    var number = execution.drills[i].info.levels[level];   
                    var powerindex = 0;                 
                    //var powerindex = execution.drills[i].exercises[j].info.weights.power * number;
                    var unit = execution.drills[i].info.unit;
                    for (var j = 0; j < execution.drills[i].exercises.length; j++) {                         
                        var id = execution.drills[i].exercises[j].id;
                        var name = execution.drills[i].exercises[j].info.name;
                        if (ret[ym].exercises[id] === undefined) {
                            var info = {
                                number: 0,
                                index: {
                                    power: powerindex
                                },
                                name: name,
                                unit: unit,
                                times: []
                            };
                            ret[ym].exercises[id] = info;
                        }
                        ret[ym].exercises[id].number += parseInt(number);
                    }
                }                
            });
            fn(ret);
        });
    }

    loadChallengeIndexChart(challengeid, groupid, subtype, start, end, fn) {
        var ret = {
            groupid: groupid,
            challengeid: challengeid,
            list: {}
        };
        FirebaseStorage.filterList("courses/workoutexecutions", "groupid", groupid, (execs) => {
            execs.forEach(function (exec) {
                var execution = exec.val();
                if (ret.list[execution.userid] === undefined) {
                    var info = {
                        created: true,
                        name: execution.userid,
                        index: 0,
                        details: {}
                    };
                    ret.list[execution.userid] = info;                    
                }
                ret.list[execution.userid].details[execution.drills[0].exercises[0].start] = [];
                for (var i = 0; i < execution.drills.length; i++) {
                    var level = execution.drills[i].executionlevel;
                    var number = execution.drills[i].info.levels[level];
                    var unit = execution.drills[i].info.unit;
                    for (var j = 0; j < execution.drills[i].exercises.length; j++) {
                        if (execution.drills[i].exercises[j].start > start &&
                            execution.drills[i].exercises[j].start < end) {
                            var multiplier = 0;
                            if (execution.drills[i].exercises[j].info.weights) {
                                multiplier = (subtype === "power" && execution.drills[i].exercises[j].info.weights.power) ? execution.drills[i].exercises[j].info.weights.power
                                    : (subtype === "power_endurance" && execution.drills[i].exercises[j].info.weights.power && execution.drills[i].exercises[j].info.weights.endurance) ? (execution.drills[i].exercises[j].info.weights.power + execution.drills[i].exercises[j].info.weights.endurance)
                                        : (subtype === "endurance" && execution.drills[i].exercises[j].info.weights.endurance) ? execution.drills[i].exercises[j].info.weights.endurance
                                            : (subtype === "stability" && execution.drills[i].exercises[j].info.weights.stability) ? execution.drills[i].exercises[j].info.weights.stability
                                                : (subtype === "mobility" && execution.drills[i].exercises[j].info.weights.mobility) ? execution.drills[i].exercises[j].info.weights.mobility
                                                    : 1;
                            }
                            if (unit === "Anzahl") {
                                ret.list[execution.userid].index += number * multiplier;
                                ret.list[execution.userid].details[execution.drills[0].exercises[0].start].push({
                                    start: execution.drills[i].exercises[j].start,
                                    end: execution.drills[i].exercises[j].end,
                                    unit: unit,
                                    number: number,
                                    multiplier: multiplier,
                                    divider: 1,
                                    calculation: number + " " + "Stück" + " * " + multiplier + " = " + (number * multiplier),
                                    exercise: execution.drills[i].exercises[j].info.name
                                });
                            }
                            if (unit === "Sekunden") {
                                ret.list[execution.userid].index += number * multiplier;
                                ret.list[execution.userid].details[execution.drills[0].exercises[0].start].push({
                                    start: execution.drills[i].exercises[j].start,
                                    end: execution.drills[i].exercises[j].end,
                                    unit: unit,
                                    number: number,
                                    multiplier: multiplier,
                                    divider: 1,
                                    calculation: number + " " + "s" + " * " + multiplier + " = " + (number * multiplier),
                                    exercise: execution.drills[i].exercises[j].info.name
                                });
                            }
                            if (unit === "Distanz") {
                                ret.list[execution.userid].index += number * multiplier / 10;
                                ret.list[execution.userid].details[execution.drills[0].exercises[0].start].push({
                                    start: execution.drills[i].exercises[j].start,
                                    end: execution.drills[i].exercises[j].end,
                                    unit: unit,
                                    number: number,
                                    multiplier: multiplier,
                                    divider: 10,
                                    calculation: number + " " + "s" + " * " + multiplier + " / 10 = " + (number * multiplier  / 10),
                                    exercise: execution.drills[i].exercises[j].info.name
                                });
                            }
                        }
                    }
                }
            });
            fn(ret);
        });
    }

    loadWorkoutExecution(id, k, fn) {
        FirebaseStorage.loadObject("courses/workoutexecutions", id, (ex) => {
            fn(ex, k);
        });
    }

    loadGroupById(groupid, fn) {
        FirebaseStorage.loadObject("courses/groups", groupid, (g) => {
            var gr = g.val();
            fn(gr);
        });
    }

    loadGroups(fn) {
        FirebaseStorage.loadAllComplete("courses/groups", (groups) => {
            fn(groups);
        });
    }

    loadUserGroups(uid, fn) {
        FirebaseStorage.loadAllComplete("courses/groups", (groups) => {
            var usergroups = [];
            var gcount = 0;
            var targetCount = 0;
            groups.forEach(function (g) {
                targetCount++;
            });
            groups.forEach(function (g) {
                var group = g.val();
                if (!group.disabled) {                    
                    FirebaseStorage.filterList("courses/groupmembers", "groupid", g.key, (groupmembers) => {
                        gcount = gcount + 1;
                        groupmembers.forEach(gm => {
                            var groupmember = gm.val();
                            if (groupmember.userid === uid) {
                                usergroups.push(g);
                            }
                        });                      
                        if (gcount === targetCount) {
                            fn(usergroups);
                        }
                    });
                }
                else {
                    gcount = gcount + 1;
                    if (gcount === targetCount) {
                        fn(usergroups);
                    }
                }
            });            
        });
    }

    loadGroupUsers(groupid, fn) {
        FirebaseStorage.loadAllComplete("users", (users) => {
            var groupusers = [];
            FirebaseStorage.filterList("courses/groupmembers", "groupid", groupid, (groupmembers) => {
                groupmembers.forEach(gm => {
                    var groupmember = gm.val();
                    users.forEach((u) => {
                        if (groupmember.userid === u.key) {
                            groupusers.push(u);
                        }
                    });
                });
                fn(groupusers);
            });
        });
    }

    assignUserToGroup(user, groupid, fn) {
        FirebaseStorage.filterList("courses/groupmembers", "groupid", groupid, (groupmembers) => {
            var assigned = false;
            groupmembers.forEach(gm => {
                var groupmember = gm.val();
                if (groupmember.userid === user.key) {
                    assigned = true;
                }
            });
            if (assigned) {
                fn("Benutzer schon zugeordnet");
            }
            else {
                var groupmember = {
                    groupid: groupid,
                    userid: user.key
                }
                FirebaseStorage.pushObject("courses/groupmembers", groupmember);
                fn("Benutzer hinzugefügt");

            }
        });
    }

    assignWorkoutToGroup(workoutid, groupid, start, end) {
        var assignment = {
            group: groupid,
            workout: workoutid,
            interval: {
                start: start,
                end: end
            }
        };
        FirebaseStorage.pushObject("courses/groupworkoutassignment", assignment);
    }

    addMessage(groupid, user, message, isWorkout, fn) {
        var ts = Date.now();
        var messageitem = {
            groupid: groupid,
            userid: user,
            message: message,
            timestamp: ts,
            workout: isWorkout
        };
        FirebaseStorage.pushObject("courses/groupmessages", messageitem);
        fn("Nachricht hinzugefügt");
    }

    loadGroupMessages(groupid, start, fn) {
        FirebaseStorage.filterList("courses/groupmessages", "groupid", groupid, (msg) => {
            var messages = [];
            msg.forEach(function (m) {
                var mVal = m.val();
                if (mVal.timestamp > start) {
                    messages.push(m);
                }
            });
            fn(messages);
        });        
    }

    listenGroupMessages(fn) {
        FirebaseStorage.listenAdd("courses/groupmessages", fn);
    }

    loadAllWorkouts(fn) {
        var ret = {};

        FirebaseStorage.loadAllComplete("courses/groups", (g) => {
            FirebaseStorage.loadAllComplete("courses/exercisegroup", (egs) => {
                FirebaseStorage.loadAllComplete("courses/groupworkouts", (gws) => {
                    gws.forEach((gw) => {
                        var groupworkout = gw.val();
                        ret[gw.key] = {
                            drills: {},
                            name: groupworkout.name,
                            warmup: groupworkout.warmup,
                            cooldown: groupworkout.cooldown
                        };
                        g.forEach((group) => {
                            if (group.key === groupworkout.groupid) {
                                ret[gw.key].group = group.val().name;
                            }
                        });
                        groupworkout.drills.forEach((d) => {
                            egs.forEach((eg) => {
                                if (eg.key === d.group) {
                                    var exercisegroup = eg.val();
                                    ret[gw.key].drills[d.group] = {
                                        group: eg.key,
                                        name: exercisegroup.name,
                                        exercises: [],
                                        exerciseObjects: []
                                    };
                                    var exckey = Object.keys(exercisegroup.exercises);
                                    for (var i = 0; i < d.exercises.length; i++) {
                                        for (var j = 0; j < exckey.length; j++) {
                                            if (d.exercises[i] === exckey[j]) {
                                                ret[gw.key].drills[d.group].exercises.push(d.exercises[i]);
                                                ret[gw.key].drills[d.group].exerciseObjects.push(exercisegroup.exercises[exckey[j]]);
                                            }
                                        }
                                    }
                                }
                            });
                        });
                    });
                    fn(ret);
                });
            });
        });
    }

    loadWorkouts(fn) {
        FirebaseStorage.loadAllComplete("courses/groupworkouts", (gws) => {
            var ret = {};
            gws.forEach(g => {
                ret[g.key] = g.val();
            })
            fn(ret);
        });
    }

    loadWarmupWorkouts(fn) {
        FirebaseStorage.filterList("courses/groupworkouts", "warmup", true, (gws) => {
            var ret = {};
            gws.forEach(g => {
                ret[g.key] = g.val();
            })
            fn(ret);
        });
    }

    loadCooldownWorkouts(fn) {
        FirebaseStorage.filterList("courses/groupworkouts", "cooldown", true, (gws) => {
            var ret = {};
            gws.forEach(g => {
                ret[g.key] = g.val();
            })
            fn(ret);
        });
    }

    convertWorkout(groupid, gw, defaultlevel, uid, fn) {
        var that = this;
        var drills = [];
        for (var i0 = 0; i0 < gw.drills.length; i0++) {
            var exercises = [];
            for (var j0 = 0; j0 < gw.drills[i0].exercises.length; j0++) {
                exercises.push({
                    id: gw.drills[i0].exercises[j0],
                    info: null,
                    start: null,
                    end: null
                });
            }
            drills.push({
                id: gw.drills[i0].group,
                info: null,
                exercises: exercises,
                executionlevel: defaultlevel
            });
        }
        var wt = gw.workouttype === undefined ? 0 : gw.workouttype;
        var workoutinfo = {
            name: gw.name,
            sets: gw.drills.length,
            exercises: (gw.drills.length > 0) ? gw.drills[0].exercises.length : 0,
            group: null,
            drills: drills,
            publish: false,
            groupid: groupid,
            userid: uid,
            usePause: true,
            workouttype: wt
        };
        var expecteddrilcnt = gw.drills.length;
        var expectedexccnt = 0;
        for (var i = 0; i < gw.drills.length; i++) {
            expectedexccnt += gw.drills[i].exercises.length;
        }
        var drilcnt = 0;
        var excnt = 0;
        that.loadGroupById(groupid, (g) => {
            workoutinfo.group = g;
            for (var i = 0; i < gw.drills.length; i++) {
                for (var j = 0; j < gw.drills[i].exercises.length; j++) {
                    that.loadExerciseByIds(gw.drills[i].group, gw.drills[i].exercises[j], (ex) => {
                        for (var l = 0; l < workoutinfo.drills.length; l++) {
                            for (var m = 0; m < workoutinfo.drills[l].exercises.length; m++) {
                                if (workoutinfo.drills[l].exercises[m].id === ex.key) {
                                    workoutinfo.drills[l].exercises[m].info = ex.val();
                                }
                            }
                        }
                        excnt++;
                        if (excnt === expectedexccnt && drilcnt === expecteddrilcnt) {
                            fn(workoutinfo);
                        }
                    });
                }
                that.loadExerciseGroupById(gw.drills[i].group, (exgroup) => {
                    for (var k = 0; k < workoutinfo.drills.length; k++) {
                        if (workoutinfo.drills[k].id === exgroup.key) {
                            workoutinfo.drills[k].info = exgroup.val();
                        }
                    }
                    drilcnt++;
                    if (excnt === expectedexccnt && drilcnt === expecteddrilcnt) {
                        fn(workoutinfo);
                    }
                });
            }
        });
    }

    loadWorkout(id, fn) {
        FirebaseStorage.loadObject("courses/groupworkouts", id, (g) => {
            var gr = g.val();
            fn(gr);
        });
    }

    loadCurrentGroupWorkouts(groupid, fn) {
        FirebaseStorage.filterList("courses/groupworkoutassignment", "group", groupid, (w) => {
            var now = Date.now();
            var groupworkouts = {};
            var workouts = [];
            w.forEach(function (gw) {
                var gwass = gw.val();
                if (now >= gwass.interval.start && now <= gwass.interval.end) {
                    workouts.push(gwass);
                }
            });
            var tobeloaded = workouts.length;
            if (tobeloaded === 0) {
                fn(groupworkouts);
            }
            else {
                var loaded = 0;
                workouts.forEach(function (w) {
                    FirebaseStorage.loadObject("courses/groupworkouts", w.workout, (gw) => {
                        var gwVal = gw.val();
                        gwVal.interval = w.interval;
                        gwVal.groupid = w.group;
                        groupworkouts[gw.key] = gwVal;
                        loaded++;
                        if (loaded === tobeloaded) {
                            fn(groupworkouts);
                        }
                    });
                });
            }
        });
    }

    loadAllGroupWorkouts(groupid, fn) {
        FirebaseStorage.filterList("courses/groupworkoutassignment", "group", groupid, (w) => {
            var groupworkouts = {};
            w.forEach(function (gw) {
                groupworkouts[gw.key] = gw.val();                
            });
            fn(groupworkouts);            
        });
    }
}