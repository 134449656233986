import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { OutlinedInput } from '@material-ui/core';
import defaultStyles from '../base'
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { FirebaseStorage } from '../../lib/database';
import { FirebaseAuth } from "../../lib/authentication";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EventData } from '../../controller/data/eventdata'
import Regex from '../../lib/regex';
import { RequestMethods } from "../../lib/request";
import dateformat from 'date-format';
import OsInfo from '../../lib/os';

var isIos = new OsInfo().isIos();
var fullwidth = (isIos) ? '100%' : '100%';

const additionalStyles = {
  monthheader: {
    marginTop: '24px',
    float: 'left',
    position: 'relative',
    fontFamily: 'Anton',
    color: '#FFFFFF',
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: '5%',
    marginRight: '5%',
    width: '90%'
  },
  spacer: {
    position: 'relative',
    float: 'left',
    width: '20px',
    height: '20px'
  },
  minsize: {
    position: 'relative',
    float: 'left',
    height: '10px'
  },
  cancelledimage: {
    position: 'relative',
    float: 'left',
    width: '60px',
    height: '20px',
    marginLeft: '5px',
    left: '0px',
    opacity: '1.0',
    color: '#00D2FF'
  },
  imagecancelledoverlay: {
    position: 'absolute',
    color: '#FFFFFF',
    fontSize: '36px',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class EventCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      overview: true,
      selected: -1,
      email: '',
      infotext: '',
      errortext: ''
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.timerID = -1;
    this.addcountoverview = 0;
    this.selectedevent = null;
    this.selectedeventtext = <div />;
    this.selectedeventtopics = <div />;
    this.times = <div />;
    this.emailinput = <div />;
    this.monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
      "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];
  }

  componentDidMount() {
    this.refreshState();

    this.timerID = setInterval(() => {
      if (!(this.state.errortext === "" && this.state.infotext === "")) {
        this.setState(state => ({
          errortext: "",
          infotext: ""
        }));
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  refreshState() {
    this.setState({
      events: []
    });
    var that = this;
    FirebaseStorage.loadAll("events", (wk) => {
      var newwks = that.state.events;
      newwks.push(wk);
      var selectedevent = "";
      if (RequestMethods.getQueryVariable("event") !== "") {
        selectedevent = RequestMethods.getQueryVariable("event");
      }
      if (that.props.selectedevent !== undefined && that.props.selectedevent !== "") {
        selectedevent = that.props.selectedevent;
      }
      if (selectedevent !== '' && wk.key === selectedevent) {
        that.setState({
          events: newwks,
          overview: false,
          selected: selectedevent
        });
      }
      else {
        that.setState({
          events: newwks
        });
      }
    });
  }

  handleClick(id) {
    this.setState(state => ({
      overview: false,
      selected: id
    }));
  }

  handleBack() {
    this.setState(state => ({
      overview: true,
      selected: -1
    }));
  }

  registerProfile(key, wk, index, that) {
    var eventdata = new EventData();
    eventdata.assignUser(key, index, FirebaseAuth.currentUser.uid);
    FirebaseStorage.loadAll("events", (wk) => {
      var newwks = that.state.events;
      newwks.push(wk);
      that.setState({
        events: newwks,
        infotext: "Erfolgreich angemeldet!"
      });
    });
  }

  unregisterProfile(key, wk, index, that) {

    var attKeys = Object.keys(wk.times[index].attendees);
    var delKey = "";
    attKeys.forEach((attKey) => {
      if (wk.times[index].attendees[attKey].key.includes(FirebaseAuth.currentUser.uid)) {
        delKey = attKey;
      }
    });
    if (delKey !== "") {
      var eventdata = new EventData();
      eventdata.unassign(key, index, delKey);
      FirebaseStorage.loadAll("events", (wk) => {
        var newwks = that.state.events;
        newwks.push(wk);
        that.setState({
          events: newwks,
          infotext: "Erfolgreich abgemeldet!"
        });
      });
    }
  }

  registerEmail(key, wk, index, that) {
    var error = "";
    if (that.state.email === "") {
      error = "Keine Emailadresse angegeben!"
    }
    if (!new Regex().validateEmail(that.state.email)) {
      error = "Keine gültige Emailadresse angegeben!"
    }
    var emailregistered = false;
    for (var i = 0; i < this.state.events.length; i++) {
      if (this.state.events[i].key === this.state.selected) {
        var selectedevent = this.state.events[i].val();
        if (selectedevent.times[index].attendees) {
          var attKeys = Object.keys(selectedevent.times[index].attendees);
          attKeys.forEach((attKey) => {
            if (selectedevent.times[index].attendees[attKey].email.toLowerCase() === that.state.email.toLowerCase()) {
              emailregistered = true;
            }
          });
        }
      }
    }
    if (emailregistered) {
      error = "Diese Email-Adresse ist bereits angemeldet!"
    }
    if (error === "") {
      var eventdata = new EventData();
      eventdata.assignEmail(key, index, that.state.email);
      FirebaseStorage.loadAllComplete("events", (evs) => {
        var newevs = [];
        evs.forEach(function (ev) {
          newevs.push(ev);
        });
        that.setState({
          events: newevs,
          infotext: that.state.email + " wurde erfolgreich registriert!"
        });
      });
    }
    else {
      this.setState(state => ({
        errortext: error
      }));
    }
  }

  textchange(e) {
    var val = e.target.value;
    this.setState(state => ({
      email: val.trim()
    }));

  }

  render() {
    const { classes } = this.props;
    var header = this.createHeader();
    this.addcountoverview = 0;
    var events =
      this.state.overview ? this.createOverviewCalendar()
        : this.createSelectedEvent();
    var bottompagespace = <div className={classes.bottompagespace} />;
    return (
      <Card className={classes.root}>
        {header}
        {events}
        {bottompagespace}
      </Card>
    );
  }

  createHeader() {
    const { classes } = this.props;
    var that = this;
    var header =
      this.state.overview ?
        (<div className={classes.containertitle}>
          <Typography className={classes.title} color="textSecondary">
            Events
          </Typography>
          <Divider variant="middle" classes={{ root: classes.divider }} /></div>)
        :
        (<div className={classes.containersubmenu}>
          <NavigateBeforeIcon className={classes.backbutton} fontSize="large" onClick={() => that.handleBack()} />
          <div className={classes.titlesubmenu} color="textPrimary">
            Events
          </div>
        </div>);
    return header;
  }

  addMonths(date, count) {
    if (date && count) {
      var m, d = (date = new Date(+date)).getDate()

      date.setMonth(date.getMonth() + count, 1)
      m = date.getMonth()
      date.setDate(d)
      if (date.getMonth() !== m) date.setDate(0)
    }
    return date
  }

  createOverviewCalendar() {
    const { classes } = this.props;
    var that = this;
    var arrPreviewMonths = [];
    var currentDate = new Date();
    for (var i0 = 0; i0 < 6; i0++) {
      arrPreviewMonths.push(i0);
    }
    var arrEvents = [];
    for (var i = 0; i < this.state.events.length; i++) {
      arrEvents.push(this.state.events[i].val());
    }
    this.addcountoverview = 1;
    var overview = (arrPreviewMonths.map(function (item) {
      var iterationDate = that.addMonths(currentDate, item);
      var countCurrentMonth = 0;
      var eventspermonth = (that.state.events.map(function (eventitem, key) {
        var event = eventitem.val();
        var add = false;
        var timesavailable = false;
        var cancelled = false;
        if (event.planned) {
          for (var j0 = 0; j0 < event.planned.length; j0++) {
            if (event.planned[j0].month === (iterationDate.getMonth() + 1) && event.planned[j0].year === iterationDate.getFullYear()) {
              add = true;
              if (event.planned[j0].cancelled && event.planned[j0].cancelled === 1) {
                cancelled = true;
              }
            }
          }
        }
        if (event.times) {
          for (var j = 0; j < event.times.length; j++) {
            if (event.times[j] != undefined && new Date(event.times[j].start).getMonth() === iterationDate.getMonth() && new Date(event.times[j].start).getFullYear() === iterationDate.getFullYear()) {
              timesavailable = true;
              add = true;
            }
          }
        }
        if (add) {
          countCurrentMonth++;
        }
        var cancelImage = (cancelled) ?
          (<div className={classes.imagecancelledoverlay}>
            <img id='eventimage'
              className={classes.imageHalfWidthUnTransparent}
              src='./assets/cancelled.png' alt="Abgesagt"
            />
          </div>) :
          (<div className={classes.imageHalfWidthUnTransparent} />);
        var buttontext = (timesavailable && !cancelled) ? "Termin buchen" : "Info zum geplanten Event"
        return ((add) ? (
          <div className={classes.textimagecontainerLeft} key={key}>
            <img id='eventimage'
              className={classes.imageHalfWidthUnTransparent}
              src={event.image}
              alt={event.title}
            />
            {cancelImage}
            <div className={classes.infolabelright} onClick={() => that.handleClick(eventitem.key)}>
              {event.title}
              <Button className={classes.buttonContentMoreFlex}>
                {buttontext}
              </Button>
            </div>
          </div>)
          :
          (<div className={classes.minsize} key={key} />))
      }));
      if (countCurrentMonth === 0) {
        eventspermonth = <div className={classes.smallteaserlabel}>Für diesen Monat sind derzeit keine Events geplant!</div>
      }
      return (<div key={item}>
        <div className={classes.monthheader}>{that.monthNames[iterationDate.getMonth()]} {iterationDate.getFullYear()}</div>
        {eventspermonth}
      </div>)

    }));
    return overview;
  }

  createOverview() {
    const { classes } = this.props;
    var that = this;
    var overview = (this.state.events.map(function (item) {
      var event = item.val();
      var add = false;
      if (event.continued && event.continued === 1) {
        add = true;
        that.addcountoverview++;
      }
      if (add === false) {
        for (var j = 0; j < event.times.length; j++) {
          if (new Date(event.times[j].start).getTime() > new Date().getTime()) {
            add = true;
            that.addcountoverview++;
          }
        }
      }
      var key = item.key;
      return ((add) ? (
        <div className={classes.containercontent} key={key}>
          <div className={classes.textimagecontainer}>
            <img id='eventimage'
              className={classes.imageFullWidthUnTransparent}
              src={event.image}
              alt={event.title}
            />
          </div>
          <div className={classes.submenuheader} color="textPrimary">
            {event.title}
          </div>
          <Button key={key} className={classes.buttonContentMore} onClick={() => that.handleClick(key)}>
            Mehr dazu
          </Button>
          <Divider variant="middle" classes={{ root: classes.dividerContent }} />
        </div>)
        :
        (<div className={classes.minsize} key={key} />))

    }));
    return overview;
  }

  fillSelectedEventContent() {
    const { classes } = this.props;
    var that = this;
    var link = <div />;
    var buttondisabled = false;
    var selectedkey = this.state.selected;
    var signedin = (FirebaseAuth.currentUser !== null && FirebaseAuth.currentUser.emailVerified === true);// && FirebaseAuth.profile !== null
    //var token = (signedin) ? FirebaseAuth.currentUser.uid : "";
    var cancelled = <div><img className={classes.cancelledimage} src='./assets/cancelled.png' alt="Abgesagt" /></div>

    for (var i = 0; i < this.state.events.length; i++) {
      if (this.state.events[i].key === this.state.selected) {
        that.selectedevent = this.state.events[i].val();
        that.selectedeventtext = (that.selectedevent.content.map(function (item, index) {
          return (<div key={index} className={classes.divinfocontainer}><div className={classes.divinfohead}>{item.topic}</div><div className={classes.divinfotext}>{item.info}</div></div>);
        }));

        if (that.selectedevent.keytopics) {
          that.selectedeventtopics = Object.entries(that.selectedevent.keytopics).map(([key, value]) => {
            var imgs = [];
            for (var i = 0; i < value; i++) {
              var k = 'img' + i;
              imgs.push(<img key={k}
                id={k}
                className={classes.kettlebellimage}
                src='./assets/kettlebell-solid.png'
                alt={i}
              />);
            }
            return (
              <div key={key} className={classes.divinfocontainer}><div className={classes.divinfohead}>{key}</div><div className={classes.divinfotext}>{imgs}</div></div>);
          });
        }

        var itemfound = false;
        that.times = (that.selectedevent.times) ? (that.selectedevent.times.map(function (item, index) {
          // if (new Date(item.start).getTime() > new Date().getTime()) {
          var inthepast = (new Date(item.start).getTime() < new Date().getTime());
          var completeddateinfo = dateformat.asString('dd.MM.yy', new Date(item.start)) + ' ' +
            dateformat.asString('hh:mm', new Date(item.start)) + ' - ' +
            dateformat.asString('hh:mm', new Date(item.end));
          itemfound = true;
          var booked = 0;
          if (item.attendees) {
            var attKeys = Object.keys(item.attendees);
            booked = attKeys.length;
          }
          var free = item.maxnumber - booked;
          var freetext = (free > 0) ? "Freie Plätze verfügbar" : "Ausgebucht";
          var pct = (booked / item.maxnumber) * 100;
          if (pct > 100) {
            pct = 100;
          }
          var progress = (item.cancelled && (item.cancelled === 1 || item.cancelled === true))
            ? (<div />)
            : (<div className={classes.trainingprogresscontainer}>
              {/* <LinearProgress className={classes.trainingprogress} variant="determinate" color="secondary" value={pct} /> */}
              <div className={classes.trainingprogresstext}>
                {freetext}
              </div>
            </div>);
          if (signedin) {
            var assigned = false;
            if (item.attendees) {
              var attKeys2 = Object.keys(item.attendees);
              attKeys2.forEach((attKey) => {
                if (item.attendees[attKey].key.includes(FirebaseAuth.currentUser.uid)) {
                  assigned = true;
                }
              });
            }
            if (inthepast) {
              return (<div key={selectedkey + item.start} className={classes.timesContainer}>
                <div className={classes.timesContainerInner}>
                  <div className={classes.training}>
                    {completeddateinfo}<br />{item.place}<br />{link}
                  </div>
                  Event abgeschlossen
                </div>
                <div className={classes.mainspacer} />
              </div>)
            }
            else if (assigned) {
              var icon = (<IconButton className={classes.trainingiconnoaction} size="small" >
                <CheckCircleIcon fontSize="small" alt="Angemeldet" />
              </IconButton>);
              var unregisterProfile = (<Button className={classes.trainingicontext} disabled={buttondisabled} size="small" onClick={() => that.unregisterProfile(selectedkey, that.selectedevent, index, that)}>
                Abmelden
              </Button>);
              link = (item.gmaps !== null && item.gmaps !== "") ? (<div>(<a href={item.gmaps} target="_blank" rel="noopener noreferrer">Google Maps Link</a>)</div>) : (<div />);
              return (<div key={selectedkey + item.start} className={classes.timesContainer}>
                <div className={classes.timesContainerInner}>
                  <div className={classes.training}>
                    {completeddateinfo}<br />{item.place}<br />{link}
                  </div>
                  {icon}
                  {unregisterProfile}
                </div>
                <div className={classes.timesContainerInner}>
                  {progress}
                </div>
                <div className={classes.mainspacer} />
              </div>
              )
            }
            else {
              var registerProfile = (item.cancelled && (item.cancelled === 1 || item.cancelled === true)) ? cancelled :
                (pct < 100) ?
                  (<Button className={classes.trainingicontext} disabled={buttondisabled} size="small" onClick={() => that.registerProfile(selectedkey, that.selectedevent, index, that)}>
                    Anmelden
                  </Button>)
                  :
                  <div />;
              link = (item.gmaps !== null && item.gmaps !== "") ? (<div>(<a href={item.gmaps} target="_blank" rel="noopener noreferrer">Google Maps Link</a>)</div>) : (<div />);
              return (<div key={selectedkey + item.start} className={classes.timesContainer}>
                <div className={classes.timesContainerInner}>
                  <div className={classes.training}>
                    {completeddateinfo}<br />{item.place}<br />{link}
                  </div>
                  {registerProfile}
                </div>
                <div className={classes.timesContainerInner}>
                  {progress}
                </div>
                <div className={classes.mainspacer} />
              </div>
              )
            }
          }
          else {
            if (inthepast) {
              return (<div key={selectedkey + item.start} className={classes.timesContainer}>
                <div className={classes.timesContainerInner}>
                  <div className={classes.training}>
                    {completeddateinfo}<br />{item.place}<br />{link}
                  </div>
                  Event abgeschlossen
                </div>
              </div>)
            }
            var registerEmail = (item.cancelled && (item.cancelled === 1 || item.cancelled === true)) ? cancelled :
              (pct < 100) ?
                (<Button className={classes.trainingicontext} size="small" disabled={buttondisabled} onClick={() => that.registerEmail(selectedkey, that.selectedevent, index, that)}>
                  Anmelden
                </Button>)
                :
                <div />;
            link = (item.gmaps !== null && item.gmaps !== "") ? (<div>(<a href={item.gmaps} target="_blank" rel="noopener noreferrer">Google Maps Link</a>)</div>) : (<div />);
            return (<div key={selectedkey + item.start} className={classes.timesContainer}>
              <div className={classes.timesContainerInner}>
                <div className={classes.training}>
                  {completeddateinfo}<br />{item.place}<br />{link}
                </div>
                {registerEmail}
              </div>
              <div className={classes.timesContainerInner}>
                {progress}
              </div>
              <div className={classes.mainspacer} />
            </div>
            )
          }
        })) : (<div></div>);
        if (itemfound === false) {
          that.times = <div />
          that.emailinput = <div />
        }
      }
    }
  }

  createSelectedEvent() {
    const { classes } = this.props;
    var that = this;
    var selectedkey = this.state.selected;
    var errortext = this.state.errortext;
    var infotext = this.state.infotext;
    var signedin = (FirebaseAuth.currentUser !== null && FirebaseAuth.currentUser.emailVerified === true);// && FirebaseAuth.profile !== null
    this.fillSelectedEventContent();
    that.emailinput = (signedin) ?
      (<div><div className={classes.infolabel}>{infotext}</div><div className={classes.errorlabel}>{errortext}</div></div>) :
      (that.selectedevent && that.selectedevent.times) ? (<div><div className={classes.emailloginlabel}>Bitte Email eingeben zur Anmeldung:</div><OutlinedInput className={classes.textbox} onChange={(e) => that.textchange(e)} /><div className={classes.infolabel}>{infotext}</div><div className={classes.errorlabel}>{errortext}</div></div>) : (<div></div>);
    var bottompagespace = <div className={classes.bottompagespace} />;
    return (<div className={classes.containercontentSub} key={selectedkey}>
      <div className={classes.textimagecontainer}>
        <img id='workoutimage'
          className={classes.imageFullWidthUnTransparent}
          src={that.selectedevent.image}
          alt={that.selectedevent.title}
        />
      </div>
      <div className={classes.submenuheader} color="textPrimary">
        {that.selectedevent.title}
      </div>
      <div className={classes.teaserlabel}>
        {that.selectedevent.teaser}
      </div>
      {bottompagespace}
      <div className={classes.divcompletecontainer}>
        {that.selectedeventtopics}
      </div>
      {bottompagespace}
      <div className={classes.divcompletecontainer}>
        {that.selectedeventtext}
      </div>
      {bottompagespace}
      <Divider variant="middle" classes={{ root: classes.dividerContent }} />
      {that.emailinput}
      {that.times}
    </div>);
  }

  createNoEvents() {
    const { classes } = this.props;
    var noevents = (this.state.overview && this.addcountoverview === 0) ?
      (<div className={classes.noeventworkouttext}>Aktuell stehen leider keine Events an. Wir informieren Euch rechtzeitig, wenn es etwas Neues gibt. Bleibt dran!</div>) :
      (<div></div>);
    return noevents;
  }

  createDummy() {


    var start = new Date(2020, 1, 1, 10, 0, 0);
    var end = new Date(2020, 1, 1, 11, 0, 0);

    var event = {
      "title": "Demo",
      "teaser": "Demo Teaser 1",
      "continued": 0,
      "planned": [
        {
          "year": 2020,
          "month": 12
        },
        {
          "year": 2021,
          "month": 1
        },
        {
          "year": 2021,
          "month": 2
        }
      ],
      "content": [
        {
          "topic": "Info 1",
          "info": "X"
        },
        {
          "topic": "Info 2",
          "info": "X"
        },
        {
          "topic": "Info 3",
          "info": "X"
        },
        {
          "topic": "Info 4",
          "info": "X"
        },
        {
          "topic": "Info 5",
          "info": "X"
        },
        {
          "topic": "Info 6",
          "info": "X"
        },
        {
          "topic": "Info 7",
          "info": "X"
        },
        {
          "topic": "Info 8",
          "info": "X"
        }
      ],
      "keytopics": {
        "Kondition": 3,
        "Technik": 2,
        "Kraft": 3,
        "Action": 3,
        "Langweile": 0
      },
      "image": "/assets/events/eishockey.jpg",
      "times": [{
        "start": start.toJSON(),
        "end": end.toJSON(),
        "gmaps": "https://maps.app.goo.gl/3xC9dM6Ans1WwkUKA",
        "place": "8:30 Uhr Eisstadion am Pulvertum Haupteingang",
        "maxnumber": 30,
        "registeredusers": [''],
        "unregisteredusers": ['']
      }
      ]
    };
    FirebaseStorage.pushObject("events", event);

  }
}

export default withStyles(useStyles)(EventCalendar);
