import { FirebaseStorage } from '../../lib/database';

export class EventData {
    addEvent(event) {
        FirebaseStorage.pushObject("events", event);
    }

    updateEvent(key, event) {
        FirebaseStorage.updateObject("events", key, event);
    }

    loadEvents(fn) {
        FirebaseStorage.loadAll("events", fn);
    }

    loadEventsComplete(fn) {
        FirebaseStorage.loadAllComplete("events", fn);
    }

    assignUser(eventkey, time, userkey) {
        var attendee = {
            key: userkey,
            email: ""
        }
        FirebaseStorage.pushObjectWithId("events/" + eventkey + "/times/" + time + "/attendees", attendee);
    }

    assignEmail(eventkey, time, email) {
        var attendee = {
            key: "",
            email: email
        }
        FirebaseStorage.pushObjectWithId("events/" + eventkey + "/times/" + time + "/attendees", attendee);
    }

    unassign(eventkey, time, userkey) {
        FirebaseStorage.deleteObject("events/" + eventkey + "/times/" + time + "/attendees/" + userkey);
    }

    checkInUser(eventkey, time, userkey) {
        var ts = new Date().getTime();
        var ckeckin = {
            user: userkey,
            event: eventkey,
            eventtime: time,
            userinfo: {},
            start: ts,
            end: null
        }
        FirebaseStorage.pushObjectWithId("checkins/events", ckeckin);
    }

    checkOutUser(eventkey, time, userkey) {
        var ts = new Date().getTime();
        FirebaseStorage.loadAllComplete("checkins/events", (checkins) => {
            checkins.forEach(function (c) {
                var ckey = c.key;
                var cval = c.val();
                if (cval.user === userkey && cval.workout === eventkey && cval.eventime === time && (cval.end === undefined || cval === null)) {
                    cval.end = ts;
                    FirebaseStorage.updateObject("checkins/events", ckey, cval);
                }
            });
        });
    }

    checkInEmail(eventkey, time, info) {
        var ts = new Date().getTime();
        var ckeckin = {
            user: "",
            event: eventkey,
            eventime: time,
            userinfo: info,
            start: ts,
            end: null
        }
        return FirebaseStorage.pushObjectWithId("checkins/events", ckeckin);
    }

    checkOutEmail(key) {
        var ts = new Date().getTime();
        FirebaseStorage.loadObject("checkins/events", key, (wc) => {
            var wcVal = wc.val();
            wcVal.end = ts;
            FirebaseStorage.updateObject("checkins/events", key, wcVal);
        });        
    }

    sendEventTimeMessage(eventkey, timekey, message, fn) {
        var itemtimekey = eventkey + "_" + timekey;
        FirebaseStorage.loadObject("events", eventkey + "/times/" + timekey, (e) => {
            var event = e.val();
            var messages = [];
            if (event.messages) {
                messages = event.messages;
            }
            var ts = new Date().toISOString();
            messages.push(
                {
                    timestamp: ts,
                    message: message
                }
            );
            FirebaseStorage.insertObject("events", eventkey + "/times/" + timekey + "/messages", messages);
            fn();
        });
    }
}