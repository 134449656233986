import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import Modal from '@material-ui/core/Modal';
import WorkoutHelp from './workouthelp'

const additionalStyles = {
  workoutheader: {
    fontFamily: 'Anton',
    fontSize: '18px',
    marginLeft: '20px',
    width: 'calc(100% - 20px)',
    textAlign: 'left'
  },
  workoutinfo: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginLeft: '40px',
    width: 'calc(100% - 40px)',
    textAlign: 'left'
  },
  workoutinfodrill: {
    fontFamily: 'Roboto',
    fontSize: '11px',
    marginLeft: '80px',
    width: 'calc(100% - 80px)',
    textAlign: 'left'
  }
};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class WorkoutListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      help: false
    };

  }

  componentDidMount() {
  }

  showhelp() {
    this.setState({
      help: true
    });
  }

  closePreview() {
    this.setState({
      help: false
    });
  }

  render() {
    const { classes } = this.props;
    var that = this;
    if (this.state.help) {
      var open = true;
      var tabindex = -1;
      return (<Modal
        tabIndex={tabindex}
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <WorkoutHelp close={() => this.closePreview()} />
      </Modal>);
    }
    var gwKeys = Object.keys(this.props.currentGroupWorkouts);
    var workouts = (<div/>);
    if (gwKeys.length === 0) {
      workouts = (<div className={[classes.floatrelative, classes.fullwidth].join(' ')}>Aktuell sind keine Workouts verfügbar!</div>);
    }
    else {
      workouts = (gwKeys.map(function (gw, key) {
        var groupworkout = that.props.currentGroupWorkouts[gw];//gw.val();
        var exercises = 0;
        if (groupworkout.drills.length > 0) {
          exercises = groupworkout.drills[0].exercises.length;
        }
        var now = Date.now();
        var enddate = new Date(groupworkout.interval.end).toLocaleDateString();
        if (now < groupworkout.interval.start || now > groupworkout.interval.end) {
          return (<div />);
        }
        var drills = groupworkout.drills.map((drill, i) => {
          return <div key={i} className={[classes.floatrelative, classes.workoutinfodrill].join(' ')}>{drill.name}</div>
        });
        return (
          <div className={[classes.workoutmessagelabel].join(' ')} key={key}>
            <div className={[classes.floatrelative, classes.workoutheader].join(' ')}>Workout: {groupworkout.name}</div>
            <div className={[classes.floatrelative, classes.workoutinfo].join(' ')}>Übungen: {groupworkout.drills.length}</div>
            {/* {drills} */}
            <div className={[classes.floatrelative, classes.workoutinfo].join(' ')}>Durchgänge: {exercises}</div>
            <div className={[classes.floatrelative, classes.workoutinfo].join(' ')}>Verfügbar bis: {enddate}</div>
            <div className={classes.mainspacer} />
            <Button style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft].join(' ')} onClick={() => that.props.showPreview(gw)}>
              Vorschau
          </Button>
            <Button style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonright].join(' ')} onClick={() => that.props.startWorkout(gw)}>
              Workout starten
          </Button>
          </div>);
      }));
    }
    return (
      <div className={[classes.fullcontainer, classes.boxHeight].join(' ')}>
        <Button className={classes.buttonContentMore} onClick={() => this.showhelp()}>
          Anleitung
        </Button>
        {workouts}
        <div className={classes.mainspacer} />
        <Divider variant="middle" classes={{ root: classes.dividerContent }} />
      </div>);
  }
}

export default withStyles(useStyles)(WorkoutListView);
