import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import { FirebaseAuth } from "../../lib/authentication";
import Regex from '../../lib/regex';

const additionalStyles = {
    textboxstreet: {
        left: '5%',
        width: 'calc(90% - 100px)'
    },
    textboxnumber: {
        left: '5%',
        width: '80px',
        marginLeft: '20px',
        marginRight: '5%'
    },
    labelnumber: {
        marginLeft: 'calc(90% - 115px)'
    },
    textboxcity: {
        marginLeft: 'calc(5% + 20px)',
        width: 'calc(90% - 80px)',
        marginRight: '5%'
    },
    textboxzip: {
        left: '5%',
        width: '60px'
    },
    labelcity: {
        marginLeft: '60px'
    }
};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class Register extends React.Component {

    constructor(props) {
        super(props);
        var register = {
            surname: "",
            fullname: "",
            email: "",
            password1: "",
            password2: "",
            street: "",
            number: "",
            zip: "",
            city: "",
            acceptAgb: false
        }

        this.state = {
            registration: register,
            infomessage: "",
            errormessage: ''
        };
    }

    componentDidMount() {
        var that = this;
        FirebaseAuth.OnRegistrationCompleted = (info) => {            
            if (info.success) {
                that.props.loginSuccessful();
            }
            else {
                that.setErrorText(info.message);
            }
        };
    }

    textchange(prop, e) {
        var val = e.target.value;
        var reg = this.state.registration;
        if (prop === 'surname') {
            reg.surname = val;
        }
        if (prop === 'fullname') {
            reg.fullname = val;
        }
        if (prop === 'email') {
            reg.email = val;
        }
        if (prop === 'password1') {
            reg.password1 = val;
        }
        if (prop === 'password2') {
            reg.password2 = val;
        }
        if (prop === 'street') {
            reg.street = val;
        }
        if (prop === 'number') {
            reg.number = val;
        }
        if (prop === 'zip') {
            reg.zip = val;
        }
        if (prop === 'city') {
            reg.city = val;

        }
        this.setState(state => ({
            registration: reg
        }));
    }

    changeAgb() {
        var newstate = !this.state.acceptAgb;
        var reg = this.state.registration;
        reg.acceptAgb = newstate;
        this.setState(state => ({
            registration: reg
        }));
        ;
    }

    setInfoText(text) {
        var that = this;
        this.setState(state => ({
            infomessage: text
        }));
        setTimeout(() => {
            that.setInfoText("");
        }, 3000);
    }

    setErrorText(text) {
        var that = this;
        this.setState(state => ({
            errormessage: text
        }));
        setTimeout(() => {
            that.setErrorText("");
        }, 3000);
    }

    register() {
        if (this.state.registration.password1 !== this.state.registration.password2) {
            this.setErrorText('Passwörter sind nicht identisch!');
        }
        else if (this.state.registration.fullname === ''
            || this.state.registration.surname === ''
            || this.state.registration.email === ''
            || this.state.registration.street === ''
            || this.state.registration.number === ''
            || this.state.registration.zip === ''
            || this.state.city === '') {
            this.setErrorText('Bitte füllen Sie alle Felder aus um sich zu registrieren!');
        }
        else if (!new Regex().validateEmail(this.state.registration.email)) {
            this.setErrorText("Keine gültige Emailadresse angegeben!");
        }
        else {
            var that = this;
            var profile = {
                "Name": that.state.registration.fullname,
                "Surname": that.state.registration.surname,
                "Street": that.state.registration.street,
                "Number": that.state.registration.number,
                "Zip": that.state.registration.zip,
                "City": that.state.registration.city,
                "betauser": false,
                "Notfications": {
                    "Event": true,
                    "Training": true,
                    "Message": true
                },
                "onlinetraining": {
                    "fitnesslevel": 3,
                    "pushalertongroupmessage": true,
                    "emailalertongroupmessage": false
                }
            };
            FirebaseAuth.register(this.state.registration.email, this.state.registration.password1, profile);
        }
    }

    render() {
        return this.createRegistration();
    }

    createRegistration() {
        const { classes } = this.props;
        var that = this;
        var registerbuttondisabled = !this.state.registration.acceptAgb;
        var bottompagespace = <div className={classes.bottompagespace} />;
        return (
            <Card className={classes.root}>
                <div className={classes.containercontent}>
                    <div className={classes.label}>Registrierung</div>
                    <div className={classes.mainspacer} />
                    <input id="Surname" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('surname', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Vorname *</div>
                    <div className={classes.mainspacer} />
                    <input id="Name" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('fullname', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Nachname *</div>
                    <div className={classes.mainspacer} />
                    <input id="Email" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('email', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Email Adresse *</div>
                    <div className={classes.mainspacer} />
                    <input id="Street" className={[classes.floatrelative, classes.formtextbox, classes.textboxstreet].join(' ')} onChange={(e) => that.textchange('street', e)} />
                    <input id="Number" className={[classes.floatrelative, classes.formtextbox, classes.textboxnumber].join(' ')} onChange={(e) => that.textchange('number', e)} />
                    <div className={[classes.floatrelative, classes.formsublabel, classes.marginLeft5pct].join(' ')}>Straße *</div>
                    <div className={[classes.floatrelative, classes.formsublabel, classes.labelnumber].join(' ')}>Hausnummer *</div>
                    <div className={classes.mainspacer} />
                    <input id="Zip" className={[classes.floatrelative, classes.formtextbox, classes.textboxzip].join(' ')} onChange={(e) => that.textchange('zip', e)} />
                    <input id="City" className={[classes.floatrelative, classes.formtextbox, classes.textboxcity].join(' ')} onChange={(e) => that.textchange('city', e)} />
                    <div className={[classes.floatrelative, classes.formsublabel, classes.marginLeft5pct].join(' ')}>PLZ *</div>
                    <div className={[classes.floatrelative, classes.formsublabel, classes.labelcity].join(' ')}>Ort *</div>
                    <div className={classes.mainspacer} />
                    <input id="Password1" type="password" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('password1', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Passwort *</div>
                    <div className={classes.mainspacer} />
                    <input id="Password2" type="password" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('password2', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Passwort wiederholen *</div>
                    <div className={classes.mainspacer} />
                    <FormControlLabel
                        control={<Checkbox checked={this.state.acceptAgb} className={classes.checkbox} name="agb" onChange={() => that.changeAgb()} />}
                        label={<div className={classes.formsublabel}>
                            Mit dem erstellen des Kontos akzeptiere ich die&nbsp;
                    <a className={classes.internallink} href='www.nolimitz-fitness.de?info=agb' target="_blank">AGBs</a>
                    &nbsp;und&nbsp;
                    <a className={classes.internallink} href='www.nolimitz-fitness.de?info=datenschutz' target="_blank">Datenschutzbestimmungen</a>
                    &nbsp;der NoLimitZ Fitness GbR und stimme der &nbsp;
                    <a className={classes.internallink} href='www.nolimitz-fitness.de?info=widerruf' target="_blank">Widerrufsbelehrung</a>
                    &nbsp;der NoLimitZ GbR zu *</div>}
                    />
                    <div className={classes.mainspacer} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>* Pflichtfelder</div>
                    <div className={classes.mainspacer} />
                    <div className={classes.infolabel}>{that.state.infomessage}</div>
                    <div className={classes.errorlabel}>{that.state.errormessage}</div>
                    <Button disabled={registerbuttondisabled} className={classes.buttonFullWidth} onClick={() => that.register()}>
                        <div className={[classes.innerCenter].join(' ')}>
                            <img className={[classes.floatrelative, classes.arrowright].join(' ')} src="./assets/arrow_right.png" alt="Pfeil" />
                            <div className={[classes.floatrelative].join(' ')}>JETZT REGISTRIEREN</div>
                        </div>
                    </Button>
                    {/* <Divider variant="middle" classes={{ root: classes.dividerContent }} />
              <div className={classes.errorlabel}>{this.state.errormessage}</div>
              <div className={classes.buttonFullWidth} onClick={() => that.props.showLogin()}>
                ZUM LOGIN
              </div> */}
                </div>
                {bottompagespace}
            </Card>
        );
    }
}

export default withStyles(useStyles)(Register);