import firebase from 'firebase';

class FCD {

  insertupdateObject(collection, obj) {
    var ref = firebase.database().ref(collection);
    for (var key in obj) {
      var child = ref.child(key);
      if (child != null) {
        child.set(obj[key]);
      }
      else {
        ref.push(obj);
      }
    }
  }

  pushObject(collection, obj) {
    var ref = firebase.database().ref(collection);
    ref.push(obj);
  }

  pushObjectWithId(collection, obj) {
    var ref = firebase.database().ref(collection);
    const newid = ref.push().getKey();
    ref.child(newid).set(obj);
    return newid;
  }

  insertObject(collection, key, obj) {
    firebase.database().ref(collection + '/' + key).set(obj);
  }

  updateObject(collection, key, obj) {
    var search = collection + "/" + key;
    var ref = firebase.database().ref(search);
    ref.set(obj);
  }

  deleteObject(path) {
    firebase.database().ref(path).set(null);
  }

  loadObject(collection, key, fn) {
    var search = collection + "/" + key;
    firebase.database().ref(search).once("value", function (snapshot) {
      fn(snapshot);
    });
  }

  loadAll(collection, fn) {
    return firebase.database().ref(collection).once("value", function (snapshot) {
      snapshot.forEach(function (childSnapshot) {
        fn(childSnapshot);
      });
    });
  }

  loadAllComplete(collection, fn) {
    return firebase.database().ref(collection).once("value", function (snapshot) {
      fn(snapshot);
    });
  }

  filterList(collection, prop, query, fn) {
    return firebase.database()
      .ref(collection)
      .orderByChild(prop)
      .startAt(query)
      .endAt(query + "\uf8ff")
      .once("value", function (snapshot) {
        fn(snapshot);
      });
  }

  filterListRange(collection, prop, start, end, fn)  {
    return firebase.database()
      .ref(collection)
      .orderByChild(prop)
      .startAt(start)
      .endAt(end)
      .once("value", function (snapshot) {
        fn(snapshot);
      });
  }

  listenAdd(collection, fn) {
    var ref = firebase.database().ref(collection);
    ref.on('child_added', (data) => {
      fn(data);
    });
  }
}

let FirebaseStorage = new FCD();

export { FirebaseStorage };