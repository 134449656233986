import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import { FirebaseAuth } from "../../lib/authentication";


const additionalStyles = {
  
};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class EditProfile extends React.Component {

  constructor(props) {
    super(props);
    var register = {
      surname: "",
      fullname: "",
      email: "",
      password1: "",
      password2: "",
      street: "",
      number: "",
      zip: "",
      city: "",
      allowpush: true,
      allowemail: true,
      onlinetraining: {
        fitnesslevel: 3,
        pushalertongroupmessage: true,
        emailalertongroupmessage: false
      }
    }
    this.state = {
      registration: register,
      editprofileinitialized: false,
      errormessage: "",
      infomessage: "",
      submenu: 1
    };
  }

  componentDidMount() {
    var register = this.state.registration;
    if (!this.state.editprofileinitialized) {
      register.surname = FirebaseAuth.profile.Surname;
      register.fullname = FirebaseAuth.profile.Name;
      register.street = FirebaseAuth.profile.Street;
      register.number = FirebaseAuth.profile.Number;
      register.zip = FirebaseAuth.profile.Zip;
      register.city = FirebaseAuth.profile.City;
      register.onlinetraining = FirebaseAuth.profile.onlinetraining;
      this.setState({
        registration: register,
        editprofileinitialized: true
      });
    }
  }

  textchange(prop, e) {
    var val = e.target.value;
    var reg = this.state.registration;
    if (prop === 'surname') {
      reg.surname = val;
    }
    if (prop === 'fullname') {
      reg.fullname = val;
    }
    if (prop === 'email') {
      reg.email = val;
    }
    if (prop === 'password1') {
      reg.password1 = val;
    }
    if (prop === 'password2') {
      reg.password2 = val;
    }
    if (prop === 'street') {
      reg.street = val;
    }
    if (prop === 'number') {
      reg.number = val;
    }
    if (prop === 'zip') {
      reg.zip = val;
    }
    if (prop === 'city') {
      reg.city = val;

    }
    this.setState(state => ({
      registration: reg
    }));
  }

  changeBool(type) {
    var reg = this.state.registration;
    var oldstate = false;
    var newstate = false;
    if (type === 'push') {
      oldstate = (this.state.registration.allowpush === undefined) ? false : this.state.registration.allowpush;
      newstate = !oldstate;
      reg.allowpush = newstate;
    }
    if (type === 'email') {
      oldstate = (this.state.registration.allowemail === undefined) ? false : this.state.registration.allowemail;
      newstate = !oldstate;
      reg.allowemail = newstate;
    }
    if (type === 'grouppush') {
      oldstate = (this.state.registration.onlinetraining.pushalertongroupmessage === undefined) ? false : this.state.registration.onlinetraining.pushalertongroupmessage;
      newstate = !oldstate;
      reg.onlinetraining.pushalertongroupmessage = newstate;
    }
    if (type === 'groupemail') {
      oldstate = (this.state.registration.onlinetraining.emailalertongroupmessage === undefined) ? false : this.state.registration.onlinetraining.emailalertongroupmessage;
      newstate = !oldstate;
      reg.onlinetraining.emailalertongroupmessage = newstate;
    }
    this.setState(state => ({
      registration: reg
    }));
    ;
  }

  changeSlider(event, newValue, type) {
    var reg = this.state.registration;
    if (type === 'fitnesslevel') {      
      reg.onlinetraining.fitnesslevel = newValue;
    }    
    this.setState(state => ({
      registration: reg
    }));
    ;
  }

  saveprofile(that) {
    var profile = {
      "Name": that.state.registration.fullname,
      "Surname": that.state.registration.surname,
      "Street": that.state.registration.street,
      "Number": that.state.registration.number,
      "Zip": that.state.registration.zip,
      "City": that.state.registration.city,
      "Notfications": {
        "AllowPush": that.state.registration.allowpush,
        "AllowEmail": that.state.registration.allowemail,
        "Event": true,
        "Training": true,
        "Message": true
      },
      "onlinetraining": {
        "fitnesslevel": that.state.registration.onlinetraining.fitnesslevel,
        "pushalertongroupmessage": that.state.registration.onlinetraining.pushalertongroupmessage,
        "emailalertongroupmessage": that.state.registration.onlinetraining.emailalertongroupmessage
      }
    };
    FirebaseAuth.UpdateProfile(profile, (info, error) => {
      that.setState(state => ({
        infomessage: info,
        errormessage: error
      }));
      setTimeout(() => {
        that.setState(state => ({
          infomessage: "",
          errormessage: ""
        }));
      }, (3000));
    });
  }


  checkisAdmin() {
    return (FirebaseAuth.currentUser !== null && (FirebaseAuth.currentUser.email.toLowerCase() === "devfalti@gmail.com" || FirebaseAuth.currentUser.email.toLowerCase() === "tobi-m.949313@web.de"));
  }

  render() {
    const { classes } = this.props;
    var header = (
      <div className={classes.containertitle}>
        <Typography className={classes.title} color="textSecondary">
          NoLimitZ Fitness
        </Typography>
        <Divider variant="middle" classes={{ root: classes.divider }} />
      </div>);
    var bottompagespace = <div className={classes.bottompagespace} />;

    return this.createEditProfile(header, bottompagespace);

  }

  previousMenu() {
    var newmenu = 0;
    if (this.state.submenu === 1) {
      newmenu = 2;
    }
    else {
      newmenu = this.state.submenu - 1;
    }
    this.setState({
      submenu: newmenu
    });
  }

  nextMenu() {
    var newmenu = 0;
    if (this.state.submenu === 2) {
      newmenu = 1;
    }
    else {
      newmenu = this.state.submenu + 1;
    }
    this.setState({
      submenu: newmenu
    });
  }

  createEditProfile(header, bottompagespace) {
    const { classes } = this.props;
    var that = this;
    var submenutext = (this.state.submenu === 1) ? "Stammdaten" : "Online-Training";
    var subheader = (<div className={[classes.header1, classes.marginBottom20].join(' ')}>
      <ArrowBackIcon className={[classes.navigationbutton1, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.previousMenu()} />
      <div className={[classes.switchheader1].join(' ')}>{submenutext}</div>
      <ArrowForwardIcon className={[classes.navigationbutton1, classes.navigationButtonRight].join(' ')} fontSize="large" onClick={() => that.nextMenu()} />
    </div>);

    var content = (<div/>);
    if (this.state.submenu === 1) {
      content = <div>
        <div className={classes.label}>Vorname</div>
          <input className={[classes.textbox].join(' ')} defaultValue={that.state.registration.surname} onChange={(e) => that.textchange('surname', e)}></input>
          <div className={classes.label}>Nachname</div>
          <input className={[classes.textbox].join(' ')} defaultValue={that.state.registration.fullname} onChange={(e) => that.textchange('fullname', e)}></input>
          <div className={classes.label}>Straße</div>
          <input className={[classes.textbox].join(' ')} defaultValue={that.state.registration.street} onChange={(e) => that.textchange('street', e)}></input>
          <div className={classes.label}>Hausnummer</div>
          <input className={[classes.textbox].join(' ')} defaultValue={that.state.registration.number} onChange={(e) => that.textchange('number', e)}></input>
          <div className={classes.label}>PLZ</div>
          <input className={[classes.textbox].join(' ')} defaultValue={that.state.registration.zip} onChange={(e) => that.textchange('zip', e)}></input>
          <div className={classes.label}>Ort</div>
          <input className={[classes.textbox].join(' ')} defaultValue={that.state.registration.city} onChange={(e) => that.textchange('city', e)}></input>
          <FormControlLabel
            control={<Checkbox checked={this.state.registration.allowpush} className={classes.checkbox} name="push" onChange={() => that.changeBool('push')} />}
            label={<div>Push Alerts erhalten</div>}
          />
          <FormControlLabel
            control={<Checkbox checked={this.state.registration.allowemail} className={classes.checkbox} name="email" onChange={() => that.changeBool('email')} />}
            label={<div>Email Alerts erhalten</div>}
          />
      </div>
    }
    else if (this.state.submenu === 2) {
      content = <div>    
          <div className={classes.label}>Fitness Level</div>
          <Slider
            className={[classes.floatrelative, classes.slider].join(' ')}
            value={this.state.registration.onlinetraining.fitnesslevel}
            onChange={(event, newValue) => that.changeSlider(event, newValue, "fitnesslevel") }
            //getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={7}
          />    
          <FormControlLabel
            control={<Checkbox checked={this.state.registration.onlinetraining.pushalertongroupmessage} className={classes.checkbox} name="grouppush" onChange={() => that.changeBool('grouppush')} />}
            label={<div>Push Alert bei Gruppennachrichten erhalten</div>}
          />
          <FormControlLabel
            control={<Checkbox checked={this.state.registration.onlinetraining.emailalertongroupmessage} className={classes.checkbox} name="groupemail" onChange={() => that.changeBool('groupemail')} />}
            label={<div>Email Alert bei Gruppennachrichten erhalten</div>}
          />
      </div>
    }

    return (
      <Card className={classes.root}>
        {header}
        <div className={classes.mainspacer} />
        {subheader}
        <div className={classes.containercontent}>
          {content}
          <Button className={classes.buttonContentMore} onClick={() => that.saveprofile(that)}>
            Speichern
          </Button>
          <Divider variant="middle" classes={{ root: classes.dividerContent }} />
          <div className={classes.infolabel}>{this.state.infomessage}</div>
          <div className={classes.errorlabel}>{this.state.errormessage}</div>
        </div>
        {bottompagespace}
      </Card>
    );
  }
}

export default withStyles(useStyles)(EditProfile);
