
class InstallPrompt {
    deferredPrompt = null;
    IsInstalled = false;

    setexecuteDeferredPrompt(p) {
        this.deferredPrompt = p;
        this.OnPromptChanged();
    }

    OnPromptChanged() {

    }

    executeDeferredPrompt() {
        try {
            if (this.deferredPrompt == null) {
                console.log('PWA Installer not available');
            } else {
                this.deferredPrompt.prompt();
                this.deferredPrompt.userChoice
                    .then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {
                            console.log('User accepted the A2HS prompt');
                        } else {
                            console.log('User dismissed the A2HS prompt');
                        }
                        this.deferredPrompt = null;
                    });
            }
        } catch (error) {
            var message = '';
            if (error != null && error.message != null) {
                message = error.message;
            }
            console.log('PWA Installer : ' + message);
        }
    }
}

let PwaInstaller = new InstallPrompt();

export { PwaInstaller };