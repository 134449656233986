import React from "react";
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { CourseData } from '../../controller/data/coursedata'
/* import { UserData } from '../../controller/data/userdata'; */
import $ from "jquery";

const additionalStyles = {  
    challengeheader: {
        fontFamily: 'Anton',
        fontSize: '18px',
        marginLeft: '20px',
        width: 'calc(100% - 20px)',
        textAlign: 'left'
      },
      challengeinfo: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        marginLeft: '40px',
        width: 'calc(100% - 40px)',
        textAlign: 'left'
      },
      infotext: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        paddingLeft: '40px',        
        textAlign: 'left',
        width: '100%'
      },
      challengedescriptionhead: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        paddingLeft: '40px',
        textAlign: 'left'
      },
      challengedescription: {
        fontFamily: 'Roboto',
        fontSize: '8px',
        paddingLeft: '40px',
        width: 'calc(100% - 40px)',
        textAlign: 'left'
      },
      rankhead: {
        left: '15%',
        fontFamily: 'Anton',
        fontSize: '14px',
      },
      rankentry: {
        left: '15%',
        fontFamily: 'Roboto',
        fontSize: '14px',
      },
      detailentry: {
        fontFamily: 'Roboto',
        fontSize: '8px',
      },
      widthname: {
          width: '50%',
          textAlign: 'left'
      },      
      widthscore: {
        width: 'calc(30% - 50px)',
        textAlign: 'left'
      },    
      widthdetailicon: {
        left: '15%',
        width: '50px',
        textAlign: 'left'
      },
      widthdetailiconsmall: {
        left: '15%',
        width: '25px',
        textAlign: 'left'
      },
      widthdetaildate: {
        left: '20%',
        width: '60%',
        textAlign: 'left'
      },
      widthdetail: {
        left: '25%',
        width: '70%',
        textAlign: 'left'
      },
      infobutton: {
        marginLeft: '20px'
      }
};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class WorkoutChallenge extends React.Component {

  constructor(props) {
    super(props);    
    this.state = { 
        challenges: {},
        descriptionvisible: false
    };
    
  }

  showdescription() {
    var toggle = this.state.descriptionvisible;
    this.setState({
      descriptionvisible: !toggle
    });
  }

  replaceAll(str, search, replace) {
    while (str.indexOf(search) > 0) {
      str = str.replace(search, replace);
    }
    return str;
  }

  componentDidMount() {
    var that = this;
    var cd = new CourseData();    
    cd.loadChallenges(this.props.currentGroupKey, false, (challenges) => {       
        var chKeys = Object.keys(challenges);        
        for (var i = chKeys.length - 1; i >= 0; i--) {
            var type = (challenges[chKeys[i]].subtype === "power") ? "Kraft"
                    : (challenges[chKeys[i]].subtype === "power_endurance") ? "Kraft-Ausdauer"        
                    : (challenges[chKeys[i]].subtype === "endurance") ? "Ausdauer"
                    : (challenges[chKeys[i]].subtype === "stability") ? "Stabilität"
                    : (challenges[chKeys[i]].subtype === "monility") ? "Mobilität" : "";            
            var challenge = {
                name: "Challenge vom " 
                    + new Date(challenges[chKeys[i]].start).toLocaleDateString() 
                    + " - " + new Date(challenges[chKeys[i]].end).toLocaleDateString(),
                type: type,
                information: challenges[chKeys[i]].information,
                list: {},
                loading: true
            };
            var c0 = that.state.challenges;
            c0[chKeys[i]] = challenge;
            that.setState({
                challenges : c0
            });
        }
        for (var i1 = 0; i1 < chKeys.length; i1++) {
            cd.loadChallengeIndexChart(chKeys[i1], 
                                        challenges[chKeys[i1]].groupid, 
                                        challenges[chKeys[i1]].subtype, 
                                        challenges[chKeys[i1]].start, 
                                        challenges[chKeys[i1]].end, 
                                        (chart) => {                                                     
                var c = that.state.challenges;                
                var listKeys = Object.keys(chart.list);
                var ukeys = Object.keys(that.props.currentGroupUsers);
                for (var j = 0; j < listKeys.length; j++) {
                    for (var k = 0; k < ukeys.length; k++) {
                        if (ukeys[k] === listKeys[j]) {                            
                            chart.list[listKeys[j]].name = that.props.currentGroupUsers[ukeys[k]].Surname +  " " + that.props.currentGroupUsers[ukeys[k]].Name;
                        }
                    }
                }
                c[chart.challengeid].list = chart.list;                
                c[chart.challengeid].loading = false;
                that.setState({
                    challenges : c
                });
            });            
        }                
    })
  }

  showdetails(detailkey) {    
    $("#"+detailkey).toggle();
  }


  render() {
    const { classes } = this.props;
    var that = this;
    var chKeys = Object.keys(this.state.challenges);    
    var noresults = false;
    var challenges = (chKeys.map(function (chKey) { 
        var results = <div className={[classes.floatrelative, classes.challengeinfo].join(' ')}>Lade...</div>;
        if (!that.state.challenges[chKey].loading) {
            var lKeys = Object.keys(that.state.challenges[chKey].list);    
            if (lKeys.length === 0) {
                results = <div className={[classes.floatrelative, classes.challengeinfo].join(' ')}>Bisher wurden noch keine Einheiten ausgeführt!</div>;
                noresults = true;
            }
            else           
            {
                var chartArray = [];
                for (var i = 0; i < lKeys.length; i++) {
                    chartArray.push({
                        name: that.state.challenges[chKey].list[lKeys[i]].name,
                        index: that.state.challenges[chKey].list[lKeys[i]].index,
                        key: lKeys[i]
                    });
                }
                chartArray.sort((a, b) => (a.index < b.index) ? 1 : -1)

                results = chartArray.map(function (entry, idx) {
                  var detKeys = Object.keys(that.state.challenges[chKey].list[entry.key].details);                    
                    var details = detKeys.map(function (detailKey, dkeyOuter) { 
                      if (that.state.challenges[chKey].list[entry.key].details[detailKey].length === 0) {
                        return (<div key={dkeyOuter}></div>);
                      }
                      var points = 0;
                      var detailsInner = that.state.challenges[chKey].list[entry.key].details[detailKey].map(function (detail, dkey) {
                        points += detail.number * detail.multiplier / detail.divider;
                        var txt = detail.exercise + ": " + detail.calculation;
                        return (
                            <div className={[classes.floatrelative, classes.widthdetail].join(' ')} key={dkey}>
                                {txt}
                            </div>);
                      });
                      var dt = new Date(parseInt(detailKey)).toLocaleDateString() + " " + new Date(parseInt(detailKey)).toLocaleTimeString() + ": " + points + " Punkte";
                      var innerdetailkey = "UserDetailsList_" + chKey + "_" + entry.key + "_" + detailKey;
                      return (
                        <div key={dkeyOuter}>
                          <div className={[classes.floatrelative, classes.widthdetaildate].join(' ')}>
                            {dt}
                          </div>
                          <div className={[classes.floatrelative, classes.widthdetailiconsmall].join(' ')}>
                            <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000'}} className={[classes.buttonIconSmall].join(' ')} onClick={() => that.showdetails(innerdetailkey)}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                            </div>
                          <div className={[classes.floatrelative, classes.fullwidth, classes.hidden].join(' ')} id={innerdetailkey}>  
                            {detailsInner}
                          </div>
                        </div>);
                    });
                    var detailkey = "UserDetails_" + chKey + "_" + entry.key;
                    var rank = idx + 1;
                    return (
                        <div className={[classes.floatrelative, classes.fullwidth].join(' ')} key={entry.key}>
                            <div className={[classes.floatrelative, classes.rankentry, classes.widthname].join(' ')}>{rank}. {entry.name}</div> 
                            <div className={[classes.floatrelative, classes.rankentry, classes.widthscore].join(' ')}>{entry.index}</div> 
                            <div className={[classes.floatrelative, classes.widthdetailicon].join(' ')}>
                            <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000'}} className={[classes.buttonIconSmall].join(' ')} onClick={() => that.showdetails(detailkey)}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                            </div>
                            <div className={[classes.floatrelative, classes.fullwidth, classes.hidden].join(' ')} id={detailkey}>
                                {details}
                            </div>
                        </div>);
                });
            }
        } 
        var description = <div/>;
        if (that.state.challenges[chKey].type === "Kraft") {
          if (that.state.descriptionvisible) {          
            description = 
            <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
              <div className={[classes.floatrelative, classes.challengedescriptionhead].join(' ')}>
                Anleitung: <br/><br/>
              </div>
              <div className={[classes.floatrelative, classes.challengedescription].join(' ')}>                
                Ihr könnt eueren Kraftindex folgendermaßen aufbauen:<br/>
                Jede Übung in einem Workout hat eine Bewertung (1-5).<br/>
                Der Wert für Kraft (z.B. 2) in einer Ausführung wird mit der Anzahl der Wiederholungen (Stück oder Sekunden) multipliziert.<br/>
                Dies wird zu euerem Kraftindex addiert.<br/><br/>
                Beispiel: <br/>
                Ihr führt ein Workout durch mit 2 Durchgängen, und jeweils 3 Übungen.<br/>
                Durchgang 1:<br/>
                Übung 1: 10 Stück mit Bewertung 2 = 20<br/>
                Übung 2: 30 Sekunden mit Bewertung 1 = 30<br/>
                Übung 3: 16 Stück mit Bewertung 4 = 64<br/>
                Durchgang 2:<br/>
                Übung 1: 10 Stück mit Bewertung 2 = 20<br/>
                Übung 2: 30 Sekunden mit Bewertung 2 = 60<br/>
                Übung 3: 16 Stück mit Bewertung 5 = 80<br/><br/>
                Damit habt ihr für die Gesamtwertung 274 Punkte erreicht!<br/><br/>
                Ihr könnt somit Punkte durch viele kleine und leichtere Workouts ebenso erreichen wie durch weniger aber dafür intensivere und schwierigere Workouts!
                </div>
              </div>
          }
          else {
            description =  (
              <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
            <div className={[classes.floatrelative, classes.challengedescriptionhead].join(' ')}>Anleitung</div>
            <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000'}} className={[classes.buttonIconSmall, classes.infobutton].join(' ')} onClick={() => that.showdescription()}>
                <InfoIcon fontSize="small" />
            </IconButton>
            </div>);
          }
        }
        var rankheader = (<div/>);
        if (!that.state.challenges[chKey].loading && !noresults) {
           rankheader = (<div><div className={[classes.floatrelative, classes.challengeheader].join(' ')}>Rangliste:</div>
           <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
               <div className={[classes.floatrelative, classes.rankhead, classes.widthname].join(' ')}>Name</div> 
               <div className={[classes.floatrelative, classes.rankhead, classes.widthscore].join(' ')}>Index</div> 
           </div></div>); 
        }
        var lines = that.state.challenges[chKey].information.split("\n");
        var infotext = lines.map((d, idx) => {
          var line = that.replaceAll(d, '\r', '');
          line = that.replaceAll(line, '\n', '');
          if (line.trim() === '') {
            return (<div key={idx} className={[classes.floatrelative, classes.infotext].join(' ')}>&nbsp;</div>)
          }
          else if (line.trim().endsWith(":")) {
            return (<div key={idx}>
              <div className={classes.mainspacer} />
              <div className={[classes.floatrelative, classes.infotext].join(' ')}><b>{line}</b></div>
            </div>)
          }
          else {
            return (<div key={idx} className={[classes.floatrelative, classes.infotext].join(' ')}>{line}</div>)
          }
        });
        return (            
            <div>
              <div className={[classes.workoutmessagelabel].join(' ')} key={chKey}>
              <div className={[classes.floatrelative, classes.challengeheader].join(' ')}>{that.state.challenges[chKey].name}</div>                        
              <div className={[classes.floatrelative, classes.challengeinfo].join(' ')}>Typ: {that.state.challenges[chKey].type}</div>
              <div className={classes.mainspacer} />
              {infotext}
              <div className={classes.mainspacer} />
              {rankheader}
              {results}  
              <div className={classes.mainspacer} />
              <div className={classes.mainspacer} />
              {description}                
            </div>
            <div className={classes.mainspacer} />
            </div>);
      }));
    
      return (
        <div className={[classes.fullcontainer, classes.boxHeight].join(' ')}>                      
          {challenges}
          <div className={classes.mainspacer} />
          <Divider variant="middle" classes={{root: classes.dividerContent}}/>         
        </div>);
  }
}

export default withStyles(useStyles)(WorkoutChallenge);
