import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Divider from '@material-ui/core/Divider';
import defaultStyles from '../base'
import protectinfo from "./protect.json";
import $ from "jquery";

const additionalStyles = {      
  
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)
  
class DataProtection extends React.Component {
  constructor (props) {
    super(props);  
    this.txt = protectinfo.content;
    this.state = {
      text: this.txt      
    };
    
  }

  createMarkup() {
    return {__html: this.txt };
  }

  componentDidMount() {
    $("#extramenucontainer").height(window.innerHeight - 35);
    $(window).resize(function() {
      $("#extramenucontainer").height(window.innerHeight - 35);
    });    
  }

  render() {
    const { classes } = this.props;    
    var title = 'Datenschutz';
    return (
            <div key='dataprotect' id='extramenucontainer' className={classes.extramenucontainer}>
            <div key='title' className={classes.title}>
                {title}
            </div>   
            <Divider variant="middle" classes={{root: classes.divider}}/>   
            <div key='content' id='dataprotect' className={classes.extramenucontent}>            
              <div dangerouslySetInnerHTML={this.createMarkup()} />
            </div>
        </div>
        );
    }    
}

export default withStyles(useStyles)(DataProtection);
