import { withStyles } from '@material-ui/core/styles';
import { OutlinedInput } from '@material-ui/core';
import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import defaultStyles from '../../../base'
import { FirebaseStorage } from "../../../../lib/database";
import { CommonFunctions } from "../../../../lib/common";
import dateformat from 'date-format';
import { EventData } from '../../../../controller/data/eventdata'

const additionalStyles = {
  itemcontent: {
    position: 'relative',
    width: '100%',
    float: 'left',
    height: 'auto'
  },
  floatrelative100: {
    width: '100%',
    float: 'left',
    position: 'relative'
  },
  newitemcontainer: {
    position: 'relative',
    float: 'left',
    textAlign: 'left',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '5px',
    marginBottom: '5px',
    padding: '5px',
    width: '90%',
    borderColor: '#00D2FF',
    borderRadius: '8px',
    border: '1px solid',
    height: 'auto'
  },
  caption: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
    background: '#000000',
    color: '#FFFFFF',
    width: '120px',
    paddingTop: '20px'
  },
  captionFull: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
    background: '#000000',
    color: '#FFFFFF',
    width: '100%',
    paddingTop: '20px'
  },
  textboxLeft: {
    marginTop: '12px',
    height: '28px',
    backgroundColor: '#FFFFFF',
    color: '#000000'
  },
  inputcontent: {
    position: 'relative',
    float: 'left',
    width: 'calc(100% - 140px)',
    marginLeft: '10px'
  },
  inputcontentSmall: {
    position: 'relative',
    float: 'left',
    width: 'calc(50% - 140px)',
    marginLeft: '10px'
  }
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class EventOverview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: null,
      selecteditem: "",
      createmode: false,
      editmode: false,
      currentItem: null,
      users: [],
      showHistoricalItems: false
    };
    this.messages = {}
  }

  componentDidMount() {
    this.refreshState();
  }

  refreshState() {
    var that = this;
    var eventdata = new EventData();
    eventdata.loadEventsComplete((evs) => {
      var newevs = {};
      evs.forEach(function (ev) {
        newevs[ev.key] = ev.val();
      });
      that.setState({
        items: newevs,
        createmode: false,
        editmode: false
      });
    });
    FirebaseStorage.loadAllComplete("users", (usrs) => {
      var newusers = [];
      usrs.forEach(function (usr) {
        newusers.push(usr);
      });
      that.setState({
        users: newusers
      });
    });
  }

  selectItem(key) {
    this.setState({
      selecteditem: key
    });
  }

  createItem() {
    var content = [];
    content.push({
      "topic": "Beschreibung",
      "info": ""
    });
    content.push({
      "topic": "Preis",
      "info": ""
    });
    var keytopics = {};
    keytopics["Action"] = 0;
    keytopics["Langeweile"] = 0;
    var item = {
      "title": "",
      "teaser": "",
      "continued": 0,
      "planned": [
        {
          "year": new Date().getFullYear(),
          "month": new Date().getMonth() + 1
        }
      ],
      "content": content,
      "keytopics": keytopics,
      "image": "",
      "times": [
      ]
    };
    this.setState({
      createmode: true,
      currentItem: item
    });
  }

  addDetail(type) {
    var cItem = this.state.currentItem;
    var currentDate = new Date();
    if (type === "planned") {
      if (cItem.planned === undefined || cItem.planned === null) {
        cItem.planned = [];
      }
      cItem.planned.push({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      });
    }
    if (type === "content") {
      if (cItem.content === undefined || cItem.content === null) {
        cItem.content = [];
      }
      cItem.content.push({
        "topic": "NewContent",
        "info": ""
      });
    }
    if (type === "keytopic") {
      if (cItem.keytopics === undefined || cItem.keytopics === null) {
        cItem.content = {};
      }
      var len = Object.keys(cItem.keytopics).length + 1;
      cItem.keytopics["Neues Topic" + len] = 0;
    }
    if (type === "time") {
      if (cItem.times === undefined || cItem.times === null) {
        cItem.times = [];
      }

      var newstart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 18, 0, 0);
      var newend = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 20, 0, 0);
      cItem.times.push(
        {
          place: "",
          gmaps: "",
          start: newstart,
          end: newend,
          maxnumber: 10
        }
      );
    }
    this.setState({
      currentItem: cItem
    });
  }

  editItem() {
    var edititem = this.state.items[this.state.selecteditem];
    this.setState({
      editmode: true,
      currentItem: edititem
    });
  }

  backMain() {
    this.setState({
      createmode: false,
      editmode: false
    });
  }

  messagechange(that, key, e) {
    that.messages[key] = e.target.value;
  }

  textchange(that, key1, key2, e) {
    var currEv = that.state.currentItem;
    var val = e.target.value;
    var idx = parseInt(key2);
    if (key1 === "title") {
      currEv.title = val;
    }
    if (key1 === "teaser") {
      currEv.teaser = val;
    }
    if (key1 === "image") {
      currEv.image = val;
    }
    if (key1 === "plannedyear") {
      currEv.planned[idx].year = val;
    }
    if (key1 === "plannedmonth") {
      currEv.planned[idx].month = val;
    }
    if (key1 === "timeplace") {
      currEv.times[idx].place = val;
    }
    if (key1 === "timegmaps") {
      currEv.times[idx].gmaps = val;
    }
    if (key1 === "timemaxnumber") {
      currEv.times[idx].maxnumber = val;
    }
    if (key1 === "timestart") {
      var dtvals = new Date(e.target.value);
      currEv.times[idx].start = dtvals.toISOString();
    }
    if (key1 === "timeend") {
      var dtvale = new Date(e.target.value);
      currEv.times[idx].end = dtvale.toISOString();
    }
    if (key1 === "contentval") {
      var c = currEv.content;
      var newc = [];
      for (var i = 0; i < c.length; i++) {
        if (i === parseInt(key2)) {
          newc.push({
            "topic": c[i].topic,
            "info": val
          });
        }
        else {
          newc.push({
            "topic": c[i].topic,
            "info": c[i].info
          });
        }
      }
      currEv.content = newc;
    }
    if (key1 === "content") {
      var c = currEv.content;
      var newc = [];
      for (var i = 0; i < c.length; i++) {
        if (i === parseInt(key2)) {
          newc.push({
            "topic": val,
            "info": c[i].info
          });
        }
        else {
          newc.push({
            "topic": c[i].topic,
            "info": c[i].info
          });
        }
      }
      currEv.content = newc;
    }
    if (key1 === "keytopic") {
      var kt = currEv.keytopics;
      var ktk = Object.keys(kt);
      var newkt = {};
      for (var i = 0; i < ktk.length; i++) {
        if (ktk[i] === key2) {
          newkt[val] = kt[ktk[i]];
        }
        else {
          newkt[ktk[i]] = kt[ktk[i]];
        }
      }
      currEv.keytopics = newkt;
    }
    if (key1 === "keytopicval") {
      var kt = currEv.keytopics;
      var ktk = Object.keys(kt);
      var newkt = {};
      for (var i = 0; i < ktk.length; i++) {
        if (ktk[i] === key2) {
          newkt[ktk[i]] = val;
        }
        else {
          newkt[ktk[i]] = kt[ktk[i]];
        }
      }
      currEv.keytopics = newkt;
    }
    that.setState({
      currentItem: currEv
    });
  }

  changeBool(that, key1, key2) {
    if (key1 === 'cancelled') {
      var currEv = that.state.currentItem;
      var oldstate = (currEv.times[key2].cancelled === undefined || currEv.times[key2].cancelled === null) ? false : currEv.times[key2].cancelled;
      var newstate = !oldstate;
      currEv.times[key2].cancelled = newstate;
      that.setState({
        currentItem: currEv
      });
    }

    if (key1 == 'showHistoricalItems') {
      var oldstate = that.state.showHistoricalItems;
      that.setState({
        showHistoricalItems: !oldstate
      });
    }
  }

  send(that, key, timekey) {
    var itemtimekey = key + "_" + timekey;
    var ed = new EventData();
    ed.sendEventTimeMessage(key, timekey, that.messages[itemtimekey], () => {
      that.showInfo("Information erfolgreich verschickt!");
    });
  }

  copyemails(that, key, timekey) {
    var ret = "";
    var attendees = that.state.items[that.state.selecteditem].times[timekey].attendees;
    var akeys = Object.keys(that.state.items[that.state.selecteditem].times[timekey].attendees);
    for (var i = 0; i < akeys.length; i++) {
      if (attendees[akeys[i]].email !== "") {
        ret += attendees[akeys[i]].email + ";";
      }
      else {
        for (var u = 0; u < that.state.users.length; u++) {
          if (that.state.users[u].key === attendees[akeys[i]].key) {
            ret += that.state.users[u].val().Email + ";";
          }
        }
      }
    }
    var cf = new CommonFunctions();
    cf.copyToClipBoard(ret);
    that.showInfo("Emails in Zwischenablage kopiert");
  }

  save(that) {
    if (that.state.createmode) {
      var ed = new EventData();
      ed.addEvent(that.state.currentItem);
      setTimeout(() => {
        that.refreshState();
      }, 100);
    }
    else if (that.state.editmode) {
      var ed = new EventData();
      ed.updateEvent(that.state.selecteditem, that.state.currentItem);
      setTimeout(() => {
        that.refreshState();
      }, 100);
    }
  }

  render() {
    const { classes } = this.props;
    var that = this;
    var content = (<div />);
    if (this.state.createmode || this.state.editmode) {
      content = that.createForm();
    }
    else {
      var showHistorical = (<div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
        <FormControlLabel
          control={<Checkbox checked={that.state.showHistoricalItems} className={classes.checkbox}
            name="showHistoricalItems" onChange={() => that.changeBool(that, 'showHistoricalItems', 0)} />}
          label={<div>Abgeschlossene Workouts anzeigen</div>}
        />
      </div>);
      var itemlist = this.createOverview();
      var selecteditem = (<div></div>);
      if (this.state.selecteditem !== "") {
        var item = this.state.items[this.state.selecteditem];
        var times = (item.times) ? item.times.map(function (timeitem, j) {
          return that.createBlock(timeitem, j);
        }) : (<div></div>);
        selecteditem = (<div>
          <div className={classes.roundedFilledLabel}>{item.title}</div>
          {showHistorical}
          {times}
        </div>);
      }

      var buttons = (this.state.selecteditem !== "") ?
        (<div>
          <Button className={classes.buttonContentMore} onClick={() => this.editItem()}>
            Event bearbeiten
        </Button>
          <Button className={classes.buttonContentMore} onClick={() => this.createItem()}>
            Event erstellen
        </Button>
        </div>) :
        (<div>
          <Button className={classes.buttonContentMore} onClick={() => this.createItem()}>
            Event erstellen
        </Button>
        </div>);

      content = (
        <div>
          <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
            {itemlist}
            <div className={[classes.floatrelative, classes.halfwidth, classes.listBox].join(' ')}>
              {selecteditem}
            </div>
          </div>
          <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
            {buttons}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className={classes.errorlabel}>{this.state.errormessage}</div>
        <div className={classes.infolabel}>{this.state.infomessage}</div>
        <div id="Content" className={classes.itemcontent}>
          {content}
        </div>
      </div>
    );
  }

  createDateString(date) {
    var m = (date.getMonth() + 1);
    var mstr = (m < 10) ? "0" + m : m;
    var d = (date.getDate());
    var dstr = (d < 10) ? "0" + d : d;
    var h = (date.getHours());
    var hstr = (h < 10) ? "0" + h : h;
    var mi = (date.getMinutes());
    var mistr = (mi < 10) ? "0" + mi : mi;
    return date.getFullYear() + "-" + mstr + "-" + dstr + "T" + hstr + ":" + mistr;
  }

  showError(text) {
    var that = this;
    this.setState({
      errormessage: text
    });
    setTimeout(() => {
      that.showError("");
    }, 3000);
  }

  showInfo(text, type) {
    var that = this;
    this.setState({
      infomessage: text
    });
    setTimeout(() => {
      that.showInfo("");
    }, 3000);
  }

  createOverview() {
    const { classes } = this.props;
    var that = this;
    if (this.state.items !== null) {
      var itemkeys = Object.keys(this.state.items);
      var existingitems = itemkeys.map((gk, idx) => {
        if (gk === this.state.selecteditem) {
          return (<div className={classes.listBoxEntrySelected} key={idx}>{this.state.items[gk].title}</div>);
        }
        else {
          return (<div className={classes.listBoxEntry} key={idx} onClick={(e) => that.selectItem(gk)}>{this.state.items[gk].title}</div>);
        }
      });

      return (
        <div className={[classes.floatrelative, classes.listArea].join(' ')}>
          <div className={[classes.label].join(' ')}>Existierende Events:</div>
          <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
            {existingitems}
          </div>
        </div>
      );
    }
    return (<div />);
  }

  createBlock(timeitem, j) {
    const { classes } = this.props;
    var that = this;
    var i = 0;
    var timeblock = <div />;
    if (that.state.showHistoricalItems || new Date(timeitem.start) > new Date()) {
      var completeddateinfo = dateformat.asString('dd.MM.yy', new Date(timeitem.start)) + ' ' +
        dateformat.asString('hh:mm', new Date(timeitem.start)) + ' - ' +
        dateformat.asString('hh:mm', new Date(timeitem.end));
      var registeredusers = [];
      var unregisteredusers = [];
      if (timeitem && timeitem.attendees) {
        var attKeys = Object.keys(timeitem.attendees);
        attKeys.forEach((attKey) => {
          if (timeitem.attendees[attKey].key !== "") {
            registeredusers.push(timeitem.attendees[attKey].key);
          }
          if (timeitem.attendees[attKey].email !== "") {
            unregisteredusers.push(timeitem.attendees[attKey].email);
          }
        });
      }

      var reguser = registeredusers.map(function (useritem, k) {
        var userinfo = useritem;
        for (var u = 0; u < that.state.users.length; u++) {
          if (that.state.users[u].key === useritem) {
            var usr = that.state.users[u].val();
            userinfo = usr.Name + " " + usr.Surname;
          }
        }
        return (<div key={i + j + k} className={[classes.sublabel, classes.floatrelative100].join(' ')}>
          {userinfo}
        </div>
        );
      });
      var unreguser = unregisteredusers.map(function (useritem, k) {
        var userinfo = useritem;
        return (<div key={i + j + k} className={[classes.sublabel, classes.floatrelative100].join(' ')}>
          {userinfo}
        </div>
        );
      });
      var itemtimekey = that.state.selecteditem + "_" + j;
      var inputblock = "InputBlock" + itemtimekey;
      var inputid = "Input" + itemtimekey;
      var buttonid = "Button" + itemtimekey;
      var mailblock = (<div key={inputblock} className={[classes.sublabel, classes.floatrelative100].join(' ')}>
        <OutlinedInput id={inputid} multiline className={[classes.textboxMulti].join(' ')} onChange={(e) => that.messagechange(that, itemtimekey, e)} />
        <div id={buttonid} className={[classes.buttonFlexSmall].join(' ')} onClick={() => that.send(that, that.state.selecteditem, j)}>
          Nachricht senden
      </div>
        <div id={buttonid} className={[classes.buttonFlexSmall].join(' ')} onClick={() => that.copyemails(that, that.state.selecteditem, j)}>
          Emails kopieren
      </div>
      </div>)
      timeblock = (<div key={i + j}>
        <div className={[classes.teaserlabel, classes.floatrelative100].join(' ')}>{completeddateinfo}</div>
        {reguser}
        {unreguser}
        {mailblock}
      </div>
      )
    }
    return (<div key={i + "_" + j}>
      {timeblock}
    </div>
    );
  }

  createForm() {
    const { classes } = this.props;
    var that = this;
    var key = "NewItem";
    var inputblock = "InputBlock" + key;
    var content = this.createContentBlocks();
    var keytopics = this.createKeyTopicBlocks();
    var plannedcontent = this.createPlannedBlocks();
    var timescontent = this.createTimeBlocks();
    var txt = (this.state.editmode) ? "Event aktualisieren" : "Event eintragen";
    return (<div key={inputblock} className={classes.newitemcontainer}>
      <div className={[classes.floatrelative].join(' ')}>
        <div className={classes.caption}>Titel:</div>
        <OutlinedInput className={[classes.textboxLeft, classes.inputcontent].join(' ')}
          value={that.state.currentItem.title}
          onChange={(e) => that.textchange(that, 'title', '', e)} />
        <div className={classes.caption}>Teaser:</div>
        <OutlinedInput className={[classes.textboxLeft, classes.inputcontent].join(' ')}
          value={that.state.currentItem.teaser}
          onChange={(e) => that.textchange(that, 'teaser', '', e)} />
        <div className={classes.caption}>Bild:</div>
        <OutlinedInput className={[classes.textboxLeft, classes.inputcontent].join(' ')}
          value={that.state.currentItem.image}
          onChange={(e) => that.textchange(that, 'image', '', e)} />
        <div className={classes.mainspacer} />
        <div className={[classes.floatrelative, classes.areaborderFull].join(' ')}>
          {plannedcontent}
          <div className={classes.extramenu}>
            <AddCircleIcon className={classes.floatrelative} fontSize="medium" onClick={() => that.addDetail("planned")} />
          </div>
        </div>
        <div className={classes.mainspacer} />
        <div className={[classes.floatrelative, classes.areaborderFull].join(' ')}>
          {content}
          <div className={classes.extramenu}>
            <AddCircleIcon className={classes.floatrelative} fontSize="medium" onClick={() => that.addDetail("content")} />
          </div>
        </div>
        <div className={classes.mainspacer} />
        <div className={[classes.floatrelative, classes.areaborderFull].join(' ')}>
          {keytopics}
          <div className={classes.extramenu}>
            <AddCircleIcon className={classes.floatrelative} fontSize="medium" onClick={() => that.addDetail("keytopic")} />
          </div>
        </div>
        <div className={classes.mainspacer} />
        <div className={[classes.floatrelative, classes.areaborderFull].join(' ')}>
          {timescontent}
          <div className={classes.extramenu}>
            <AddCircleIcon className={classes.floatrelative} fontSize="medium" onClick={() => that.addDetail("time")} />
          </div>
        </div>
      </div>
      <div className={classes.mainspacer} />
      <div className={[classes.buttonFlexSmall, classes.floatrelative100].join(' ')} onClick={() => that.save(that)}>
        {txt}
      </div>
      <div className={[classes.buttonFlexSmall, classes.floatrelative100].join(' ')} onClick={() => that.backMain()}>
        Zurück
      </div>
    </div>);
  }

  createContentBlocks() {
    const { classes } = this.props;
    var that = this;
    var contentKeys = Object.keys(that.state.currentItem.content);
    var content = contentKeys.map(function (k) {
      var head = "Inhalt " + (parseInt(k) + 1);
      return (<div className={classes.floatrelative100} key={k}>
        <div className={classes.fullWidth}>{head}:</div>
        <div className={classes.caption}>Info:</div>
        <OutlinedInput className={[classes.textboxLeft, classes.inputcontent].join(' ')}
          value={that.state.currentItem.content[contentKeys[k]].topic}
          onChange={(e) => that.textchange(that, 'content', k, e)} />
        <div className={classes.caption}>Inhalt:</div>
        <OutlinedInput className={[classes.textboxLeft, classes.inputcontent].join(' ')}
          value={that.state.currentItem.content[contentKeys[k]].info}
          onChange={(e) => that.textchange(that, 'contentval', k, e)} />
      </div>
      );
    });
    return content;
  }

  createKeyTopicBlocks() {
    const { classes } = this.props;
    var that = this;
    var keytopickeys = Object.keys(that.state.currentItem.keytopics);
    var keytopics = keytopickeys.map(function (k, idx) {      
      return (<div key={idx}>        
        <div className={classes.caption}>Topic:</div>
        <OutlinedInput className={[classes.textboxLeft, classes.inputcontentSmall].join(' ')}
          value={k}
          onChange={(e) => that.textchange(that, 'keytopic', k, e)} />
        <div className={classes.caption}>Bewertung:</div>
        <OutlinedInput className={[classes.textboxLeft, classes.inputcontentSmall].join(' ')}
          value={that.state.currentItem.keytopics[k]}
          onChange={(e) => that.textchange(that, 'keytopicval', k, e)} />
      </div>
      );
    });
    return keytopics;
  }

  createPlannedBlocks() {
    const { classes } = this.props;
    var that = this;
    var plannedcontent = (<div />);
    if (that.state.currentItem.planned !== undefined && that.state.currentItem.planned !== null && that.state.currentItem.planned.length > 0) {
      var plannedkeys = Object.keys(that.state.currentItem.planned);
      plannedcontent = plannedkeys.map(function (p) {
        var head = "Geplanter Monat " + (parseInt(p) + 1);
        return (<div key={p}>
          <div className={classes.fullWidth}>{head}:</div>
          <div className={classes.caption}>Jahr (geplant):</div>
          <OutlinedInput className={[classes.textboxLeft, classes.inputcontentSmall].join(' ')}
            value={that.state.currentItem.planned[p].year}
            onChange={(e) => that.textchange(that, 'plannedyear', p, e)} />
          <div className={classes.caption}>Monat (geplant):</div>
          <OutlinedInput className={[classes.textboxLeft, classes.inputcontentSmall].join(' ')}
            value={that.state.currentItem.planned[p].month}
            onChange={(e) => that.textchange(that, 'plannedmonth', p, e)} />
        </div>
        );
      });
    }
    return plannedcontent;
  }

  createTimeBlocks() {
    const { classes } = this.props;
    var that = this;
    var timescontent = (<div />);
    if (that.state.currentItem.times !== undefined && that.state.currentItem.times !== null && that.state.currentItem.times.length > 0) {
      var timeskeys = Object.keys(that.state.currentItem.times);
      timescontent = timeskeys.map(function (t) {
        var start = that.createDateString(new Date(that.state.currentItem.times[t].start));
        var end = that.createDateString(new Date(that.state.currentItem.times[t].end));
        var cancelled = (that.state.currentItem.times[t].cancelled === undefined || that.state.currentItem.times[t].cancelled === null) ? false : that.state.currentItem.times[t].cancelled;
        var head = "Veranstaltungstermin " + (parseInt(t) + 1);
        if (new Date(that.state.currentItem.times[t].start) > new Date() && (that.state.currentItem.times[t].attendees === undefined || that.state.currentItem.times[t].attendees === null || that.state.currentItem.times[t].attendees.length === 0)) {
          return (<div key={t} className={[classes.floatrelative, classes.fullWidthMargin20Both, classes.areaborderFull].join(' ')}>
            <div className={classes.fullWidth}>{head}:</div>
            <TextField
              id="datetime-start"
              label="Start"
              type="datetime-local"
              onChange={(e) => that.textchange(that, 'timestart', t, e)}
              defaultValue={start}
              className={classes.floatrelative, classes.datebox}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-end"
              label="Ende"
              type="datetime-local"
              onChange={(e) => that.textchange(that, 'timeend', t, e)}
              defaultValue={end}
              className={classes.floatrelative, classes.datebox}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.caption}>Ort:</div>
            <OutlinedInput className={[classes.textboxLeft, classes.inputcontentSmall].join(' ')}
              value={that.state.currentItem.times[t].place}
              onChange={(e) => that.textchange(that, 'timeplace', t, e)} />
            <div className={classes.caption}>Google Maps Link:</div>
            <OutlinedInput className={[classes.textboxLeft, classes.inputcontentSmall].join(' ')}
              value={that.state.currentItem.times[t].gmaps}
              onChange={(e) => that.textchange(that, 'timegmaps', t, e)} />
            <div className={classes.caption}>Max. Teilnehmerzahl:</div>
            <OutlinedInput className={[classes.textboxLeft, classes.inputcontentSmall].join(' ')}
              value={that.state.currentItem.times[t].maxnumber}
              onChange={(e) => that.textchange(that, 'timemaxnumber', t, e)} />
            <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
              <FormControlLabel
                control={<Checkbox checked={cancelled} className={classes.checkbox}
                  name="cancelled" onChange={() => that.changeBool(that, 'cancelled', t)} />}
                label={<div>Abgesagt</div>}
              />
            </div>
          </div>
          );
        }
        else {
          return (
            <div key={t} className={[classes.floatrelative, classes.fullWidthMargin20Both, classes.areaborderFull].join(' ')}>
              <div className={classes.fullWidth}>{head}:</div>
              <div className={[classes.fullWidthMargin20Both, classes.textleft].join(' ')}>Start: {start}</div>
              <div className={[classes.fullWidthMargin20Both, classes.textleft].join(' ')}>Ende: {end}</div>
              <div className={[classes.fullWidthMargin20Both, classes.textleft].join(' ')}>Ort: {that.state.currentItem.times[t].place}</div>
              <div className={[classes.fullWidthMargin20Both, classes.textleft].join(' ')}>Google Maps Link: {that.state.currentItem.times[t].gmaps}</div>
              <div className={[classes.fullWidthMargin20Both, classes.textleft].join(' ')}>Max. Teilnehmer: {that.state.currentItem.times[t].maxnumber}</div>
              <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                <FormControlLabel
                  control={<Checkbox checked={cancelled} className={classes.checkbox}
                    name="cancelled" onChange={() => that.changeBool(that, 'cancelled', t)} />}
                  label={<div>Abgesagt</div>}
                />
              </div>
            </div>);
        }
      });
    }
    return timescontent;
  }
}

export default withStyles(useStyles)(EventOverview);
