import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import defaultStyles from '../base';

const additionalStyles = {
    contactimagecontainer: {
        position: 'relative',
        float: 'left',
        width: '90%',
        marginLeft: '8px',
        marginRight: '8px'
    },
    contactimage: {
        position: 'relative',
        width: '80%',
        margin: 'auto',
        padding: '2px',
    },
    contactDivider: {
        position: 'relative',
        backgroundColor: '#AAAAAA',
        height: 1,
        width: 34,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '12px',
        marginBottom: '24px'
    },
    contactContainercontent: {
        float: 'left',
        position: 'relative',
        padding: '0px',
        width: '50%',
        marginTop: '24px'
    },
    contactContainercontentSelected: {
        float: 'left',
        position: 'relative',
        padding: '0px',
        width: '100%',
        marginTop: '24px'
    },
    contactImageFullWidthUnTransparent90: {
        width: '90%',
        left: '5%',
        opacity: '1.0'
    },
    contactImageFullWidthUnTransparent40: {
        width: '40%',
        left: '5%',
        opacity: '1.0'
    },
    tableinfocontainer: {
        position: 'relative',
        float: 'left',
        left: '5%',
        width: '90%',
        display: 'none'
    },
    tableinfocontainerVisible: {
        position: 'relative',
        float: 'left',
        left: '5%',
        width: '90%',
        display: 'block'
    },
    tableinfotext: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: '100',
        textAlign: 'left'
    },
    tableinfohead: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: '500',
        verticalAlign: 'text-top',
        textAlign: 'left'
    },
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class NewsContactInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contacts: this.createContactInfo(),
            imageid: 3
        };
    }


    componentDidMount() {

    }

    render() {
        var information = this.createContacts();
        return information;
    }

    createContacts() {
        const { classes } = this.props;
        var that = this;
        var imgid = this.state.imageid;
        var contacts =
            (this.state.contacts.map(function (item, idx) {
                var profileid = "profile" + idx;
                var license = item.licenses.map((l, i) => (
                    <React.Fragment key={i}>{l}{i < (item.licenses.length - 1) ? <br /> : ''}</React.Fragment>
                ));
                var specials = item.specials.map((l, i) => (
                    <React.Fragment key={i}>{l}{i < (item.specials.length - 1) ? <br /> : ''}</React.Fragment>
                ));
                var motto = item.motto.map((l, i) => (
                    <React.Fragment key={i}>{l}{i < (item.motto.length - 1) ? <br /> : ''}</React.Fragment>
                ));
                var plannedlicense = item.plannedlicenses.map((l, i) => (
                    <React.Fragment key={i}>{l}{i < (item.plannedlicenses.length - 1) ? <br /> : ''}</React.Fragment>
                ));
                var mainClass = classes.contactContainercontent;
                var subClass = classes.contactImageFullWidthUnTransparent40;
                var infoClass = classes.tableinfocontainer;
                if (that.props.profileid !== -1) {
                    mainClass = classes.contactContainercontentSelected;
                    subClass = classes.contactImageFullWidthUnTransparent90;
                    infoClass = classes.tableinfocontainerVisible;
                }
                var btn = (that.props.profileid > -1) ? <div /> : (<Button className={classes.buttonContentMore} onClick={() => that.props.selectProfile(idx)}>
                    Mehr
                </Button>);
                
                        
                return (
                    (that.props.profileid > -1 && idx !== that.props.profileid) ? <div key={item.id} /> :
                        <div key={item.id} className={mainClass}>
                            <div className={classes.textimagecontainer}>
                                <img id={profileid} alt="Profile" src={item.images[imgid]} className={subClass} />
                            </div>
                            <div className={classes.submenuheaderlight}>
                                <div>{item.name} {item.surname}</div>
                            </div>
                            {btn}
                            <div className={infoClass}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={classes.tableinfohead}>geboren:</td><td className={classes.tableinfotext}>{item.birthday}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableinfohead}>Spitzname:</td><td className={classes.tableinfotext}>{item.nickname}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableinfohead}>Mail:</td><td className={classes.tableinfotext}>{item.mail}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableinfohead}>Lizenzen:</td><td className={classes.tableinfotext}>{license}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableinfohead}>Lizenzen in Arbeit:</td><td className={classes.tableinfotext}>{plannedlicense}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableinfohead}>Vorlieben:</td><td className={classes.tableinfotext}>{specials}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableinfohead}>Motto:</td><td className={classes.tableinfotext}>{motto}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>);

            }));
        return contacts;
    }

    createContactInfo() {
        return [{
            id: 0,
            name: "Meier",
            surname: "Tobias",
            images: [
                "./assets/contact/gnaaaz1.jpg",
                "./assets/contact/gnaaaz2.jpg",
                "./assets/contact/gnaaaz3.jpg",
                "./assets/contact/gnaaaz4.jpg"
            ],
            birthday: "09.09.1983",
            nickname: "Gnaaaz",
            mail: "gnaaaz@nolimitz-fitness.de",
            licenses: [
                "C-Lizenz Fitness-Coach"
            ],
            plannedlicenses: [
                "B-Lizenz Fitness-Coach",
                "C-Lizenz Ernährungsberatung"
            ],
            specials: ["Zweirad-Guide",
                "Berge",
                "Wintersport",
                "Eventplanung",
                "Triathlon",
                "Muskelaufbau"
            ],
            motto: [
                "Wer es probiert kann verlieren... Wer es nicht versucht hat schon verloren!"
            ],
        },
        {
            id: 1,
            name: "Faltermeier",
            surname: "Andreas",
            images: [
                "./assets/contact/falti2.jpg",
                "./assets/contact/falti1.jpg",
                "./assets/contact/falti3.jpg",
                "./assets/contact/falti4.jpg"
            ],
            birthday: "30.07.1980",
            nickname: "Falti",
            mail: "falti@nolimitz-fitness.de",
            licenses: [
                "C-Lizenz Fussball (BFV, Kinder/Jugend)",
                "C-Lizenz Fitness-Coach",
                "C-Lizenz Ernährungsberatung"
            ],
            plannedlicenses: [
                "B-Lizenz Fitness-Coach"
            ],
            specials: ["Teamsport",
                "Motivation",
                "Fitness",
                "Eventplanung",
                "Triathlon"
            ],
            motto: [
                "Motivation bringt Dich in Gang! Gewohnheit bringt Dich voran!"
            ]
        }];

    }
}

export default withStyles(useStyles)(NewsContactInfo);