import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { OutlinedInput } from '@material-ui/core';
import defaultStyles from '../base'
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { FirebaseStorage } from '../../lib/database';
import { FirebaseAuth } from "../../lib/authentication";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { WorkoutData } from '../../controller/data/workoutdata'
import Regex from '../../lib/regex';
import { RequestMethods } from "../../lib/request";
import dateformat from 'date-format';
import $ from "jquery";

const additionalStyles = {

  spacer: {
    position: 'relative',
    float: 'left',
    width: '20px',
    height: '20px'
  },
  kettlebellimage: {
    position: 'relative',
    float: 'left',
    width: '10px',
    height: '10px',
    marginLeft: '5px',
    left: '0px',
    opacity: '1.0',
    color: '#00D2FF'
  },
  cancelledimage: {
    position: 'relative',
    float: 'left',
    width: '60px',
    height: '20px',
    marginLeft: '5px',
    left: '0px',
    opacity: '1.0',
    color: '#00D2FF'
  }
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class Workout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      workouts: [],
      overview: true,
      selected: -1,
      email: '',
      errortext: "",
      infotext: ""
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.timerID = -1;
  }

  componentDidMount() {
    this.resize();
    var that = this;
    $(window).resize(function () {
      that.resize();
    });
    this.refreshState();

    this.timerID = setInterval(() => {
      if (!(this.state.errortext === "" && this.state.infotext === "")) {
        this.setState(state => ({
          errortext: "",
          infotext: ""
        }));
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  resize() {
    $(".trainingprogress").width(($(".trainingprogress").parent().width() * 0.9) - 120);
  }

  refreshState() {
    this.setState({
      workouts: []
    });
    var that = this;
    FirebaseStorage.loadAll("workouts", (wk) => {
      var newwks = that.state.workouts;
      newwks.push(wk);
      var selectedworkout = "";
      if (RequestMethods.getQueryVariable("workout") !== "") {
        selectedworkout = RequestMethods.getQueryVariable("workout");            
      }
      if (that.props.selectedworkout !== undefined && that.props.selectedworkout !== "") {
        selectedworkout = that.props.selectedworkout;
      }
      if (selectedworkout !== '' && wk.key === selectedworkout) {
        that.setState({
          workouts: newwks,
          overview: false,
          selected: selectedworkout
        });
      }
      else {
        that.setState({
          workouts: newwks
        });
      }
    });
  }

  handleClick(id) {
    this.setState(state => ({
      overview: false,
      selected: id
    }));
  }

  handleBack() {
    this.setState(state => ({
      overview: true,
      selected: -1
    }));
  }

  registerProfile(key, wk, that) {
    var eventdata = new WorkoutData();
    eventdata.assignUser(key, FirebaseAuth.currentUser.uid);
    var newwks = [];
    FirebaseStorage.loadAll("workouts", (wk) => {
      //var newwks = that.state.workouts;
      newwks.push(wk);
      that.setState({
        workouts: newwks,
        infotext: "Erfolgreich angemeldet!"
      });
    });
  }

  unregisterProfile(key, wk, that) {
    var attKeys = Object.keys(wk.time.attendees);
    var delKey = "";
    attKeys.forEach((attKey) => {
      if (wk.time.attendees[attKey].key.includes(FirebaseAuth.currentUser.uid)) {
        delKey = attKey;
      }
    });
    if (delKey !== "") {
      var eventdata = new WorkoutData();
      eventdata.unassign(key, delKey);
      var newwks = [];
      FirebaseStorage.loadAll("workouts", (wk) => {
        //var newwks = that.state.workouts;
        newwks.push(wk);
        that.setState({
          workouts: newwks,
          infotext: "Erfolgreich abgemeldet!"
        });
      });
    }
  }

  registerEmail(key, wk, that) {
    var error = "";
    if (that.state.email === "") {
      error = "Keine Emailadresse angegeben!"
    }
    if (!new Regex().validateEmail(that.state.email)) {
      error = "Keine gültige Emailadresse angegeben!"
    }
    var emailregistered = false;
    for (var i = 0; i < this.state.workouts.length; i++) {
      if (this.state.workouts[i].key === this.state.selected) {
        var selectedworkout = this.state.workouts[i].val();
        if (selectedworkout.time.attendees) {
          var attKeys = Object.keys(selectedworkout.time.attendees);
          attKeys.forEach((attKey) => {
            if (selectedworkout.time.attendees[attKey].email.toLowerCase() === that.state.email.toLowerCase()) {
              emailregistered = true;
            }
          });
        }
      }
    }
    if (emailregistered) {
      error = "Diese Email-Adresse ist bereits angemeldet!"
    }
    if (error === "") {
      var eventdata = new WorkoutData();
      eventdata.assignEmail(key, that.state.email);
      FirebaseStorage.loadAllComplete("workouts", (wks) => {
        var newwks = [];
        wks.forEach(function (wk) {
          newwks.push(wk);
        });
        that.setState({
          workouts: newwks,
          infotext: that.state.email + " wurde erfolgreich registriert!"
        });
      });
    }
    else {
      this.setState(state => ({
        errortext: error
      }));
    }
  }

  textchange(e) {
    var val = e.target.value;
    this.setState(state => ({
      email: val.trim()
    }));
  }

  createSelectedWorkout(selectedworkout) {
    const { classes } = this.props;
    var that = this;
    var mode = (window.orientation === undefined) ? 'web' : 'mobile';
    var times = <div />;
    var errortext = this.state.errortext;
    var infotext = this.state.infotext;
    var selectedworkouttext = <div />;
    var selectedworkcoretopics = <div />;
    var selectedkey = this.state.selected;    
    var bottompagespace = <div className={classes.bottompagespace} />;
    var signedin = (FirebaseAuth.currentUser !== null && FirebaseAuth.currentUser.emailVerified === true);//&& FirebaseAuth.profile !== null
    var buttondisabled = false;
    var link = <div />;
    var cancelled = <div><img className={classes.cancelledimage} src='./assets/cancelled.png' alt="Abgesagt" /></div>
    var emailinput = (signedin) ? (<div><div className={classes.infolabel}>{infotext}</div><div className={classes.errorlabel}>{errortext}</div></div>) : (<div><div className={classes.emailloginlabel}>Bitte Email eingeben zur Anmeldung:</div><OutlinedInput className={classes.textbox} onChange={(e) => that.textchange(e)} /><div className={classes.infolabel}>{infotext}</div><div className={classes.errorlabel}>{errortext}</div></div>);
    
    selectedworkouttext = (<div className={classes.divinfocontainer}><div className={classes.divinfotextfull}>
        {selectedworkout.text}
      </div></div>);    

    selectedworkcoretopics = Object.entries(selectedworkout.keytopics).map(([key, value]) => {
      var imgs = [];
      for (var i = 0; i < value; i++) {
        var k = 'img' + i;
        imgs.push(<img key={k}
          id={k}
          className={classes.kettlebellimage}
          src='./assets/kettlebell-solid.png'
          alt={i}
        />);
      }
      return (
        <div key={key} className={classes.divinfocontainer}><div className={classes.divinfohead}>{key}</div><div className={classes.divinfotext}>{imgs}</div></div>);
    });
    var item = selectedworkout.time;    
    if (new Date(item.end).getTime() > new Date().getTime()) {
      var dates = [];
      var currentdate = new Date(item.start).getTime();
      var timestring = dateformat.asString('hh:mm', new Date(currentdate));
      while (currentdate <= new Date(item.end).getTime()) {
        dates.push( dateformat.asString('dd.MM.yy', new Date(currentdate)) + ' ' + timestring );
        currentdate += 1000 * 60 *60 * 24* 7;
      }
      var completeddateinfo = dates.map(function(dt, idx) {
        return (<div key={idx} className={[classes.floatrelative, classes.fullwidth].join(' ')}>{dt}</div>)
      });

      var booked = 0;
      if (item.attendees) {
        var attKeys = Object.keys(item.attendees);
        booked = attKeys.length;
      }
      var required = 10 - booked;
      var freetext = (required > 0) ? (required + " Anmeldungen benötigt damit der Kurs stattfindet") : "Kurs findet statt";      
      var progress = (item.cancelled && (item.cancelled === 1 || item.cancelled === true)) 
        ? (<div />) 
        : (<div className={classes.trainingprogresscontainer}>            
            <div className={classes.trainingprogresstext}>{freetext}</div>
          </div>);
      if (signedin) {
        var assigned = false;
        if (item.attendees) {
          var attKeys2 = Object.keys(item.attendees);
          attKeys2.forEach((attKey) => {
            if (item.attendees[attKey].key.includes(FirebaseAuth.currentUser.uid)) {
              assigned = true;
            }
          });
        }
        if (assigned) {
          var icon = (<IconButton className={classes.trainingiconnoaction} size="small" >
            <CheckCircleIcon fontSize="small" alt="Angemeldet" />
          </IconButton>);
          var unregisterProfile = (<Button className={classes.trainingicontext} disabled={buttondisabled} size="small" onClick={() => that.unregisterProfile(selectedkey, selectedworkout, that)}>
            Kurs stornieren
          </Button>);
          link = (item.gmaps) ? (<div>(<a href={item.gmaps} target="_blank" rel="noopener noreferrer">Google Maps Link</a>)</div>) : (<div />);
          times =  (<div key={selectedkey + item.start} className={classes.timesContainer}>
            <div className={classes.timesContainerInner}>
              <div className={classes.training}>
              <div className={[classes.floatrelative, classes.fullwidth].join(' ')}><b>Termine:</b></div>
                {completeddateinfo}<br />({item.place}) {link}
              </div>
              {icon}
              {unregisterProfile}
            </div>
            <div className={classes.timesContainerInner}>
              {progress}
            </div>
            <div className={classes.mainspacer} />
          </div>
          )
        }
        else {

          var registerProfile = (item.cancelled && (item.cancelled === 1 || item.cancelled === true)) ? cancelled :            
              (<Button className={classes.trainingicontext} disabled={buttondisabled} size="small" onClick={() => that.registerProfile(selectedkey, selectedworkout, that)}>
                Kurs buchen
              </Button>);
          link = (item.gmaps) ? (<div>(<a href={item.gmaps} target="_blank" rel="noopener noreferrer">Google Maps Link</a>)</div>) : (<div />);
          times =  (<div key={selectedkey + item.start} className={classes.timesContainer}>
            <div className={classes.timesContainerInner}>
              <div className={classes.training}>
                <div className={[classes.floatrelative, classes.fullwidth].join(' ')}><b>Termine:</b></div>
                {completeddateinfo}<br />{item.place}<br />{link}
              </div>
              {registerProfile}
            </div>
            <div className={classes.timesContainerInner}>
              {progress}
            </div>
            <div className={classes.mainspacer} />
          </div>
          )
        }
      }
      else {
        var registerEmail = (item.cancelled && (item.cancelled === 1 || item.cancelled === true)) ? cancelled :
            (<Button className={classes.trainingicontext} size="small" disabled={buttondisabled} onClick={() => that.registerEmail(selectedkey, selectedworkout, that)}>
              Kurs buchen
            </Button>);
        link = (item.gmaps) ? (<div>(<a href={item.gmaps} target="_blank" rel="noopener noreferrer">Google Maps Link</a>)</div>) : (<div />);
        times = (<div key={selectedkey + item.start} className={classes.timesContainer}>
          <div className={classes.timesContainerInner}>
            <div className={classes.training}>
            <div className={[classes.floatrelative, classes.fullwidth].join(' ')}><b>Termine:</b></div>
              {completeddateinfo}<br />{item.place}<br />{link}
            </div>
            {registerEmail}
          </div>
          <div className={classes.timesContainerInner}>
            {progress}
          </div>
          <div className={classes.mainspacer} />
        </div>
        )
      }
    }
    var header =
        (<div className={classes.containersubmenu}>

          <div className={classes.titlesubmenu} color="textPrimary">
            Workout
      </div>
          <NavigateBeforeIcon className={classes.backbutton} fontSize="large" onClick={that.handleBack} />
        </div>);
    
    var backstyle = { 
      backgroundImage: "url(" + selectedworkout.image + ")",
      backgroundSize: "cover"
    }
    var fontsite = (mode == 'web') ? {
      fontSize: '24px'
    } : {
      fontSize: '15px'
    }
    var leftbadges = selectedworkout.content.map(function(cont, idx) {
      return (<div key={idx} className={classes.leftbadge} style={fontsite}>
          {cont.info}
      </div>)
    });
    var rightbadges = selectedworkout.sidekicks.map(function(cont, idx) {
      return (<div key={idx} className={classes.rightbadge} style={fontsite}>
          {cont.info}
      </div>)
    });
    var workouts = (<div className={classes.containercontentSub} key={selectedkey}>       
        <div className={classes.containercontent} style={backstyle}>
            <div className={classes.submenuheader} color="textPrimary">
              {selectedworkout.title}
            </div>   
            {rightbadges}         
            {leftbadges}                               
        </div>
        <div className={classes.teaserlabel}>
          {selectedworkout.teaser}
        </div>
        {bottompagespace}
        <div className={classes.divcompletecontainer}>
          {selectedworkcoretopics}
        </div>
        {bottompagespace}
        <div className={[classes.divcompletecontainer, classes.textcenter].join(' ')}>
          {selectedworkouttext}
        </div>
        {bottompagespace}
        <Divider variant="middle" classes={{ root: classes.dividerContent }} />
        {emailinput}
        {times}
      </div>);
      return (

        <Card className={classes.root}>
          {header}
          {workouts}
          {bottompagespace}
        </Card>
      );
  }

  renderOverview() {
    const { classes } = this.props;
    var that = this;    
    var bottompagespace = <div className={classes.bottompagespace} />;
    var header = (<div className={classes.containertitle}>
          <Typography className={classes.title} color="textSecondary">
            Workout
      </Typography>
          <Divider variant="middle" classes={{ root: classes.divider }} /></div>);
    var workouts = this.renderOverviewContent();
    

    return (
      <Card className={classes.root}>
        {header}
        {workouts}
        {bottompagespace}
      </Card>
    );
  }

  renderOverviewContent() {
    const { classes } = this.props;
    var that = this;
    var mode = (window.orientation === undefined) ? 'web' : 'mobile';
    var activeworkouts = 0;
    var workouts =
    (this.state.workouts.map(function (item) {
      var workout = item.val();
      var key = item.key;
      if (workout.deprecated === undefined || workout.deprecated == false) {
        activeworkouts++;
        var backstyle = { 
          backgroundImage: "url(" + workout.image + ")",
          backgroundSize: "cover"
        }
        var fontsite = (mode == 'web') ? {
          fontSize: '24px'
        } : {
          fontSize: '15px'
        }
        var leftbadges = workout.content.map(function(cont, idx) {
          return (<div key={idx} className={classes.leftbadge} style={fontsite}>
              {cont.info}
          </div>)
        });
        var rightbadges = workout.sidekicks.map(function(cont, idx) {
          return (<div key={idx} className={classes.rightbadge} style={fontsite}>
              {cont.info}
          </div>)
        });
      
        return (
          <div className={classes.containercontent} key={key} style={backstyle}>
            <div className={classes.submenuheader} color="textPrimary">
              {workout.title}
            </div>
            <Button key={key} className={classes.buttonContentMore} onClick={() => that.handleClick(key)}>
              Details und Anmeldung
            </Button>
            {rightbadges} 
            {leftbadges}         
            
            <Divider variant="middle" classes={{ root: classes.dividerContent }} />
          </div>)
        }
        else {
          return (<div key={key} />)
        }
    }));
    if (activeworkouts > 0) {
      return workouts;
    }
    else {
      return (<div className={classes.noeventworkouttext}>Aktuell stehen leider keine Events an. Wir informieren Euch rechtzeitig, wenn es etwas Neues gibt. Bleibt dran!</div>);
    }
  }

  render() {
    var that = this;
    
    for (var i = 0; i < that.state.workouts.length; i++) {
      if (that.state.workouts[i].key === that.state.selected) {    
        var workout = that.state.workouts[i].val();            
        return that.createSelectedWorkout(workout);
      }
    }

    return this.renderOverview();
    
  }
}

export default withStyles(useStyles)(Workout);
