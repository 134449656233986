import { withStyles } from '@material-ui/core/styles';
import React from "react";
import defaultStyles from '../base'
import agbinfo from "./agb.json";
import Divider from '@material-ui/core/Divider';
import $ from "jquery";

const additionalStyles = {      
    
    agbheader: {
        fontFamily: 'Anton',
        fontSize: '18px',
        marginTop: '12px',
        marginBottom: '12px',
        textAlign: 'center',
        color: '#00D2FF',
    },
    agbsubheader: {
        fontFamily: 'Roboto',
        fontSize: '10px',
        marginTop: '12px',
        marginBottom: '12px',
        marginLeft: '5%',
        marginRight: '5%',
        textAlign: 'center'
    },
    agbtitle: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        marginTop: '12px',
        marginBottom: '12px',
        marginLeft: '5%',
        marginRight: '5%',
        textAlign: 'left'
    },
    agbtext: {
        fontFamily: 'Roboto',
        fontSize: '10px',
        marginTop: '12px',
        marginBottom: '12px',
        marginLeft: '10%',
        marginRight: '10%',
        textAlign: 'left'
    },
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)
  
class Agb extends React.Component {

  constructor (props) {
    super(props);    
    this.state = {     
      agb: 'Das sind die AGBs',      
    };
  }

  componentDidMount() {
    $("#extramenucontainer").height(window.innerHeight - 35);
    $(window).resize(function() {
      $("#extramenucontainer").height(window.innerHeight - 35);
    });    
  }

  render() {
    const { classes } = this.props;
    var title = 'AGB';
    return (<div key='agb' id='extramenucontainer' className={classes.extramenucontainer}>
                <div key='title' className={classes.title}>
                    {title}
                </div>   
                <Divider variant="middle" classes={{root: classes.divider}}/>    
                <div key='content' id='agb' className={classes.extramenucontent}>
                    { 
                        agbinfo.sections.map(function(section, mainindex){                                                    
                            return(<div key={mainindex}>
                                    <div className={classes.agbheader}>{section.key}</div>
                                    <div className={classes.agbsubheader}>{section.subheader}</div>
                                    {
                                        section.dots.map(function(dot, index) {
                                            var no = index+1;
                                            return(<div key={index}>
                                                    <div className={classes.agbtitle}>{no}. {dot.key}</div>
                                                    <div className={classes.agbtext}>{dot.text}</div>
                                                    {
                                                        dot.sections.map(function(section, index2) {
                                                            var no2 = index2 + 1;
                                                            return(<div key={index2}>
                                                                    <div className={classes.agbtitle}>{no}.{no2} {section.key}</div>
                                                                    <div className={classes.agbtext}>{section.text}</div>
                                                                </div>)
                                                        })
                                                    }
                                                </div>)
                                        })
                                    }
                            </div>)
                        })
                    } 
                </div>   
            </div>      
        );
    }
}

export default withStyles(useStyles)(Agb);
