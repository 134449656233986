import React from "react";
import { withStyles } from '@material-ui/core/styles';
import defaultStyles from '../base';
import $ from "jquery";

const additionalStyles = {
    
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class NewsPartner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.partners = [ 
            {
              name : "",
              image : "./assets/partners/emsdynamics_logo.png",
              url : "https://www.ems-dynamics.de/"
            },
            {
              name : "",
              image : "./assets/partners/augenstern_logo.png",
              url: "http://www.augenstern-schmuck.de"
            },
            {
              name : "",
              image : "./assets/partners/defshop_logo.png",
              url: "https://www.def-shop.com/defshop/"
            },
            {
              name : "",
              image : "https://www.lenk-bau.de/cms/upload/design/logo.png",
              url: "https://www.lenk-bau.de"
            }
          ];
        this.currentIndex = 0;
    }

    componentDidMount() {
        var that = this;
        this.timerID = setInterval(() => {
            $("#partnerimage").fadeTo(1000, 0.0, function () {
                that.currentIndex++;
                if (that.currentIndex > that.partners.length - 1) {
                    that.currentIndex = 0;
                }
                $("#partnerimage").attr('src', that.partners[that.currentIndex].image);
                $("#partnerimage").fadeTo(1000, 0.3);
            });
        }, 10000);
    }

    openPartner() {
        window.open(this.partners[this.currentIndex].url);
    }

    render() {
        const { classes } = this.props;
        var that = this;
        return (<div className={[classes.floatrelative, classes.textimagecontainer].join(' ')} onClick={() => this.openPartner()}>
                <img id='partnerimage' alt='Home'
                    className={[classes.imageFullWidthUnTransparent,  classes.clickable].join(' ')}
                    src={this.partners[that.currentIndex].image}
                    onClick={() => this.openPartner()}
                />
            </div>);
    }

}

export default withStyles(useStyles)(NewsPartner);