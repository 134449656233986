import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Timer from 'react-compound-timer';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Vimeo from '@u-wave/react-vimeo';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveIcon from '@material-ui/icons/Save';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import Checkbox from '@material-ui/core/Checkbox';
import $ from "jquery";

const additionalStyles = {
    preparecontainer: {
        width: '25%',
        padding: '0px',
        margin: '0px'
    },
    imageMaxHeight: {
        maxHeight: '80px'
    },
    cancelButton: {
        position: 'absolute',
        float: 'right',
        right: '0px'
    },
    progressbar: {
        width: '50px',
        height: '50px'
    },
    countdown: {
        width: 'calc(100% - 110px - 110px - 40px)',
        marginLeft: '20px',
        height: '50px',
        overflow: 'hidden'
    },
    countdowntext: {
        height: '50px',
    }
}
const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class WorkoutExecutePerform extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workoutinfo: this.props.workoutinfo,
            prestart: true,
            warmup: false,
            showcancelreally: false,
            cooldown: false,
            currentDrill: 0,
            currentExercise: 0,
            currentWarmup: 0,
            currentCooldown: 0,
            paused: false,
            completed: false,
            currentImage: 0,
            runtime: null,
            informationtype: 1
        };
        this.imageTimer = -1;
    }

    componentDidMount() {
        var that = this;
        this.imageTimer = setInterval(() => {
            that.refreshTimer();
        }, 500);
    }

    refreshTimer() {
        if (!this.state.prestart && !this.state.completed) {
            var ts = Date.now();
            var start = (this.state.workoutinfo.warmup === null) ? this.state.workoutinfo.drills[0].exercises[0].start : this.state.workoutinfo.warmup.drills[0].exercises[0].start;
            var difference = new Date(ts - start);
            var diff_hours = (difference.getHours() - 1).toString();
            if (diff_hours.length === 1) {
                diff_hours = "0" + diff_hours;
            }
            var diff_mins = difference.getMinutes().toString();
            if (diff_mins.length === 1) {
                diff_mins = "0" + diff_mins;
            }
            var diff_secs = difference.getSeconds().toString();
            if (diff_secs.length === 1) {
                diff_secs = "0" + diff_secs;
            }
            this.setState({
                runtime: diff_hours + ":" + diff_mins + ":" + diff_secs
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.imageTimer);
    }

    close() {
        this.props.close(this.state.workoutinfo, true);
    }

    start() {
        var ts = Date.now();
        var wi = this.state.workoutinfo;
        if (wi.warmup === null) {
            wi.drills[0].exercises[0].start = ts;
            this.setState({
                workoutinfo: wi,
                prestart: false
            });
        }
        else {

            wi.warmup.drills[0].exercises[0].start = ts;
            this.setState({
                workoutinfo: wi,
                prestart: false,
                warmup: true
            });
        }
    }

    setStartTime() {
        var ts = Date.now();
        var wi = this.state.workoutinfo;
        var cd = this.state.currentDrill;
        var ce = this.state.currentExercise;
        wi.drills[cd].exercises[ce].start = ts;
        this.setState({
            workoutinfo: wi,
            paused: false
        });
    }

    setEndTime() {
        var that = this;
        var ts = Date.now();
        var wi = this.state.workoutinfo;
        if (this.state.warmup) {
            var currentwarmup = this.state.currentWarmup;
            wi.warmup.drills[currentwarmup].exercises[0].end = ts;
            currentwarmup++;
            if (currentwarmup === wi.warmup.drills.length) {
                wi.drills[this.state.currentDrill].exercises[this.state.currentExercise].start = ts;
                that.setState({
                    warmup: false,
                    workoutinfo: wi,
                    paused: true
                });
                if (!wi.usePause) {
                    setTimeout(() => {
                        that.setState({                            
                            paused: false
                        });
                    }, 100);
                }
            }
            else {
                that.setState({
                    workoutinfo: wi,
                    paused: true
                });
                setTimeout(() => {
                    wi.warmup.drills[currentwarmup].exercises[0].start = ts;
                    that.setState({
                        currentWarmup: currentwarmup,
                        workoutinfo: wi,
                        paused: false
                    });
                }, 100);
            }
        }
        else if (this.state.cooldown) {
            var currentcooldown = this.state.currentCooldown;
            wi.cooldown.drills[currentcooldown].exercises[0].end = ts;
            currentcooldown++;
            if (currentcooldown === wi.cooldown.drills.length) {
                that.setState({
                    cooldown: false,
                    workoutinfo: wi,
                    paused: wi.usePause
                });
            }
            else {
                that.setState({
                    workoutinfo: wi,
                    paused: true
                });
                setTimeout(() => {
                    wi.cooldown.drills[currentcooldown].exercises[0].start = ts;
                    that.setState({
                        currentCooldown: currentcooldown,
                        workoutinfo: wi,
                        paused: false
                    });
                }, 100);
            }
        }
        else {
            wi.drills[this.state.currentDrill].exercises[this.state.currentExercise].end = ts;
            var cd = this.state.currentDrill;
            var ce = this.state.currentExercise;
            cd++;
            if (cd >= wi.drills.length) {
                cd = 0;
                ce++;
            }
            var completed = false;
            var cooldown = false;
            if (this.state.currentDrill === (wi.drills.length - 1) &&
                this.state.currentExercise === wi.drills[this.state.currentDrill].exercises.length - 1) {
                completed = true;
                if (wi.cooldown !== null) {
                    cooldown = true;
                }
            }
            var pause = true;
            this.setState({
                workoutinfo: wi,
                paused: pause,
                completed: completed,
                cooldown: cooldown,
                currentDrill: cd,
                currentExercise: ce
            });
            if (completed === false && wi.usePause === false) {
                setTimeout(() => {
                    var tsStart = Date.now();
                    var wi = that.state.workoutinfo;
                    var cd = that.state.currentDrill;
                    var ce = that.state.currentExercise;
                    wi.drills[cd].exercises[ce].start = tsStart;
                    pause = false;
                    that.setState({
                        workoutinfo: wi,
                        paused: pause
                    });
                }, 100);

            }
            if (completed === true && cooldown === true) {
                setTimeout(() => {
                    var currentcooldown = this.state.currentCooldown;
                    wi.cooldown.drills[currentcooldown].exercises[0].end = ts;
                    pause = false;
                    that.setState({
                        workoutinfo: wi,
                        paused: pause
                    });
                }, 100);

            }
        }
    }

    next() {
        if (this.state.warmup || this.state.cooldown || !this.state.paused) {
            this.setEndTime();
        }
        else {
            this.setStartTime();
        }
    }

    cancel() {
        this.props.close(this.state.workoutinfo, false);
    }

    showReallyCancel(status) {
        if (status) {
            $("#cancelreally").show();
        }
        else {
            $("#cancelreally").hide();
        }
    }

    changeBool(type) {
        var wi = this.state.workoutinfo;
        if (type === 'publish') {
            var oldstate = (this.state.workoutinfo.publish === undefined) ? false : this.state.workoutinfo.publish;
            var newstate = !oldstate;
            wi.publish = newstate;
        }
        this.setState({
            workoutinfo: wi
        });
        ;
    }

    beep(short, halftime) {
        if (halftime) {
            $('#Timer_Half').hide();
            $('#halftime').show();
            setTimeout(() => {
                $('#halftime').hide();
                $('#Timer_Full').show();
            }, 5000);
        }

        if (short) {
            document.getElementById('shortbeep').muted = false;
            document.getElementById('shortbeep').play();
        }
        else {
            document.getElementById('overbeep').muted = false;
            document.getElementById('overbeep').play();
        }
    }

    sound(title) {
        document.getElementById(title).muted = false;
        document.getElementById(title).play();
    }

    setInformationType(t) {
        this.setState({
            informationtype: t
        })
    }

    render() {
        if (this.state.prestart) {

            return this.renderPreStart();
        }
        else if (this.state.warmup) {
            if (this.state.paused) {
                return (<div />);
            }
            return this.renderWarmup();
        }
        else if (this.state.cooldown) {
            if (this.state.paused) {
                return (<div />);
            }
            return this.renderCooldown();
        }
        else if (this.state.completed) {
            return this.renderCompleted();
        }
        else {
            if (this.state.paused) {
                if (this.state.workoutinfo.usePause === false) {
                    return (<div />);
                }
                return this.renderExecutionPause();
            }
            return this.renderExecution();
        }
    }

    renderPreStart() {
        const { classes } = this.props;
        var introtext = (<div />);
        var infoheader = this.createExerciseHeader(false, this.state.workoutinfo.drills[this.state.currentDrill].exercises[this.state.currentExercise], false, false);
        var info = (this.state.workoutinfo.warmup === null)
            ? this.createExerciseView(false, this.state.workoutinfo.drills[this.state.currentDrill], this.state.workoutinfo.drills[this.state.currentDrill].exercises[this.state.currentExercise])
            : (<div />);
        var cancelline = this.createCancelLine();
        return (

            <div>
                {infoheader}
                <div className={classes.mainspacer} />
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft].join(' ')} onClick={() => this.start()}>
                    <PlayCircleOutlineIcon />
                </IconButton>
                {introtext}
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonright].join(' ')} onClick={() => this.showReallyCancel(true)}>
                    <CancelIcon />
                </IconButton>
                <div className={classes.mainspacer} />
                {cancelline}
                <div className={classes.mainspacer} />
                {info}
            </div>
        );
    }

    renderWarmup() {
        const { classes } = this.props;
        var timer = this.createCompleteTimer();
        var audio = this.createAudio();
        var infoheader = this.createExerciseHeader(true, this.state.workoutinfo.warmup.drills[this.state.currentWarmup].exercises[0], true, false);
        var infoPerform = this.createExerciseView(true, this.state.workoutinfo.warmup.drills[this.state.currentWarmup], this.state.workoutinfo.warmup.drills[this.state.currentWarmup].exercises[0]);
        var countdown = this.createCountDown(this.state.workoutinfo.warmup.drills[this.state.currentWarmup], this.state.workoutinfo.warmup.drills[this.state.currentWarmup].exercises[0]);
        var cancelline = this.createCancelLine();
        return (
            <div>
                {infoheader}
                {audio}
                <div className={classes.mainspacer} />
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft].join(' ')} onClick={() => this.next()}>
                    <CheckCircleIcon />
                </IconButton>
                {countdown}
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonright].join(' ')} onClick={() => this.showReallyCancel(true)}>
                    <CancelIcon />
                </IconButton>
                <div className={classes.mainspacer} />
                {cancelline}
                <div className={classes.mainspacer} />
                {timer}
                {infoPerform}
                <div className={classes.mainspacer} />
            </div>
        );
    }

    renderExecution() {
        const { classes } = this.props;
        var timer = this.createCompleteTimer();
        var audio = this.createAudio();
        var infoheader = this.createExerciseHeader(true, this.state.workoutinfo.drills[this.state.currentDrill].exercises[this.state.currentExercise], false, false);
        var infoPerform = this.createExerciseView(true, this.state.workoutinfo.drills[this.state.currentDrill], this.state.workoutinfo.drills[this.state.currentDrill].exercises[this.state.currentExercise]);
        var countdown = this.createCountDown(this.state.workoutinfo.drills[this.state.currentDrill], this.state.workoutinfo.drills[this.state.currentDrill].exercises[this.state.currentExercise]);
        var cancelline = this.createCancelLine();
        return (
            <div>
                {infoheader}
                {audio}
                <div className={classes.mainspacer} />
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft].join(' ')} onClick={() => this.next()}>
                    <CheckCircleIcon />
                </IconButton>
                {countdown}
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonright].join(' ')} onClick={() => this.showReallyCancel(true)}>
                    <CancelIcon />
                </IconButton>
                <div className={classes.mainspacer} />
                {cancelline}
                <div className={classes.mainspacer} />
                {timer}
                {infoPerform}
                <div className={classes.mainspacer} />

            </div>
        );
    }

    renderExecutionPause() {
        const { classes } = this.props;
        var timer = this.createCompleteTimer();
        var number = (this.state.currentExercise) * this.state.workoutinfo.drills.length + this.state.currentDrill;
        var max = this.state.workoutinfo.drills.length * this.state.workoutinfo.drills[0].exercises.length;
        var introtextPause = (number > 0)
            ? (<div className={[classes.floatrelative, classes.countdown, classes.centerdcontent, classes.labelInfo].join(' ')}>
                Du hast {number} von {max} Übungen geschafft. Weiter so!
                <br />
            </div>)
            : (<div />);
        var infoheader = this.createExerciseHeader(false, this.state.workoutinfo.drills[this.state.currentDrill].exercises[this.state.currentExercise], false, false);
        var infoPause = this.createExerciseView(false, this.state.workoutinfo.drills[this.state.currentDrill], this.state.workoutinfo.drills[this.state.currentDrill].exercises[this.state.currentExercise]);
        var cancelline = this.createCancelLine();
        return (
            <div>
                {infoheader}
                <div className={classes.mainspacer} />
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft].join(' ')} onClick={() => this.next()}>
                    <PlayCircleOutlineIcon />
                </IconButton>
                {introtextPause}
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonright].join(' ')} onClick={() => this.showReallyCancel(true)}>
                    <CancelIcon />
                </IconButton>
                <div className={classes.mainspacer} />
                {cancelline}
                <div className={classes.mainspacer} />
                {timer}
                {infoPause}
                <div className={classes.mainspacer} />
            </div>
        );
    }

    renderCooldown() {
        const { classes } = this.props;
        var timer = this.createCompleteTimer();
        var audio = this.createAudio();
        var infoheader = this.createExerciseHeader(true, this.state.workoutinfo.cooldown.drills[this.state.currentCooldown].exercises[0], false, true);
        var infoPerform = this.createExerciseView(true, this.state.workoutinfo.cooldown.drills[this.state.currentCooldown], this.state.workoutinfo.cooldown.drills[this.state.currentCooldown].exercises[0]);
        var countdown = this.createCountDown(this.state.workoutinfo.cooldown.drills[this.state.currentCooldown], this.state.workoutinfo.cooldown.drills[this.state.currentCooldown].exercises[0]);
        var cancelline = this.createCancelLine();
        return (
            <div>
                {infoheader}
                {audio}
                <div className={classes.mainspacer} />
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft].join(' ')} onClick={() => this.next()}>
                    <CheckCircleIcon />
                </IconButton>
                {countdown}
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonright].join(' ')} onClick={() => this.showReallyCancel(true)}>
                    <CancelIcon />
                </IconButton>
                <div className={classes.mainspacer} />
                {cancelline}
                <div className={classes.mainspacer} />
                {timer}
                {infoPerform}
                <div className={classes.mainspacer} />

            </div>
        );
    }

    renderCompleted() {
        const { classes } = this.props;
        var that = this;
        var introtextComplete = (<div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Gratulation, du hast das Training erfolgreich beendet!
            <br />
        </div>)
        return (

            <div>
                <div className={classes.mainspacer} />
                <div className={classes.mainspacer} />
                {introtextComplete}
                <div className={classes.mainspacer} />
                <FormControlLabel
                    control={<Checkbox checked={this.state.workoutinfo.publish} className={classes.checkbox}
                        name="publish" onChange={() => that.changeBool('publish')} />}
                    label={<div>Gruppenmitglieder dürfen meine Ergebnisse sehen</div>}
                />
                <IconButton style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={classes.buttonContentMore} onClick={() => this.close()}>
                    <SaveIcon />
                </IconButton>
            </div>
        );
    }

    createCancelLine() {
        const { classes } = this.props;
        return (<div id="cancelreally" className={[classes.floatrelative, classes.fullwidth, classes.hidden].join(' ')}>
            <div className={[classes.floatrelative, classes.marginLeft60, classes.labelInfo].join(' ')}>
                Wirklich abbrechen? Fortschritt geht komplett verloren!
            </div>
            <Button style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft, classes.marginLeft60].join(' ')} onClick={() => this.cancel()}>
                Ja
            </Button>
            <Button style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft, classes.marginLeft60].join(' ')} onClick={() => this.showReallyCancel(false)}>
                Nein
            </Button>
        </div>);
    }

    createCheckpoint(val, beeper, halftime) {
        var that = this;
        return {
            time: val,
            callback: () => 
            {
                that.beep(beeper, halftime)
            },
        };
    }

    createSoundMark(val, sound) {
        var that = this;
        return {
            time: val,
            callback: () => that.sound(sound),
        };
    }

    createAudio() {
        var shortbeep = "https://mcdn.podbean.com/mf/web/at6m47/shortbeep.mp3";
        var overbeep = "https://mcdn.podbean.com/mf/web/dvgb8f/overbeep.mp3";
        var go = "https://mcdn.podbean.com/mf/web/98hs66/Recording_1619958277927.mp3";
        return (<div>
            <audio id="shortbeep" src={shortbeep} autoPlay muted />
            <audio id="overbeep" src={overbeep} autoPlay muted />
            <audio id="go" src={go} autoPlay muted />
        </div>)
    }

    createStartCheckpoint(val) {
        return {
            time: val,
            callback: () => $('#prepare').hide()
        };
    }

    getCurrentExerciseText() {
        return "";
    }

    createExerciseHeader(execution, exercise, warmup, cooldown) {
        const { classes } = this.props;
        var completenumber = this.state.workoutinfo.drills.length * this.state.workoutinfo.drills[0].exercises.length;
        if (this.state.workoutinfo.warmup !== null) {
            completenumber += this.state.workoutinfo.warmup.drills.length;
        }
        if (this.state.workoutinfo.cooldown !== null) {
            completenumber += this.state.workoutinfo.cooldown.drills.length;
        }
        var number = (warmup) ? (this.state.currentWarmup + 1)
            : (cooldown) ? (this.state.currentCooldown + 1)
                : (this.state.currentExercise) * this.state.workoutinfo.drills.length + this.state.currentDrill + 1;
        var completecurrentnumber = (warmup) ? (this.state.currentWarmup + 1)
            : (cooldown) ? ((this.state.workoutinfo.warmup !== null) ? this.state.workoutinfo.warmup.drills.length : 0) + (this.state.workoutinfo.drills.length * this.state.workoutinfo.drills[0].exercises.length) + (this.state.currentCooldown + 1)
                : ((this.state.workoutinfo.warmup !== null) ? this.state.workoutinfo.warmup.drills.length : 0) + (this.state.currentExercise) * this.state.workoutinfo.drills.length + this.state.currentDrill + 1;
        var txt = "";
        if (this.state.currentWarmup === 0 && this.state.currentDrill === 0 && this.state.currentExercise === 0 && !this.state.warmup && this.state.workoutinfo.warmup !== null) {
            txt = "Wir starten mit einem Warmup!";
            number = 1;
            completecurrentnumber = 1;
        }
        else {
            var prefix = (warmup) ? "Warmup"
                : (cooldown) ? "Cool Down"
                    : (execution) ? "Übung" : "Vorschau zur Übung";
            txt = prefix + " " + number + ": " + exercise.info.name;
        }
        var marks = [];
        for (var i = 0; i < completenumber; i++) {
            marks.push({
                value: i + 1,
                label: ''
            });
        }

        return (<div>
            <div className={classes.mainspacer} />
            <div className={classes.mainspacer} />
            <Slider
                className={[classes.floatrelative, classes.slider].join(' ')}
                getAriaValueText={this.getCurrentExerciseText}
                value={completecurrentnumber}
                aria-labelledby="discrete-slider"
                min={1}
                max={completenumber}
                step={1}
                marks={marks}
                valueLabelDisplay="on"
            />
            <div className={[classes.floatrelative, classes.submenuheaderlight, classes.fullwidth, classes.textcenter].join(' ')}>
                {txt}
            </div>
        </div>);
    }

    onVideoError(errorObj) {
        $('#Video').hide();        
        $('#VideoError').show();        
    }

    onVideoLoaded() {
        $('#Video').show();        
        $('#VideoError').hide();    
    }

    createExerciseView(execution, group, exercise) {
        const { classes } = this.props;
        var weights = this.createWeights(exercise.info);
        var lines = (exercise.info.description)
            ? exercise.info.description.split("\n")
            : [];
        var exdesc = lines.map((d, idx) => {
            if (d.trim().endsWith(":")) {
                return (<div key={idx}>
                    <div className={classes.mainspacer} />
                    <div className={[classes.floatrelative, classes.infotextboldunderline, classes.fullwidth, classes.textleft, classes.marginLeft20].join(' ')}>{d}</div>
                </div>)
            }
            else {
                return (<div key={idx} className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textleft, classes.marginLeft60].join(' ')}>{d}</div>)
            }
        });
        var exurl = (exercise.info.images !== null &&
            exercise.info.images.length > this.state.currentImage)
            ? <img className={[classes.imageFullWidth].join(' ')} alt="Übungsbild" src={exercise.info.images[this.state.currentImage]}></img>
            : <div />;            
        if (exercise.info.video !== "") {
            var divid = "Video";
            var diverrorid ="VideoError";
            var videopreviewerror = (<div id={diverrorid} className={[classes.errorlabel, classes.hidden].join(' ')}>Fehler beim Laden des Videos</div>);
            exurl = (
                <div>
                    {videopreviewerror}
                    <div className={[classes.imageFullWidth].join(' ')} >
                        <Vimeo 
                            id={divid}
                            video={exercise.info.video}                         
                            autoplay
                            background
                            loop
                            responsive 
                            onError={(errorObj) => { this.onVideoError(errorObj) } }
                            onLoaded={() => { this.onVideoLoaded() } }/>
                    </div>
                </div>);
        }


        var showImageButton = (this.state.informationtype === 1)
            ? (<div className={[classes.floatrelative, classes.selectionbuttonactive].join(' ')}>Video</div>)
            : (<div className={[classes.floatrelative, classes.selectionbuttoninactive].join(' ')} onClick={() => this.setInformationType(1)}>Video</div>);
        var showDescButton = (this.state.informationtype === 2)
            ? (<div className={[classes.floatrelative, classes.selectionbuttonactive].join(' ')}>Beschreibung</div>)
            : (<div className={[classes.floatrelative, classes.selectionbuttoninactive].join(' ')} onClick={() => this.setInformationType(2)}>Beschreibung</div>);
        var showWeightsButton = (this.state.informationtype === 3)
            ? (<div className={[classes.floatrelative, classes.selectionbuttonactive].join(' ')}>Bewertung</div>)
            : (<div className={[classes.floatrelative, classes.selectionbuttoninactive].join(' ')} onClick={() => this.setInformationType(3)}>Bewertung</div>);

        var information = (this.state.informationtype === 1)
            ? (<div className={[classes.floatrelative, classes.fullwidth, classes.centerdcontent].join(' ')}>
                {exurl}
            </div>)
            : (this.state.informationtype === 2) ? exdesc : weights;
        var info =
            (<div>
                <div className={classes.mainspacer} />
                <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                    {showImageButton}
                    {showDescButton}
                    {showWeightsButton}
                </div>
                <div className={classes.mainspacer} />
                {information}
            </div>);
        return info;
    }

    createCompleteTimer() {
        const { classes } = this.props;
        return (<div className={[classes.floatrelative, classes.infotextbold, classes.fullwidth, classes.textcenter].join(' ')}>
            Gesamtzeit des Workouts: {this.state.runtime}
        </div>);
    }

    createCountDown(group, exercise) {
        const { classes } = this.props;
        var timerfullid = "Timer_Full";
        var timerhalfid = "Timer_Half";
        if (group.info.unit === 'Sekunden') {
            var initialtimefull = group.info.levels[group.executionlevel] * 1000 + 5000; //5s für Vorbereitung
            var initialtimehalf = ((group.info.levels[group.executionlevel] * 1000) / 2) + 5000; //5s für Vorbereitung
            var checkpoints = [];
            var checkpointshalf = [];
            if (exercise.info.twosided) {
                initialtimefull += 5000;
                for (var i = 1; i < 4; i++) {
                    checkpointshalf.push(this.createCheckpoint((i * 1000), true, false));
                }
                checkpointshalf.push(this.createCheckpoint(0, false, true));                
            }
            checkpoints.push(this.createSoundMark(group.info.levels[group.executionlevel] * 1000,'go'));
            if (exercise.info.twosided) {
                checkpoints.push(this.createSoundMark((group.info.levels[group.executionlevel] * 1000) / 2,'go'));
            }
            for (var i = 1; i < 4; i++) {
                checkpoints.push(this.createCheckpoint((i * 1000), true, false));
            }
            checkpoints.push(this.createCheckpoint(0, false, false));
            checkpoints.push(this.createStartCheckpoint(initialtimefull - 5000));

            var fulltimerStyle = classes.progressbar;
            var timer1 = (<div id={timerfullid}><Timer
                initialTime={initialtimefull}
                direction="backward"
                timeToUpdate={100}
                checkpoints={checkpoints}
            >
                {({ getTime }) => (
                    <React.Fragment>
                        <CircularProgressbar className={fulltimerStyle}
                            value={Math.round(((initialtimefull - 5000 - getTime()) / (initialtimefull - 5000)) * 100)}
                            text={`${Math.round(getTime() / 1000)} sec`}
                            styles={buildStyles({
                                rotation: 0.0,
                                strokeLinecap: 'round',
                                textSize: '14px',
                                pathTransitionDuration: 0.2,
                                pathColor: '#ffffff',
                                textColor: '#00D2FF',
                                trailColor: '#00D2FF',
                                backgroundColor: '#000000',
                            })}
                        />
                    </React.Fragment>
                )}
            </Timer></div>)
            var timer2 = (exercise.info.twosided) ? (<div id={timerhalfid}><Timer
                initialTime={initialtimehalf}
                direction="backward"
                timeToUpdate={100}
                checkpoints={checkpointshalf}
            >
                {({ getTime }) => (
                    <React.Fragment>
                        <CircularProgressbar className={classes.progressbar}
                            value={Math.round(((initialtimehalf - 5000 - getTime()) / (initialtimefull - 10000)) * 100)}
                            text={`${Math.round(getTime() / 1000)} sec`}
                            styles={buildStyles({
                                rotation: 0.0,
                                strokeLinecap: 'round',
                                textSize: '14px',
                                pathTransitionDuration: 0.2,
                                pathColor: '#ffffff',
                                textColor: '#00D2FF',
                                trailColor: '#00D2FF',
                                backgroundColor: '#000000',
                            })}
                        />
                    </React.Fragment>
                )}
            </Timer></div>) : (<div />);

            return (<div className={[classes.floatrelative, classes.countdown, classes.centerdcontent].join(' ')}>
                <div id='prepare' className={[classes.floatrelative, classes.countdowntext, classes.fullwidth, classes.centerdcontent, classes.labelInfo].join(' ')}>Vorbereitung</div>
                <div id='halftime' className={[classes.hidden, classes.countdowntext, classes.floatrelative, classes.fullwidth, classes.centerdcontent, classes.labelInfo].join(' ')}>Seitenwechsel</div>
                {timer2}
                {timer1}
            </div>);
        }
        else {
            var initialtimeCurrent = 0;
            var ext = " ";
            if (group.info.unit === 'Anzahl') {
                ext = " Stück";
            }
            if (group.info.unit === 'Sekunden') {
                ext = " Sekunden";
            }
            if (group.info.perSide) {
                ext += " pro Seite"
            }
            var leveltext = group.info.levels[group.executionlevel] + ext;
            return (<div className={[classes.floatrelative, classes.countdown, classes.centerdcontent, classes.labelInfo].join(' ')}>
                <Timer id={timerfullid}
                    initialTime={initialtimeCurrent}
                    direction="forward"
                >
                    {({ getTime }) => (
                        <React.Fragment>
                            <div className={[classes.floatrelative, classes.fullwidth, classes.labelStandardBlue].join(' ')}>{leveltext}</div>
                        </React.Fragment>
                    )}
                </Timer>
            </div>);
        }
    }

    createWeights(info) {
        const { classes } = this.props;
        var that = this;
        if (info !== null && info.weights) {
            var pmgs = this.createImages(info.weights.power);
            var eimgs = this.createImages(info.weights.endurance);
            var simgs = this.createImages(info.weights.stability);
            var mimgs = this.createImages(info.weights.mobility);
            return (
                <div className={classes.divinfocontainer}>
                    <div className={[classes.floatrelative, classes.infotext, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.textleft, classes.width100, classes.marginLeft20].join(' ')}>Kraft</div>
                        <div className={[classes.floatrelative, classes.textleft, classes.marginLeft20].join(' ')}>{pmgs}</div>
                    </div>
                    <div className={[classes.floatrelative, classes.infotext, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.textleft, classes.width100, classes.marginLeft20].join(' ')}>Ausdauer</div>
                        <div className={[classes.floatrelative, classes.textleft, classes.marginLeft20].join(' ')}>{eimgs}</div>
                    </div>
                    <div className={[classes.floatrelative, classes.infotext, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.textleft, classes.width100, classes.marginLeft20].join(' ')}>Stabilität</div>
                        <div className={[classes.floatrelative, classes.textleft, classes.marginLeft20].join(' ')}>{simgs}</div>
                    </div>
                    <div className={[classes.floatrelative, classes.infotext, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.textleft, classes.width100, classes.marginLeft20].join(' ')}>Mobilität</div>
                        <div className={[classes.floatrelative, classes.textleft, classes.marginLeft20].join(' ')}>{mimgs}</div>
                    </div>
                </div>);
        };
        return (<div />);
    }

    createImages(no) {
        const { classes } = this.props;
        var imgs = [];
        for (var i = 0; i < no; i++) {
            var k = 'img' + i;
            imgs.push(<img key={k}
                id={k}
                className={classes.kettlebellimage}
                src='./assets/kettlebell-solid.png'
                alt={i}
            />);
        }
        return imgs;
    }
}

export default withStyles(useStyles)(WorkoutExecutePerform);