import OsInfo from '../lib/os';
var isIos = new OsInfo().isIos();

let wakeLock = null;

class Power {

  requestWakeLock() {
    try {
      var isIos = new OsInfo().isIos();
      if (!isIos) {
        wakeLock = navigator.wakeLock.request('screen');
        wakeLock.addEventListener('release', () => {
          console.log('Wake Lock was released');
        });
        console.log('Wake Lock is active');
      }
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  releaseWakeLock() {
    if (!wakeLock) {
      return;
    }
    try {
      var isIos = new OsInfo().isIos();
      if (!isIos) {
        wakeLock.release();
        wakeLock = null;
      }
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };


}

export default Power;