import { FirebaseStorage } from '../../lib/database';

export class AdminData {

    logError(error, source, user) {
        var log = {
            message: error.message,
            stack: error.stack, 
            source: source,
            user: user,
            timestamp: new Date().getTime()
        }
        FirebaseStorage.pushObject("errorlog", log);       
    }
}