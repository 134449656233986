import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import { FirebaseAuth } from "../../lib/authentication";
import Regex from '../../lib/regex';

const additionalStyles = {

};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      infotext: "",
      errortext: ""
    };
  }

  componentDidMount() {
    var that = this;
    FirebaseAuth.OnLoginFailed = (info) => 
    { 
      that.setErrorText(info.message);
    };
    FirebaseAuth.OnLoginCompleted = (info) => {
      var login = (FirebaseAuth.currentUser !== null && FirebaseAuth.currentUser.emailVerified === true);
      if (login) {
        that.props.loginSuccessful();
      }
    };
  }

  textchange(prop, e) {
    var val = e.target.value.trim();
    var email = this.state.email;
    var pwd = this.state.password;
    if (prop === 'email') {
      email = val;
    }
    if (prop === 'password') {
      pwd = val;
    }

    this.setState(state => ({
      email: email,
      password: pwd
    }));
  }

  login() {
    if (!new Regex().validateEmail(this.state.email)) {
      this.setErrorText("Ungültige Email Adresse eingegeben!");
    }
    else {
      FirebaseAuth.login(this.state.email, this.state.password);
    }
  }

  resetPassword() {
    if (!new Regex().validateEmail(this.state.email)) {
      this.setErrorText("Ungültige Email Adresse eingegeben!");
    }
    else {
      FirebaseAuth.resetPassword(this.state.email);
      this.setInfoText("Email zum Zurücksetzen des Passworts wurde versandt!");
    }
  }

  setInfoText(text) {
    var that = this;
    this.setState(state => ({
      infotext: text
    }));
    setTimeout(() => {
      that.setInfoText("");
    }, 3000);
  }

  setErrorText(text) {
    var that = this;
    this.setState(state => ({
      errortext: text
    }));
    setTimeout(() => {
      that.setErrorText("");
    }, 3000);
  }

  render() {
    const { classes } = this.props;
    var that = this;

    var bottompagespace = <div className={classes.bottompagespace} />;

    return (
      <Card className={classes.root}>
        <div>
          <div className={classes.label}>Login</div>
          <div className={classes.mainspacer} />
          <input className={[classes.formtextbox, classes.centerControl90pct].join(' ')} ref={this.registerEmail} onChange={(e) => that.textchange('email', e)} />
          <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Email Adresse</div>
          <div className={classes.mainspacer} />
          <input type="password" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} ref={this.registerPassword1} onKeyPress={(event) => { if (event.key === 'Enter') { that.login() } }} onChange={(e) => that.textchange('password', e)} />
          <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Passwort</div>
          <div className={classes.mainspacer} />
          <div className={classes.infolabel}>{that.state.infotext}</div>
          <div className={classes.errorlabel}>{that.state.errortext}</div>
          <Button className={classes.buttonContentMore} onClick={() => that.login()}>
            Einloggen
          </Button>
          <Divider variant="middle" classes={{ root: classes.dividerContent }} />
          <div className={classes.buttonFullWidthSmall} onClick={() => that.resetPassword()}>
            Passwort vergessen - Reset Email schicken
          </div>
          <Divider variant="middle" classes={{ root: classes.dividerContent }} />
          <div className={classes.labelStandard}>Du hast noch kein Profil?</div>
          <div className={classes.labelStandardBlue}>Registriere dich jetzt!</div>
          <div className={classes.mainspacer} />
          <div className={[classes.floatrelative, classes.buttonFullWidth].join(' ')} onClick={() => that.props.showRegistration()}>
            <div className={[classes.innerCenter].join(' ')}>
              <img className={[classes.floatrelative, classes.arrowright].join(' ')} src="./assets/arrow_right.png" alt="Pfeil" />
              <div className={[classes.floatrelative].join(' ')}>ZUR REGISTRIERUNG</div>
            </div>
          </div>
          <div className={[classes.floatrelative].join(' ')}>
            <div className={[classes.innerCenter].join(' ')}>
              <img className={[classes.floatrelative, classes.logo].join(' ')} src="./assets/logo512.png" alt="Logo" />
            </div>
          </div>
        </div>
        {bottompagespace}
      </Card>
    );
  }
}

export default withStyles(useStyles)(Login);