import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import defaultStyles from '../base';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FirebaseStorage } from '../../lib/database';
import { FirebaseAuth } from "../../lib/authentication";

const additionalStyles = {  
  dropdownprops: {     
    color: "#000000",
    backgroundColor: 'lightgrey',  
  },
  cartcontainer: {
    float: 'left',
    position: 'relative',
    textAlign: 'left',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '5px',
    marginBottom: '5px',
    padding: '5px',
    width: '90%',
    borderColor: '#00D2FF',
    borderRadius: '8px',
    border: '1px solid'
  },
  itemCart: {
    float: 'left',
    width: '100px',
    height: '120px'
  },
  imageCart: {
    float: 'left',
    width: '100px',    
    opacity: '1.0',
    height: '100px'
  },
  infoCart: {
    float: 'left',
    width: '100px',    
    height: '20px'
  },
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class Shop extends React.Component {
  constructor (props) {
    super(props);    
    
    this.articles = [ 
      {
        name : "Kapuzenjacke Striker 2.0",
        articleno: "6819 89",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/6819_89_M01.jpg",
        price_original: 54.99,                
        sizes : [
          "34",
          "36",
          "38",
          "S",
          "M",
          "L",
          "XL",
        ]       
      },
      {
        name : "Kapuzenjacke Run 2.0",
        articleno: "6875 89",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/6875_89.jpg",
        price_original: 54.99,         
        sizes : [
          "34",
          "36",
          "38",
          "S",
          "M",
          "L",
          "XL",
        ]   
      },
      {
        name : "Ziptop Striker 2.0",
        articleno: "8619 89",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/8619_89.jpg",
        price_original: 49.99,          
        sizes : [
          "34",
          "36",
          "38",
          "S",
          "M",
          "L",
          "XL",
        ]            
      },
      {
        name : "Präsentationshose Striker 2.0",
        articleno: "6519 89",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/6519_89_M01.jpg",
        price_original: 44.99,  
        sizes : [
          "34",
          "36",
          "38",
          "S",
          "M",
          "L",
          "XL",
        ]          
      },
      {
        name : "Polo Striker 2.0",
        articleno: "6319 89",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/6319_89_M01.jpg",
        price_original: 39.99,  
        sizes : [
          "34",
          "36",
          "38",
          "S",
          "M",
          "L",
          "XL",
        ]          
      },
      {
        name : "Longsleeve Comfort",
        articleno: "6455 89",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/6455_89.jpg",
        price_original: 39.99,  
        sizes : [
          "34",
          "36",
          "38",
          "S",
          "M",
          "L",
          "XL",
        ]          
      },
      {
        name : "T-Shirt run",
        articleno: "6175 89",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/6175_89_M01.jpg",
        price_original: 19.99,  
        sizes : [
          "34",
          "36",
          "38",
          "S",
          "M",
          "L",
          "XL",
        ]          
      },
      {
        name : "2-in-1 Short",
        articleno: "6249 08",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/6249_08.jpg",
        price_original: 34.99,  
        sizes : [
          "34",
          "36",
          "38",
          "S",
          "M",
          "L",
          "XL",
        ]          
      },
      {
        name : "Trikot Kurzarm Damen",
        articleno: "",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/4219D_89_M01.jpg",
        price_original: 24.99,  
        sizes : [
          "34",
          "36",
          "38",
          "40",
          "42",
          "44",
        ]          
      },
      {
        name : "Short Competition 2.0",
        articleno: "",
        image : "https://cdn.jako.de/userdata/dcshop/images/thumb_3/6218_89_D_M01.jpg",
        price_original: 27.99,  
        sizes : [
          "34-36",
          "38-40",
          "42-44",          
        ]          
      }
      
      
    ];
    var selectedsizes = [];
    var selectednumbers = [];
    for (var i = 0; i < this.articles.length; i++) {
      this.articles[i].price_ek = this.articles[i].price_original - (this.articles[i].price_original * 0.4);
      this.articles[i].price = (Math.ceil(
        ((this.articles[i].price_original - (this.articles[i].price_original * 0.3) + 5.0)))).toString() +  " €";
      selectednumbers.push(1);
      selectedsizes.push(this.articles[i].sizes[0]);
    }
    this.state = {
      selectedsizes : selectedsizes,
      selectednumbers : selectednumbers,
      cart : []
    };
  }

  componentDidMount() {
    
    this.setState({      
    });     
  }

  componentWillUnmount() {
    
  }

  handleClick(id) {                
    var cartitem = {
      id : id,
      size : this.state.selectedsizes[id],
      number : this.state.selectednumbers[id]
    };
    var cart = this.state.cart;
    var updated = false;
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].id === id && cart[i].size === this.state.selectedsizes[id]) {
        cart[i].number += this.state.selectednumbers[id];
        updated = true;
      }
    }
    if (!updated) {
      cart.push(cartitem);
    }
    this.setState(state => ({
      cart: cart
    }));    
  }

  dropdownchange = (that, event, id) => {
    var sizes = that.state.selectedsizes;
    sizes[id] = event.target.value;
    this.setState(state => ({
      selectedsizes: sizes
    }));
  };

  dropdownnumberchange = (that, event, id) => {
    var numbers = that.state.selectednumbers;
    numbers[id] = event.target.value;
    this.setState(state => ({
      selectednumbers: numbers
    }));
  };

  clear(that) {   
    this.setState(state => ({
      cart: []
    }));  
  }

  send(that) {    
    var ts = Date.now();
    var email = FirebaseAuth.currentUser.email.toLowerCase();
    var order = "Bestellung von " + email +  ": \r\n\r\n";
    for (var i = 0; i < that.state.cart.length; i++) {
      order += that.articles[that.state.cart[i].id].name + ": " + that.state.cart[i].number + " X " + that.state.cart[i].size;
    }
    var info = {
      "email" : email,
      "message" : order,
      "timestamp" : ts,
    }
    
      that.setState(state => ({        
        infomessage: "Bestellung erfolgreich abgeschickt",
        errormessage: ''
      }));      
      FirebaseStorage.pushObject("contactmessage", info);
    

    this.setState(state => ({
      cart: []
    })); 
    setTimeout(() => { 
      that.setState(state => ({        
        infomessage: ""
      }));
    }, 3000);    
  }


  render() {
      
    const { classes } = this.props;
    var header = this.createHeader();
    var cart = this.createCart();
    var message = this.createInfoMessage();
    var articles = this.createArticles();    
    
    var bottompagespace = <div className={classes.bottompagespace}><Divider variant="middle" className={classes.contactDivider}/></div>;
    
    return (

      <Card className={classes.root}>      
        {header}                             
        <div className={classes.mainspacer}/>
        {cart}
        {message}
        {bottompagespace}                          
        {articles}              
        {bottompagespace}                  
      </Card>
    );
  }
  
  createHeader() {
    const { classes } = this.props;
    var header = ( <div className={classes.containertitle}>
        <Typography className={classes.title} color="textSecondary">
        Shop
        </Typography>
        <Divider variant="middle" classes={{root: classes.divider}}/></div>);
    return header;
  }

  createInfoMessage() {
    const { classes } = this.props;
    var that = this;
    var infotext = that.state.infomessage;
    return <div className={classes.infolabel}>{infotext}</div>;
  }

  createCart() {
    const { classes } = this.props;
    var that = this;  
    var cart = this.state.cart;
    var articles = this.articles;

    if (cart.length === 0) {
      return (
        <div className={classes.cartcontainer}>
          Warenkorb ist leer
      </div>);   
    }
    var cartcontent = (cart.map(function(item, key){                          
              return (
              <div className={classes.itemCart} key={key}>            
                <img id='eventimage'
                  className={classes.imageCart}
                  src={articles[item.id].image}
                  alt={articles[item.id].name}
                />                                                
                <div className={classes.infoCart}>
                  {item.number} X {item.size}
                </div>
            </div>)
          }));        
    return (
      <div className={classes.cartcontainer}>            
        Warenkorb:<br/>
        {cartcontent}<br/>
        <Button className={classes.buttonContentMoreFlex} onClick={() => that.send(that)}>
          Bestellen
        </Button> 
        <Button className={classes.buttonContentMoreFlex} onClick={() => that.clear(that)}>
          Warenkorb leeren
        </Button>  
      </div>);   

  }

  createArticles() {
    const { classes } = this.props;
    var that = this;    
    var si = this.state.selectedsizes;
    var nm = this.state.selectednumbers;
    var nums = [1,2,3,4];
    var overview = (this.articles.map(function(item, keyO){            
      var buttontext = "Zum Warenkorb";      
      var selectid = "size-" + keyO;
      var selectnoid = "no-" + keyO;
      var sizes = (<FormControl className={classes.formControl}>
      <InputLabel id="input-size">Größe</InputLabel>
      <Select
        labelId="input-size"
        id={selectid}        
        value={si[keyO]}
        onChange={(e) => that.dropdownchange(that, e, keyO)}
        MenuProps={{ classes: { paper: classes.dropdownprops } }}
        input={<Input />}
      >
        {item.sizes.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>);
      var numbers = (<FormControl className={classes.formControl}>
        <InputLabel id="input-number">Anzahl</InputLabel>
        <Select
          labelId="input-number"
          id={selectnoid}        
          value={nm[keyO]}
          onChange={(e) => that.dropdownnumberchange(that, e, keyO)}
          MenuProps={{ classes: { paper: classes.dropdownprops } }}
          input={<Input />}
        >
          {nums.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>);
      var price = <div className={classes.sectionlabel}>{item.price}</div>
      return (
      <div className={classes.textimagecontainerLeft} key={keyO}>            
        <div className={classes.sectionlabel}>{item.name}</div> 
        <img id='eventimage'
          className={classes.imageHalfWidthUnTransparent}
          src={item.image}
          alt={item.name}
        />            
        <div className={classes.infolabelright}>                  
          {sizes}
          {numbers}
          <br/>
          {price}
        </div>                      
        <div className={classes.sectionlabel}>
          <Button className={classes.buttonContentMoreFlex} onClick={() => that.handleClick(keyO)}>
            {buttontext}
          </Button>              
        </div>
    </div>);                    

}));
return overview;
  }
}

export default withStyles(useStyles)(Shop);
