import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import defaultStyles from '../base';
import { CourseData } from '../../controller/data/coursedata';

const result = {
    ...defaultStyles
};

const useStyles = theme => (result)

class WorkoutPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selecteddrill: 0,
            selectedexercise: 0,
            currentImage: 0,
            workoutinfo: null
        };
        this.imageTimer = -1;
    }

    componentDidMount() {
        this.loadWorkout();
        var that = this;
        this.imageTimer = setInterval(() => {
            that.refreshImage();
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.imageTimer);
    }

    loadWorkout() {
        var cs = new CourseData();
        cs.loadWorkout(this.props.workout, (gw) => {
            var drills = [

            ]
            for (var i1 = 0; i1 < gw.drills.length; i1++) {
                var exercises = [];
                for (var j1 = 0; j1 < gw.drills[i1].exercises.length; j1++) {
                    exercises.push({
                        id: gw.drills[i1].exercises[j1],
                        info: null
                    });
                }
                drills.push({
                    id: gw.drills[i1].group,
                    info: null,
                    exercises: exercises
                });
            }
            var workoutstate = {
                name: gw.name,
                sets: gw.drills.length,
                exercises: (gw.drills.length > 0) ? gw.drills[0].exercises.length : 0,
                group: null,
                drills: drills
            };
            this.setState(state => ({
                workoutinfo: workoutstate
            }));
            cs.loadGroupById(gw.groupid, (g) => {
                var w = this.state.workoutinfo;
                w.group = g;
                this.setState(state => ({
                    workoutinfo: w
                }));
            });
            for (var i = 0; i < gw.drills.length; i++) {
                for (var j = 0; j < gw.drills[i].exercises.length; j++) {
                    cs.loadExerciseByIds(gw.drills[i].group, gw.drills[i].exercises[j], (ex) => {
                        var w = this.state.workoutinfo;
                        for (var l = 0; l < w.drills.length; l++) {
                            for (var m = 0; m < w.drills[l].exercises.length; m++) {
                                if (w.drills[l].exercises[m].id === ex.key) {
                                    w.drills[l].exercises[m].info = ex.val();
                                }
                            }
                        }
                        this.setState(state => ({
                            workoutinfo: w
                        }));
                    });
                }
                cs.loadExerciseGroupById(gw.drills[i].group, (exgroup) => {
                    var w = this.state.workoutinfo;
                    for (var k = 0; k < w.drills.length; k++) {
                        if (w.drills[k].id === exgroup.key) {
                            w.drills[k].info = exgroup.val();
                        }
                    }
                    this.setState(state => ({
                        workoutinfo: w
                    }));
                });
            }
        });
    }

    refreshImage() {
        var currentimage = this.state.currentImage;
        if (this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info !== null &&
            this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.images !== null) {
            currentimage++;
            if (currentimage >= this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.images.length) {
                currentimage = 0;
            }
        }
        this.setState({
            currentImage: currentimage
        })
    }

    previousdrill() {
        var newdrill = 0;
        if (this.state.selecteddrill === 0) {
            newdrill = this.state.workoutinfo.drills.length - 1;
        }
        else {
            newdrill = this.state.selecteddrill - 1;
        }
        this.setState({
            selecteddrill: newdrill,
            currentImage: 0
        });
    }

    nextdrill() {
        var newdrill = 0;
        if (this.state.selecteddrill === this.state.workoutinfo.drills.length - 1) {
            newdrill = 0;
        }
        else {
            newdrill = this.state.selecteddrill + 1;
        }
        this.setState({
            selecteddrill: newdrill,
            currentImage: 0
        });
    }

    previousexercise() {
        var newex = 0;
        if (this.state.workoutinfo.drills[this.state.selecteddrill].info !== null &&
            this.state.workoutinfo.drills[this.state.selecteddrill].info !== undefined) {
            if (this.state.selectedexercise === 0) {
                newex = this.state.workoutinfo.drills[this.state.selecteddrill].exercises.length - 1;
            }
            else {
                newex = this.state.selectedexercise - 1;
            }
        }
        this.setState({
            selectedexercise: newex,
            currentImage: 0
        });
    }

    nextexercise() {
        var newex = 0;
        if (this.state.workoutinfo.drills[this.state.selecteddrill].info !== null &&
            this.state.workoutinfo.drills[this.state.selecteddrill].info !== undefined) {
            if (this.state.selectedexercise === this.state.workoutinfo.drills[this.state.selecteddrill].exercises.length - 1) {
                newex = 0;
            }
            else {
                newex = this.state.selectedexercise + 1;
            }
        }
        this.setState({
            selectedexercise: newex,
            currentImage: 0
        });
    }

    render() {
        const { classes } = this.props;
        var that = this;
        if (!this.state || this.state.workoutinfo === null) {
            return (
                <div className={classes.fullscreen}>
                    Lade.....
                    <Button className={classes.buttonContentMore} onClick={() => this.props.close()}>
                        Schließen
                    </Button>
                </div>
            );
        }
        var backarea = (<div className={[classes.backmenu].join(' ')}>
            <NavigateBeforeIcon className={[classes.backbuttonHome, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.props.close()} />
        </div>);
        //var header = <div className={[classes.topic, classes.marginTop20, classes.marginBottom20].join(' ')}>Vorschau: {this.state.workoutinfo.name}</div>

        var submenucaption = (<div className={[classes.submenucaption].join(' ')} color="textPrimary">
            Vorschau: {this.state.workoutinfo.name}
        </div>);
        var introtext = <div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter, classes.paddingLeft20, classes.paddingRight20].join(' ')}>
            Dein Workout besteht aus {this.state.workoutinfo.drills.length} Übungen mit jeweils {this.state.workoutinfo.drills[this.state.selecteddrill].exercises.length} Durchgängen.<br />
            Du kannst die Übungen hier in der Vorschau genau studieren.<br />
            Die Anzahl der Wiederholungen bzw. die Dauer der Ausführung hängt davon ab, welchen Fitnesslevel du in deinem Profil eingstellt hast.<br />
            Du kannst beim Start des Workouts aber für jede Übung individuell einstellen, welchen Fitnesslevel du trainieren willst.<br />
        </div>

        var workoutheader = (<div className={[classes.header1, classes.marginBottom20].join(' ')}>
            <ArrowBackIcon className={[classes.navigationbutton1, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.previousdrill()} />
            <div className={[classes.switchheader1].join(' ')}>Übung {this.state.selecteddrill + 1} von {this.state.workoutinfo.drills.length}</div>
            <ArrowForwardIcon className={[classes.navigationbutton1, classes.navigationButtonRight].join(' ')} fontSize="large" onClick={() => that.nextdrill()} />
        </div>)

        var exerciseheader = (this.state.workoutinfo.drills[this.state.selecteddrill] !== null) ?
            (<div className={[classes.header2, classes.marginBottom20].join(' ')}>
                <ArrowBackIcon className={[classes.navigationbutton2, classes.navigationButtonLeft].join(' ')} onClick={() => that.previousexercise()} />
                <div className={[classes.switchheader2].join(' ')}>Durchgang {this.state.selectedexercise + 1} von {this.state.workoutinfo.drills[this.state.selecteddrill].exercises.length}</div>
                <ArrowForwardIcon className={[classes.navigationbutton2, classes.navigationButtonRight].join(' ')} onClick={() => that.nextexercise()} />
            </div>)
            :
            <div className={[classes.header2, classes.marginBottom20].join(' ')}>
                <ArrowBackIcon className={[classes.navigationbutton2, classes.navigationButtonLeft].join(' ')} onClick={() => that.previousexercise()} />
                <div className={[classes.switchheader2].join(' ')}>Durchgang {this.state.selectedexercise + 1}</div>
                <ArrowForwardIcon className={[classes.navigationbutton2, classes.navigationButtonRight].join(' ')} onClick={() => that.nextexercise()} />
            </div>;
        var extext = <div className={[classes.floatrelative, classes.infotextbold, classes.fullwidth].join(' ')}>Lade Übungsdetails....</div>;
        var exdesc = <div />;
        var exurl = <div />;
        var weights = this.createWeights(this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info);
        if (this.state.workoutinfo.drills[this.state.selecteddrill].exercises !== null &&
            this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info !== null) {
            extext = <div className={[classes.floatrelative, classes.teaserlabel, classes.fullwidth, classes.textcenter].join(' ')}>{this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.name}</div>;

            var lines = (this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.description)
                ? this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.description.split("\n")
                : [];
            exdesc = lines.map((d, idx) => {
                if (d.trim().endsWith(":")) {
                    return (<div key={idx}>
                        <div className={classes.mainspacer} />
                        <div className={[classes.floatrelative, classes.infotextboldunderline, classes.fullwidth, classes.textleft, classes.paddingLeft20, classes.paddingRight20].join(' ')}>{d}</div>
                    </div>)
                }
                else {
                    return (<div key={idx} className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textleft, classes.paddingLeft60, classes.paddingRight20].join(' ')}>{d}</div>)
                }
            });
            exurl = (this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.images !== null &&
                this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.images.length > this.state.currentImage)
                ? <img className={[classes.imageFullWidth].join(' ')} alt="Übungsbild" src={this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.images[this.state.currentImage]}></img>
                : <div />;            
        }
        var info =
            (<div>
                {extext}
                <div className={[classes.floatrelative, classes.fullwidth, classes.centerdcontent].join(' ')}>
                    {exurl}
                </div>
                <div className={classes.mainspacer} />
                {weights}
                <div className={classes.mainspacer} />
                {exdesc}
            </div>);
        return (

            <div className={classes.fullscreen}>
                {backarea}
                {submenucaption}
                <div className={classes.mainspacer} />
                {introtext}
                <div className={classes.mainspacer} />
                {workoutheader}
                {exerciseheader}
                {info}
            </div>
        );
    }

    createWeights(info) { 
        const { classes } = this.props;   
        if (info !== null && info.weights) {
            var pmgs = this.createImages(info.weights.power);
            var eimgs = this.createImages(info.weights.endurance);
            var simgs = this.createImages(info.weights.stability);
            var mimgs = this.createImages(info.weights.mobility);
            return (
                <div className={classes.divinfocontainer}>
                    <div className={[classes.floatrelative, classes.infotext, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.textleft, classes.width100, classes.marginLeft20].join(' ')}>Kraft</div>
                        <div className={[classes.floatrelative, classes.textleft, classes.marginLeft20].join(' ')}>{pmgs}</div>
                    </div>
                    <div className={[classes.floatrelative, classes.infotext, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.textleft, classes.width100, classes.marginLeft20].join(' ')}>Ausdauer</div>
                        <div className={[classes.floatrelative, classes.textleft, classes.marginLeft20].join(' ')}>{eimgs}</div>
                    </div>
                    <div className={[classes.floatrelative, classes.infotext, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.textleft, classes.width100, classes.marginLeft20].join(' ')}>Stabilität</div>
                        <div className={[classes.floatrelative, classes.textleft, classes.marginLeft20].join(' ')}>{simgs}</div>
                    </div>
                    <div className={[classes.floatrelative, classes.infotext, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.textleft, classes.width100, classes.marginLeft20].join(' ')}>Mobilität</div>
                        <div className={[classes.floatrelative, classes.textleft, classes.marginLeft20].join(' ')}>{mimgs}</div>
                    </div>
                </div>);
        };
        return (<div/>);
    }

    createImages(no) {
        const { classes } = this.props;
        var imgs = [];
        for (var i = 0; i < no; i++) {
            var k = 'img' + i;
            imgs.push(<img key={k}
                id={k}
                className={classes.kettlebellimage}
                src='./assets/kettlebell-solid.png'
                alt={i}
            />);
        }
        return imgs;
    }
}

export default withStyles(useStyles)(WorkoutPreview);