import { FirebaseStorage } from '../../lib/database';

export class WorkoutData {
    addWorkout(event) {
        FirebaseStorage.pushObject("workouts", event);
    }

    updateWorkout(key, event) {
        FirebaseStorage.updateObject("workouts", key, event);
    }

    loadWorkouts(fn) {
        FirebaseStorage.loadAll("workouts", fn);
    }

    loadWorkoutsComplete(fn) {
        FirebaseStorage.loadAllComplete("workouts", fn);
    }

    assignUser(eventkey, userkey) {
        var attendee = {
            key: userkey,
            email: ""
        }
        FirebaseStorage.pushObjectWithId("workouts/" + eventkey + "/time/attendees", attendee);
    }

    checkInUser(eventkey, time, userkey) {
        var ts = new Date().getTime();
        var ckeckin = {
            user: userkey,
            workout: eventkey,
            workouttime: time,
            userinfo: {},
            start: ts,
            end: null
        }
        FirebaseStorage.pushObjectWithId("checkins/workouts", ckeckin);
    }

    checkOutUser(eventkey, time, userkey) {
        var ts = new Date().getTime();
        FirebaseStorage.loadAllComplete("checkins/workouts", (checkins) => {
            checkins.forEach(function (c) {
                var ckey = c.key;
                var cval = c.val();
                if (cval.user === userkey && cval.workout === eventkey && cval.workouttime === time && (cval.end === undefined || cval === null)) {
                    cval.end = ts;
                    FirebaseStorage.updateObject("checkins/workouts", ckey, cval);
                }
            });
        });
    }

    assignEmail(eventkey, email) {
        var attendee = {
            key: "",
            email: email            
        }
        FirebaseStorage.pushObjectWithId("workouts/" + eventkey + "/time/attendees", attendee);
    }

    checkInEmail(eventkey, time, info) {
        var ts = new Date().getTime();
        var ckeckin = {
            user: "",
            workout: eventkey,
            workouttime: time,
            userinfo: info,
            start: ts,
            end: null
        }
        return FirebaseStorage.pushObjectWithId("checkins/workouts", ckeckin);
    }

    checkOutEmail(key) {
        var ts = new Date().getTime();
        FirebaseStorage.loadObject("checkins/workouts", key, (wc) => {
            var wcVal = wc.val();
            wcVal.end = ts;
            FirebaseStorage.updateObject("checkins/workouts", key, wcVal);
        });        
    }

    unassign(eventkey, userkey) {
        FirebaseStorage.deleteObject("workouts/" + eventkey + "/time/attendees/" + userkey);
    }

    sendWorkoutTimeMessage(eventkey, timekey, message, fn) {
        var itemtimekey = eventkey + "_" + timekey;
        FirebaseStorage.loadObject("workouts", eventkey + "/time", (e) => {
            var event = e.val();
            var messages = [];
            if (event.messages) {
                messages = event.messages;
            }
            var ts = new Date().toISOString();
            messages.push(
                {
                    timestamp: ts,
                    message: message
                }
            );
            FirebaseStorage.insertObject("workouts", eventkey + "/time/messages", messages);
            fn();
        });
    }
} 