import React from 'react';
import defaultStyles from './base';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import EventCalendar from "./events/eventcalendar"
import News from "./news/news"
import Workout from "./workout/workout"
import AdminOverview from "./admin/AdminOverview"
import Individual from "./individual/individual"
import EditProfile from "./profile/editprofile"
import { RequestMethods } from "../lib/request";
import Shop from "./shop/shop"
import Courses from "./courses/courses"
import Impressum from "./impressum/impressum"
import Agb from "./agb/agb"
import Revocation from "./revocation/revocation"
import { FirebaseAuth } from "../lib/authentication";
import $ from "jquery";
import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";

const additionalStyles = {
    menucontainer: {
        width: '200px'
    },
    menuitem: {
        left: '20px',
        width: '160px',
        fontFamily: 'Lemonada',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '20px',
        "&:hover": {
            background: "#00D2FF"
        },
    },
    menuitemactive: {
        left: '20px',
        width: '160px',
        fontFamily: 'Lemonada',
        fontSize: '18px',
        textTransform: 'uppercase',
        marginTop: '20px',
        color: "#00D2FF"
    },
    menuitemsmall: {
        left: '20px',
        width: '160px',
        fontFamily: 'Lemonada',
        fontSize: '14px',
        textTransform: 'uppercase',
        marginTop: '20px'
    },
    scrollablecontainer: {
        width: 'calc(100% - 200px)',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    labelSmallGray: {
        background: 'transparent',
        color: '#CCCCCC',
        height: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'right',
        display: 'block',
        fontFamily: 'Roboto',
        fontSize: '9px'
    },
    labelSmallGrayLeft: {
        background: 'transparent',
        color: '#CCCCCC',
        keft: '3px',
        height: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'left',
        display: 'block',
        fontFamily: 'Roboto',
        fontSize: '9px'
    },
    buttonLogoff: {
        background: '#00D2FF',
        color: 'black',
        height: '24px',
        width: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'right',
        display: 'none'
    },
    buttonLogoffVisible: {
        background: '#00D2FF',
        color: 'black',
        height: '24px',
        width: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'right',
        display: 'block',
        "&:hover": {
            backgroundColor: '#00D2FF'
        }
    },
    buttonNotification: {
        background: '#00D2FF',
        color: 'black',
        height: '24px',
        width: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'left',
        display: 'none',
        marginLeft: '10px',
        "&:hover": {
            backgroundColor: '#00D2FF'
        }
    },
    topmenu: {
        position: 'relative',
        top: '0px',
        margin: '0px',
        padding: '0px',
        width: '100%',
        height: '35px',
        backgroundColor: 'transparent'
    }
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class WebHome extends React.Component {

    constructor(props) {
        super(props);
        var that = this;
        FirebaseAuth.Init();
        FirebaseAuth.OnInitCompleted = (info) => {
            that.checkisAdmin();
        };
        FirebaseAuth.OnLogoffCompleted = (info) => {
            that.checkisAdmin();
        };
    }

    componentDidMount() {
        var initialindex = 0;
        if (RequestMethods.getQueryVariable("tab") !== "") {
            initialindex = parseInt(RequestMethods.getQueryVariable("tab"));
            if (isNaN(initialindex)) {
                initialindex = 0;
            }
        }
        this.setState({
            menuIndex: initialindex,
            isAdmin: false
        });
    }

    handleChangeIndex = (index) => {
        this.setState({
            menuIndex: index
        });
        this.props.OnChangeIndex(index);
    };


    handleBack = () => {
        this.setState({
            menuIndex: 1
        });
    }


    render() {
        var that = this;
        const { classes } = this.props;
        var version = <div id="release" className={classes.labelSmallGrayLeft}></div>
        var userinfo = (this.checkLoginState()) ?
            (<div id="CurrentUserInfo" className={classes.labelSmallGray}>{FirebaseAuth.currentUser.email}</div>) :
            (<div id="CurrentUserInfo" className={classes.labelSmallGray}></div>);
        var editprofile = (this.checkLoginState()) ?
            (<IconButton id="EditProfileButton" className={classes.buttonLogoffVisible} onClick={() => that.handleChangeIndex(9)} size="small" >
                <AccountCircleIcon fontSize="small" />
            </IconButton>) :
            (<IconButton id="EditProfileButton" className={classes.buttonLogoff} onClick={() => that.handleChangeIndex(9)} size="small" >
                <AccountCircleIcon fontSize="small" />
            </IconButton>);
        var logoff = (this.checkLoginState()) ?
            (<IconButton id="LogoffButton" className={classes.buttonLogoffVisible} onClick={() => that.handleLogout()} size="small" >
                <ExitToAppIcon fontSize="small" />
            </IconButton>) :
            (<IconButton id="LogoffButton" className={classes.buttonLogoff} onClick={() => that.handleLogout()} size="small" >
                <ExitToAppIcon fontSize="small" />
            </IconButton>);
        var menu = this.createMenu();
        var content = this.createcontent();


        return (
            <div id="contentcontainer" className={[classes.scrollable, classes.fullheight, classes.fullwidth, classes.floatrelative].join(' ')}>

                <div id="topmenu" className={classes.topmenu}>
                    {version}
                    {editprofile}
                    {logoff}
                    {userinfo}
                </div>
                <div id="mainarea">
                    {menu}
                    {content}
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText="Akzeptieren"
                    cookieName="NoLimitZCookieConsent"
                    style={{ background: "#000000", fontSize: "20px", fontFamily: "Lemonada" }}
                    buttonStyle={{ background: "#00D2FF", color: "#000000", fontSize: "20px" }}
                    expires={1}
                    debug={false}
                    overlay={true}
                >
                    Wir verwenden Cookies um die Benutzerfreundlichkeit zu verbessern. Bitte akzeptieren Sie um die Seite besuchen zu können!{" "}
                </CookieConsent>
            </div>
        );
    }

    checkisAdmin() {
        var isadmin = (FirebaseAuth.currentUser !== null && (FirebaseAuth.currentUser.email.toLowerCase() === "devfalti@gmail.com" || FirebaseAuth.currentUser.email.toLowerCase() === "tobi-m.949313@web.de"));
        this.setState({
            isAdmin: isadmin
        });
    }

    checkLoginState() {
        return (FirebaseAuth.currentUser !== null && FirebaseAuth.currentUser.emailVerified === true);
    }

    handleLogout() {
        FirebaseAuth.logoff();
    }

    showNotificationInfo() {
        $("#notificationmessage").toggle();
    }

    createMenuItemClass(item) {
        const { classes } = this.props;
        var menitem = [classes.menuitem, classes.floatrelative, classes.clickable].join(' ');
        var menitemactive = [classes.menuitemactive, classes.floatrelative, classes.clickable].join(' ');
        return (this.state != undefined && item == this.state.menuIndex) ? menitemactive : menitem;
    }

    createMenu() {
        const { classes } = this.props;

        if (this.state != undefined && this.state.isAdmin != undefined && this.state.isAdmin) {
            return (
                <div className={[classes.menucontainer, classes.floatrelative].join(' ')}>
                    <div className={this.createMenuItemClass(0)} onClick={() => this.handleChangeIndex(0)}>News</div>
                    <div className={this.createMenuItemClass(1)} onClick={() => this.handleChangeIndex(1)}>Kurse</div>
                    <div className={this.createMenuItemClass(2)} onClick={() => this.handleChangeIndex(2)}>Events</div>
                    <div className={this.createMenuItemClass(3)} onClick={() => this.handleChangeIndex(3)}>Online Training</div>
                    <div className={this.createMenuItemClass(4)} onClick={() => this.handleChangeIndex(4)}>Admin</div>
                    <div className={this.createMenuItemClass(5)} onClick={() => this.handleChangeIndex(5)}>Shop</div>
                    <div className={[classes.mainspacer, classes.floatrelative].join(' ')} />
                    <div className={[classes.mainspacer, classes.floatrelative].join(' ')} />
                    <div className={[classes.mainspacer, classes.floatrelative].join(' ')} />
                    <div className={this.createMenuItemClass(6)} onClick={() => this.handleChangeIndex(6)}>Datenschutz</div>
                    <div className={this.createMenuItemClass(7)} onClick={() => this.handleChangeIndex(7)}>Impressum</div>
                    <div className={this.createMenuItemClass(8)} onClick={() => this.handleChangeIndex(8)}>AGB</div>
                </div>
            )
        }
        else {
            return (
                <div className={[classes.menucontainer, classes.floatrelative].join(' ')}>
                    <div className={this.createMenuItemClass(0)} onClick={() => this.handleChangeIndex(0)}>News</div>
                    <div className={this.createMenuItemClass(1)} onClick={() => this.handleChangeIndex(1)}>Kurse</div>
                    <div className={this.createMenuItemClass(2)} onClick={() => this.handleChangeIndex(2)}>Events</div>
                    <div className={this.createMenuItemClass(3)} onClick={() => this.handleChangeIndex(3)}>Online Training</div>
                    <div className={[classes.mainspacer, classes.floatrelative].join(' ')} />
                    <div className={[classes.mainspacer, classes.floatrelative].join(' ')} />
                    <div className={[classes.mainspacer, classes.floatrelative].join(' ')} />
                    <div className={this.createMenuItemClass(6)} onClick={() => this.handleChangeIndex(6)}>Datenschutz</div>
                    <div className={this.createMenuItemClass(7)} onClick={() => this.handleChangeIndex(7)}>Impressum</div>
                    <div className={this.createMenuItemClass(8)} onClick={() => this.handleChangeIndex(8)}>AGB</div>
                </div>
            )
        }
    }

    createcontent() {
        const { classes } = this.props;

        var ret = (<div>Illegal Menu</div>);
        if (this.state) {
            if (this.state.menuIndex === 0) {
                ret = (<News />)
            }
            else if (this.state.menuIndex === 1) {
                ret = (<Workout />)
            }
            else if (this.state.menuIndex === 2) {
                ret = (<EventCalendar />)
            }
            else if (this.state.menuIndex === 3) {
                ret = (<Courses />)
            }
            else if (this.state.menuIndex === 4) {
                ret = (<AdminOverview />)
            }
            else if (this.state.menuIndex === 5) {
                ret = (<Shop />)
            }
            else if (this.state.menuIndex === 6) {
                ret = (<Revocation />)
            }
            else if (this.state.menuIndex === 7) {
                ret = (<Impressum />)
            }
            else if (this.state.menuIndex === 8) {
                ret = (<Agb />)
            }
            else if (this.state.menuIndex === 9) {
                ret = (<EditProfile />)
            }
        }
        return (
            <div id="slideContainer" className={[classes.scrollablecontainer, classes.floatrelative].join(' ')}>
                {ret}
            </div>)
    }
}


export default withStyles(useStyles)(WebHome);