import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import defaultStyles from '../base';
import WorkoutExecutePrepare from './workoutexecuteprepare';
import WorkoutExecutePerform from './workoutexecuteperform';
import WorkoutExecuteSingle from './workoutexecutesingle';
import { CourseData } from '../../controller/data/coursedata';
import { UserData } from '../../controller/data/userdata'
import { FirebaseAuth } from "../../lib/authentication";
import Power from "../../lib/power";

const result = {
    ...defaultStyles
};

const useStyles = theme => (result)



class WorkoutExecute extends React.Component {

    constructor(props) {
        super(props);        
        this.state = {
            selecteddrill: 0,
            selectedexercise: 0,
            currentImage: 0,
            workoutinfo: null,
            prepared: false
        };
        this.power = null;
    }

    componentDidMount() {        
        this.loadWorkout();
        this.power = new Power();
        this.power.requestWakeLock();
    }

    componentWillUnmount() {
        if (this.power !== null) {
            this.power.releaseWakeLock();
        }
    }

    loadWorkout() {
        var cs = new CourseData();
        var defaultlevel = FirebaseAuth.profile.onlinetraining.fitnesslevel;
        cs.loadWorkout(this.props.workout, (gw) => {
            cs.convertWorkout(this.props.group, gw, defaultlevel, FirebaseAuth.currentUser.uid, (w) => {
                this.setState(state => ({
                    workoutinfo: w
                }));
            });            
        });
    }

    refreshImage() {
        var currentimage = this.state.currentImage;
        if (this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info !== null &&
            this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.images !== null) {
            currentimage++;
            if (currentimage >= this.state.workoutinfo.drills[this.state.selecteddrill].exercises[this.state.selectedexercise].info.images.length) {
                currentimage = 0;
            }
        }
        this.setState({
            currentImage: currentimage
        })
    }

    previousdrill() {
        var newdrill = 0;
        if (this.state.selecteddrill === 0) {
            newdrill = this.state.workoutinfo.drills.length - 1;
        }
        else {
            newdrill = this.state.selecteddrill - 1;
        }
        this.setState({
            selecteddrill: newdrill,
            currentImage: 0
        });
    }

    nextdrill() {
        var newdrill = 0;
        if (this.state.selecteddrill === this.state.workoutinfo.drills.length - 1) {
            newdrill = 0;
        }
        else {
            newdrill = this.state.selecteddrill + 1;
        }
        this.setState({
            selecteddrill: newdrill,
            currentImage: 0
        });
    }

    previousexercise() {
        var newex = 0;
        if (this.state.workoutinfo.drills[this.state.selecteddrill].info !== null &&
            this.state.workoutinfo.drills[this.state.selecteddrill].info !== undefined) {
            if (this.state.selectedexercise === 0) {
                newex = this.state.workoutinfo.drills[this.state.selecteddrill].exercises.length - 1;
            }
            else {
                newex = this.state.selectedexercise - 1;
            }
        }
        this.setState({
            selectedexercise: newex,
            currentImage: 0
        });
    }

    nextexercise() {
        var newex = 0;
        if (this.state.workoutinfo.drills[this.state.selecteddrill].info !== null &&
            this.state.workoutinfo.drills[this.state.selecteddrill].info !== undefined) {
            if (this.state.selectedexercise === this.state.workoutinfo.drills[this.state.selecteddrill].exercises.length - 1) {
                newex = 0;
            }
            else {
                newex = this.state.selectedexercise + 1;
            }
        }
        this.setState({
            selectedexercise: newex,
            currentImage: 0
        });
    }

    checkLoading() {
        if (!this.state || this.state.workoutinfo === null) {
            return true;
        }
        for (var i = 0; i < this.state.workoutinfo.drills.length; i++) {
            if (this.state.workoutinfo.drills[i].info == null) {
                return true;
            }
            for (var j = 0; j < this.state.workoutinfo.drills[i].exercises.length; j++) {
                if (this.state.workoutinfo.drills[i].exercises[j].info == null) {
                    return true;
                }
            }
        }
        return false;
    }

    randomWarmup(wi, fn) {
        var warmupkeys = Object.keys(this.props.warmups);
        var random = Math.floor(Math.random() * Math.floor(warmupkeys.length));
        
        var cs = new CourseData();
        var warmupworkout = this.props.warmups[warmupkeys[random]];
        cs.convertWorkout(warmupkeys[random], warmupworkout, 3, FirebaseAuth.currentUser.uid, (w) => {
            wi.warmup = w;
            fn(wi);
        });   
    }

    randomCooldown(wi, fn) {
        var cooldownkeys = Object.keys(this.props.cooldowns);
        var random = Math.floor(Math.random() * Math.floor(cooldownkeys.length));
        
        var cs = new CourseData();
        var cooldownworkout = this.props.cooldowns[cooldownkeys[random]];
        cs.convertWorkout(cooldownkeys[random], cooldownworkout, 3, FirebaseAuth.currentUser.uid, (w) => {
            wi.cooldown = w;
            fn(wi);
        });
    }

    startWorkout(levels, usePause, warmup, cooldown) {
        var that = this;
        var wi = this.state.workoutinfo;
        wi.usePause = usePause;
        wi.warmup = null;
        wi.cooldown = null;
        for (var i = 0; i < levels.length; i++) {
            wi.drills[i].executionlevel = levels[i];
        }
        if (warmup && cooldown) {
            this.randomWarmup(wi, (wi) => {
                that.randomCooldown(wi, (wi) => {
                    that.setPrepared(wi);
                }); 
            });
        }
        else if (warmup) {
            this.randomWarmup(wi, (wi) => {
                that.setPrepared(wi);
            });               
        }        
        else if (cooldown) {
            this.randomCooldown(wi, (wi) => {
                that.setPrepared(wi);
            });               
        }
        else {
            that.setPrepared(wi);
        }
    }

    setPrepared(wi){
        this.setState({
            workoutinfo: wi,
            prepared: true
        });
    }

    getTimeSpanText(end, start) {
        var difference = new Date(end - start);
        var diff_hours = (difference.getHours() - 1).toString();
        if (diff_hours.length === 1) {
            diff_hours = "0" + diff_hours;
        }
        var diff_mins = difference.getMinutes().toString();
        if (diff_mins.length === 1) {
            diff_mins = "0" + diff_mins;
        }
        var diff_secs = difference.getSeconds().toString();
        if (diff_secs.length === 1) {
            diff_secs = "0" + diff_secs;
        }
        return diff_hours + ":" + diff_mins + ":" + diff_secs;
    }

    saveWorkout(workout, save) {
        if (save) {
            var cs = new CourseData();
            var userdata = new UserData();            
            var str = "";                        
            userdata.loadUser(workout.userid, (u) => {
                cs.insertWorkoutExecution(workout, u, str, true);                
            });
        }
        this.props.close();
    }

    render() {
        const { classes } = this.props;
        
        if (this.checkLoading()) {
            return (
                <div className={classes.fullscreen}>
                    Bereite Workout vor....<br /><br />
                    <Button className={classes.buttonContentMore} onClick={() => this.props.close()}>
                        Schließen
                    </Button>
                </div>
            );
        }
        var header = <div className={[classes.topic, classes.marginTop20, classes.marginBottom20].join(' ')}>Workout: {this.state.workoutinfo.name}</div>
        if (this.state.workoutinfo.workouttype === undefined || this.state.workoutinfo.workouttype === 0) {
            if (!this.state.prepared) {
                return (
                    <div className={classes.fullscreen}>
                        {header}                    
                        <WorkoutExecutePrepare workoutinfo={this.state.workoutinfo} cancel={() => this.saveWorkout(null, false) }  close={(levels, pause, warmup, cooldown) => this.startWorkout(levels, pause, warmup, cooldown)} />
                    </div>);
            }
            else {
                return (
                    <div className={classes.fullscreen}>                                        
                        <WorkoutExecutePerform workoutinfo={this.state.workoutinfo} close={(workout, save) => this.saveWorkout(workout, save)} />
                    </div>);
            }
        }
        else {
            return (
                <div className={classes.fullscreen}>
                    {header}                    
                    <WorkoutExecuteSingle workoutinfo={this.state.workoutinfo} cancel={() => this.saveWorkout(null, false) }  close={(workout, save) => this.saveWorkout(workout, save)} />
                </div>);
        }
    }
}

export default withStyles(useStyles)(WorkoutExecute);