class Browser {
    
    OnBackClick() {
        
    }
}


let BrowserMethods = new Browser();

export { BrowserMethods };