import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { OutlinedInput } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import { FirebaseAuth } from "../../lib/authentication";
import { CourseData } from '../../controller/data/coursedata'
import { UserData } from '../../controller/data/userdata'
import $ from "jquery";

const sortby = (field, reverse, primer) => {

  const key = primer ?
    function (x) {
      return primer(x[field])
    } :
    function (x) {
      return x[field]
    };

  reverse = !reverse ? 1 : -1;

  return function (a, b) {
    return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
  }
}

const additionalStyles = {

  fullcontainer: {
    float: 'left',
    border: '0px',
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
    marginRight: '10px'
  },
  boxHeight: {
    minHeight: '30px',
  },
  chattextwidth: {
    width: 'calc(100% - 40px - 170px)',
    marginLeft: '20px',
    padding: '0px'
  },
  chatbuttonwidth: {
    width: '150px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  chatMaxHeight: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class WorkoutMessageView extends React.Component {

  constructor(props) {
    super(props);
    var start = new Date();
    start.setDate(start.getDate() - 7);
    this.state = {
      loading: true,
      messages: {},
      latestmessage: null,
      start: start,
      showAllMessage: false
    };
    this.chatMessage = "";
  }

  componentDidMount() {
    this.initialLoadMessages();
  }

  initialLoadMessages() {
    var that = this;
    var coursedata = new CourseData();
    var start = (this.state.showAllMessage) ? new Date(2020, 0, 1).getTime() : this.state.start.getTime();
    coursedata.loadGroupMessages(this.props.currentGroupKey, start, (msg) => {
      msg.forEach((m) => {
        that.processMessage(m);
      });
      coursedata.listenGroupMessages((m) => {
        var mVal = m.val();
        if (that.state.latestmessage === null || mVal.timestamp > this.state.latestmessage) {
          that.processMessage(m);
        }
      });
    });
  }

  processMessage(m) {
    var that = this;
    var message = m.val();
    var msgs = that.state.messages;
    if (message.groupid === this.props.currentGroupKey) {
      msgs[m.key] = message;
      if (message.workout && message.executionid) {
        msgs[m.key].message = "Lade Workout Auswertung....";
        var coursedata = new CourseData();
        coursedata.loadWorkoutExecution(message.executionid, m.key, (wexec, x) => {
          var exec = wexec.val();
          var msgs = that.state.messages;
          msgs[x].message = that.formatWorkoutMessage(exec);
          that.setState({
            messages: msgs
          });
        });
      }
      if (that.state.latestmessage === null || message.timestamp > this.state.latestmessage) {
        that.setState({
          loading: false,
          messages: msgs,
          latestmessage: message.timestamp
        });
      }
      else {
        that.setState({
          loading: false,
          messages: msgs
        });
      }
    }
  }

  textchange(that, e) {
    that.chatMessage = e.target.value;
  }

  send(that) {
    var coursedata = new CourseData();
    var userdata = new UserData();
    userdata.loadUser(FirebaseAuth.currentUser.uid, (u) => {
      var user = {
        email: u.email,
        key: u.key,
        name: u.name,
        surname: u.surname
      };
      coursedata.addMessage(that.props.currentGroupKey, user, that.chatMessage, false, (str) => {
        $('#Nachricht').val("");
        console.log(str);
      });
    });
  }

  formatWorkoutMessage(workout) {
    var str = "";
    if (workout.publish) {
      str = "Ich habe gerade das Workout " + workout.name + " fertiggestellt mit folgenden Ergebnissen: \r\n\r\n";
      var start = (workout.warmup != undefined && workout.warmup != null) ? workout.warmup.drills[0].exercises[0].start : workout.drills[0].exercises[0].start;
      var end = (workout.cooldown != undefined && workout.cooldown != null) ? workout.cooldown.drills[workout.cooldown.drills.length - 1].exercises[workout.cooldown.drills[workout.cooldown.drills.length - 1].exercises.length - 1].end : workout.drills[workout.drills.length - 1].exercises[workout.drills[workout.drills.length - 1].exercises.length - 1].end;
      str += "Gesamtzeit: "
        + new Date(start).toLocaleDateString() + " " + new Date(start).toLocaleTimeString() + " - "
        + new Date(end).toLocaleDateString() + " " + new Date(end).toLocaleTimeString() + "\r\n\r\n";
      for (var j = 0; j < workout.drills[0].exercises.length; j++) {
        for (var i = 0; i < workout.drills.length; i++) {
          var ext = "";
          if (workout.drills[i].info.unit === 'Anzahl') {
            ext = " Stück";
          }
          if (workout.drills[i].info.unit === 'Sekunden') {
            ext = " Sekunden";
          }
          if (workout.drills[i].info.unit === 'Distanz') {
            ext = " Meter";
          }
          var exectime = this.getTimeSpanText(workout.drills[i].exercises[j].end, workout.drills[i].exercises[j].start);
          str += workout.drills[i].info.levels[workout.drills[i].executionlevel] + ext + " " + workout.drills[i].exercises[j].info.name + " in " + exectime + "\r\n";
        }
      }
    }
    else {
      str = "Ich habe gerade das Workout " + workout.name + " fertiggestellt!";
    }
    return str;
  }

  getTimeSpanText(end, start) {
    var difference = new Date(end - start);
    var diff_hours = (difference.getHours() - 1).toString();
    if (diff_hours.length === 1) {
      diff_hours = "0" + diff_hours;
    }
    var diff_mins = difference.getMinutes().toString();
    if (diff_mins.length === 1) {
      diff_mins = "0" + diff_mins;
    }
    var diff_secs = difference.getSeconds().toString();
    if (diff_secs.length === 1) {
      diff_secs = "0" + diff_secs;
    }
    return diff_hours + ":" + diff_mins + ":" + diff_secs;
  }

  replaceAll(str, search, replace) {
    while (str.indexOf(search) > 0) {
      str = str.replace(search, replace);
    }
    return str;
  }

  changeBool(type) {
    var that = this;
    if (type === 'showAllMessages') {
      var oldstate = (this.state.showAllMessage === undefined) ? false : this.state.showAllMessage;
      var newstate = !oldstate;
      var start = new Date();
      start.setDate(start.getDate() - 7);
      this.setState({
        loading: true,
        messages: {},
        latestmessage: null,
        start: start,
        showAllMessage: newstate
      });
      setTimeout(() => {
        that.initialLoadMessages();
      }, 1000);
    }
  }

  render() {
    const { classes } = this.props;
    var that = this;
    var messArr = [];
    for (var i = 0, keys = Object.keys(this.state.messages), ii = keys.length; i < ii; i++) {
      messArr.push(this.state.messages[keys[i]]);// console.log('key : ' + keys[i] + ' val : ' + arrMessages[keys[i]]);
    }
    var messagesSorted = messArr.sort(sortby('timestamp', true, parseInt));
    var messages = (<div className={[classes.floatrelative, classes.fullwidth].join(' ')}>Lade Nachrichten...</div>);
    if (!this.state.loading) {
      messages = (messagesSorted.map(function (m, key) {
        var msgclass = (m.workout) ? classes.workoutmessagelabel : classes.chatmessagelabel;
        var lines = m.message.split("\n");
        var msgtext = lines.map((d, idx) => {
          var line = that.replaceAll(d, '\r', '');
          line = that.replaceAll(line, '\n', '');
          if (line.trim() === '') {
            return (<div key={idx} className={[classes.floatrelative, classes.fullwidth].join(' ')}>&nbsp;</div>)
          }
          else if (line.trim().endsWith(":")) {
            return (<div key={idx}>
              <div className={classes.mainspacer} />
              <div className={[classes.floatrelative, classes.fullwidth].join(' ')}><b>{line}</b></div>
            </div>)
          }
          else {
            return (<div key={idx} className={[classes.floatrelative, classes.fullwidth].join(' ')}>{line}</div>)
          }
        });
        var timestamp = new Date(m.timestamp).toLocaleDateString() + " " + new Date(m.timestamp).toLocaleTimeString();
        return (
          <div className={classes.containerwithborder} key={key}>
            <div className={classes.infoLabelLeft}>{m.userid.surname} {m.userid.name} [{timestamp}]</div>
            <div className={msgclass}>
              {msgtext}
            </div>
            {/* <div className={classes.clear} /> */}
          </div>);
      }));
    }
    return (
      <div className={[classes.fullcontainer, classes.boxHeight].join(' ')}>
        <OutlinedInput id="Nachricht" multiline className={classes.textboxMulti} onChange={(e) => that.textchange(that, e)} />
        <div className={classes.mainspacer} />
        {/* <TextField id="Nachricht" multiline className={[classes.textboxFlex, classes.chattextwidth].join(' ')} onChange={(e) => that.textchange(that, e)} /> */}
        <Button className={[classes.buttonContentDefault, classes.chatbuttonwidth].join(' ')} onClick={() => that.send(that)}>
          Senden
            </Button>
        <div className={classes.mainspacer} />
        <Divider variant="middle" classes={{ root: classes.dividerContent }} />
        <div className={[classes.floatrelative, classes.chatMaxHeight].join(' ')}>
          {messages}
        </div>
        <div className={classes.mainspacer} />
        <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
          <FormControlLabel
            control={<Checkbox checked={this.state.showAllMessage} className={classes.checkbox}
              name="showAllMessages" onChange={() => that.changeBool('showAllMessages')} />}
            label={<div>Alle Nachrichten anzeigen</div>}
          />
        </div>
        <div className={classes.mainspacer} />
        <Divider variant="middle" classes={{ root: classes.dividerContent }} />
      </div>
    );
  }
}

export default withStyles(useStyles)(WorkoutMessageView);
