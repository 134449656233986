import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import defaultStyles from '../base';
import Messages from "./content/Messages/Messages"
import WorkoutOverview from "./content/Training/WorkoutOverview"
import Users from "./content/Users/Users"
import OnlineTrainingOverview from "./content/courses/OnlineTrainingOverview"
import OnlineTrainingWorkouts from "./content/courses/OnlineTrainingWorkouts"
import OnlineTrainingGroups from "./content/courses/OnlineTrainingGroups"
import EventOverview from "./content/Training/EventOverview"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const additionalStyles = {        
  
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class AdminOverview extends React.Component {

  constructor (props) {
    super(props);    
    this.state = {
      selectedsubmenu: 1
    };
  }

  componentDidMount() {    
      this.refreshState();         
  }
  
  refreshState() {
      this.setState({
        events: []
      });      
      
    }

    createHeader() {
        const { classes } = this.props;
        return (<div className={classes.containertitle}>
                    <Typography className={classes.title} color="textSecondary">
                        Administration
                    </Typography>
                    <Divider variant="middle" classes={{root: classes.divider}}/>
                </div>) ;
    };

    previousMenu() {
      var newmenu = 0;
      if (this.state.selectedsubmenu === 1) {
        newmenu = 7;
      }
      else {
        newmenu = this.state.selectedsubmenu - 1;
      }
      this.setState({
        selectedsubmenu: newmenu
      });
    }
  
    nextMenu() {
      var newmenu = 0;
      if (this.state.selectedsubmenu === 7) {
        newmenu = 1;
      }
      else {
        newmenu = this.state.selectedsubmenu + 1;
      }
      this.setState({
        selectedsubmenu: newmenu
      });
    }

    createSubeader() {
      const { classes } = this.props;
      var that = this;
      var submenu = "";
      if (this.state.selectedsubmenu === 1) {
        submenu = "Nachrichten";
      }
      if (this.state.selectedsubmenu === 2) {
        submenu = "Übungen";
      }
      if (this.state.selectedsubmenu === 3) {
        submenu = "Einheiten";
      }  
      if (this.state.selectedsubmenu === 4) {
        submenu = "Trainingsgruppen";
      }    
      if (this.state.selectedsubmenu === 5) {
        submenu = "Events";
      }
      if (this.state.selectedsubmenu === 6) {
        submenu = "Workouts";
      }
      if (this.state.selectedsubmenu === 7) {
        submenu = "Users";
      }
      var subheader = (<div className={[classes.header1, classes.marginBottom20].join(' ')}>
          <ArrowBackIcon className={[classes.navigationbutton1, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.previousMenu()} />
          <div className={[classes.switchheader1].join(' ')}>{submenu}</div>
          <ArrowForwardIcon className={[classes.navigationbutton1, classes.navigationButtonRight].join(' ')} fontSize="large" onClick={() => that.nextMenu()} />
        </div>);
      return (subheader) ;
  };

  createContent() {
      var content = (<div/>);
      if (this.state.selectedsubmenu === 1) {
        content = <Messages/>;
      }
      if (this.state.selectedsubmenu === 2) {
        content = (<OnlineTrainingOverview/>);
      }
      if (this.state.selectedsubmenu === 3) {
        content = (<OnlineTrainingWorkouts/>);
      }
      if (this.state.selectedsubmenu === 4) {
        content = (<OnlineTrainingGroups/>);
      }      
      if (this.state.selectedsubmenu === 5) {
        content = <EventOverview/>;
      }
      if (this.state.selectedsubmenu === 6) {
        content = <WorkoutOverview/>;
      }
      if (this.state.selectedsubmenu === 7) {
        content = <Users/>;
      }
      return content;
  }

  render() {
    
    const { classes } = this.props;

    var header = this.createHeader();
    var subheader = this.createSubeader();
    var content = this.createContent();
    var bottompagespace = <div className={classes.bottompagespace}/>;
    return (
        <Card className={classes.root}>      
            {header}       
            {bottompagespace} 
            {subheader}        
            {bottompagespace} 
            {content}
            {bottompagespace} 
        </Card>
        );
  }

  
}

export default withStyles(useStyles)(AdminOverview);
