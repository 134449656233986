import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Divider from '@material-ui/core/Divider';
import defaultStyles from '../base'
import $ from "jquery";
import OsInfo from '../../lib/os';

var isIos = new OsInfo().isIos();
var fullheight = (isIos) ? '100%' : '100vh';
const additionalStyles = {      
  impressumcontent: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    height: fullheight,
    overflow: 'auto',
    paddingTop: '10px' 
  }
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)
  
class Impressum extends React.Component {

  componentDidMount() {
    $("#extramenucontainer").height(window.innerHeight - 35);
    $(window).resize(function() {
      $("#extramenucontainer").height(window.innerHeight - 35);
    });    
  }

  render() {
    const { classes } = this.props;    
    var title = 'Impressum';
    return (
            <div key='impressum' id='extramenucontainer' className={classes.extramenucontainer}>
            <div key='title' className={classes.title}>
                {title}
            </div>   
            <Divider variant="middle" classes={{root: classes.divider}}/> 
            <div key='content' id='impressum' className={classes.impressumcontent}>            
                    <b>No LimitZ Fitness Meier &amp; Faltermeier GbR</b><br/><br/><br/>
                    <b>vertretungsberechtigte Gesellschafter:</b><br/> 
                    Tobias Meier und Andreas Faltermeier<br/>
                    D&uuml;rnharter Straße 6a<br/>
                    94369 Rain<br/><br/>
                    E-Mail: info@nolimitz-fitness.de<br/><br/>
                    Steuernummer: 162/170/51405<br/>
                    <br/><br/>

                    Verantwortliche i.S.d. § 55 Abs. 2 RStV: Tobias Meier und Andreas Faltermeier, D&uuml;rnharter Straße 6a, 94369 Rain
            </div>
        </div>
        );
    }
}

export default withStyles(useStyles)(Impressum);
