import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import defaultStyles from '../base';
import NewsItems from './newsitems';
import NewsSocial from './newssocial';
import NewsPartner from './newspartner';
import NewsContactInfo from './newscontactinfo'
import $ from "jquery";

const additionalStyles = {

};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class NewsNoLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.timerID = -1;
        this.images = [
            "./assets/home/outdoor.jpg",
            "./assets/home/bike.jpg",
            "./assets/home/team.jpg",
            "./assets/home/camp.jpg"
          ];
        this.texts = [
            "Outdoor Fitness",
            "Bike Touren",
            "Teambuilding",
            "Trainings Camps"
          ];
        this.currentIndex = 0;
    }

    componentDidMount() {
        var that = this;
        this.timerID = setInterval(() => {
            $("#hometext").fadeTo(1000, 0.0);
            $("#homeimage").fadeTo(1000, 0.0, function () {
                that.currentIndex++;
                if (that.currentIndex > that.images.length - 1) {
                    that.currentIndex = 0;
                }
                $("#homeimage").attr('src', that.images[that.currentIndex]);
                $("#hometext").html(that.texts[that.currentIndex]);
                $("#homeimage").fadeTo(1000, 0.3);
                $("#hometext").fadeTo(1000, 1.0);
            });
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
      }

    render() {
        const { classes } = this.props;
        var that = this;

        var teaserimages = (<div className={classes.containercontent}>
            <div className={classes.textimagecontainer}>
                <img id='homeimage' alt='Home'
                    className={classes.imageFullWidthSemiTransparent}
                    src={this.images[that.currentIndex]}
                />
                <div id='hometext' className={classes.overlaycentertext}>{this.texts[that.currentIndex]}</div>
            </div>
        </div>);
        return (
            <div>
                <div className={classes.labelStandard}>Bock auf Outdoor-Events, Sportevents, MTB Touren?</div>
                <div className={classes.labelStandardBlue}>Dann los....</div>
                <div className={classes.mainspacer} />
                <Button className={classes.buttonContentMore} onClick={() => that.props.showLogin()}>
                    Login / Registrieren
                </Button>
                <NewsSocial />                
                <div className={classes.mainspacer} />
                <Divider variant="middle" classes={{ root: classes.dividerContent }} />
                <NewsItems />
                <div className={classes.mainspacer} />
                {teaserimages}
                <div className={classes.mainspacer} />                
                <NewsContactInfo  profileid={this.props.profileid} selectProfile={(profileid) => { that.props.selectProfile(profileid) } }/>
                <div className={classes.mainspacer} />
                <Divider variant="middle" classes={{ root: classes.dividerContent }} />
                <div className={classes.labelStandardBlue}>Unsere Partner</div>
                <div className={classes.mainspacer} />
                <NewsPartner />
                <div className={classes.mainspacer} />
                <div className={classes.mainspacer} />
            </div>
        );
    }
}

export default withStyles(useStyles)(NewsNoLogin);