import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { WorkoutData } from '../../controller/data/workoutdata'
import { EventData } from '../../controller/data/eventdata'
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import Regex from '../../lib/regex';
import $ from "jquery";

const additionalStyles = {
    combobox: {
        width: '90%',
        marginRight: '5%',
        marginLeft: '5%',
        height: '28px',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '12px'
    },
    menuitem: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '12px'
    },
    textboxstreet: {
        left: '5%',
        width: 'calc(90% - 100px)'
    },
    textboxnumber: {
        left: '5%',
        width: '80px',
        marginLeft: '20px',
        marginRight: '5%'
    },
    labelnumber: {
        marginLeft: 'calc(90% - 115px)'
    },
    textboxcity: {
        marginLeft: 'calc(5% + 20px)',
        width: 'calc(90% - 80px)',
        marginRight: '5%'
    },
    textboxzip: {
        left: '5%',
        width: '60px'
    },
    labelcity: {
        marginLeft: '60px'
    }
};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class Checkin extends React.Component {

    constructor(props) {
        super(props);
        var register = {
            surname: "",
            fullname: "",
            email: "",
            mobile: "",
            street: "",
            number: "",
            zip: "",
            city: ""
        }

        this.state = {
            registration: register,
            currentkey: "",
            eventlist: [],
            selectedevent: "",
            workoutlist: [],
            selectedworkout: "",
            type: "workout",
            infomessage: "",
            errormessage: ''
        };

        this.text = "Die angegebenen Daten werden vertraulich behandelt und lediglich für die gegebenenfalls nötige Nachverfolgung von Infektionsketten im Zusammenhang mit der Corona-Pandemie verwendet. Rechtsgrundlage sind erlassene Verordnungen und Bestimmungen zur aktuellen Corona-Pandemie. Die erhobenen Daten werden vier Wochen ausbewahrt und ausschließlich auf Anforderung der zuständigen Behörden weitergegeben. Als betroffene Person haben Sie im Hinblick auf Ihre erhobenen personenbezogenen Daten das Recht auf Auskunft, Berichtigung, sowie nach Ablauf der Aufbewahrungspflicht das Recht auf Löschung Ihrer Daten";        
    }

    componentDidMount() {
        var that = this;  
        this.loadNextEvents();
        this.loadNextWorkouts();      
        $("#checkincontainer").height(window.innerHeight - 35);
        $(window).resize(function() {
          $("#checkincontainer").height(window.innerHeight - 35);
        });
    }

    loadNextEvents() {
        var that = this;
        var ed = new EventData();
        ed.loadEventsComplete((evs) => {
            var newevs = [];
            evs.forEach(function (ev) {
                var event = ev.val();
                var entry = null;
                if (event.times) {
                    for (var j = 0; j < event.times.length; j++) {
                        if (new Date(event.times[j].start).getTime() > (new Date().getTime() - 7200000)) {
                            if (entry == null) {
                                entry = {
                                    key: ev.key,
                                    time: j,
                                    start: event.times[j].start,
                                    end: event.times[j].end,
                                    title: event.title
                                };
                            }
                            else {
                                if (new Date(event.times[j].start).getTime() < new Date(entry.start).getTime()) {
                                    entry.start = event.times[j].start;
                                    entry.time = j;
                                    entry.end = event.times[j].end;
                                }
                            }
                        }
                    }
                }
                if (entry !== null) {
                    newevs.push(entry);
                }                
              });
              var selectedevent = "";
              if (newevs.length > 0) {
                selectedevent = newevs[0].key;
              }
              that.setState({
                  eventlist: newevs,
                  selectedevent: selectedevent
              });
        });
    }

    loadNextWorkouts() {
        var that = this;
        var wd = new WorkoutData();
        wd.loadWorkoutsComplete((ws) => {
            var newws = [];
            ws.forEach(function (w) {
                var workout = w.val();
                var entry = null;
                if (workout.times) {
                    for (var j = 0; j < workout.times.length; j++) {
                        if (new Date(workout.times[j].start).getTime() > (new Date().getTime() - 7200000)) {
                            if (entry == null) {
                                entry = {
                                    key: w.key,
                                    time: j,
                                    start: workout.times[j].start,
                                    end: workout.times[j].end,
                                    title: workout.title
                                };
                            }
                            else {
                                if (new Date(workout.times[j].start).getTime() < new Date(entry.start).getTime()) {
                                    entry.start = workout.times[j].start;
                                    entry.time = j;
                                    entry.end = workout.times[j].end;
                                }
                            }
                        }
                    }
                }
                if (entry !== null) {
                    newws.push(entry);
                }                
              });
              var selectedworkout = "";
              if (newws.length > 0) {
                selectedworkout = newws[0].key;
              }
              that.setState({
                  workoutlist: newws,
                  selectedworkout: selectedworkout
              });
        });
    }

    textchange(prop, e) {
        var val = e.target.value;
        var reg = this.state.registration;
        if (prop === 'surname') {
            reg.surname = val;
        }
        if (prop === 'fullname') {
            reg.fullname = val;
        }
        if (prop === 'email') {
            reg.email = val;
        }        
        if (prop === 'mobile') {
            reg.mobile = val;
        }
        if (prop === 'street') {
            reg.street = val;
        }
        if (prop === 'number') {
            reg.number = val;
        }
        if (prop === 'zip') {
            reg.zip = val;
        }
        if (prop === 'city') {
            reg.city = val;

        }
        this.setState(state => ({
            registration: reg
        }));
    }

    comboboxchange(e) {
        var val = e.target.value;
        if (this.state.type === "event") {
            this.setState({
                selectedevent: val
            });
        }
        if (this.state.type === "workout") {
            this.setState({
                selectedworkout: val
            });
        }
    }

    settype(type) {
        this.setState({
            type: type
        });
    }

    setInfoText(text) {
        var that = this;
        this.setState(state => ({
            infomessage: text
        }));
        setTimeout(() => {
            that.setInfoText("");
        }, 3000);
    }

    setErrorText(text) {
        var that = this;
        this.setState(state => ({
            errormessage: text
        }));
        setTimeout(() => {
            that.setErrorText("");
        }, 3000);
    }

    register() {
        if (this.state.registration.password1 !== this.state.registration.password2) {
            this.setErrorText('Passwörter sind nicht identisch!');
        }
        else if (this.state.registration.fullname === ''
            || this.state.registration.surname === ''
            || this.state.registration.email === ''
            || this.state.registration.mobile === ''
            || this.state.registration.street === ''
            || this.state.registration.number === ''
            || this.state.registration.zip === ''
            || this.state.city === '') {
            this.setErrorText('Bitte füllen Sie alle Felder aus um sich zu registrieren!');
        }
        else if (!new Regex().validateEmail(this.state.registration.email)) {
            this.setErrorText("Keine gültige Emailadresse angegeben!");
        }
        else {
            var that = this;
            var profile = {
                "Name": that.state.registration.fullname,
                "Surname": that.state.registration.surname,
                "Street": that.state.registration.street,
                "Number": that.state.registration.number,
                "Zip": that.state.registration.zip,
                "City": that.state.registration.city,
                "Email": that.state.registration.email,
                "Mobile": that.state.registration.mobile
            };
            var type = this.gettype();
            if (type === "workout") {
                var time = 0;
                for (var i = 0; i < this.state.workoutlist.length; i++) {
                    if (this.state.workoutlist[i].key === this.state.selectedworkout){
                        time = this.state.workoutlist[i].time;
                    }
                }
                var wd = new WorkoutData();
                var key = wd.checkInEmail(this.state.selectedworkout, time, profile);
                this.setState({
                    currentkey: key
                });
            }
            else if (type === "event") {
                var time = 0;
                for (var i = 0; i < this.state.eventlist.length; i++) {
                    if (this.state.eventlist[i].key === this.state.selectedevent){
                        time = this.state.eventlist[i].time;
                    }
                }
                var ed = new EventData();
                var key = ed.checkInEmail(this.state.selectedworkout, time, profile);
                this.setState({
                    currentkey: key
                });
            }
        }
    }

    checkout() {
        var type = this.gettype();
        if (type === "workout") {            
            var wd = new WorkoutData();
            var key = wd.checkOutEmail(this.state.currentkey);
            this.setState({
                currentkey: ""
            });
        }
        else if (type === "event") {            
            var ed = new EventData();
            var key = ed.checkOutEmail(this.state.currentkey);
            this.setState({
                currentkey: ""
            });
        }
    }

    gettype() {
        var workoutavailable = (this.state.workoutlist != null && this.state.workoutlist.length > 0);
        var eventavailable = (this.state.eventlist != null && this.state.eventlist.length > 0);
        var type = this.state.type;
        if (workoutavailable && !eventavailable) {
            type = "workout";
        }
        if (!workoutavailable && eventavailable) {
            type = "event";
        }
        return type;
    }

    render() {
        if (this.state.currentkey === "") {
            return this.createRegistration();
        }
        else {
            return this.createCheckOut();
        }
    }

    createRegistration() {
        const { classes } = this.props;
        var that = this;
        var bottompagespace = <div className={classes.bottompagespace} />;
        var combobox = <div/>;
        var comboboxsubtitle = <div/>;
        var workoutavailable = (this.state.workoutlist != null && this.state.workoutlist.length > 0);
        var eventavailable = (this.state.eventlist != null && this.state.eventlist.length > 0);
        if (!workoutavailable && !eventavailable) {
            return (
                <Card className={classes.root}>
                    <div className={classes.containercontent}>
                        <div className={classes.label}>Aktuell sind keine Events und Workouts geplant</div>
                    </div>
                </Card>)
        }
        var type = this.gettype();

        if (type === "workout" && this.state.workoutlist != null && this.state.workoutlist.length > 0) {
            combobox = (<div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                            <TextField
                                id="NewWorkout"
                                select
                                value={this.state.selectedworkout}
                                className={[classes.combobox].join(' ')}
                                onChange={(e) => that.comboboxchange(e)}
                            >
                                {this.state.workoutlist.map((workout) => (
                                    <MenuItem className={classes.menuitem} key={workout.key} value={workout.key}>
                                        {workout.title} ({new Date(workout.start).toLocaleDateString() +  " " + new Date(workout.start).toLocaleTimeString()})
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>);
            comboboxsubtitle = <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Workout *</div>;
        }
        if (type === "event" && this.state.eventlist != null && this.state.eventlist.length > 0) {
            combobox = (<div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                            <TextField
                                id="NewEvent"
                                select
                                value={this.state.selectedevent}
                                className={[classes.combobox].join(' ')}
                                onChange={(e) => that.comboboxchange(e)}
                            >
                                {this.state.eventlist.map((event) => (
                                    <MenuItem className={classes.menuitem} key={event.key} value={event.key}>
                                        {event.title} ({new Date(event.start).toLocaleDateString() +  " " + new Date(event.start).toLocaleTimeString()})
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>);
            comboboxsubtitle = <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Event *</div>;
        }
        var workoutenabled = (this.state.type === "workout");
        var eventenabled = (this.state.type === "event");

        var selection = (<div/>);
        if (workoutavailable && eventavailable) {
            selection = (<div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                            <FormControlLabel
                                control={<Checkbox checked={eventenabled} className={classes.checkbox}
                                    name="event" onChange={() => that.settype('event')} />}
                                label={<div>Event</div>}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={workoutenabled} className={classes.checkbox}
                                    name="workout" onChange={() => that.settype('workout')} />}
                                label={<div>Workout</div>}
                            />
                        </div>);
        }

        return (
            <Card className={classes.root}>
                <div className={[classes.containercontent, classes.scrollabley].join(' ')} id="checkincontainer">
                    <div className={classes.label}>Check In</div>
                    {selection}
                    <div className={classes.mainspacer} />
                    {combobox}
                    {comboboxsubtitle}
                    <div className={classes.mainspacer} />
                    <input id="Surname" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('surname', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Vorname *</div>
                    <div className={classes.mainspacer} />
                    <input id="Name" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('fullname', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Nachname *</div>
                    <div className={classes.mainspacer} />
                    <input id="Email" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('email', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Email Adresse *</div>
                    <div className={classes.mainspacer} />
                    <input id="Mobile" className={[classes.formtextbox, classes.centerControl90pct].join(' ')} onChange={(e) => that.textchange('mobile', e)} />
                    <div className={[classes.formsublabel, classes.marginLeft5pct].join(' ')}>Telefonnummer *</div>
                    <div className={classes.mainspacer} />
                    <input id="Street" className={[classes.floatrelative, classes.formtextbox, classes.textboxstreet].join(' ')} onChange={(e) => that.textchange('street', e)} />
                    <input id="Number" className={[classes.floatrelative, classes.formtextbox, classes.textboxnumber].join(' ')} onChange={(e) => that.textchange('number', e)} />
                    <div className={[classes.floatrelative, classes.formsublabel, classes.marginLeft5pct].join(' ')}>Straße *</div>
                    <div className={[classes.floatrelative, classes.formsublabel, classes.labelnumber].join(' ')}>Hausnummer *</div>
                    <div className={classes.mainspacer} />
                    <input id="Zip" className={[classes.floatrelative, classes.formtextbox, classes.textboxzip].join(' ')} onChange={(e) => that.textchange('zip', e)} />
                    <input id="City" className={[classes.floatrelative, classes.formtextbox, classes.textboxcity].join(' ')} onChange={(e) => that.textchange('city', e)} />
                    <div className={[classes.floatrelative, classes.formsublabel, classes.marginLeft5pct].join(' ')}>PLZ *</div>
                    <div className={[classes.floatrelative, classes.formsublabel, classes.labelcity].join(' ')}>Ort *</div>
                    <div className={classes.mainspacer} />
                    <div className={[classes.floatrelative, classes.fullwidth, classes.formsublabel, classes.marginLeft5pct].join(' ')}>* Pflichtfelder</div>                    
                    <div className={classes.mainspacer} />                    
                    <div className={classes.infolabel}>{that.state.infomessage}</div>
                    <div className={classes.errorlabel}>{that.state.errormessage}</div>
                    <Button className={classes.buttonFullWidth} onClick={() => that.register()}>
                        <div className={[classes.innerCenter].join(' ')}>
                            <img className={[classes.floatrelative, classes.arrowright].join(' ')} src="./assets/arrow_right.png" alt="Pfeil" />
                            <div className={[classes.floatrelative].join(' ')}>Einchecken</div>
                        </div>
                    </Button>
                    <div className={classes.mainspacer} />
                    <div className={[classes.floatrelative, classes.fullwidth, classes.formsublabel, classes.marginLeft5pct].join(' ')}>{this.text}</div>
                </div>
                {bottompagespace}
            </Card>
        );
    }

    createCheckOut() {
        const { classes } = this.props;
        var that = this;
        var bottompagespace = <div className={classes.bottompagespace} />;
        var type = this.gettype();
        var text = "";
        if (type === "workout") {
            for (var i = 0; i < this.state.workoutlist.length; i++) {
                if (this.state.workoutlist[i].key === this.state.selectedworkout){
                    text = "Du befindest dich gerade im Workout " + this.state.workoutlist[i].title + " (" + 
                        new Date(this.state.workoutlist[i].start).toLocaleDateString() +  " " + new Date(this.state.workoutlist[i].start).toLocaleTimeString() + 
                        "). Klicke auf Auschecken wenn du das Workout verlässt!";
                }
            }
        }
        if (type === "event") {
            for (var i = 0; i < this.state.eventlist.length; i++) {
                if (this.state.eventlist[i].key === this.state.selectedevent){
                    text = "Du befindest dich gerade im Event " + this.state.eventlist[i].title + " (" + 
                        new Date(this.state.eventlist[i].start).toLocaleDateString() +  " " + new Date(this.state.eventlist[i].start).toLocaleTimeString() + 
                        "). Klicke auf Auschecken wenn du das Event verlässt!";
                }
            }
        }
        return (
            <Card className={classes.root}>
                <div className={[classes.containercontent, classes.scrollabley].join(' ')} id="checkincontainer">
                    <div className={classes.label}>Check Out</div>
                    <div className={classes.mainspacer} />                    
                    <div className={classes.mainspacer} />     
                    <div className={classes.mainspacer} />     
                    <div className={classes.infobig}>Hallo {this.state.registration.surname} {this.state.registration.fullname}</div>
                    <div className={classes.infobig}>{text}</div>
                    <div className={classes.mainspacer} />
                    <div className={classes.infolabel}>{that.state.infomessage}</div>
                    <div className={classes.errorlabel}>{that.state.errormessage}</div>
                    <Button className={classes.buttonFullWidth} onClick={() => that.checkout()}>
                        <div className={[classes.innerCenter].join(' ')}>
                            <img className={[classes.floatrelative, classes.arrowright].join(' ')} src="./assets/arrow_right.png" alt="Pfeil" />
                            <div className={[classes.floatrelative].join(' ')}>Auschecken</div>
                        </div>
                    </Button>
                </div>
                {bottompagespace}
            </Card>
        );
    }
}

export default withStyles(useStyles)(Checkin);