import firebase from 'firebase';


class FCM {
  
  messaging = null;
  currentToken = "";
  permissionBlocked = false;
  messagingSupported = true;
  currentMessage = "";

  init() {
    const app = firebase.initializeApp({
      apiKey: "AIzaSyCp_ObwEyVyECChYhIF5AAQuf4TLP6E2Kc",
      authDomain: "nolimitz-fitness.firebaseapp.com",
      databaseURL: "https://nolimitz-fitness.firebaseio.com",
      projectId: "nolimitz-fitness",
      storageBucket: "nolimitz-fitness.appspot.com",
      messagingSenderId: "786776082175",
      appId: "1:786776082175:web:fac1386694202d53d52eca",
      measurementId: "G-X4GEMGZTCB"
      }); 

    const pushNotfifcationApplicationServerPublicKey = 'BExtxuJ6j0HzNpixXJMGEsMmM4iMmSXayzoQfGxVNfcOOSVJpRywFZlh0FTCvVvgmC5ydeSCJZ8gwA8D4K1RsiA';    
    if (firebase.messaging.isSupported()) {
      this.messaging = app.messaging();
      this.messaging.usePublicVapidKey(
        // Project Settings => Cloud Messaging => Web Push certificates
        pushNotfifcationApplicationServerPublicKey
      );
      this.messaging.onMessage((payload) => {
        console.log("Message received in FCM");        
        this.OnMessageReceived(payload);
      });
    }
    else {
      this.messagingSupported = false;
      console.log("Firebase messaging not supported");
    }
  }

  requestPermission() {
    var that = this;
    if (this.messagingSupported && this.messaging !== null) {
      this.messaging.requestPermission()
          .then(async function() {
            that.currentToken = await that.messaging.getToken();
            that.currentMessage = "Benachrichtigungen sind auf Ihrem Gerät aktiviert";
            that.OnTokenReceived(that.currentToken);            
            console.log("Permission to notify: " + that.currentToken);
          })
          .catch(function(err) {
            that.permissionBlocked = true;
            if (err.toString().includes("messaging/permission-blocked"))
            {
              that.currentMessage = "Auf ihrem Gerät wurde die Erlaubnis für Benachrichtigungen gesperrt. Bitte entsperren Sie diese in den App Einstellungen, wenn Sie Benachrichtigungen erhalten wollen!";
            }
            else {
              that.currentMessage = "Auf ihrem Gerät konnte die Erlaubnis für Benachrichtigungen nicht gesetzt werden. Bitte melden Sie sich über info@nolimitz-fitness.de bei uns, um Hilfe zu erhalten. Fehlermeldung: " + err.toString();
            }
            console.log("Unable to get permission to notify.", err);
            that.OnTokenReceived(null);
          });
      }
      else {
        this.currentMessage = "Die NoLimitZ Benachrichtigungen werden auf Ihrem Gerät nicht untestützt. Wir arbeiten derzeit an einer Lösung!"; 
        that.OnTokenReceived(null);
      }
  }

  OnTokenReceived(token) {

  }

  OnMessageReceived(payload) {

  }
}

let FirebaseMessaging = new FCM();

export { FirebaseMessaging };