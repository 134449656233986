import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import defaultStyles from '../../../base'
import { UserData } from '../../../../controller/data/userdata';


const additionalStyles = {

};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      users: null,
      selectedUser: "",
      betauser: false,
      paid: [],
      start: "",
      end: ""
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers() {
    var that = this;
    var ud = new UserData();
    ud.loadAllUsers((users) => {
      var u = {};
      users.forEach((nw) => {
        u[nw.key] = nw.val();
      });
      that.setState({
        users: u
      })
    });
  }

  selectUser(uk) {
    var beta = this.state.users[uk].betauser === undefined ? false : this.state.users[uk].betauser;
    var paid = this.state.users[uk].paid === undefined ? [] : this.state.users[uk].paid;
    this.setState({
      selectedUser: uk,
      betauser: beta,
      paid: paid
    });
  }

  changeBool(type) {
    if (type === 'betauser') {
      var oldstate = (this.state.betauser === undefined) ? false : this.state.betauser;
      var newstate = !oldstate;
      this.setState({
        betauser: newstate
      });
    }
  }

  datechange(prop, event) {
    var val = new Date(event.target.value);
    if (prop === 'start') {
      this.setState({
        start: val
      });
    }
    if (prop === 'end') {
      this.setState({
        end: val
      });
    }
  }

  addPayment() {
    if (this.state.start === "") {
      this.showError("Start nicht gesetzt");
      return;
    }
    if (this.state.end === "") {
      this.showError("Ende nicht gesetzt");
      return;
    }
    if (this.state.start >= this.state.end) {
      this.showError("Fehler: Start > Ende");
      return;
    }
    var newpayment = {
      start: this.state.start.getTime(),
      end: this.state.end.getTime(),
    };
    var paid = this.state.paid;
    paid.push(newpayment);
    this.setState({
      paid: paid
    });
  }
  updateUser() {
    if (this.state.selectedUser !== "") {
      var ud = new UserData();
      var user = this.state.users[this.state.selectedUser];
      user.betauser = this.state.betauser;
      user.paid = this.state.paid;
      ud.updateUser(this.state.selectedUser, user);
    }
  }

  showError(text) {
    var that = this;
    this.setState({
      errormessage: text
    });
    setTimeout(() => {
      that.showError("");
    }, 3000);
  }

  showInfo(text) {
    var that = this;
    this.setState({
      infomessage: text
    });
    setTimeout(() => {
      that.showInfo("");
    }, 3000);
  }

  render() {
    const { classes } = this.props;
    var that = this;
    var users = this.createUsers();
    return (
      <div>
        {users}
      </div>
    );
  }

  createUsers() {
    const { classes } = this.props;
    var that = this;
    if (this.state.users === null) {
      return (<div />)
    }

    var userkeys = Object.keys(this.state.users);
    var user = userkeys.map((uk, idx) => {
      if (uk === this.state.selectedUser) {
        return (<div className={classes.listBoxEntrySelected} key={idx}>{this.state.users[uk].Surname} {this.state.users[uk].Name}</div>);
      }
      else {
        return (<div className={classes.listBoxEntry} key={idx} onClick={(e) => that.selectUser(uk)}>{this.state.users[uk].Surname} {this.state.users[uk].Name} </div>);
      }
    });

    var userview = this.createUserView();

    return (
      <div className={[classes.floatrelative, classes.areaborderFull].join(' ')}>
        <div className={classes.completeWidthHeaderLabel}>
          Benutzer
      </div>
        <div className={classes.errorlabel}>{this.state.errormessage}</div>
        <div className={classes.infolabel}>{this.state.infomessage}</div>
        <div className={[classes.floatrelative, classes.areaborderHalf].join(' ')}>
          <div className={[classes.label].join(' ')}>Aktuelle News:</div>
          <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
            {user}
          </div>
        </div>
        {userview}
      </div>);
  }

  createUserView() {
    const { classes } = this.props;
    var that = this;
    if (this.state.selectedUser === '') {
      return (<div />);
    }
    var currentUser = this.state.users[this.state.selectedUser];
    var updatebutton = (
      <Button className={classes.buttonContentMore} onClick={() => this.updateUser()}>
        Benutzer aktualisieren
      </Button>);
    var add =
      (<Button className={classes.buttonContentMore} onClick={() => this.addPayment()}>
        Bezahlzeitraum einfügen
      </Button>);
    var paid = this.state.paid.map((item, idx) => {
      return (<div key={idx} className={[classes.floatrelative, classes.fullWidthMargin40Both, classes.standardlabel].join(' ')}>
        {new Date(item.start).toLocaleDateString()} - {new Date(item.end).toLocaleDateString()}
      </div>)
    });
    return (
      <div className={[classes.floatrelative, classes.areaborderHalf].join(' ')}>
        <div className={[classes.floatrelative, classes.fullWidthMargin20Both, classes.standardlabel].join(' ')}>{currentUser.Surname} {currentUser.Name}</div>
        <div className={[classes.floatrelative, classes.fullWidthMargin20Both, classes.standardlabel].join(' ')}>{currentUser.Email}</div>
        <div className={[classes.floatrelative, classes.fullWidthMargin20Both, classes.standardlabel].join(' ')}>{currentUser.Zip} {currentUser.City}</div>
        <div className={[classes.floatrelative, classes.fullWidthMargin20Both, classes.standardlabel].join(' ')}>Bezahlte Zeiträume:</div>
        {paid}
        <FormControlLabel
          control={<Checkbox checked={this.state.betauser} className={classes.checkbox}
            name="betauser" onChange={() => that.changeBool('betauser')} />}
          label={<div>Beta User</div>}
        />
        <TextField
          id="datetime-start"
          label="Von"
          type="date"
          onChange={(e) => that.datechange('start', e)}
          defaultValue={this.state.start}
          className={classes.floatrelative, classes.dateboxfull}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className={classes.mainspacer} />
        <TextField
          id="datetime-end"
          label="Bis"
          type="date"
          onChange={(e) => that.datechange('end', e)}
          defaultValue={this.state.end}
          className={classes.floatrelative, classes.dateboxfull}
          InputLabelProps={{
            shrink: true,
          }} />
        <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
          {add}
        </div>
        <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
          {updatebutton}
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Users);
