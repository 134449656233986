import OsInfo from '../lib/os';


var isIos = new OsInfo().isIos();
var fullwidth = (isIos) ? '100%' : '100%';
var width5 = (isIos) ? '5%' : '5%';
var width50 = (isIos) ? '50%' : '50%';
var width35 = (isIos) ? '35%' : '35%';
var width90 = (isIos) ? '90%' : '90%';
var width100 = (isIos) ? '100%' : '100%';
var fullheight = (isIos) ? '100%' : '100%';
const defaultStyles = {
  fullscreen: {
    position: 'fixed',
    float: 'left',
    width: '100%',
    height: '100%',
    zIndex: 10000,
    background: '#000000',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  topic: {
    position: 'relative',
    float: 'left',
    width: '100%',
    height: '40px',
    fontFamily: 'Anton',
    fontSize: '20px',
    fontWeight: 'bold',
    background: '#000000',
    color: '#00D2FF'
  },
  header1: {
    position: 'relative',
    float: 'left',
    width: '100%',
    fontFamily: 'Anton',
    fontWeight: 'bold',
    background: '#00D2FF',
    textAlign: 'center',
    color: '#000000'
  },
  switchheader1: {
    position: 'relative',
    display: 'inline-block',
    top: '4px',
    fontSize: '22px',
    fontFamily: 'Anton',
    fontWeight: 'normal',
    marginTop: '0px',
    height: '45px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#000000'
  },
  header2: {
    position: 'relative',
    float: 'left',
    width: '100%',
    fontSize: '12px',
    fontFamily: 'Anton',
    fontWeight: 'normal',
    background: '#00D2FF',
    textAlign: 'center',
    color: '#000000'
  },
  switchheader2: {
    position: 'relative',
    display: 'inline-block',
    top: '4px',
    fontSize: '14px',
    fontFamily: 'Anton',
    fontWeight: 'normal',
    marginTop: '0px',
    height: '30px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#000000'
  },
  backbuttonHome: {
    position: 'relative',
    display: 'inline-block',
    color: '#FFFFFF',
    border: 0,
    paddingLeft: '2px',
    paddingTop: '2px',
    paddingBottom: '2px',
    height: '35px',
    width: '35px',
    cursor: 'pointer'
  },
  submenucaption: {
    position: 'relative',
    display: 'inline-block',
    fontFamily: 'Anton',
    fontSize: '20px',
    marginTop: '0px',
    height: '45px',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#FFFFFF'
  },
  navigationbutton1: {
    position: 'relative',
    display: 'inline-block',
    top: '5px',
    color: '#000000',
    border: 0,
    paddingLeft: '2px',
    paddingTop: '2px',
    paddingBottom: '2px',
    height: '35px',
    width: '35px',
    cursor: 'pointer'
  },
  navigationbutton2: {
    position: 'relative',
    display: 'inline-block',
    top: '5px',
    color: '#000000',
    border: 0,
    paddingLeft: '2px',
    paddingTop: '2px',
    paddingBottom: '2px',
    height: '25px',
    width: '25px',
    cursor: 'pointer'
  },
  editbutton: {
    display: 'inline-block',
    color: '#000000',
    border: 0,
    cursor: 'pointer',
    float: 'right'
  },
  hidden: {
    display: 'none'
  },
  fullWidth: {
    width: '100%'
  },
  margin20Both: {
    marginLeft: '20px',
    marginRight: '20px'
  },
  fullWidthMargin20Both: {
    width: 'calc(100% - 40px)',
    marginLeft: '20px',
    marginRight: '20px'
  },
  fullWidthMargin40Both: {
    width: 'calc(100% - 80px)',
    marginLeft: '40px',
    marginRight: '40px'
  },
  autoheight: {
    height: 'auto'
  },
  scrollabley: {
    overflowY: 'auto'
  },
  inlineblockitem: {
    display: 'inline-block'
  },
  rightalign: {
    float: 'right'
  },
  slider: {
    width: "80%",
    left: "10%"
  },
  navigationButtonLeft: {
    float: 'left',
    marginLeft: '10px'
  },
  navigationButtonRight: {
    float: 'right',
    marginRight: '10px'
  },
  width100: {
    width: '100px'
  },
  width200: {
    width: '200px'
  },
  marginLeft20: {
    marginLeft: '20px'
  },
  marginRight20: {
    marginRight: '20px'
  },
  paddingLeft20: {
    paddingLeft: '20px'
  },
  paddingLeft60: {
    paddingLeft: '60px'
  },
  paddingRight20: {
    paddingRight: '20px'
  },
  marginLeft5pct: {
    marginLeft: '5%'
  },
  marginLeft60: {
    marginLeft: '60px'
  },
  marginTop20: {
    marginTop: '20px'
  },
  marginBottom20: {
    marginBottom: '20px'
  },
  centerdcontent: {
    textAlign: 'center'
  },
  centerControl90pct: {
    width: '90%',
    left: '5%'
  },
  whiteBackground: {
    backgroundColor: '#FFFFFF'
  },
  imageMaxWidth: {
    maxWidth: '600px',
    maxHeight: '400px',
    width: '60vw',
    height: '40vw'
  },
  imageFullWidth: {
    width: '100vw',
    height: '66vw'
  },
  infotext: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '100',
  },
  infotextbold: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bolder'
  },
  infotextboldunderline: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'bolder',
    textDecoration: 'underline',
  },
  root: {
    width: fullwidth,
    background: 'transparent',
    fontFamily: 'Anton',
    fontSize: '12px',
    textTransform: 'none'
  },
  mainspacer: {
    position: 'relative',
    float: 'left',
    width: fullwidth,
    height: '20px'
  },
  clear: {
    clear: 'left'
  },
  clickable: {
    cursor: 'pointer'
  },
  relative: {
    position: 'relative'
  },
  floatrelative: {
    float: 'left',
    position: 'relative'
  },
  textcenter: {
    textAlign: 'center'
  },
  textleft: {
    textAlign: 'left'
  },
  marginAuto: {
    margin: 'auto'
  },
  fullwidth: {
    width: '100%'
  },
  halfwidth: {
    width: '50%'
  },
  topleft0: {
    top: '0px',
    left: '0px'
  },
  overflowwrap: {
    overflowWrap: 'break-word',
    overflow: 'hidden'
  },
  containertitle: {
    padding: '0px',
    width: fullwidth
  },
  containerwithborder: {
    position: 'relative',
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%'
  },
  title: {
    fontFamily: 'Anton',
    fontSize: '40px',
    marginTop: '12px',
    marginBottom: '12px',
    color: '#00D2FF'
  },
  divider: {
    backgroundColor: '#00D2FF',
    height: '7px',
    width: '34px',
    margin: 'auto'
  },
  dividerFullBlue: {
    float: 'left',
    position: 'relative',
    backgroundColor: '#00D2FF',
    height: '2px',
    width: '100%'
  },
  dividerContent: {
    position: 'relative',
    backgroundColor: '#AAAAAA',
    height: 1,
    width: 34,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '12px',
    marginBottom: '24px'
  },
  containercontent: {
    float: 'left',
    position: 'relative',
    padding: '0px',
    width: fullwidth,
    marginTop: '24px'
  },
  containercontentblock: {
    position: 'relative',
    float: 'left',
    padding: '0px',
    width: fullwidth
  },
  noeventworkouttext: {
    position: 'relative',
    padding: '0px',
    width: fullwidth,
    marginTop: '24px',
    fontSize: '24px'
  },
  backmenu: {
    position: 'relative',
    top: '0px',
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '35px',
    textAlign: 'center',
    backgroundColor: '#454545'
  },
  submenuheader: {
    position: 'relative',
    fontFamily: 'Anton',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    border: 0,
    fontSize: '24px',
    marginTop: '12px',
    marginBottom: '12px',
    fontWeight: 'lighter'
  },
  leftbadge: {
    position: 'relative',
    fontFamily: 'Anton',
    color: '#000000',
    backgroundColor: '#00D2FF',    
    fontSize: '24px',
    marginLeft: '0px',
    marginRight: '30%',
    marginTop: '12px',
    marginBottom: '12px',
    paddingLeft: '10px',
    width: '70%',
    height: '30px',
    fontWeight: 'lighter',
    textAlign: 'left',
  },
  rightbadge: {
    position: 'relative',
    fontFamily: 'Anton',
    color: '#000000',
    backgroundColor: '#FFFFFF',    
    fontSize: '24px',
    marginLeft: '50%',
    marginRight: '0%',
    marginTop: '12px',
    marginBottom: '12px',
    paddingLeft: '10px',
    width: '50%',
    height: '30px',
    fontWeight: 'lighter',
    textAlign: 'left',
  },
  submenuheaderlight: {
    position: 'relative',
    fontFamily: 'Anton',
    color: '#FFFFFF',
    fontSize: '18px'
  },
  titlesubmenu: {
    fontFamily: 'Anton',
    fontSize: '20px',
    paddingTop: '2px',
  },
  containersubmenu: {
    position: 'relative',
    margin: '0px',
    padding: '0px',
    width: fullwidth,
    height: '35px',
    backgroundColor: '#454545'
  },
  containercontentSub: {
    padding: '0px',
    width: fullwidth,
    marginTop: '0px'
  },
  overlaycentertext: {
    position: 'absolute',
    color: '#FFFFFF',
    fontSize: '36px',
    width: width90,
    top: '50%',
    left: width50,
    transform: 'translate(-50%, -50%)',
  },
  extramenucontainer: {
    position: 'relative',
    float: 'left',
    width: '100%',
    left: '0px',
    top: '0px',
    padding: '0px',
    margin: '0px',
    height: 'calc(' + fullheight + ' - 35px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'auto'

  },
  extramenucontent: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    paddingTop: '10px'
  },
  description: {
    fontFamily: 'Anton',
    fontSize: '14px',
    fontWeight: 'lighter',
    marginTop: '2px',
    marginBottom: '2px',
    marginLeft: width5,
    marginRight: width5,
    textAlign: 'left',
    height: 'auto'
  },
  errorlabel: {
    color: '#FF0000',
    marginTop: '6px',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginLeft: width5,
    textAlign: 'left'
  },
  infolabel: {
    color: '#00FF00',
    marginTop: '6px',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginLeft: width5,
    textAlign: 'left'
  },
  emailloginlabel: {
    color: '#FFFFFF',
    marginTop: '26px',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '10px',
    marginLeft: width5,
    textAlign: 'left'
  },
  textbox: {
    width: '90%',
    marginTop: '12px',
    height: '28px',
    backgroundColor: '#FFFFFF',
    color: '#000000'
  },
  formtextbox: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    padding: '5px'
  },
  mintextbox: {
    width: '30%',
    height: '28px',
    backgroundColor: '#FFFFFF',
    color: '#000000'
  },
  textboxFlex: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    minHeight: '28px',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    fontFamily: 'Roboto',
    fontSize: '10px',
  },
  textboxMulti: {
    width: '90%',
    height: '100px',
    fontFamily: 'Roboto',
    fontSize: '10px',
    overflowY: 'auto',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    verticalAlign: 'top'
  },
  infobullets: {
    color: '#FFFFFF',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '10px',
    marginLeft: '5px',
    textAlign: 'left'
  },
  info: {
    color: '#FFFFFF',
    marginTop: '24px',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '12px',
    marginLeft: width5,
    textAlign: 'left'
  },
  infobig: {
    color: '#FFFFFF',
    marginTop: '24px',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '18px',
    marginLeft: width5,
    textAlign: 'left'
  },
  standardlabel: {
    color: '#FFFFFF',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'left'
  },

  //Table
  listentry: {
    float: 'left',
    position: 'relative',
    width: 'calc(100% - 40px)',
    left: '20px',
    paddingBottom: '5px',
    paddingTop: '5px',
    marginBottom: '5px',
    marginTop: '5px',
    borderBottom: '1px solid black'
  },

  //Link
  internallink: {
    color: '#00D2FF',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textDecoration: 'none'
  },

  //Labels
  label: {
    color: '#00D2FF',
    marginTop: '24px',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '16px',
    marginLeft: width5,
    textAlign: 'left'
  },
  labelStandardBlack: {
    color: '#000000',
    background: '#FFFFFF',
    fontFamily: 'Anton',
    fontWeight: 'lighter',
    fontSize: '14px',
    textAlign: 'left'
  },
  labelStandard: {
    color: '#FFFFFF',
    background: 'transparent',
    fontFamily: 'Anton',
    fontWeight: 'normal',
    fontSize: '20px'
  },
  labelStandardBlue: {
    color: '#00D2FF',
    background: 'transparent',
    fontFamily: 'Anton',
    fontWeight: 'normal',
    fontSize: '20px'
  },
  labelInfo: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '100',
  },
  labelInfoBlue: {
    color: '#00D2FF',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '100',
  },
  formsublabel: {
    color: '#FFFFFF',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'left'
  },
  sublabel: {
    color: '#454545',
    marginTop: '6px',
    background: 'transparent',
    fontFamily: 'Roboto',
    fontSize: '12px',
    marginLeft: width5,
    textAlign: 'left'
  },
  roundedFilledLabel: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    background: '#00D2FF',
    borderRadius: '8px',
    border: 0,
    color: '#000000',
    width: width90,
    left: width5,
    marginTop: '6px',
    marginBottom: '6px',
    padding: '1px'
  },
  completeWidthHeaderLabel: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 'normal',
    background: '#00D2FF',
    border: 0,
    color: '#FFFFFF',
    width: width100,
    left: '0px',
    marginTop: '6px',
    marginBottom: '6px',
    padding: '1px'
  },
  completeWidthHeaderLabel2: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    background: '#00D2FF',
    border: 0,
    color: '#000000',
    width: width100,
    left: '0px',
    marginTop: '6px',
    marginBottom: '6px',
    padding: '1px'
  },
  teaserlabel: {
    position: 'relative',
    fontFamily: 'Roboto',
    color: '#00D2FF',
    fontSize: '16px',
    fontWeight: 'lighter',
    textAlign: 'center',
    marginLeft: width5,
    marginRight: width5
  },
  teaserlabelnomargin: {
    fontFamily: 'Roboto',
    color: '#00D2FF',
    fontSize: '16px',
    fontWeight: 'lighter',
    textAlign: 'center'
  },
  smallteaserlabel: {
    float: 'left',
    position: 'relative',
    fontFamily: 'Roboto',
    color: '#00D2FF',
    fontSize: '16px',
    fontWeight: 'lighter',
    textAlign: 'center',
    marginLeft: width5,
    marginRight: width5
  },
  sectionlabel: {
    float: 'left',
    position: 'relative',
    fontFamily: 'Anton',
    color: '#FFFFFF',
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: width5,
    marginRight: width5,
    width: width90
  },
  sectionlabel2: {
    float: 'left',
    position: 'relative',
    fontFamily: 'Roboto',
    color: '#FFFFFF',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  infolabelright: {
    position: 'relative',
    float: 'right',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '100',
    textAlign: 'center',
    width: width35,
    marginLeft: width5,
    marginRight: width5
  },
  infoLabelLeft: {
    position: 'relative',
    left: '0px',
    top: '0px',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    marginRight: '0px',
    minHeight: '15px',
    overflowWrap: 'break-word',
    overflow: 'hidden'
  },
  infoLabelLeftMargin: {
    position: 'relative',
    left: '0px',
    top: '0px',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'left',
    width: '100%',
    marginLeft: '20px',
    marginRight: '0px',
    minHeight: '15px',
    overflowWrap: 'break-word',
    overflow: 'hidden'
  },
  workoutmessagelabel: {
    position: 'relative',
    left: '0px',
    top: '0px',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'left',
    width: 'calc(100% - 46px)',
    marginLeft: '20px',
    marginRight: '20px',
    minHeight: '15px',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    color: '#FFFFFF',
    padding: '3px',
    backgroundColor: '#00D2FF',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.8) 100%), url(${require("./logo.svg")})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center'
  },
  chatmessagelabel: {
    position: 'relative',
    left: '0px',
    top: '0px',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'left',
    width: 'calc(100% - 46px)',
    marginLeft: '20px',
    marginRight: '20px',
    minHeight: '15px',
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    color: '#000000',
    padding: '3px',
    backgroundColor: '#ffffff',
    overflowWrap: 'break-word',
    overflow: 'hidden'
  },
  //Checkbox
  checkbox: {
    position: 'relative',
    fontFamily: 'Roboto',
    color: '#FFFFFF',
    fontSize: '10px',
    fontWeight: 'lighter',
    textAlign: 'center',
    marginLeft: width5,
    marginRight: width5,
    marginTop: '10px'
  },

  //Buttons
  buttonFullWidth: {
    marginTop: '12px',
    marginBottom: '12px',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    background: '#00D2FF',
    color: 'black',
    height: 'auto',
    width: fullwidth,
    padding: '2px',
    cursor: 'pointer'
  },
  buttonFullWidthSmall: {
    marginTop: '12px',
    marginBottom: '12px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    background: '#00D2FF',
    color: 'black',
    height: 'auto',
    width: fullwidth,
    padding: '2px',
    cursor: 'pointer'
  },
  backbutton: {
    borderRadius: '8px',
    color: '#FFFFFF',
    border: 0,
    position: 'absolute',
    float: 'left',
    top: '0px',
    left: '0px',
    paddingLeft: '20px',
    paddingTop: '2px',
    paddingBottom: '2px',
    height: '35px',
    cursor: 'pointer'
  },
  buttonContentDefault: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    background: '#00D2FF',
    borderRadius: '2px',
    border: 0,
    color: 'black',
    minheight: '30px',
    cursor: 'pointer'
  },
  buttonStandard: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    background: '#00D2FF',
    borderRadius: '8px',
    border: 0,
    color: 'black',
    height: 22,
    padding: '4px',
    cursor: 'pointer'
  },
  buttonContentMore: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    background: '#00D2FF',
    borderRadius: '8px',
    border: 0,
    color: 'black',
    height: 35,
    marginTop: '12px',
    marginBottom: '12px',
    cursor: 'pointer'
  },
  selectionbuttonactive: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    cursor: 'pointer',
    background: '#00D2FF',
    borderRadius: '3px',
    color: 'black',
    border: 0,
    padding: '2px',
    height: 20
  },
  selectionbuttoninactive: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    cursor: 'pointer',
    background: '#000000',
    borderRadius: '3px',
    color: '#00D2FF',
    border: '1px solid #00D2FF',
    padding: '2px',
    height: 20
  },
  buttonContentMoreFlex: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 'normal',
    textAlign: 'center',
    verticalAlign: 'bottom',
    background: '#00D2FF',
    borderRadius: '4px',
    border: 0,
    color: 'black',
    height: '30px',
    marginTop: '12px',
    marginBottom: '12px',
    width: '90%',
    cursor: 'pointer'
  },
  buttonFlexSmall: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
    verticalAlign: 'bottom',
    background: '#00D2FF',
    borderRadius: '4px',
    border: 0,
    color: 'black',
    height: '26px',
    marginTop: '12px',
    marginBottom: '12px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    width: '120px',
    cursor: 'pointer'
  },
  buttonblack: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '9px',
    margin: 'auto',
    width: '25%',
    maxWidth: '100px',
    height: '20px',
    borderRadius: '3px',
    backgroundColor: '#000000',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#000000'
    },
    '&:disabled': {
      color: '#FFFFFF',
      backgroundColor: '#CCCCCC'
    },
    border: '0px',
    cursor: 'pointer'
  },
  buttonIconSmall: {
    position: 'relative',
    width: '20px',
    height: '20px',
    backgroundColor: '#000000',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#000000'
    },
    '&:disabled': {
      color: '#FFFFFF',
      backgroundColor: '#CCCCCC'
    },
    border: '0px',
    cursor: 'pointer'
  },
  //List
  listArea: {
    width: '46%',
    marginLeft: '2%',
    marginRight: '2%',
    border: '1px solid #FFFFFF',
    borderRadius: '2px'
  },
  listAreaFull: {
    width: '96%',
    marginLeft: '2%',
    marginRight: '2%',
    border: '1px solid #FFFFFF',
    borderRadius: '2px'
  },
  listBox: {
    border: '1px solid #FFFFFF',
    borderRadius: '2px'
  },
  listBoxEntry: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
    verticalAlign: 'bottom',
    color: '#000000',
    background: '#FFFFFF',
    cursor: 'pointer'
  },
  listBoxEntrySelected: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
    verticalAlign: 'bottom',
    color: '#000000',
    background: '#00D2FF',
    cursor: 'pointer'
  },
  // Image Styles
  textimagecontainer: {
    position: 'relative',
    textAlign: 'center',
    marginTop: '0px'
  },
  textimagecontainerLeft: {
    float: 'left',
    position: 'relative',
    textAlign: 'left',
    marginTop: '0px',
    marginBottom: width5,
    width: width100,
  },
  imageFullWidthSemiTransparent: {
    width: width100,
    opacity: '0.3'
  },
  imageFullWidthUnTransparent: {
    width: width100,
    opacity: '1.0'
  },
  imageHalfWidthUnTransparent: {
    float: 'left',
    marginLeft: width5,
    width: '45%',
    opacity: '1.0'
  },
  image90WidthUnTransparent: {
    width: width90,
    left: width5,
    opacity: '1.0'
  },
  imageFullWidthUnTransparent90: {
    width: width90,
    left: width5,
    opacity: '1.0'
  },

  // Table Information Stylke    
  divcompletecontainer: {
    position: 'relative',
    float: 'left',
    left: '0px',
    width: width100
  },
  divinfocontainer: {
    position: 'relative',
    float: 'left',
    marginLeft: width5,
    marginRight: width5,
    width: width90
  },
  divinfotext: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '100',
    textAlign: 'left',
    width: '65%'
  },
  divinfotextfull: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '100',
    textAlign: 'left',
    width: '95%'
  },
  divinfohead: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '500',
    marginLeft: '0px',
    marginRight: '0px',
    width: '35%',
    verticalAlign: 'text-top',
    textAlign: 'left'
  },

  //Training with Progress
  training: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: '2px',
    textAlign: 'left',
    position: 'relative',
    float: 'left',
    width: '65%'
  },
  trainingicon: {
    position: 'relative',
    float: 'left',
    width: '20px',
    height: '20px',
    borderRadius: '8pt',
    border: 0
  },
  trainingicontext: {
    position: 'relative',
    float: 'right',
    fontFamily: 'Roboto',
    fontSize: '9px',
    width: '25%',
    maxWidth: '100px',
    height: '20px',
    borderRadius: '2px',
    backgroundColor: '#00D2FF',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#00D2FF'
    },
    '&:disabled': {
      color: '#FFFFFF',
      backgroundColor: '#CCCCCC'
    },
    border: '1px solid #FFFFFF',
    marginLeft: '0px',
    marginRight: '0px',
  },
  defaulticon: {
    position: 'relative',
    float: 'left',
    width: '20px',
    height: '20px',
    border: 0,
    background: '#00D2FF',
    color: '#000000'
  },
  trainingiconnoaction: {
    position: 'relative',
    float: 'left',
    width: '20px',
    height: '20px',
    border: 0,
    background: '#00D2FF',
    color: '#000000'
  },
  timesContainer: {
    position: 'relative',
    marginLeft: width5,
    marginRight: width5,
    marginTop: '15px',
    marginBottom: '15px'
  },
  timesContainerInner: {
    position: 'relative',
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '0px',
    marginBottom: '0px'
  },
  trainingprogresscontainer: {
    position: 'relative',
    marginLeft: '0px',
    marginRight: '0px'
  },
  trainingprogress: {
    position: 'relative',
    float: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    marginTop: '2px',
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: '2px',
    textAlign: 'left',
    width: '65%',
    height: '6px'
  },
  trainingprogresstext: {
    position: 'relative',
    float: 'right',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'right',
    width: '100%'
  },
  bottompagespace: {
    position: 'relative',
    float: 'left',
    width: width100,
    height: '20px'
  },
  workoutbuttonleft: {
    float: 'left',
    left: '20px',
    width: '110px',
    fontSize: '0.8em'
  },
  workoutbuttonright: {
    float: 'right',
    right: '20px',
    width: '110px',
    fontSize: '0.8em'
  },

  //Images
  arrowright: {
    height: '20px',
    width: '20px',
    marginRight: '10px',
  },
  logo: {
    height: '512px',
    width: '512px'
  },

  innerCenter: {
    display: 'table',
    margin: '0 auto'
  },
  kettlebellimage: {
    position: 'relative',
    float: 'left',
    width: '10px',
    height: '10px',
    marginLeft: '5px',
    left: '0px',
    opacity: '1.0',
    color: '#00D2FF'
  },

  //area
  areaborderHalf: {
    width: '46%',
    marginLeft: '2%',
    marginRight: '2%',
    border: '1px solid #FFFFFF',
    borderRadius: '2px'
  },
  areaborderFull: {
    width: '96%',
    marginLeft: '2%',
    marginRight: '2%',
    border: '1px solid #FFFFFF',
    borderRadius: '2px'
  },
  datebox: {
    width: '40%',
    marginRight: '5%',
    marginLeft: '5%',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '10px',
    fontFamily: 'Anton'
  },
  dateboxfull: {
    width: '90%',
    marginRight: '5%',
    marginLeft: '5%',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '10px',
    fontFamily: 'Anton'
  }
};

export default defaultStyles;