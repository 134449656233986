import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { FirebaseMessaging } from "./lib/fcm";
import { BrowserMethods } from "./lib/browser";
import { PwaInstaller } from "./lib/installprompt";

 FirebaseMessaging.init();

if (window.matchMedia('(display-mode: standalone)').matches) {
  PwaInstaller.IsInstalled = true;
}

handleBackEvents();

function handleBackEvents() {
  window.history.pushState({}, '');

  window.addEventListener('popstate', () => {
    BrowserMethods.OnBackClick();
    setTimeout(() => {
      window.history.pushState({}, '');
    }, 10);
  });
  window.addEventListener("beforeunload", () => {
    return false;
  });
}

window.addEventListener("beforeinstallprompt", function (beforeInstallPromptEvent) {
  beforeInstallPromptEvent.preventDefault(); // Prevents immediate prompt display
  PwaInstaller.setexecuteDeferredPrompt(beforeInstallPromptEvent);
});
serviceWorker.register('./firebase-messaging-sw.js');

ReactDOM.render(
  <App />,
  document.getElementById('root')
);