import { withStyles } from '@material-ui/core/styles';
import React from "react";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import defaultStyles from '../../../base'
import { CourseData } from '../../../../controller/data/coursedata'
import MenuItem from '@material-ui/core/MenuItem';

const additionalStyles = {
    textboxhalf: {
        width: '40%',
        marginRight: '5%',
        marginLeft: '5%',
        height: '28px',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '12px'
    },    
    combobox: {
        width: '90%',
        marginRight: '5%',
        marginLeft: '5%',
        height: '28px',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '12px'
    },
    menuitem: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '12px'
    },
    marginindented: {
        marginLeft: '50px'
    },
    labelWorkout: {
        textAlign: 'left',
        marginLeft: '10px',
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: 'light'
    },
    listArea : {
        width: '46%',
        marginLeft: '2%',
        marginRight: '2%',
        border: '1px solid #FFFFFF',
        borderRadius: '2px'
    },
    labelGroup: {
        textAlign: 'left',
        marginLeft: '30px',
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: 'light'
    },
    labelExercise: {
        textAlign: 'left',
        marginLeft: '50px',
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: 'light'
    }
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class OnlineTrainingWorkouts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            exercisegroups: null,
            groups: null,
            groupworkouts: null,
            selectedGroupWorkout: '',
            name: "",
            group: "",
            workout: "",
            start: new Date(),
            end: new Date(),
            drills: 0,
            exercises: 0,
            initialized: false,
            groupworkout: null,
            errormessage: '',
            mode: 1,
            warmup: false,
            cooldown: false,
            workouttype: 0
        }
        this.workouttypes = [{
            id : 0,
            name: "Zirkeltraining"
        },
        {
            id : 1,
            name: "Einzeleinheit"
        }]
    }

    componentDidMount() {
        this.loadExercisegroups();
        this.loadGroups();
        this.loadWorkouts();
    }

    loadExercisegroups() {
        var cd = new CourseData();
        cd.loadExerciseGroups((egs) => {
            var newgs = {};
            egs.forEach((eg) => {
                newgs[eg.key] = {
                    info: eg.val()
                };
                if (!newgs[eg.key].info.hasOwnProperty('levels')) {
                    newgs[eg.key].info.levels = [];
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);

                }
            });
            this.setState({
                exercisegroups: newgs
            });

        });
    }

    loadGroups() {
        var cd = new CourseData();
        cd.loadGroups((gs) => {
            var newgs = {};
            var gr = "";
            gs.forEach((eg) => {
                if (gr === "") {
                    gr = eg.key;
                }
                newgs[eg.key] = {
                    info: eg.val()
                };
            });
            this.setState({
                groupkeys: Object.keys(newgs),
                groups: newgs,
                group: gr
            });

        });
    }

    loadWorkouts() {
        var cd = new CourseData();
        cd.loadAllWorkouts((wo) => {    
            var gs = Object.keys(wo);
            var gw = "";
            gs.forEach((eg) => {
                if (gw === "") {
                    gw = eg;
                }
            });      
            this.setState({
                groupworkouts: wo,
                workout: gw
            });

        });
    }

    selectGroupWorkout(gws){
        this.setState({
            selectedGroupWorkout: gws
        });
    }

    render() {
        const { classes } = this.props;
        var content = (<div className={[classes.floatrelative, classes.infotextbold, classes.fullwidth].join(' ')}>Lade ...</div>); 
        if (this.state.exercisegroups != null && this.state.groups != null && this.state.groupworkouts !== null) {            
            if (this.state.mode === 1) {
                if (!this.state.initialized) {
                    var currentGroupWorkouts = this.createWorkoutList();
                    var selectedWorkoutPreview = this.createSelectedWorkoutPreview();
                    var initialiteform = this.createInitializeForm();
                    content = (<div>
                        <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                        {currentGroupWorkouts}
                        {selectedWorkoutPreview}
                        </div>
                        <div className={classes.mainspacer} />
                        {initialiteform}
                    </div>);
                }
                else {
                    content = this.createWorkoutSelection();
                }
            }
        }
        return (

            <div>
                <div className={classes.completeWidthHeaderLabel}>
                    Online Training - Workouts
                </div>
                <div className={classes.autoheight}>
                    <div className={classes.errorlabel}>{this.state.errormessage}</div>
                    <div className={classes.mainspacer} />
                    {content}                    
                </div>
            </div>
        );
    }

    createWorkoutList() {
        const { classes } = this.props;   
        var that = this;     
        if (this.state.groupworkouts !== null) {
            var gwkeys = Object.keys(this.state.groupworkouts);
            var existinggws = gwkeys.map((gws, idx) => {
                if (gws === this.state.selectedGroupWorkout) {
                    return (<div className={classes.listBoxEntrySelected} key={idx}>{this.state.groupworkouts[gws].name}</div>);
                }
                else {
                    return (<div className={classes.listBoxEntry} key={idx} onClick={(e) => that.selectGroupWorkout(gws)}>{this.state.groupworkouts[gws].name}</div>);
                }
            });

            return (
                <div className={[classes.floatrelative, classes.listArea].join(' ')}>
                    <div className={[classes.label].join(' ')}>Existierende Workouts:</div>
                        <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                            {existinggws}
                        </div>
                    <div className={classes.mainspacer} />
                    <Button className={classes.buttonContentMore} onClick={() => this.editWorkout()}>
                        Workout bearbeiten
                    </Button>
                </div>
            );
        }
        return (<div />);
    }

    createSelectedWorkoutPreview() {
        const { classes } = this.props;           
        if (this.state.selectedGroupWorkout !== '') {                      
            var drillkeys = Object.keys(this.state.groupworkouts[this.state.selectedGroupWorkout].drills);

            var drills = drillkeys.map((drls, idx2) => {
                var exercises = this.state.groupworkouts[this.state.selectedGroupWorkout].drills[drls].exerciseObjects.map((excs, idx3) => {
                    return (<div key={idx3}>
                        <div className={classes.labelExercise}>Übung: {excs.name}</div>
                    </div>)
                });
                return (<div key={idx2}>
                    <div className={classes.labelGroup}>Übungsgruppe: {this.state.groupworkouts[this.state.selectedGroupWorkout].drills[drls].name}</div>
                    {exercises}
                </div>)
            });
            return (
                <div className={[classes.floatrelative, classes.listArea].join(' ')}>
                    <div className={[classes.label].join(' ')}>Vorschau</div>
                    <div className={classes.labelWorkout}>{this.state.groupworkouts[this.state.selectedGroupWorkout].name}</div>
                    {drills}                    
                </div>
            );            
        }
        return (<div />);
    }

    createInitializeForm() {
        const { classes } = this.props;
        var that = this;
        var warmupdisabled = !this.state.warmup && this.state.cooldown;
        var cooldowndisabled = this.state.warmup && !this.state.cooldown;
        var numbers = (this.state.warmup || this.state.cooldown) 
                ? (<div/>) 
                : (<div>
                <div className={classes.label}>Durchgänge / Übungen</div>
                <input className={[classes.textboxhalf].join(' ')} defaultValue={that.state.drills} onChange={(e) => that.textchange('exercises', e)}></input>
                <input className={[classes.textboxhalf].join(' ')} defaultValue={that.state.exercises} onChange={(e) => that.textchange('drills', e)}></input>
            </div>);
        var warmupcooldown = (<div className={classes.mainspacer} />);
        if (this.state.workouttype === 0) {
            warmupcooldown = (
            <div>
                <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                <FormControlLabel
                    control={<Checkbox disabled={warmupdisabled} checked={this.state.warmup} className={classes.checkbox}
                        name="warmup" onChange={() => that.changeBool('warmup')} />}
                    label={<div>Warmup Einheit</div>}
                                />
                </div>
                <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                    <FormControlLabel
                        control={<Checkbox disabled={cooldowndisabled} checked={this.state.cooldown} className={classes.checkbox}
                            name="cooldown" onChange={() => that.changeBool('cooldown')} />}
                        label={<div>Cooldown Einheit</div>}
                                    />
                </div>
            </div>);
        }
        var content = (<div className={[classes.floatrelative, classes.listArea].join(' ')}>
            <div className={classes.label}>Name</div>
            <input className={[classes.textbox].join(' ')} defaultValue={that.state.name} onChange={(e) => that.textchange('name', e)}></input>
            <div className={classes.label}>Typ</div>
            <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                <TextField
                    id="NewWorkoutType"
                    select
                    value={this.state.workouttype}
                    className={[classes.combobox].join(' ')}
                    onChange={(e) => that.workouttypechange(e)}
                >
                    {this.workouttypes.map((workouttype) => (
                        <MenuItem className={classes.menuitem} key={workouttype.id} value={workouttype.id}>
                            {workouttype.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            {warmupcooldown}
            {numbers}
            <Button className={classes.buttonContentMore} onClick={() => this.addWorkout()}>
                Workout einfügen
            </Button>
        </div>);
        return content;
    }

    createWorkoutSelection() {
        const { classes } = this.props;
        var that = this;
        var groups = this.state.groupworkout.drills.map((item, idx) => {
            var exercises = item.exercises.map((exitem, idx2) => {
                var exercisekeys = Object.keys(this.state.exercisegroups[item.group].info.exercises);
                var id2 = "exercise" + idx + "_" + idx2;
                var label2 = "Übung " + (idx2 + 1);
                return (<div key={idx2}>
                    <div className={[classes.sublabel, classes.marginindented].join(' ')}>{label2}</div>
                    <TextField
                        id={id2}
                        select
                        value={exitem}
                        className={[classes.combobox, classes.marginindented].join(' ')}
                        onChange={(e) => that.exercisechange(idx, idx2, e)}
                    >
                        {exercisekeys.map((option) => (
                            <MenuItem className={classes.menuitem} key={option} value={option}>
                                {this.state.exercisegroups[item.group].info.exercises[option].name}
                            </MenuItem>
                        ))}
                    </TextField>
                    {exercises}
                </div>)
            });
            var exercisegroupkeys = Object.keys(this.state.exercisegroups);
            var id = "exercisegroup" + idx;
            var label = "Durchgang " + (idx + 1);
            return (<div key={idx}>
                <div className={classes.label}>{label}</div>
                <TextField
                    id={id}
                    select
                    value={item.group}
                    className={classes.combobox}
                    onChange={(e) => that.groupchange(idx, e)}
                >
                    {exercisegroupkeys.map((option) => (
                        <MenuItem className={classes.menuitem} key={option} value={option}>
                            {this.state.exercisegroups[option].info.name}
                        </MenuItem>
                    ))}
                </TextField>
                {exercises}
            </div>)
        });
        var info = (<div></div>);
        
        if (this.state.selectedGroupWorkout !== '') {
            var warmupcooldown = (<div className={classes.mainspacer} />);
            if (this.state.selectedGroupWorkout.workouttype === undefined || this.state.selectedGroupWorkout.workouttype === 0) {
                var warmupdisabled = !this.state.warmup && this.state.cooldown;
                var cooldowndisabled = this.state.warmup && !this.state.cooldown;
                warmupcooldown = (
                    <div>
    <                   div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                            <FormControlLabel
                            control={<Checkbox disabled={warmupdisabled} checked={this.state.warmup} className={classes.checkbox}
                                name="warmup" onChange={() => that.changeBool('warmup')} />}
                            label={<div>Warmup Einheit</div>}
                                        />
                        </div>
                        <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                            <FormControlLabel
                                control={<Checkbox disabled={cooldowndisabled} checked={this.state.cooldown} className={classes.checkbox}
                                    name="cooldown" onChange={() => that.changeBool('cooldown')} />}
                                label={<div>Cooldown Einheit</div>}
                                            />
                        </div>
                    </div>);
            }
            
            info = (
            <div>
                <div className={classes.label}>Name</div>
                <input className={[classes.textbox].join(' ')} defaultValue={that.state.name} onChange={(e) => that.textchange('name', e)}></input>
                {warmupcooldown}
            </div>);
        }
        var content = (<div>
            <Button className={classes.buttonContentMore} onClick={() => this.save()}>
                Aktualisieren
            </Button>
            <Button className={classes.buttonContentMore} onClick={() => this.reset()}>
                Abbrechen
            </Button>
            {info}
            {groups}            
        </div>);
        return content;
    }

    changeBool(type) {        
        if (type === 'warmup') {
            var oldstate = (this.state.warmup === undefined) ? false : this.state.warmup;
            var newstate = !oldstate;
            this.setState({
                warmup: newstate,
                drills: 5,
                exercises: 1
            });
        }
        if (type === 'cooldown') {
            var oldstate1 = (this.state.cooldown === undefined) ? false : this.state.cooldown;
            var newstate1 = !oldstate1;
            this.setState({
                cooldown: newstate1,
                drills: 5,
                exercises: 1
            });
        }
    }

    save() {
        var cd = new CourseData();
        if (this.state.selectedGroupWorkout === '') {
            cd.insertGroupWorkout(this.state.groupworkout);
        }
        else {
            var gw = this.state.groupworkout;
            if (this.state.selectedGroupWorkout.workouttype === undefined || this.state.selectedGroupWorkout.workouttype === 0) {
                gw.cooldown = this.state.cooldown;
                gw.warmup = this.state.warmup;
                gw.workouttype = 0;
            }
            else {
                gw.workouttype = 1;
            }
            gw.name = this.state.name;
            cd.updateGroupWorkout(this.state.selectedGroupWorkout, gw);            
        }
        this.reset();
    }

    reset() {
        this.setState({
            name: "",
            warmup: false,
            cooldown: false,
            group: "",
            workout: "",
            start: new Date(),
            end: new Date(),
            drills: 0,
            exercises: 0,
            initialized: false,
            groupworkout: null,
            errormessage: '',
            selectedGroupWorkout: '',
            workouttype: 0
        });
    }

    addWorkout() {
        if (this.state.name === "") {
            this.showError("Fehler: Kein Name eingegeben");
            return;
        }
        if (this.state.workouttype === 0) {
            if ((!this.state.warmup && !this.state.cooldown) && (this.state.drills === 0 || this.state.exercises === 0)) {
                this.showError("Fehler: Ungültige Übungs- / Durchgangsanzahl");
                return;
            }
        }
        
        var exercisegroupkeys = Object.keys(this.state.exercisegroups);
        var groupworkout = {
            drills: [],
            name: this.state.name,
            cooldown: this.state.cooldown,
            warmup: this.state.warmup,
            workouttype: this.state.workouttype
        };
        var drills = (this.state.workouttype === 1) ? 1 : (this.state.warmup || this.state.cooldown) ? 5 : this.state.drills;
        var exercises = (this.state.workouttype === 1) ? 1 : (this.state.warmup || this.state.cooldown) ? 1 : this.state.exercises;
        for (var i = 0; i < drills; i++) {
            var exc = [];
            var exercisekeys = Object.keys(this.state.exercisegroups[exercisegroupkeys[0]].info.exercises);
            for (var j = 0; j < exercises; j++) {
                exc.push(exercisekeys[0]);
            }
            groupworkout.drills.push({                
                group: exercisegroupkeys[0],
                exercises: exc
            });
        }
        this.setState({
            initialized: true,
            groupworkout: groupworkout,
            selectedGroupWorkout: '',
            drills: drills,
            exercises: exercises
        });
    }

    editWorkout() {
        var editworkout = this.state.groupworkouts[this.state.selectedGroupWorkout];
        var groupworkout = {
            drills: [],
            name: editworkout.name,
            cooldown: editworkout.cooldown,
            warmup: editworkout.warmup
        };
        var drillkeys = Object.keys(editworkout.drills);
        for (var i = 0; i < drillkeys.length; i++) {            
            groupworkout.drills.push({
                group: editworkout.drills[drillkeys[i]].group,
                exercises: editworkout.drills[drillkeys[i]].exercises
            });
        }
        
        this.setState({
            initialized: true,
            groupworkout: groupworkout,
            name: editworkout.name,
            cooldown: (editworkout.cooldown === undefined) ? false : editworkout.cooldown,
            warmup: (editworkout.warmup === undefined) ? false : editworkout.warmup,
            drills: drillkeys.length,
            exercises: editworkout.drills[drillkeys[0]].exercises.length
        });
    }

    showError(text) {
        var that = this;
        this.setState({
            errormessage: text
        });
        setTimeout(() => {
            that.showError("");
        }, 3000);
    }

    datechange(prop, newdate) {
        if (prop === 'start') {
            this.setState({
                start: newdate
            });
        }
        if (prop === 'end') {
            this.setState({
                end: newdate
            });
        }
    }

    textchange(prop, e) {
        var val = e.target.value;
        var ival = parseInt(val);
        if (isNaN(ival)) {
            ival = 0;
        }
        if (prop === 'drills') {
            this.setState({
                drills: ival
            });
        }
        if (prop === 'exercises') {
            this.setState({
                exercises: ival
            });
        }
        if (prop === 'name') {
            this.setState({
                name: val
            });
        }
        if (prop === 'group') {
            this.setState({
                group: val
            });
        }
        if (prop === 'workout') {
            this.setState({
                workout: val
            });
        }
    }

    exercisechange(idx, idx2, e) {
        var groupworkout = this.state.groupworkout;
        var val = e.target.value;
        groupworkout.drills[idx].exercises[idx2] = val;
        this.setState({
            groupworkout: groupworkout
        });

    }

    groupchange(idx, e) {
        var groupworkout = this.state.groupworkout;
        var val = e.target.value;
        var exc = [];
        var exercisekeys = Object.keys(this.state.exercisegroups[val].info.exercises);
        for (var j = 0; j < this.state.exercises; j++) {
            exc.push(exercisekeys[0]);
        }
        groupworkout.drills[idx].group = val;
        groupworkout.drills[idx].exercises = exc;
        this.setState({
            groupworkout: groupworkout
        });

    }

    workouttypechange(e) {
        var val = e.target.value;
        this.setState({
            workouttype: val
        });
    }
}

export default withStyles(useStyles)(OnlineTrainingWorkouts);