import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'

const additionalStyles = {
  fullcontainer: {
    float: 'left',
    border: '0px',
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
    marginRight: '10px'
  },
  boxHeight: {
    minHeight: '30px',
  },
  workoutcontainerleft: {
    position: 'relative',
    float: 'left',
    marginLeft: '0px',
    marginRight: '0px',
    width: 'calc(100% - 235px)'
  }
};


const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class WorkoutGroupInfoView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};

  }

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;
    var that = this;
    var userkeys = Object.keys(this.props.currentGroupUsers);
    var users = (userkeys.map(function (ukey, key) {
      var u = that.props.currentGroupUsers[ukey];
      return (
        <div className={classes.containerwithborder} key={key}>
          <div className={classes.infoLabelLeft}>{u.Surname} {u.Name}</div>
          <div className={classes.clear} />
        </div>);
    }));
    return (
      <div className={[classes.fullcontainer, classes.boxHeight].join(' ')}>
        {users}
        <div className={classes.mainspacer} />
        <Divider variant="middle" classes={{ root: classes.dividerContent }} />
      </div>);
  }
}

export default withStyles(useStyles)(WorkoutGroupInfoView);
