import { FirebaseStorage } from '../../lib/database';

export class UserData {

    loadAllUsers(fn) {
        FirebaseStorage.loadAllComplete("users", (users)=> {
            fn(users);
        });        
    }

    loadUser(uid, fn) {
        FirebaseStorage.loadObject("users", uid, (u) => {    
            var user = u.val();                    
            var usr = {
                key: uid,
                token: user.Token,
                email: (user.Email) ? user.Email : "",
                name: (user.Name) ? user.Name : "",
                surname: (user.Surname) ? user.Surname : ""
            };
            fn(usr);
        });
    }

    updateUser(key, user) {
        FirebaseStorage.updateObject("users", key, user);
    }

    loadUserProfile(uid, fn) {
        FirebaseStorage.loadObject("users", uid, (u) => {    
            var user = u.val();                            
            fn(user);
        });
    }
}