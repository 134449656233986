import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Button from '@material-ui/core/Button';
import defaultStyles from '../../../base'
import { FirebaseStorage } from "../../../../lib/database";
import { OutlinedInput } from '@material-ui/core';
import OsInfo from '../../../../lib/os';
import { NewsData } from '../../../../controller/data/newsdata';
import TextField from '@material-ui/core/TextField';

var isIos = new OsInfo().isIos();
var width90 = (isIos) ? '90%' : '90vw';

const additionalStyles = {
  textboxmultiline: {
    width: width90,
    marginTop: '12px',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    height: '180px'
  },
  messageoutcontent: {
    height: 'auto'
  }
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class Messages extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      info: {
        "title": "",
        "text": ""
      },
      news: null,
      start: "",
      end: ""
    };
    this.notificationTitle = React.createRef();
    this.notificationText = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }


  componentDidMount() {
    this.loadNews();
  }

  loadNews() {
    var that = this;
    var nd = new NewsData();
    nd.loadAllNews((news) => {
      var n = {};
      news.forEach((nw) => {
        n[nw.key] = nw.val();
      });
      that.setState({
        news: n
      })
    });
  }

  datechange(prop, event) {
    var val = new Date(event.target.value);
    if (prop === 'start') {
      this.setState({
        start: val
      });
    }
    if (prop === 'end') {
      this.setState({
        end: val
      });
    }
  }

  handleClick() {
    var notif = {
      "Title": this.state.info["title"],
      "Text": this.state.info["text"]
    };
    FirebaseStorage.pushObject("notfications", notif);
    this.showInfo("Newsletter erfolgreich verschickt!", "newsletter");
  }

  handleTestClick() {
    var notif = {
      "Title": this.state.info["title"],
      "Text": this.state.info["text"]
    };
    FirebaseStorage.pushObject("notficationtests", notif);
    this.showInfo("Newsletter-Test erfolgreich verschickt!", "newsletter");
  }

  addNews() {
    if (this.state.start === "") {
      this.showError("Start nicht gesetzt", "news");
      return;
    }
    if (this.state.end === "") {
      this.showError("Ende nicht gesetzt", "news");
      return;
    }
    if (this.state.start >= this.state.end) {
      this.showError("Fehler: Start > Ende", "news");
      return;
    }
    var nd = new NewsData();
    var news = {
      title: this.state.info["flashtitle"],
      text: this.state.info["flashtext"],
      start: this.state.start.getTime(),
      end: this.state.end.getTime(),
    }
    nd.insertNews(news);
    this.loadNews();    
    this.showInfo("News erfolgreich erstellt!", "news");
  }

  textchange(prop, e) {
    var val = e.target.value;
    var statecontent = this.state.info;
    statecontent[prop] = val;
    this.setState(state => ({
      info: statecontent
    }));
  }

  showError(text, type) {
    var that = this;
    if (type === "news") {
      this.setState({
        errormessagenews: text
      });
    }
    if (type === "newsletter") {
      this.setState({
        errormessage: text
      });
    }
    setTimeout(() => {
      that.showError("", type);
    }, 3000);
  }

  showInfo(text, type) {
    var that = this;    
    if (type === "news") {
      this.setState({
        infomessagenews: text
      });
    }
    if (type === "newsletter") {
      this.setState({
        infomessage: text
      });
    }
    setTimeout(() => {
      that.showInfo("", type);
    }, 3000);
  }

  render() {    
    var newsletter = this.createNewsletter();
    var news = this.createNews();
    return (
      <div>
        {newsletter}
        {news}
       </div>
    );
  }

  createNewsletter() {
    const { classes } = this.props;
    var that = this;
    return (

      <div>
        <div className={classes.completeWidthHeaderLabel}>
          Benachrichtigung / Newsletter
      </div>
        <div className={classes.errorlabel}>{this.state.errormessage}</div>
        <div className={classes.infolabel}>{this.state.infomessage}</div>
        <div id="MessageContent" className={[classes.floatrelative, classes.areaborderFull].join(' ')}>

          <OutlinedInput aria-label="Notification Title" className={classes.textbox} placeholder="Notification Title" ref={this.notificationTitle} onChange={(e) => that.textchange('title', e)} />
          <OutlinedInput aria-label="Notification Text" multiline className={classes.textboxmultiline} placeholder="Notification Text" ref={this.notificationText} onChange={(e) => that.textchange('text', e)} />
          <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
            <Button className={classes.buttonContentMore} onClick={() => that.handleClick()}>
              Send
          </Button>
            <Button className={classes.buttonContentMore} onClick={() => that.handleTestClick()}>
              Send Test
          </Button>
          </div>
        </div>
      </div>
    );
  }

  createNews() {
    const { classes } = this.props;
    var that = this;
    if (this.state.news === null) {
      return (<div />)
    }

    var newskeys = Object.keys(this.state.news);
    var news = newskeys.map((nk, idx) => {
      var start = new Date(this.state.news[nk].start).toLocaleDateString();
      var end = new Date(this.state.news[nk].end).toLocaleDateString();
      return (<div className={classes.listBoxEntry} key={idx}><b>{start} - {end}</b>:<br/> {this.state.news[nk].title} </div>);
    });
    var addbutton = (<Button className={classes.buttonContentMore} onClick={() => this.addNews()}>
      News hinzufügen
    </Button>);

    return (
      <div className={[classes.floatrelative, classes.areaborderFull].join(' ')}>
        <div className={classes.completeWidthHeaderLabel}>
          News
      </div>
        <div className={classes.errorlabel}>{this.state.errormessagenews}</div>
        <div className={classes.infolabel}>{this.state.infomessagenews}</div>
        <div className={[classes.floatrelative, classes.areaborderHalf].join(' ')}>          
          <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
            <OutlinedInput aria-label="News Title" className={classes.textbox} placeholder="News Title" onChange={(e) => that.textchange('flashtitle', e)} />
            <div className={classes.mainspacer} /> 
            <OutlinedInput aria-label="News Text" multiline className={classes.textboxMulti} placeholder="News Text" onChange={(e) => that.textchange('flashtext', e)} />
            <div className={classes.mainspacer} /> 
            <TextField
              id="datetime-start"
              label="Von"
              type="date"
              onChange={(e) => that.datechange('start', e)}
              defaultValue={this.state.start}
              className={classes.floatrelative, classes.dateboxfull}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.mainspacer} /> 
            <TextField
              id="datetime-end"
              label="Bis"
              type="date"
              onChange={(e) => that.datechange('end', e)}
              defaultValue={this.state.end}
              className={classes.floatrelative, classes.dateboxfull}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
              {addbutton}
            </div>
          </div>
        </div>
        <div className={[classes.floatrelative, classes.areaborderHalf].join(' ')}>
          <div className={[classes.label].join(' ')}>Aktuelle News:</div>
          <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
            {news}
          </div>
        </div>
      </div>);
  }
}

export default withStyles(useStyles)(Messages);
