import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Vimeo from '@u-wave/react-vimeo';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import defaultStyles from '../../../base'
import { CourseData } from '../../../../controller/data/coursedata'
import OsInfo from '../../../../lib/os';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import $ from "jquery";

var isIos = new OsInfo().isIos();
var width90 = (isIos) ? '90%' : '90vw';

const additionalStyles = {
    textboxmultiline: {
        width: width90,
        marginTop: '12px',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        height: '180px'
    },
    execerisecontainer: {
        border: '1px solid #FFFFFF',
        marginLeft: '5%',
        width: '90%'
    },
    levelbox: {
        width: '30%',
        height: '28px',
        marginLeft: '5%',
    },
    sliderlabel: {
        width: "30%",
        left: "10%",
        marginTop: "10px"
    },
    slidermini: {
        width: "30%",
        left: "20%",
        marginRight: "10%"
    }
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class OnlineTrainingOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            exercisegroupkeys: [ ],
            exercisegroups: { },
            selectedExerciseGroup : "",
            selectedExercise : ""
        }
    }

    componentDidMount() {
        this.loadExercisegroups();
    }

    loadExercisegroups() {
        var cd = new CourseData();        
        cd.loadExerciseGroups((egs) => {
            var newgs = {};
            egs.forEach((eg) => {                
                newgs[eg.key] = {
                    info: eg.val()
                };
                if (!newgs[eg.key].info.hasOwnProperty('levels')) {
                    newgs[eg.key].info.levels = [];
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                    newgs[eg.key].info.levels.push(0);
                }
                var exkeys = []
                if (newgs[eg.key].info.exercises) {
                    exkeys = Object.keys(newgs[eg.key].info.exercises);
                }
                for (var i = 0; i < exkeys.length; i++) {
                    if (!newgs[eg.key].info.exercises[exkeys[i]].hasOwnProperty('twosided')) {
                        newgs[eg.key].info.exercises[exkeys[i]].twosided = false;
                    }
                    if (!newgs[eg.key].info.exercises[exkeys[i]].hasOwnProperty('weights')) {                                    
                        newgs[eg.key].info.exercises[exkeys[i]].weights = {
                            power : 1,
                            endurance : 1,
                            stability : 1,
                            mobility : 1
                        };
                    }
                }     
            });
            var newgkeys = Object.keys(newgs);
            this.setState({
                loading: false,
                exercisegroupkeys: newgkeys,
                exercisegroups: newgs,
                selectedExerciseGroup : ""
            });

        });
    }

    changeSlider(type, newValue, key, ekey) {
        var change = this.state.exercisegroups;
        if (type === "power") {
            change[key].info.exercises[ekey].weights.power = newValue;
        }
        if (type === "endurance") {
            change[key].info.exercises[ekey].weights.endurance = newValue;
        }
        if (type === "stability") {
            change[key].info.exercises[ekey].weights.stability = newValue;
        }
        if (type === "mobility") {
            change[key].info.exercises[ekey].weights.mobility = newValue;
        }
        this.setState({
            exercisegroups: change
        });
    }

    changeBool(type, key, ekey) {
        var change = this.state.exercisegroups;
        if (type === 'twosided') {
            var oldstate = (change[key].info.exercises[ekey].twosided === undefined) ? false : change[key].info.exercises[ekey].twosided;
            var newstate = !oldstate;
            change[key].info.exercises[ekey].twosided = newstate;
        }
        this.setState({
            exercisegroups: change
        });
    }

    render() {
        const { classes } = this.props;
        var content = (<div className={[classes.floatrelative, classes.infotextbold, classes.fullwidth].join(' ')}>Lade Übungsgruppen....</div>);
        var content2 = (<div/>)
        var content3 = (<div/>)
        var content4 = (<div/>)
        if (!this.state.loading) {
            //content = this.createWorkoutGroups();
            content = this.createWorkoutGroupList();
            content2 = this.createWorkoutGroupExerciseList();
            if (this.state.selectedExerciseGroup !== "") {
                content3 = this.createSelectedWorkoutGroup();
            }            
            if (this.state.selectedExerciseGroup !== "" && this.state.selectedExercise !== "") {
                content4 = this.createselectedExercise();
            }
        }
        return (

            <div>
                <div className={classes.completeWidthHeaderLabel}>
                    Online Training - Übungen
                </div>
                <div className={classes.autoheight}>
                    {content}
                    {content2}
                    {content3}
                    {content4}
                </div>
            </div>
        );
    }

    onVideoError(errorObj, videoid, key, ekey) {
        $('#Video_'  + key + "_" + ekey).hide();        
        $('#VideoError_'  + key + "_" + ekey).show();        
    }

    onVideoLoaded(videoid, key, ekey) {
        $('#Video_'  + key + "_" + ekey).show();        
        $('#VideoError_'  + key + "_" + ekey).hide();    
    }

    createWorkoutGroupList() {
        const { classes } = this.props;   
        var that = this;     
        if (this.state.exercisegroups !== null) {
            var gwkeys = Object.keys(this.state.exercisegroups);
            var existinggws = gwkeys.map((gws, idx) => {
                if (gws === this.state.selectedExerciseGroup) {
                    return (<div className={classes.listBoxEntrySelected} key={idx}>{this.state.exercisegroups[gws].info.name}</div>);
                }
                else {
                    return (<div className={classes.listBoxEntry} key={idx} onClick={(e) => that.selectWorkoutGroup(gws)}>{this.state.exercisegroups[gws].info.name}</div>);
                }
            });

            return (
                <div className={[classes.floatrelative, classes.listArea].join(' ')}>
                    <div className={[classes.label].join(' ')}>Existierende Workoutgruppen:</div>
                        <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                            {existinggws}
                        </div>
                    <div className={classes.mainspacer} />
                    <Button className={classes.buttonContentMore} onClick={() => this.addExerciseGroup()}>
                        Gruppe hinzufügen
                    </Button>
                </div>
            );
        }
        return (<div />);
    }

    selectWorkoutGroup(gws){
        this.setState({
            selectedExerciseGroup: gws,
            selectedExercise: ""
        });
    }

    createWorkoutGroupExerciseList() {
        const { classes } = this.props;   
        var that = this;     
        if (this.state.exercisegroups !== null && this.state.selectedExerciseGroup !== "" && this.state.exercisegroups[this.state.selectedExerciseGroup].info.exercises) {
            var objkeys = Object.keys(this.state.exercisegroups[this.state.selectedExerciseGroup].info.exercises);
            var existingobjs = objkeys.map((o, idx) => {
                if (o === this.state.selectedExercise) {
                    return (<div className={classes.listBoxEntrySelected} key={idx}>{this.state.exercisegroups[this.state.selectedExerciseGroup].info.exercises[o].name}</div>);
                }
                else {
                    return (<div className={classes.listBoxEntry} key={idx} onClick={(e) => that.selectExercise(o)}>{this.state.exercisegroups[this.state.selectedExerciseGroup].info.exercises[o].name}</div>);
                }
            });

            return (
                <div className={[classes.floatrelative, classes.listArea].join(' ')}>
                    <div className={[classes.label].join(' ')}>Existierende Workoutgruppen:</div>
                        <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                            {existingobjs}
                        </div>
                    <div className={classes.mainspacer} />
                    <Button className={classes.buttonContentMore} onClick={() => this.addExercise()}>
                        Übung hinzufügen
                    </Button>
                </div>
            );
        }
        return (
            <div className={[classes.floatrelative, classes.listArea].join(' ')}>
                <Button className={classes.buttonContentMore} onClick={() => this.addExercise()}>
                    Übung hinzufügen
                </Button>
            </div>
        );
    }

    selectExercise(gws){
        this.setState({
            selectedExercise: gws
        });
    }

    createSelectedWorkoutGroup() {
        const { classes } = this.props;
        var that = this;

        var key = this.state.selectedExerciseGroup;
        var namekey = key + "_name";
        var unitkey = key + "_unit";
        var levels = this.state.exercisegroups[key].info.levels.map((item, idx) => {
            if (idx === 0) {
                return (<div/>);
            }
            var levelkey = key + "_name" + idx;
            return (<div className={[classes.floatrelative, classes.levelbox].join(' ')} key={idx}>
                        <div className={[classes.floatrelative].join(' ')}>{idx}:</div>
                        <input className={[classes.mintextbox].join(' ')} key={levelkey} defaultValue={item} onChange={(e) => that.egchange('level',idx, e)}></input>
                    </div>)
            });

        return (<div className={[classes.floatrelative, classes.infotextbold, classes.fullwidth].join(' ')}>
                    <Button className={classes.buttonContentMore} onClick={() => this.save()}>
                        Änderungen speichern
                    </Button> 
                    <div className={classes.label}>Name der Übungsgruppe</div>
                    <input className={[classes.textbox].join(' ')} key={namekey} id={namekey} defaultValue={this.state.exercisegroups[key].info.name} onChange={(e) => that.egchange('name', 0, e)}></input>
                    <div className={classes.label}>Einheit der Übungsgruppe</div>
                    <input className={[classes.textbox].join(' ')} key={unitkey} id={unitkey} defaultValue={this.state.exercisegroups[key].info.unit} onChange={(e) => that.egchange('unit', 0, e)}></input>
                    <div className={classes.label}>Levels der Übungsgruppe</div>
                    {levels}
                    <div className={classes.mainspacer}/></div>)
    }

    createselectedExercise() {
        const { classes } = this.props;
        var that = this;

        var key = this.state.selectedExerciseGroup;
        var ekey = this.state.selectedExercise;

        var defeimgkey = key + "_" + ekey + "_image0";
        var imgs = (this.state.exercisegroups[key].info.exercises[ekey].images.length > 0) 
        ? this.state.exercisegroups[key].info.exercises[ekey].images.map((item, idx) => {
            var eimgkey = key + "_" + ekey + "_image" + idx;
            return <input className={[classes.textbox].join(' ')} key={eimgkey} defaultValue={item} onChange={(e) => that.echange('image', ekey, idx, e)}></input>
            })
        : <input className={[classes.textbox].join(' ')} defaultValue="" key={defeimgkey} onChange={(e) => that.echange('image', ekey, 0, e)}></input>
        var desc = this.state.exercisegroups[key].info.exercises[ekey].description;                
        var enamekey = key + "_" + ekey + "_name";
        var evideokey = key + "_" + ekey + "_video";
        var edesckey = key + "_" + ekey + "_desc";

        var imagepreview = (<div className={[classes.errorlabel].join(' ')}>Kein Bild hinterlegt</div>);
        var diverrorid ="VideoError_" + key + "_" + ekey;
        var videopreviewerror = (<div id={diverrorid} className={[classes.errorlabel, classes.hidden].join(' ')}>Keine oder ungültige Vimeo Video ID gesetzt</div>);
        var videopreview = (<div/>);
        if (this.state.exercisegroups[key].info.exercises[ekey].video !== "") {
            var videoid = this.state.exercisegroups[key].info.exercises[ekey].video;                    
            var divid ="Video_" + key + "_" + ekey;
            videopreview = (
            <Vimeo 
                id={divid}
                video={videoid}                         
                autoplay
                background
                loop
                responsive 
                onError={(errorObj) => { this.onVideoError(errorObj, videoid, key, ekey) } }
                onLoaded={() => { this.onVideoLoaded(videoid, key, ekey) } }/>);                    
        }
        if (this.state.exercisegroups[key].info.exercises[ekey].images.length > 0 &&
            this.state.exercisegroups[key].info.exercises[ekey].images[0] !== "") {                    
            imagepreview = (<img className={[classes.floatrelative, classes.fullwidth].join(' ')} alt="Übungsbild" src={this.state.exercisegroups[key].info.exercises[ekey].images[0]}></img>);                    
        }
        
        return (<div key={ekey} className={[classes.floatrelative, classes.fullwidth, classes.execerisecontainer].join(' ')}>
                    <div className={classes.label}>Name der Übung</div>
                    <input className={[classes.textbox].join(' ')} key={enamekey} defaultValue={this.state.exercisegroups[key].info.exercises[ekey].name} onChange={(e) => that.echange('name', ekey, 0, e)}></input>
                    <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.sliderlabel].join(' ')}>Kraft</div>
                        <Slider
                            className={[classes.floatrelative, classes.slidermini].join(' ')}
                            value={this.state.exercisegroups[key].info.exercises[ekey].weights.power}
                            onChange={(event, newValue) => that.changeSlider("power", newValue, key, ekey)}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={8}
                        />
                    </div>
                    <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.sliderlabel].join(' ')}>Ausdauer</div>
                        <Slider
                            className={[classes.floatrelative, classes.slidermini].join(' ')}
                            value={this.state.exercisegroups[key].info.exercises[ekey].weights.endurance}
                            onChange={(event, newValue) => that.changeSlider("endurance", newValue, key, ekey)}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={8}
                        />
                    </div>
                    <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>                                
                        <div className={[classes.floatrelative, classes.sliderlabel].join(' ')}>Stabilität</div>
                        <Slider
                            className={[classes.floatrelative, classes.slidermini].join(' ')}
                            value={this.state.exercisegroups[key].info.exercises[ekey].weights.stability}
                            onChange={(event, newValue) => that.changeSlider("stability", newValue, key, ekey)}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={8}
                        />
                    </div>
                    <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                        <div className={[classes.floatrelative, classes.sliderlabel].join(' ')}>Mobilität</div>
                        <Slider
                            className={[classes.floatrelative, classes.slidermini].join(' ')}
                            value={this.state.exercisegroups[key].info.exercises[ekey].weights.mobility}
                            onChange={(event, newValue) => that.changeSlider("mobility", newValue, key, ekey)}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={8}
                        />
                    </div>                            
                    <div className={classes.label}>Bilder zur Übung</div>
                    {imgs}
                    {imagepreview}
                    <div className={classes.label}>Video zur Übung (Vimeo ID)</div>
                    <input className={[classes.textbox].join(' ')} key={evideokey} defaultValue={this.state.exercisegroups[key].info.exercises[ekey].video} onChange={(e) => that.echange('video', ekey, 0, e)}></input>                            
                    {videopreview}
                    {videopreviewerror}
                    <FormControlLabel
                        control={<Checkbox checked={this.state.exercisegroups[key].info.exercises[ekey].twosided} className={classes.checkbox}
                            name="twosided" onChange={() => that.changeBool('twosided', key, ekey)} />}
                        label={<div>Beidseitige Durchführung</div>}
                    />
                    <div className={classes.label}>Beschreibung zur Übung</div>
                    <textarea className={[classes.textboxmultiline].join(' ')} rows="4" cols="50" key={edesckey} defaultValue={desc} onChange={(e) => that.echange('desc', ekey, 0, e)}></textarea>
                    <div className={classes.mainspacer}/>
                </div>
        ); 
    }

    addExerciseGroup() {
        var squatgroup = {
            name: 'New Group',
            information: [
              ],
      
            unit: "Anzahl",
            levels: [],
            exercises: {}
          };
          squatgroup.levels = [];
          squatgroup.levels.push(0);
          squatgroup.levels.push(0);
          squatgroup.levels.push(0);
          squatgroup.levels.push(0);
          squatgroup.levels.push(0);
          squatgroup.levels.push(0);
          squatgroup.levels.push(0);
          squatgroup.levels.push(0);
        var cd = new CourseData();
        var key = cd.insertExerciseGroup(squatgroup);
        var egs = this.state.exercisegroups;
        var egkeys = this.state.exercisegroupkeys;
        egs[key] ={
            info: squatgroup
        };
        egkeys.push(key);
        this.setState({
            exercisegroups : egs,
            exercisegroupkeys: egkeys,
            selectedExerciseGroup: egkeys[egkeys.length - 1]
        })
    }

    addExercise() {
        var newex = {
            name: "New Exercise",
            description: "",
            video: "",
            images: [""],
            twosided: false,
            weights: {
                power : 1,
                endurance : 1,
                stability : 1,
                mobility : 1
            }
          };
        var cd = new CourseData();
        var key = cd.insertExercise(newex, this.state.exercisegroupkeys[this.state.selectedExerciseGroup]);        
        var egs = this.state.exercisegroups;
        if (!egs[this.state.selectedExerciseGroup].info.exercises) {
            egs[this.state.selectedExerciseGroup].info.exercises = {};
        }
        egs[this.state.selectedExerciseGroup].info.exercises[key] = newex;            
        this.setState({
            exercisegroups : egs
        })
    }

    save() {
        var exercisegroups = this.state.exercisegroups;
        var exercisegroupkeys = Object.keys(this.state.exercisegroups);  
        for (var i = 0; i < exercisegroupkeys.length; i++)
        {
            var cd = new CourseData();
            cd.updateExerciseGroup(exercisegroups[exercisegroupkeys[i]].info, exercisegroupkeys[i]);
        }
    }

    echange(prop, ekey, idx, e) {    
        var val = e.target.value;  
        var key = this.state.selectedExerciseGroup;
        var egs = this.state.exercisegroups;
        if (prop === 'name') {
            egs[key].info.exercises[ekey].name = val;
        }
        if (prop === 'video') {
            egs[key].info.exercises[ekey].video = val;            
        }
        if (prop === 'desc') {
            egs[key].info.exercises[ekey].description = val;
        }
        if (prop === 'image') {
            egs[key].info.exercises[ekey].images[idx] = val;
        }
        this.setState({
            exercisegroups: egs
        });
    }

    egchange(prop, idx, e) {        
        var val = e.target.value;
        var key = this.state.selectedExerciseGroup;
        var egs = this.state.exercisegroups;
        if (prop === 'name') {
            egs[key].info.name = val;
        }
        if (prop === 'unit') {
            egs[key].info.unit = val;
        }
        if (prop === 'level') {
            var intVal = parseInt(val);
            if (isNaN(intVal)) {
                intVal = 0;
            }
            egs[key].info.levels[idx] = intVal;
        }
        this.setState({
            exercisegroups : egs
        });
    }

}

export default withStyles(useStyles)(OnlineTrainingOverview);
