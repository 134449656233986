import React from "react";
import { withStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import defaultStyles from '../base';

const additionalStyles = {
    
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class NewsSocial extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            news: []
        };
    }

    render() {
        return this.createLinks();
    }

    createLinks() {
        const { classes } = this.props;
        var links = (<div>          
          <a href="https://www.facebook.com/Nolimitz-Fitness-103273131439744" target="_blank" rel="noopener noreferrer">
              <FacebookIcon id="FacebookIcon" fontSize="large" alt="Facebook" className={classes.linkImage} />
          </a>
          <a href="https://www.instagram.com/nolimitz_fitness" target="_blank" rel="noopener noreferrer">
            <InstagramIcon id="InstagramIcon" fontSize="large" alt="Instagram" className={classes.linkImage} /> 
          </a>
          </div>)        
        return links;
      }
}

export default withStyles(useStyles)(NewsSocial);