import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiTabs: {
            root: {
                color: '#FFFFFF',
                backgroundColor: '#454545'
            }
        },
        MuiButton: {
          root: {
              color: '#FFFFFF',
              '&:disabled': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:focused': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:focusVisible': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:active': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:hover': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:required': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:expanded': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:visited': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:error': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:checked': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              },
              '&:selected': {
                backgroundColor: '#00D2FF',
                color: '#FFFFFF'
              }
          }          
        },
        MuiIconButton: {
          root: {
              color: '#0000FF',
              '&:disabled': {
                color: '#0000FF'
              }
          }          
        },
        MuiTab: {
            root: {
                color: '#FFFFFF',
                backgroundColor: '#454545',
                    '&:hover': {
                        color: '#00D2FF',
                    }
            }
        },
        MuiTypography: {
          body1 : {
            fontSize: '10px'
          }
        },
        MuiOutlinedInput: {
          input: {           
          }
        },
        MuiInputBase: {
          root: {                      
            
          },
          input: {
            color: '#000000',
            fontSize: '10px'
          },
          multiline: {            
            height: '100px',  
            color: '#000000',
            fontSize: '10px',
            overflowY: 'auto',
            padding: '0px'
          }
        },
    },    
    palette:  { 
        text: {
            primary: "#ffffff",
            secondary: "#00D2FF"
        },         
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#00D2FF',
    },    
    error: {
      main: '#FF0000',
    },
    background: {
      default: '#000000',
    }    
  },
});

export default theme;