import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { OutlinedInput } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import defaultStyles from '../base';
import { FirebaseStorage } from '../../lib/database';
import { FirebaseAuth } from "../../lib/authentication";

const additionalStyles = {  
  subheaderindviual: {
    float: 'left',
    position: 'relative',
    fontFamily: 'Anton', 
    color: '#FFFFFF',
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: '5%',
    marginRight: '5%',
    width: '90%'
  },
  overlaytoplefttextsmall: {
    position: 'absolute',
    color: '#FFFFFF',
    fontFamily: 'Roboto', 
    fontSize: '18px',
    top: '85px',
    left: '15px',
    width: '100%',
    textAlign: 'center',
    textShadow: '1px  1px 1px black, 1px -1px 1px black, -1px  1px 1px black, -1px -1px 1px black'
  }
};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class Individual extends React.Component {
  constructor (props) {
    super(props);    
    this.state = {
      overview: true,
      type: -1,
      email: "",
      message: "",
      errormessage: "",
      infomessage: ""
    };
    this.contactEmail = React.createRef();
    this.contactMessage = React.createRef();
    this.individualInfo = [ 
      {
        header : "Spezielle Sportart ausprobieren",
        text: [
          "Mal was ganz anderes probieren?",
          "Ist das überhaupt möglich?",
          "Wenn ja:",
          "&nbsp;&nbsp;du bringst das Team",
          "&nbsp;&nbsp;wir bringen das Event",
          "&nbsp;",
          "Frag nach einem individuellen Angebot"
        ],
        image : "./assets/individual/indivdualsport.jpg"
      },
      {
        header : "Fussball - Trainingslager",
        text: [
          "Bock auf ein Trainingslager?",
          "Inland oder Ausland?",
          "Sommer oder Winter?",
          "Wir kümmern uns um die Organisation",
          "Wenn ihr wollt:",
          "Wir kümmern uns um die Trainingspläne",
          "Wir kümmern uns um die Trainingsdurchführung",
          "&nbsp;",
          "Frag nach einem individuellen Angebot"
        ],
        image : "./assets/individual/trainingslager.jpg"
      },
      {
        header : "Fitnessprogramm für dein Team",
        text: [
          "Fit halten in den Wintermonaten?",
          "Angepasst auf euere Bedürfnisse?",
          "Am besten vor Ort bei euerem Verein?",
          "&nbsp;",
          "Individuelle Terminplanung",
          "Individuelle Inhalte",
          "&nbsp;",
          "Frag nach einem individuellen Angebot"
        ],
        image : "./assets/individual/fitness.jpg"
      }
    ];
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    
  }


  textchange(prop, e) {
    var val = e.target.value;
    if (prop === 'email') {
      this.setState(state => ({
        email: val
      }));
    }
    if (prop === 'message') {
      this.setState(state => ({
        message: val
      }));
    }    
  }

  send(type) {    
    var that = this;
    var ts = Date.now();
    var loggedin = (FirebaseAuth.currentUser !== null);
    var email = (loggedin) ? FirebaseAuth.currentUser.email.toLowerCase() : that.state.email;
    var info = {
      "email" : email,
      "message" : "Individuelle Anfrage von " + email + ": " + that.individualInfo[that.state.type].header + ":  " + that.state.message,
      "timestamp" : ts,
    }
    if (email === '' || this.state.message === '')
    {                
      that.setState(state => ({
        infomessage: "",
        errormessage: 'Bitte füllen Sie alle Felder aus eine Nachricht abzuschicken!'
      }));
    }
    else {
      that.setState(state => ({
        email : "",
        message : "",
        infomessage: "Nachricht erfolgreich abgeschickt",
        errormessage: ''
      }));
      FirebaseStorage.pushObject("contactmessage", info);
    }
  }

  handleClick(id) {            
    this.setState(state => ({
      overview: false,
      type: id
    }));
  }

  handleBack() {    
    this.setState(state => ({
      overview: true,
      type: -1
    }));
 }

  render() {
      
    const { classes } = this.props;
    var header = this.createHeader();
    var individual = (this.state.overview) ?
                    this.createOverview() :
                    this.createContact();    
    
    var bottompagespace = <div className={classes.bottompagespace}><Divider variant="middle" className={classes.contactDivider}/></div>;
    
    return (

      <Card className={classes.root}>      
        {header}                             
        <div className={classes.mainspacer}/>
        {individual}              
        {bottompagespace}                  
      </Card>
    );
  }     
  
  createHeader() {
    const { classes } = this.props;
    var that = this;    
    var header = 
      this.state.overview ?
      ( <div className={classes.containertitle}>
        <Typography className={classes.title} color="textSecondary">
        Individuelles Angebot
        </Typography>
        <Divider variant="middle" classes={{root: classes.divider}}/></div>)         
          :
      ( <div className={classes.containersubmenu}>              
          <NavigateBeforeIcon className={classes.backbutton} fontSize="large" onClick={() => that.handleBack()} />      
        <div className={classes.titlesubmenu} color="textPrimary">
        Individuelles Angebot
        </div>
        </div>);
        return header;
  }

  createOverview() {
    const { classes } = this.props;
    var that = this;
    var overview = (this.individualInfo.map(function(item, keyO){            
              var buttontext = "Anfrage stellen";
              var text = (item.text.map(function(item, key){  
                return (<div key={key}  className={classes.infobullets}  dangerouslySetInnerHTML={{__html: item}}></div>);
              }));
              return (
              <div className={classes.textimagecontainerLeft} key={keyO}>            
                <div className={classes.subheaderindviual}>{item.header}</div> 
                <img id='eventimage'
                  className={classes.imageHalfWidthUnTransparent}
                  src={item.image}
                  alt={item.header}
                />            
                <div className={classes.infolabelright}>                  
                  {text}
                </div>                      
                <div className={classes.sectionlabel}>
                  <Button className={classes.buttonContentMoreFlex} onClick={() => that.handleClick(keyO)}>
                    {buttontext}
                  </Button>              
                </div>
            </div>);                    

      }));
      return overview;
  }

  createContact() {
    const { classes } = this.props;
    var that = this;
    var errortext = this.state.errormessage;
    var infotext = this.state.infomessage;    
    var loggedin = (FirebaseAuth.currentUser !== null);
    var text = (this.individualInfo[this.state.type].text.map(function(item, key){  
      return (<div key={key} dangerouslySetInnerHTML={{__html: item}}></div>);
    }));
    var contact = (loggedin) ? 
                    (<div className={classes.containercontent}>
                      <div className={classes.submenuheader}>{this.individualInfo[this.state.type].header}</div>
                      <div className={classes.textimagecontainer}>
                        <img id='workoutimage'
                          className={classes.imageFullWidthSemiTransparent}
                          src={this.individualInfo[this.state.type].image}
                          alt={this.individualInfo[this.state.type].header}
                        />
                      </div>
                      <div className={classes.overlaytoplefttextsmall}>{text}</div>                
                      <div className={classes.label}>Nachricht</div>
                      <OutlinedInput id="Name" multiline className={classes.textboxMulti} ref={this.contactMessage} onChange={(e) => that.textchange('message', e)}/>    
                      <div className={classes.buttonFullWidth} onClick={() => that.send(1)}>
                        Nachricht an NoLimitZ senden
                      </div>
                      <div className={classes.infolabel}>{infotext}</div>
                      <div className={classes.errorlabel}>{errortext}</div>
                  </div>)
                  :
                  (<div className={classes.containercontent}>
                    <div className={classes.submenuheader}>{this.individualInfo[this.state.type].header}</div>
                    <div className={classes.textimagecontainer}>
                        <img id='workoutimage'
                          className={classes.imageFullWidthUnTransparent}
                          src={this.individualInfo[this.state.type].image}
                          alt={this.individualInfo[this.state.type].header}
                        />
                    </div>
                    <div className={classes.overlaytoplefttextsmall}>{text}</div>
                    <div className={classes.label}>Email</div>
                    <OutlinedInput id="Surname" className={classes.textbox} ref={this.contactEmail} onChange={(e) => that.textchange('email', e)}/>
                    <div className={classes.label}>Nachricht</div>
                    <OutlinedInput id="Name" multiline className={classes.textboxMulti} ref={this.contactMessage} onChange={(e) => that.textchange('message', e)}/>    
                    <div className={classes.buttonFullWidth} onClick={() => that.send()}>
                      Nachricht an NoLimitZ senden
                    </div>
                    <div className={classes.infolabel}>{infotext}</div>
                    <div className={classes.errorlabel}>{errortext}</div>
                </div>);
    return contact;
  }
}

export default withStyles(useStyles)(Individual);
