import React from "react";
import { withStyles } from '@material-ui/core/styles';
import defaultStyles from '../base';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import NewsItems from './newsitems';
import NewsPartner from './newspartner';
import NewsContactInfo from './newscontactinfo'
import { FirebaseAuth } from "../../lib/authentication";
import { EventData } from '../../controller/data/eventdata'
import { WorkoutData } from '../../controller/data/workoutdata'
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import dateformat from 'date-format';

const additionalStyles = {
    listtext: {
        width: 'calc(100% - 120px)'
    }
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class NewsLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workouts: [],
            events: [],
            workoutsloading: true,
            eventsloading: true,
            myinfo: 0
        };
    }

    componentDidMount() {
        this.loadWorkouts();
        this.loadEvents();
    }

    componentWillUnmount() {
    }

    loadWorkouts() {
        var regs = [];
        var that = this;
        var workoutdata = new WorkoutData();
        workoutdata.loadWorkouts((workouts) => {
            //FirebaseStorage.loadAll("workouts", (workouts) => {
            var workout = workouts.val();
            if (FirebaseAuth.currentUser !== null) {
                if (workout.times !== undefined) {
                    for (var j = 0; j < workout.times.length; j++) {
                        if (workout.times[j] && workout.times[j].attendees) {
                            var attKeys = Object.keys(workout.times[j].attendees);
                            var checkinKeys = (workout.times[j].checkin) ? Object.keys(workout.times[j].checkin) : [];
                            attKeys.forEach((attKey) => {
                                if (new Date(workout.times[j].start).getTime() > (new Date().getTime() - 7200000) &&
                                    workout.times[j].attendees[attKey].key.includes(FirebaseAuth.currentUser.uid)) {
                                        var checkedin = false;
                                        for (var c = 0; c < checkinKeys.length; c++) {
                                            if (workout.times[j].checkin[checkinKeys[c]].key === FirebaseAuth.currentUser.uid && 
                                                (workout.times[j].checkin[checkinKeys[c]].end === undefined || workout.times[j].checkin[checkinKeys[c]].end === null))
                                                {
                                                    checkedin = true;
                                                }
                                        }                                
                                        regs.push({
                                        event: workout.title,
                                        start: dateformat.asString('dd.MM.yy', new Date(workout.times[j].start)) + ' ' + dateformat.asString('hh:mm', new Date(workout.times[j].start)),
                                        end: dateformat.asString('dd.MM.yy', new Date(workout.times[j].end)) + ' ' + dateformat.asString('hh:mm', new Date(workout.times[j].end)),
                                        index: j,
                                        workout: workout,
                                        key: workouts.key,
                                        checkedin: checkedin
                                    })
                                }
                            });
                        }
                    }
                }
            }
            that.setState(state => ({
                workouts: regs,
                workoutsloading: false
            }));
        });
    }

    checkin(key, index) {
        var wk = this.state.workouts;
        for (var i = 0; i < wk.length; i++) {
            if (wk[i].key === key && wk[i].index === index) {
                wk[i].checkedin = true;
            }
        }
        var workoutdata = new WorkoutData();
        workoutdata.checkInUser(key, index, FirebaseAuth.currentUser.uid);
        this.setState(state => ({
            workouts: wk
        }));
    }

    checkout(key, index) {
        var wk = this.state.workouts;
        for (var i = 0; i < wk.length; i++) {
            if (wk[i].key === key && wk[i].index === index) {
                wk[i].checkedin = false;
            }
        }
        var workoutdata = new WorkoutData();
        workoutdata.checkOutUser(key, index, FirebaseAuth.currentUser.uid);
        this.setState(state => ({
            workouts: wk
        }));
    }

    loadEvents() {
        var regs = [];
        var that = this;
        var eventdata = new EventData();
        eventdata.loadEvents((events) => {
            var event = events.val();
            if (FirebaseAuth.currentUser !== null) {
                if (event.times) {
                    for (var j = 0; j < event.times.length; j++) {
                        if (event.times[j] && event.times[j].attendees) {
                            var attKeys = Object.keys(event.times[j].attendees);
                            attKeys.forEach((attKey) => {
                                if (new Date(event.times[j].start).getTime() > new Date().getTime() &&
                                    event.times[j].attendees[attKey].key.includes(FirebaseAuth.currentUser.uid)) {
                                    regs.push({
                                        event: event.title,
                                        start: dateformat.asString('dd.MM.yy', new Date(event.times[j].start)) + ' ' + dateformat.asString('hh:mm', new Date(event.times[j].start)),
                                        end: dateformat.asString('dd.MM.yy', new Date(event.times[j].end)) + ' ' + dateformat.asString('hh:mm', new Date(event.times[j].end)),
                                        index: j,
                                        eventinfo: event,
                                        key: events.key
                                    });
                                }
                            });
                        }
                    }
                }
            }
            that.setState(state => ({
                events: regs,
                eventsloading: false
            }));
        });
    }

    previous() {
        var current = this.state.myinfo;
        if (current === 0) {
            current = 1;
        }
        else {
            current--;
        }
        this.setState({
            myinfo: current
        });
    }

    next() {
        var current = this.state.myinfo;
        if (current === 1) {
            current = 0;
        }
        else {
            current++;
        }
        this.setState({
            myinfo: current
        });
    }

    render() {
        const { classes } = this.props;
        var that = this;
        var info = this.createProfileView();
        return (
            <div>
                <NewsItems />
                <div className={classes.mainspacer} />
                {info}
                <div className={classes.mainspacer} />
                <NewsContactInfo profileid={this.props.profileid} selectProfile={(profileid) => { that.props.selectProfile(profileid) }} />
                <div className={classes.mainspacer} />
                <NewsPartner />
                <div className={classes.mainspacer} />
                <div className={classes.mainspacer} />
            </div>
        );
    }

    createProfileView() {
        const { classes } = this.props;
        var that = this;
        var headertext = (this.state.myinfo === 0)
            ? "Meine Workouts"
            : "Meine Events";
        var header = (<div className={[classes.header1].join(' ')}>
            <ArrowBackIcon className={[classes.navigationbutton1, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.previous()} />
            <div className={[classes.switchheader1].join(' ')}>{headertext}</div>
            <ArrowForwardIcon className={[classes.navigationbutton1, classes.navigationButtonRight].join(' ')} fontSize="large" onClick={() => that.next()} />
        </div>);
        var divider = (<div className={classes.dividerFullBlue} />);
        if (this.state.myinfo === 0) {
            if (this.state.workoutsloading === true) {
                return (
                    <div>
                        {header}
                        <div className={classes.labelStandard}>
                            Lade.....
                        </div>
                    </div>
                );
            }
            else {
                if (this.state.workouts.length === 0) {
                    return (
                        <div>
                            {header}
                            <div className={[classes.floatrelative, classes.fullWidth, classes.labelStandardBlack].join(' ')}>
                                Aktuell bist du zu keinen Workouts angemeldet!
                            </div>
                            {divider}
                        </div>
                    );
                }
                else {
                    var reginfo = (this.state.workouts.map(function (item, key) {
                        var btn = (item.checkedin) 
                                    ? <div className={[classes.relative, classes.buttonStandard, classes.inlineblockitem, classes.rightalign, classes.margin20Both].join(' ')} fontSize="small" onClick={() => that.checkout(item.key, item.index)}>Check Out</div>
                                    : <div className={[classes.relative, classes.buttonStandard, classes.inlineblockitem, classes.rightalign, classes.margin20Both].join(' ')} fontSize="small" onClick={() => that.checkin(item.key, item.index)}>Check In</div>;
                        return (
                            <div className={[classes.listentry].join(' ')} key={key}>
                                <div className={[classes.floatrelative, classes.listtext].join(' ')}>
                                {item.start} - {item.end}&nbsp;//&nbsp;{item.event}                                                                                                
                                </div>
                                {btn}
                                <EditIcon className={[classes.editbutton].join(' ')} fontSize="small" onClick={() => that.props.editWorkout(item.key)} />
                            </div>)
                    }));
                    return (
                        <div>
                            {header}
                            <div className={[classes.floatrelative, classes.fullWidth, classes.labelStandardBlack].join(' ')}>
                                {reginfo}
                            </div>
                            {divider}
                        </div>
                    );
                }
            }
        }
        else if (this.state.myinfo === 1) {

            if (this.state.workoutsloading === true) {
                return (
                    <div>
                        {header}
                        <div className={classes.labelStandard}>
                            Lade.....
                        </div>
                    </div>
                );
            }
            else {
                if (this.state.events.length === 0) {
                    return (
                        <div>
                            {header}
                            <div className={[classes.floatrelative, classes.fullWidth, classes.labelStandardBlack].join(' ')}>
                                Aktuell bist du zu keinen Events angemeldet!
                            </div>
                            {divider}
                        </div>
                    );
                }
                else {
                    var reginfo2 = (this.state.events.map(function (item, key) {
                        return (
                            <div className={[classes.listentry].join(' ')} key={key}>
                                {item.start} - {item.end}&nbsp;//&nbsp;{item.event}
                                <EditIcon className={[classes.editbutton].join(' ')} fontSize="small" onClick={() => that.props.editEvent(item.key)} />
                            </div>)
                    }));
                    return (
                        <div>
                            {header}
                            <div className={[classes.floatrelative, classes.fullWidth, classes.labelStandardBlack].join(' ')}>
                                {reginfo2}
                            </div>
                            {divider}
                        </div>
                    );
                }
            }
        }
        return (<div />);
    }
}

export default withStyles(useStyles)(NewsLogin);