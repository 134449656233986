import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Divider from '@material-ui/core/Divider';
import NewsNoLogin from '../news/newsnologin'
import NewsLogin from '../news/newslogin'
import NewsContactInfo from '../news/newscontactinfo'
import Login from '../profile/login'
import Register from '../profile/register'
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import { FirebaseAuth } from "../../lib/authentication";

const additionalStyles = {

};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class News extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      profileid: -1,
      registrationmode: false,
      loginmode: false
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  back() {
    this.setState({
      profileid: -1,
      registrationmode: false,
      loginmode: false
    });
  }

  selectProfile(profileid) {
    this.setState({
      profileid: profileid
    });
  }

  showLogin() {
    this.setState({
      registrationmode: false,
      loginmode: true
    });
  }

  showRegistration() {
    this.setState({
      registrationmode: true,
      loginmode: false
    });
  }

  reload() {
    window.location.reload(true);
  }

  render() {
    const { classes } = this.props;
    var that = this;

    var header = (
      <div className={classes.containertitle}>
        <Typography className={classes.title} color="textSecondary">
          NoLimitZ Fitness
        </Typography>
        <Divider variant="middle" classes={{ root: classes.divider }} />
      </div>);
    var backarea = (<div className={[classes.backmenu].join(' ')}>
      <NavigateBeforeIcon className={[classes.backbuttonHome, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.back()} />
    </div>);
    var bottompagespace = <div className={classes.bottompagespace} />;

    if (this.state.profileid > -1) {

      return (
        <Card className={classes.root}>
          {backarea}
          {header}
          <div className={classes.mainspacer} />
          <NewsContactInfo profileid={this.state.profileid} selectProfile={(profileid) => { this.selectProfile(profileid) }} />
          {bottompagespace}
        </Card>
      )
    }


    if (FirebaseAuth.currentUser !== null) {
      if (FirebaseAuth.currentUser.emailVerified === false) {
        return (<Card className={classes.root}>
          {header}
          <div className={classes.label}>Bitte schließen Sie die Registrierung ab für den Benutzer durch Bestätigung mit dem Link in der Email.</div>
          <Button className={classes.buttonContentMore} onClick={() => that.reload()}>
            Neu laden
          </Button>
          {bottompagespace}
        </Card>);
      }
      else {
        return (
          <Card className={classes.root}>
            {header}
            <div className={classes.mainspacer} />
            <NewsLogin
              profileid={this.state.profileid}
              selectProfile={(profileid) => { that.selectProfile(profileid) }}
              editEvent={(eventkey) => { that.props.editEvent(eventkey) }}
              editWorkout={(workoutkey) => { that.props.editWorkout(workoutkey) }}
            />
            {bottompagespace}
          </Card>
        );
      }
    }
    else {
      if (this.state.loginmode) {
        return (
          <Card className={classes.root}>
            {backarea}
            {header}
            <div className={classes.mainspacer} />
            <Login
              loginSuccessful={() => that.back()}
              showRegistration={() => that.showRegistration()} />
            {bottompagespace}
          </Card>
        )
      }
      else if (this.state.registrationmode) {
        return (
          <Card className={classes.root}>
            {backarea}
            {header}
            <div className={classes.mainspacer} />
            <Register 
              loginSuccessful={() => that.back()}
              showLogin={() => { that.showLogin() }} />
            {bottompagespace}
          </Card>
        )
      }
      else {
        return (
          <Card className={classes.root}>
            {header}
            <div className={classes.mainspacer} />
            <NewsNoLogin profileid={this.state.profileid}
              selectProfile={(profileid) => { that.selectProfile(profileid) }}
              showLogin={() => { that.showLogin() }}
            />
            {bottompagespace}
          </Card>
        )
      }
    }
  }
}

export default withStyles(useStyles)(News);
