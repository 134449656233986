import React from "react";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Vimeo from '@u-wave/react-vimeo';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import $ from "jquery";

const additionalStyles = {
    preparecontainer: {
        width: '100%',
        padding: '0px',
        margin: '0px'
    },
    imageMaxHeight: {
        height: '60px'
    },
    exerciseBlock: {
        width: '30%',
        marginLeft: '2%',
    }
}
const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class WorkoutExecuteSingle extends React.Component {

    constructor(props) {
        super(props);        
        this.state = {
            workoutinfo: this.props.workoutinfo,
            start: null,
            end: null,
            errormessage: "",
            infomessage: ""
        };

    }

    componentDidMount() {
    }

    close(start) {
        
        if (start) {
            if (this.state.start === "") {
                this.showError("Start nicht gesetzt");
                return;
            }  
            if (this.state.end === "") {
                this.showError("Ende nicht gesetzt");
                return;
            }  
            if (this.state.start >= this.state.end) {
                this.showError("Fehler: Start > Ende");
                return;
            }
            var workout = this.state.workoutinfo;
            workout.drills[0].exercises[0].start = this.state.start.getTime();
            workout.drills[0].exercises[0].end = this.state.end.getTime();
            workout.publish = true;
            this.props.close(workout, true);
        }
        else{
            this.props.cancel();
        }
    }

    changeSlider(event, newValue) {
        var workoutinfo = this.state.workoutinfo;
        workoutinfo.drills[0].executionlevel = newValue;
        this.setState({
            workoutinfo: workoutinfo
        });
    }

    onVideoError(errorObj, videoid, i) {
        $('#Video_' + i).hide();        
        $('#VideoError_' + i).show();        
    }

    onVideoLoaded(videoid, i) {
        $('#Video_' + i).show();        
        $('#VideoError_' + i).hide();    
    }

    datechange(prop, event) {
        var val = new Date(event.target.value);
        if (prop === 'start') {
            this.setState({
                start: val
            });
        }
        if (prop === 'end') {
            this.setState({
                end: val
            });
        }
    }

    showError(text) {
        var that = this;
        this.setState({
            errormessage: text
        });
        setTimeout(() => {
            that.showError("");
        }, 3000);
    }

    render() {
        const { classes } = this.props;
        var that = this;
        var introtext = (<div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Bitte stelle ein, welche Stufe des Workouts du absolviert hast!
            <br />
        </div>)        
        var img = "";
        if (this.state.workoutinfo.drills[0].exercises[0].info != null &&
            this.state.workoutinfo.drills[0].exercises[0].info.images != null &&
            this.state.workoutinfo.drills[0].exercises[0].info.images.length > 0) {
            img = this.state.workoutinfo.drills[0].exercises[0].info.images[0];                    
        }
        var ext = " ";
        if (this.state.workoutinfo.drills[0].info.unit === 'Anzahl') {
            ext = " Stück";
        }
        if (this.state.workoutinfo.drills[0].info.unit === 'Sekunden') {
            ext = " Sekunden";
        }
        if (this.state.workoutinfo.drills[0].info.unit === 'Distanz') {
            ext = " Meter";
        }
        var leveltext = this.state.workoutinfo.drills[0].info.levels[this.state.workoutinfo.drills[0].executionlevel] + ext;
        var imgvideo = (<img className={[classes.floatrelative, classes.exerciseBlock, classes.imageMaxHeight].join(' ')} alt="Übungsbild" src={img}></img>);
        if (this.state.workoutinfo.drills[0].exercises[0].info.video !== "") {
            var divid = "Video";
            var diverrorid ="VideoError";
            var videopreviewerror = (<div id={diverrorid} className={[classes.errorlabel, classes.hidden].join(' ')}>Fehler beim Laden des Videos</div>);
            imgvideo = (
                <div>
                    {videopreviewerror}
                    <div className={[classes.floatrelative, classes.exerciseBlock, classes.imageMaxHeight].join(' ')}>
                        <Vimeo 
                            id={divid}
                            video={this.state.workoutinfo.drills[0].exercises[0].info.video}                         
                            autoplay
                            background
                            loop
                            responsive 
                            controls={false}
                            muted={true}
                            showPortrait={false}
                            showTitle={false}
                            showByline={false}
                            onError={(errorObj) => { this.onVideoError(errorObj, this.state.workoutinfo.drills[0].exercises[0].info.video, 0) } }
                            onLoaded={() => { this.onVideoLoaded(this.state.workoutinfo.drills[0].exercises[0].info.video, 0) } }/>
                    </div>
                </div>);
        }            
        var workoutinput = (
            <div className={[classes.floatrelative, classes.preparecontainer].join(' ')}>
                <div className={[classes.floatrelative, classes.exerciseBlock].join(' ')}>{this.state.workoutinfo.drills[0].exercises[0].info.name}</div>
                {imgvideo}
                <div className={[classes.floatrelative, classes.exerciseBlock].join(' ')}>
                    {leveltext}
                    <Slider
                        className={[classes.floatrelative, classes.slider].join(' ')}
                        value={this.state.workoutinfo.drills[0].executionlevel}
                        onChange={(event, newValue) => that.changeSlider(event, newValue)}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={1}
                        max={7}
                    />
                </div>
                <div className={classes.mainspacer} />
            </div>
        );     
        var startend = ( <div>                
                <TextField
                    id="datetime-start"
                    label="Start"
                    type="datetime-local"
                    onChange={(e) => that.datechange('start', e)}
                    defaultValue={this.state.start}
                    className={classes.datebox}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    id="datetime-end"
                    label="Ende"
                    type="datetime-local"
                    onChange={(e) => that.datechange('end', e)}
                    defaultValue={this.state.end}
                    className={classes.datebox}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />                
            </div>);        
        var lines = (this.state.workoutinfo.drills[0].exercises[0].info.description)
            ? this.state.workoutinfo.drills[0].exercises[0].info.description.split("\n")
            : [];
        var exdesc = lines.map((d, idx) => {
            if (d.trim().endsWith(":")) {
                return (<div key={idx}>
                    <div className={classes.mainspacer} />
                    <div className={[classes.floatrelative, classes.infotextboldunderline, classes.fullwidth, classes.textleft, classes.marginLeft20].join(' ')}>{d}</div>
                </div>)
            }
            else {
                return (<div key={idx} className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textleft, classes.marginLeft60].join(' ')}>{d}</div>)
            }
        });
        return (

            <div>
                {introtext}  
                <div className={classes.autoheight}>
                    <div className={classes.errorlabel}>{this.state.errormessage}</div>
                    <div className={classes.infolabel}>{this.state.infomessage}</div>
                    <div className={classes.mainspacer} />                    
                </div>                            
                <div className={classes.mainspacer} />
                {workoutinput}
                <div className={classes.mainspacer} />
                {exdesc}
                <div className={classes.mainspacer} />
                {startend}
                <div className={classes.mainspacer} />
                <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                    <Button style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft].join(' ')} onClick={() => this.close(true)}>
                        Workout eintragen
                    </Button>
                    <Button style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonright].join(' ')} onClick={() => this.close(false)}>
                        Abbrechen
                    </Button>
                </div>  
            </div>
        );
    }
}

export default withStyles(useStyles)(WorkoutExecuteSingle);