import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from '@material-ui/core/styles';
import defaultStyles from './base'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Background from '../img/logo512.png';
import RowingIcon from '@material-ui/icons/Rowing';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EventCalendar from "./events/eventcalendar"
import News from "./news/news"
import EditProfile from "./profile/editprofile"
import Workout from "./workout/workout"
import AdminOverview from "./admin/AdminOverview"
import Individual from "./individual/individual"
import Shop from "./shop/shop"
import Courses from "./courses/courses"
import Agb from "./agb/agb"
import Revocation from "./revocation/revocation"
import Impressum from "./impressum/impressum"
import DataProtection from "./dataprotection/dataprotection"
import { PwaInstaller } from "../lib/installprompt";
import { RequestMethods } from "../lib/request";
import { FirebaseAuth } from "../lib/authentication";
// import { FirebaseMessaging } from "../lib/fcm";
import { DBUpdate } from '../controller/data/update'
import $ from "jquery";
import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";


const additionalStyles = {
    intro: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        left: '0px',
        top: '0px',
        margin: '0px',
        padding: '0px',
        overflowY: 'hidden',
        overflowX: 'hidden',
        zIndex: 10000,
        background: '#00D2FF'
    },
    introimage: {
        width: '256px',
        height: '256px',
        backgroundImage: `url(${Background})`,
        left: 'calc(50% - 128px)',
        top: 'calc(50% - 128px)',
        backgroundSize: 'cover',
        position: 'absolute',
        zIndex: 100000
    },
    introtext: {
        width: '256px',
        height: '256px',
        left: 'calc(50% - 128px)',
        top: 'calc(50% + 132px)',
        backgroundSize: 'cover',
        position: 'absolute',
        color: '#000000',
        zIndex: 100000
    },
    contentcontainer: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        left: '0px',
        top: '0px',
        margin: '0px',
        padding: '0px',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    contentcontainerscrollable: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        left: '0px',
        top: '0px',
        margin: '0px',
        padding: '0px',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    slideContainer: {
        position: 'relative',
        float: 'left',
        width: '100%',
        left: '0px',
        top: '0px',
        padding: '0px',
        margin: '0px',
        height: 'calc(100% - 84px - 20px - 35px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        WebkitOverflowScrolling: 'auto'
    },
    root: {
        padding: '0px',
        height: '100%',
        margin: '0px'
    },
    panel: {
        padding: '0px'
    },
    appBar: {
        position: 'relative',
        float: 'left',
        width: '100%',
        left: '0px',
        top: '0px',
        height: '84px',
        margin: '0px',
        padding: '0px'
    },
    footer: {
        position: 'relative',
        float: 'left',
        width: '100%',
        left: '0px',
        top: '0px',
        height: '20px',
        textAlign: 'center',
        color: '#00D2FF',
        margin: '0px'
    },
    footercontainer: {
        position: 'relative',
        width: '300px',
        margin: 'auto'
    },
    footerelement: {
        position: 'relative',
        width: '100px',
        float: 'left',
        cursor: 'pointer',
        background: 'transparent',
        fontFamily: 'Roboto',
        fontSize: '10pt'
    },
    tab: {
        textTransform: 'none',
        fontFamily: 'Anton',
        fontSize: '12px',
        fontWeight: 'normal',
        minWidth: '40px'
    },
    menuicon: {
        width: '20px',
        height: '20px'
    },
    topmenu: {
        position: 'relative',
        top: '0px',
        margin: '0px',
        padding: '0px',
        width: '100%',
        height: '35px',
        backgroundColor: 'transparent'
    },
    extramenu: {
        position: 'relative',
        top: '0px',
        margin: '0px',
        padding: '0px',
        width: '100%',
        height: '35px',
        backgroundColor: '#454545'
    },
    backbuttonHome: {
        borderRadius: '8px',
        color: '#FFFFFF',
        border: 0,
        position: 'relative',
        float: 'left',
        paddingLeft: '2px',
        paddingTop: '2px',
        paddingBottom: '2px',
        height: '30px',
        cursor: 'pointer'
    },
    buttonInstall: {
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontWeight: 'normal',
        background: '#00D2FF',
        borderRadius: '8pt',
        border: 0,
        color: 'black',
        height: '24px',
        marginTop: '2px',
        marginLeft: '30px',
        float: 'left',
        display: 'none'
    },
    buttonLogoff: {
        background: '#00D2FF',
        color: 'black',
        height: '24px',
        width: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'right',
        display: 'none'
    },
    buttonLogoffVisible: {
        background: '#00D2FF',
        color: 'black',
        height: '24px',
        width: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'right',
        display: 'block',
        "&:hover": {
            backgroundColor: '#00D2FF'
        }
    },
    buttonNotification: {
        background: '#00D2FF',
        color: 'black',
        height: '24px',
        width: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'left',
        display: 'none',
        marginLeft: '10px',
        "&:hover": {
            backgroundColor: '#00D2FF'
        }
    },
    labelSmallGray: {
        background: 'transparent',
        color: '#CCCCCC',
        height: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'right',
        display: 'block',
        fontFamily: 'Roboto',
        fontSize: '9px'
    },
    labelSmallGrayLeft: {
        background: 'transparent',
        color: '#CCCCCC',
        keft: '3px',
        height: '24px',
        marginRight: '10px',
        marginTop: '2px',
        float: 'left',
        display: 'block',
        fontFamily: 'Roboto',
        fontSize: '9px'
    }
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class Home extends React.Component {

    constructor(props) {
        super(props);
        var that = this;
        FirebaseAuth.Init();
        FirebaseAuth.OnInitCompleted = (info) => {
            $("#intro").remove();
            var login = that.checkLoginState();
            var admin = that.checkisAdmin();
            that.setState({
                loading: false,
                login: login,
                admin: admin
            });
        };
        FirebaseAuth.OnLogoffCompleted = (info) => {
            that.setState({
                login: false,
                admin: false
            });
        };
        this.state = {
            loading: true,
            selectedevent: '',
            selectedworkout: '',
        };
        var update = new DBUpdate();
        //update.updateWorkoutExecutions();
        //update.updateNews();
    }

    handleChange = (event, newValue) => {
        this.setState({
            menuIndex: newValue
        });
    };

    handleChangeIndex = (index) => {
        this.setState({
            menuIndex: index
        });
        this.props.OnChangeIndex(index);
    };

    handleExtraMenu(idx) {
        this.setState({
            extramenu: idx
        });
    }

    handleBack = () => {
        this.setState({
            extramenu: -1
        });
    }

    additionalProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    checkLoginState() {
        return (FirebaseAuth.currentUser !== null && FirebaseAuth.currentUser.emailVerified === true);
    }

    checkisAdmin() {
        return (FirebaseAuth.currentUser !== null && (FirebaseAuth.currentUser.email.toLowerCase() === "devfalti@gmail.com" || FirebaseAuth.currentUser.email.toLowerCase() === "tobi-m.949313@web.de"));
    }

    showNotificationInfo() {
        $("#notificationmessage").toggle();
    }


    componentDidMount() {
        var initialindex = 0;
        var extramenu = -1;
        if (RequestMethods.getQueryVariable("tab") !== "") {
            initialindex = parseInt(RequestMethods.getQueryVariable("tab"));
            if (isNaN(initialindex)) {
                initialindex = 0;
            }
        }
        if (RequestMethods.getQueryVariable("info") === "impressum") {
            extramenu = 1;
        }
        if (RequestMethods.getQueryVariable("info") === "datenschutz") {
            extramenu = 2;
        }
        if (RequestMethods.getQueryVariable("info") === "agb") {
            extramenu = 3;
        }
        if (RequestMethods.getQueryVariable("info") === "widerruf") {
            extramenu = 4;
        }
        this.setState({
            release: '',
            menuIndex: initialindex,
            extramenu: extramenu,
            login: false,
            admin: false,
            registered: false
        });
    }

    componentWillUnmount() {

    }

    checkInstallButtonVisible() {
        if (PwaInstaller.deferredPrompt != null && !PwaInstaller.IsInstalled) {
            $("#InstallButton").show();
        }
        else {
            $("#InstallButton").hide();
        }
    }

    handleLogout() {
        FirebaseAuth.logoff();
    }

    showWorkouts(workoutkey) {
        this.setState({
            menuIndex: 1,
            selectedworkout: workoutkey
        });
    }

    showEvents(eventkey) {
        this.setState({
            menuIndex: 2,
            selectedevent: eventkey
        });
    }

    render() {
        const { classes } = this.props;
        PwaInstaller.OnPromptChanged = this.checkInstallButtonVisible;
        var that = this;
        if (this.state.loading) {
            return (<div id="contentcontainer" className={classes.contentcontainer}>
                <div id="intro" className={classes.intro}>
                    <div id="introimage" className={classes.introimage}></div>
                    <div id="introtext" className={classes.introtext}>Lade...</div>
                </div>
            </div>);
        }
        var userinfo = (this.checkLoginState()) ?
            (<div id="CurrentUserInfo" className={classes.labelSmallGray}>{FirebaseAuth.currentUser.email}</div>) :
            (<div id="CurrentUserInfo" className={classes.labelSmallGray}></div>);
        var editprofile = (this.checkLoginState()) ?
            (<IconButton id="EditProfileButton" className={classes.buttonLogoffVisible} onClick={() => that.handleExtraMenu(5)} size="small" >
                <AccountCircleIcon fontSize="small" />
            </IconButton>) :
            (<IconButton id="EditProfileButton" className={classes.buttonLogoff} onClick={() => that.handleExtraMenu(5)} size="small" >
                <AccountCircleIcon fontSize="small" />
            </IconButton>);
        var logoff = (this.checkLoginState()) ?
            (<IconButton id="LogoffButton" className={classes.buttonLogoffVisible} onClick={() => that.handleLogout()} size="small" >
                <ExitToAppIcon fontSize="small" />
            </IconButton>) :
            (<IconButton id="LogoffButton" className={classes.buttonLogoff} onClick={() => that.handleLogout()} size="small" >
                <ExitToAppIcon fontSize="small" />
            </IconButton>);
        var menuIndex = (this.state && this.state.menuIndex) ? this.state.menuIndex : 0;
        var version = <div id="release" className={classes.labelSmallGrayLeft}></div>
        if (this.state.extramenu > -1) {
            var content = (this.state.extramenu === 1) ? <Impressum /> : (this.state.extramenu === 2) ? <DataProtection /> : (this.state.extramenu === 3) ? <Agb /> : (this.state.extramenu === 4) ? <Revocation /> : <EditProfile />;
            return (<div className={[classes.contentcontainerscrollable].join(' ')}>
                <div id="intro" className={classes.intro}>
                    <div id="introimage" className={classes.introimage}></div>
                    <div id="introtext" className={classes.introtext}>Lade...</div>
                </div>
                <div className={classes.extramenu}>
                    <NavigateBeforeIcon className={classes.backbuttonHome} fontSize="large" onClick={() => that.handleBack()} />
                </div>
                {content}
            </div>)
        }
        else {
            var profile = <Tab className={classes.tab} label="News" icon={<img alt="News" className={classes.menuicon} src='./assets/newspaper-solid.svg' />}
                {...this.additionalProps(0)} />
            var profilepanel = (<TabPanel id="newspanel" className={classes.panel} value={menuIndex} index={0} dir={useStyles.direction}>
                <News
                    editEvent={(eventkey) => { that.showEvents(eventkey) }}
                    editWorkout={(workoutkey) => { that.showWorkouts(workoutkey) }} />
            </TabPanel>);
            var workout = <Tab className={classes.tab} label="Kurse" icon={<img alt="Workout" className={classes.menuicon} src='./assets/kettlebell-solid.png' />}
                {...this.additionalProps(1)} />
            var workoutpanel = (<TabPanel className={classes.panel} value={menuIndex} index={1} dir={useStyles.direction}>
                <Workout selectedworkout={this.state.selectedworkout} />
            </TabPanel>)
            var events = <Tab className={classes.tab} label="Events" icon={<img alt="Events" className={classes.menuicon} src='./assets/calendar-alt-solid.svg' />}
                {...this.additionalProps(2)} />
            var eventspanel = (<TabPanel className={classes.panel} value={menuIndex} index={2} dir={useStyles.direction}>
                <EventCalendar selectedevent={this.state.selectedevent} />
            </TabPanel>)
            var course = null;
            var coursepanel = <TabPanel className={classes.panel} value={menuIndex} index={3} dir={useStyles.direction} />;
            var admin = null;
            var adminpanel = <TabPanel className={classes.panel} value={menuIndex} index={4} dir={useStyles.direction} />;
            var shop = null;
            var shopanel = <TabPanel className={classes.panel} value={menuIndex} index={5} dir={useStyles.direction} />;

            var group = RequestMethods.getQueryVariable("groupkey");
            course = <Tab className={classes.tab} label="Online Training" icon={<FitnessCenterIcon alt="Online Training" className={classes.menuicon} />}
                {...this.additionalProps(3)} />;
            coursepanel = (<TabPanel className={classes.panel} value={menuIndex} index={3} dir={useStyles.direction}>
                <Courses group={group} />
            </TabPanel>);

            if (this.checkisAdmin()) {
                admin = <Tab className={classes.tab} label="Admin" icon={<SupervisorAccountIcon alt="Admin" className={classes.menuicon} />}
                    {...this.additionalProps(4)} />;
                adminpanel = (<TabPanel className={classes.panel} value={menuIndex} index={4} dir={useStyles.direction}>
                    <AdminOverview />
                </TabPanel>);
                shop = <Tab className={classes.tab} label="Shop" icon={<ShoppingCartIcon alt="Shop" className={classes.menuicon} />}
                    {...this.additionalProps(5)} />;
                shopanel = (<TabPanel className={classes.panel} value={menuIndex} index={5} dir={useStyles.direction}>
                    <Shop />
                </TabPanel>);
            }
            return (
                <div id="contentcontainer" className={classes.contentcontainer}>
                    <div id="intro" className={classes.intro}>
                        <div id="introimage" className={classes.introimage}></div>
                        <div id="introtext" className={classes.introtext}>Lade...</div>
                    </div>
                    <div id="topmenu" className={classes.topmenu}>
                        {version}
                        {editprofile}
                        {logoff}
                        {userinfo}
                    </div>
                    <SwipeableViews className={classes.slideContainer}
                        id="slideContainer"
                        axis={useStyles.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={menuIndex}
                        onChangeIndex={this.handleChangeIndex}>
                        {profilepanel}
                        {workoutpanel}
                        {eventspanel}
                        {coursepanel}
                        {adminpanel}
                        {shopanel}
                    </SwipeableViews>
                    <CookieConsent
                        location="top"
                        buttonText="Akzeptieren"
                        cookieName="NoLimitZCookieConsent"
                        style={{ background: "#000000", fontSize: "20px", fontFamily: "Lemonada" }}
                        buttonStyle={{ background: "#00D2FF", color: "#000000", fontSize: "20px" }}
                        expires={1}
                        debug={false}
                        overlay={true}
                    >
                        Wir verwenden Cookies um die Benutzerfreundlichkeit zu verbessern. Bitte akzeptieren Sie um die Seite besuchen zu können!{" "}
                    </CookieConsent>
                    <AppBar id="appbar" className={classes.appBar}>
                        <Tabs
                            value={menuIndex}
                            onChange={this.handleChange}
                            variant="fullWidth"
                            centered
                        >
                            {profile}
                            {workout}
                            {events}
                            {course}
                            {admin}
                            {shop}
                        </Tabs>
                    </AppBar>
                    <div id="footer" position="static" className={classes.footer}>
                        <div className={classes.footercontainer}>
                            <div className={classes.footerelement} onClick={() => { that.handleExtraMenu(1) }}>Impressum</div>
                            <div className={classes.footerelement} onClick={() => { that.handleExtraMenu(2) }}>Datenschutz</div>
                            <div className={classes.footerelement} onClick={() => { that.handleExtraMenu(3) }}>AGB</div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default withStyles(useStyles)(Home);