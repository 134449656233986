import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GroupIcon from '@material-ui/icons/Group';
import LoopIcon from '@material-ui/icons/Loop';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import defaultStyles from '../base';
import deepmerge from '../../lib/deepmerge'
import { FirebaseAuth } from "../../lib/authentication";
import { CourseData } from '../../controller/data/coursedata'
import { DemoCourseData } from '../../controller/data/demo/democoursedata'
import WorkoutPreview from './workoutpreview'
import WorkoutExecute from './workoutexecute'
import WorkoutMessageView from './workoutmessageview'
import WorkoutChallenge from './workoutchallenge'
import WorkoutListView from './workoutlistview'
import WorkoutGroupInfoView from './workoutgroupinfoview'
import { RequestMethods } from "../../lib/request";
import { BrowserMethods } from "../../lib/browser";

const additionalStyles = {
  groupbox: {
    maxWidth: '250px',
    minWidth: '100px',
    height: '150px',
    width: '45%'
  },
  fullcontainer: {
    float: 'left',
    border: '0px',
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
    marginRight: '10px'
  },
  boxHeight: {
    minHeight: '30px',
  },
  workoutbuttonwidth: {
    width: '150px',
    marginLeft: '20px',
    marginRight: '20px'
  },
  workoutcontainerleftsymbol: {
    position: 'relative',
    float: 'left',
    marginLeft: '0px',
    marginRight: '0px',
    width: '60px'
  },
  workoutcontainerleft: {
    position: 'relative',
    float: 'left',
    marginLeft: '0px',
    marginRight: '0px',
    width: 'calc(100% - 235px)'
  },
  workoutcontainerright: {
    position: 'relative',
    float: 'left',
    marginLeft: '0px',
    marginRight: '0px',
    width: '150px'
  },
  workouticon: {
    width: '50px',
    height: '50px',
    margin: '5px'
  },
  groupicon: {
    position: 'relative',
    display: 'inline-block',
    color: '#000000',
    border: 0,
    paddingLeft: '2px',
    paddingTop: '2px',
    paddingBottom: '2px',
    height: '35px',
    width: '35px',
    top: '8px'
  },
  widthGroupInfo: {
    width: 'calc(100% - 35px - 20px)'
  },
  widthTimeSpan: {
    width: 'calc(100% - 20px)'
  },
  widthExerciseName: {
    width: '65%',
    textAlign: 'right'
  },
  widthExerciseNo: {
    width: '30%',
    textAlign: 'left'
  },
  statslabel: {
    float: 'left',
    position: 'relative',
    fontFamily: 'Anton',
    color: '#FFFFFF',
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'left',
    left: '20px'
  },
};

const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class Courses extends React.Component {

  constructor(props) {
    super(props);
    BrowserMethods.OnBackClick = () => this.backButtonClick(this);
    this.txtChat = React.createRef();

    var submenu = 1;
    var currentGroupkey = '';
    if (RequestMethods.getQueryVariable("submenu") !== "") {
      submenu = parseInt(RequestMethods.getQueryVariable("submenu"));
      if (isNaN(submenu)) {
        submenu = 1;
      }
    }
    if (RequestMethods.getQueryVariable("submenu") !== "") {
      submenu = parseInt(RequestMethods.getQueryVariable("submenu"));
      if (isNaN(submenu)) {
        submenu = 1;
      }
    }
    if (RequestMethods.getQueryVariable("groupkey") !== "") {
      currentGroupkey = RequestMethods.getQueryVariable("groupkey");
    }
    var currentyear = new Date().getFullYear();
    var currentmonth = new Date().getMonth();

    this.state = {
      mygroups: [],
      excercises: [],
      currentGroupKey: currentGroupkey,
      currentGroup: '',
      currentInterval: null,
      currentMessages: [],
      currentMessageTimestamp: Date.now(),
      currentGroupUsers: [],
      currentGroupWorkouts: {},
      currentWorkoutPreview: '',
      currentWorkoutExecute: '',
      submenu: submenu,
      warmups: {},
      statsloaded: false,
      statsloading: false,
      statsstart: new Date(currentyear, currentmonth, 1),
      statsend: (currentmonth === 1) ? new Date(currentyear + 1, 1, 1) : new Date(currentyear, currentmonth + 1, 1),
      stats: {}
    };
    this.timerID = -1;
    this.chatMessage = "";

  }

  componentDidMount() {
    this.refreshState();
  }

  refreshState() {
    var that = this;
    if (FirebaseAuth.currentUser != null) {
      var coursedata = new CourseData();
      if (this.props.group && this.props.group !== '') {
        coursedata.loadGroupById(that.props.group, (g) => {
          that.selectGroup(that.props.group, g.name);
        })
      }
      else {
        coursedata.loadUserGroups(FirebaseAuth.currentUser.uid, (groups) => {
          that.setState({
            mygroups: groups
          });
          that.loadStats();
        });
        coursedata.loadWarmupWorkouts((w) => {
          that.setState({
            warmups: w
          });
        });
        coursedata.loadCooldownWorkouts((w) => {
          that.setState({
            cooldowns: w
          });
        });
      }
    }
  }

  loadStats() {
    var that = this;
    that.setState({
      statsloading: true
    });
    var coursedata = new CourseData();
    coursedata.loadUserStatistics(FirebaseAuth.currentUser.uid, (stats) => {
      that.setState({
        stats: stats,
        statsloading: false,
        statsloaded: true
      });
    });
  }

  selectGroup(groupkey, groupname) {
    var that = this;
    var coursedata = new CourseData();
    coursedata.loadGroupUsers(groupkey, (users) => {
      var userlist = {};
      users.forEach((u) => {
        userlist[u.key] = u.val();
      });
      coursedata.loadCurrentGroupWorkouts(groupkey, (gws) => {
        that.setState({
          currentGroupKey: groupkey,
          currentGroup: groupname,
          currentMessageTimestamp: Date.now(),
          currentGroupUsers: userlist,
          currentGroupWorkouts: gws
        });
      });
    });
  }

  showPreview(workoutkey) {
    this.setState({
      currentWorkoutPreview: workoutkey
    });
  }

  startWorkout(workoutkey) {
    this.setState({
      currentWorkoutExecute: workoutkey
    });
  }

  closePreview() {
    this.setState({
      currentWorkoutPreview: ''
    });
  }

  closeWorkout() {
    this.setState({
      currentWorkoutExecute: ''
    });
  }

  previousMenu() {
    var newmenu = 0;
    if (this.state.submenu === 1) {
      newmenu = 4;
    }
    else {
      newmenu = this.state.submenu - 1;
    }
    this.setState({
      submenu: newmenu
    });
  }

  nextMenu() {
    var newmenu = 0;
    if (this.state.submenu === 4) {
      newmenu = 1;
    }
    else {
      newmenu = this.state.submenu + 1;
    }
    this.setState({
      submenu: newmenu
    });
  }

  previousMonth() {
    var start = this.state.statsstart;
    start = new Date(start.setMonth(start.getMonth() - 1));
    var end = this.state.statsend;
    end = new Date(end.setMonth(end.getMonth() - 1));
    this.setState({
      statsstart: start,
      statsend: end
    });
  }

  nextMonth() {
    var start = this.state.statsstart;
    start = new Date(start.setMonth(start.getMonth() + 1));
    var end = this.state.statsend;
    end = new Date(end.setMonth(end.getMonth() + 1));
    this.setState({
      statsstart: start,
      statsend: end
    });
  }

  backButtonClick(that) {
    if (that.state) {
      if (that.state.currentWorkoutPreview === '' && that.state.currentWorkoutExecute === '') {
        that.setState({
          currentGroup: ''
        });
      }
      else if (that.state.currentWorkoutPreview !== '') {
        that.closePreview();
      }
      /* else if (that.state.currentWorkoutExecute !== '') {
        alert('Cannot close');
      } */
    }
  }

  backToMain() {
    this.setState({
      currentGroup: ''
    });
  }

  render() {
    const { classes } = this.props;
    var that = this;
    var header = this.createHeader();
    var bottompagespace = <div className={classes.bottompagespace}><Divider variant="middle" className={classes.contactDivider} /></div>;
    var content = (<div />);
    if (FirebaseAuth.currentUser !== null && FirebaseAuth.profile !== null && FirebaseAuth.profile.betauser) {
      if (this.state && this.state.currentWorkoutPreview && this.state.currentWorkoutPreview !== '') {
        var open = true;
        var tabindex = -1;
        return (
          <Modal
            tabIndex={tabindex}
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <WorkoutPreview workout={this.state.currentWorkoutPreview} close={() => this.closePreview()} />
          </Modal>

        );
      }
      else if (this.state && this.state.currentWorkoutExecute && this.state.currentWorkoutExecute !== '') {
        var open2 = true;
        var tabindex2 = -1;
        return (
          <Modal
            tabIndex={tabindex2}
            open={open2}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <WorkoutExecute workout={this.state.currentWorkoutExecute} warmups={this.state.warmups} cooldowns={this.state.cooldowns} group={this.state.currentGroupKey} close={() => this.closeWorkout()} />
          </Modal>
        );
      }
      else if (this.state && this.state.currentGroup && this.state.currentGroup !== '') {
        header = (<div className={[classes.backmenu].join(' ')}>
          <NavigateBeforeIcon className={[classes.backbuttonHome, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.backToMain()} />
          <div className={[classes.submenucaption].join(' ')} color="textPrimary">
            Online Training
          </div>
        </div>);
        var submenutext = (this.state.submenu === 1) ? "Aktuelle Workouts"
          : (this.state.submenu === 2) ? "Nachrichten"
            : (this.state.submenu === 3) ? "Challenges" : "Gruppenmitglieder";
        var subheader = (<div className={[classes.header1, classes.marginBottom20].join(' ')}>
          <ArrowBackIcon className={[classes.navigationbutton1, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.previousMenu()} />
          <div className={[classes.switchheader1].join(' ')}>{submenutext}</div>
          <ArrowForwardIcon className={[classes.navigationbutton1, classes.navigationButtonRight].join(' ')} fontSize="large" onClick={() => that.nextMenu()} />
        </div>);
        var content2 = (<div />);
        if (this.state.submenu === 1) {
          content2 = <WorkoutListView
            currentGroupWorkouts={that.state.currentGroupWorkouts}
            showPreview={(key) => that.showPreview(key)}
            startWorkout={(key) => that.startWorkout(key)}
          />
        }
        else if (this.state.submenu === 2) {
          content2 = <WorkoutMessageView
            currentGroupKey={that.state.currentGroupKey}
          />
        }
        else if (this.state.submenu === 3) {
          content2 = <WorkoutChallenge
            currentGroupKey={that.state.currentGroupKey} currentGroupUsers={that.state.currentGroupUsers}
          />
        }
        else if (this.state.submenu === 4) {
          content2 = <WorkoutGroupInfoView
            currentGroupUsers={that.state.currentGroupUsers}
          />
        }
        content = (<div>
          <div className={classes.submenuheader}>{this.state.currentGroup}</div>
          <div className={classes.mainspacer} />
          {subheader}
          {content2}
        </div>);
      }
      else {
        var mygrouplist = this.createMyGroupsView();
        var mystats = this.createMyStatsView();
        content = <div>
          {mystats}
          <div className={classes.mainspacer} />
          {mygrouplist}
        </div>;
      }
    }
    else {
      content = (<div>
        <div className={classes.submenuheader}>Ihr wollt ein Online Training absolvieren? Als Ergänzung zum eigenen Sportprogramm, als Begleitung zum Kursprogramm, oder als Fitnesstraining? Kontaktiere uns per Email unter info@nolimitz-fitness.de , und wir ermitteln deine Bedürfnisse an ein individuelles Trainingsprogramm. </div>
      </div>);
    }

    return (
      <Card className={classes.root}>
        {header}
        <div className={classes.mainspacer} />
        {content}
        {bottompagespace}
      </Card>
    );

  }

  createHeader() {
    const { classes } = this.props;
    var header = (<div className={classes.containertitle}>
      <Typography className={classes.title} color="textSecondary">
        Trainingscockpit
      </Typography>
      <Divider variant="middle" classes={{ root: classes.divider }} /></div>);
    return header;
  }

  createMyGroupsView() {
    const { classes } = this.props;
    var that = this;
    var overview = (this.state && this.state.mygroups) ?
      (this.state.mygroups.map(function (mygroup, key) {
        var item = mygroup.val();
        return (
          <div className={[classes.completeWidthHeaderLabel2, classes.clickable, classes.marginLeft20, classes.groupbox].join(' ')} key={key} onClick={() => that.selectGroup(mygroup.key, item.name)}>
            <GroupIcon className={[classes.floatrelative, classes.groupicon].join(' ')} fontSize="large" />
            <div className={[classes.floatrelative, classes.sectionlabel2, classes.widthGroupInfo, classes.paddingLeft20].join(' ')}>{item.name}</div>
            <div className={[classes.floatrelative, classes.sublabel, classes.widthGroupInfo, classes.paddingLeft20].join(' ')}>{item.information}</div>
          </div>);
      })) : (<div className={classes.sublabel}>Aktuell bist du nicht Teil einer Fitnessgruppe</div>);
    return (
      <div>
        <div className={[classes.completeWidthHeaderLabel2, classes.clickable].join(' ')}>
          <div className={classes.sectionlabel}>Meine Gruppen</div>
        </div>
        <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
          {overview}
        </div>
      </div>);
  }

  createMyStatsView() {
    const { classes } = this.props;
    var that = this;

    var overview = (<div />);
    var ym = this.state.statsstart.getFullYear() + "_" + this.state.statsstart.getMonth();

    if (this.state.statsloading) {
      overview = <div className={classes.sublabel}>Statistiken werden geladen</div>;
    }
    else {
      var timespantext = this.state.statsstart.toLocaleDateString() + " - " + this.state.statsend.toLocaleDateString();
      var timespanheader = (<div className={[classes.header1, classes.marginBottom20].join(' ')}>
        <ArrowBackIcon className={[classes.navigationbutton1, classes.navigationButtonLeft].join(' ')} fontSize="large" onClick={() => that.previousMonth()} />
        <div className={[classes.switchheader1].join(' ')}>{timespantext}</div>
        <ArrowForwardIcon className={[classes.navigationbutton1, classes.navigationButtonRight].join(' ')} fontSize="large" onClick={() => that.nextMonth()} />
      </div>);
      if (this.state.stats[ym] === undefined || this.state.stats[ym].completetime === 0) {
        overview = (
          <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
            {timespanheader}
            <div className={classes.sublabel}>Du hast in diesem Monat noch nicht trainiert</div>
          </div>)
      }
      else {

        var monthkeys = Object.keys(this.state.stats);
        monthkeys.sort();
        

        var prev = this.state.statsstart;
        prev = new Date(prev.setMonth(prev.getMonth() - 1));
        var prev2 = this.state.statsstart;
        prev2 = new Date(prev2.setMonth(prev2.getMonth() - 2));
        var prevym = prev.getFullYear() + "_" + prev.getMonth();
        var prev2ym = prev2.getFullYear() + "_" + prev2.getMonth();

        var time = (this.state.stats[ym] !== undefined)  ? this.state.stats[ym].completetime : 0;
        var timeprev = (this.state.stats[prevym] !== undefined)  ? this.state.stats[prevym].completetime : 0;
        var timeprev2 = (this.state.stats[prev2ym] !== undefined)  ? this.state.stats[prev2ym].completetime : 0;
        /* 
        overview = (
          <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
            <div className={[classes.floatrelative, classes.fullwidth, classes.paddingLeft20, classes.labelInfo].join(' ')}>Gesamte Trainingszeit: {time}</div>
            <div className={[classes.floatrelative, classes.fullwidth, classes.paddingLeft20, classes.labelInfo].join(' ')}>Gesamte Trainingszeit: {timeprev}</div>
            <div className={[classes.floatrelative, classes.fullwidth, classes.paddingLeft20, classes.labelInfo].join(' ')}>Gesamte Trainingszeit: {timeprev2}</div>
          </div>
        );
        */
        
                var time = new Date(this.state.stats[ym].completetime).toISOString().substr(11, 8)
                var ekeys = Object.keys(this.state.stats[ym].exercises);
                var workouts = ekeys.map((ekey, idx) => {
                  var e = this.state.stats[ym].exercises[ekey];
                  var unit = e.unit === 'Sekunden' ? ' s' : e.unit === 'Distanz' ? 'm' : '';
                  return (<div className={[classes.floatrelative, classes.fullwidth].join(' ')} key={idx}>
                    <div className={[classes.floatrelative, classes.fullwidth, classes.paddingLeft60, classes.labelInfoBlue, classes.widthExerciseName].join(' ')}>{e.name}</div>
                    <div className={[classes.floatrelative, classes.fullwidth, classes.paddingLeft60, classes.labelInfoBlue, classes.widthExerciseNo].join(' ')}>{e.number} {unit}</div>
                  </div>)
                });
                overview = (
                  <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                    {timespanheader}
                    <div className={classes.mainspacer} />
                    <div className={[classes.floatrelative, classes.fullwidth, classes.paddingLeft20, classes.labelInfo].join(' ')}>Gesamte Trainingszeit: {time}</div>
                    <div className={classes.mainspacer} />
                    <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                      <div className={[classes.floatrelative, classes.fullwidth, classes.paddingLeft60, classes.labelInfoBlue, classes.widthExerciseName].join(' ')}><b>Übung</b></div>
                      <div className={[classes.floatrelative, classes.fullwidth, classes.paddingLeft60, classes.labelInfoBlue, classes.widthExerciseNo].join(' ')}><b>Durchführung</b></div>
                    </div>
                    {workouts}
                  </div>) 
      }
    }
    return (<div>
      <div className={[classes.completeWidthHeaderLabel2, classes.clickable].join(' ')}>
        <div className={classes.sectionlabel}>Aktueller Trainingsstand</div>
      </div>
      <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
        {overview}
      </div>
    </div>);
  }
}

export default withStyles(useStyles)(Courses);