import { withStyles } from '@material-ui/core/styles';
import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { OutlinedInput } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import defaultStyles from '../../../base'
import { CourseData } from '../../../../controller/data/coursedata'
import { UserData } from '../../../../controller/data/userdata';

const additionalStyles = {    
    combobox: {
        width: '90%',
        marginRight: '5%',
        marginLeft: '5%',
        height: '28px',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '12px'
    },
    menuitem: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '12px'
    }
};

const result = {
    ...defaultStyles,
    ...additionalStyles,
};

const useStyles = theme => (result)

class OnlineTrainingGroups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groupkeys: null,
            groups: null,
            users: null,
            workouts: null,
            editMode: false,
            selectedGroup: "",
            selectedGroupUsers: [],
            selectedGroupWorkoutTimes: {},
            selectedUser: "",
            selectedWorkout: "",
            name: "",
            description: "",
            start: "",
            end: "",
            enabled: true,
            errormessage: "",
            infomessage: "",
            groupchallenges: null,
            challengestart: "",
            challengeend: "",
            type: "index",
            subtype: "power",
            challenginformation: "",
            availabletypes: [
                {
                    id: "index",
                    name: "Index"
                }
            ],
            availablesubtypes: [
                {
                    id: "power",
                    name: "Kraft"
                },
                {
                    id: "endurance",
                    name: "Ausdauer"
                },
                {
                    id: "stability",
                    name: "Stabilität"
                },
                {
                    id: "mobility",
                    name: "Mobilität"
                },
                {
                    id: "power_endurance",
                    name: "Kraft + Ausdauer"
                }
            ],
        }
    }

    componentDidMount() {
        this.loadGroups();
        this.loadGroupWorkouts();
        this.loadUsers();
    }

    loadGroups() {
        var that = this;
        var cd = new CourseData();
        cd.loadGroups((gs) => {
            var newgs = {};
            var gr = "";
            gs.forEach((eg) => {
                if (gr === "") {
                    gr = eg.key;
                }
                newgs[eg.key] = {
                    info: eg.val()
                };
            });
            that.setState({
                groupkeys: Object.keys(newgs),
                groups: newgs
            });
            setTimeout(() => {
                that.selectGroup(gr)
            }, 100);
        });
    }

    loadUsers() {
        var that = this;
        var ud = new UserData();
        var list = {};
        ud.loadAllUsers((users) => {
            users.forEach((user) => {
                list[user.key] = user.val();
            });
            that.setState({
                users: list
            });
        });
    }

    loadGroupWorkouts() {
        var that = this;
        var cd = new CourseData();
        cd.loadWorkouts((workouts) => {
            that.setState({
                workouts: workouts
            });
        });
    }

    addGroup() {
        var cd = new CourseData();
        var group = {
            name: this.state.name,
            information: this.state.description
        };
        cd.insertGroup(group);
        this.loadGroups();
    }

    textchange(prop, e) {
        var val = e.target.value;
        var ival = parseInt(val);
        if (isNaN(ival)) {
            ival = 0;
        }
        if (prop === 'name') {
            this.setState({
                name: val
            });
        }
        if (prop === 'description') {
            this.setState({
                description: val
            });
        }
        if (prop === "information") {
            this.setState({
                challenginformation: val
            });
        }
    }

    selectGroup(gk) {
        var that = this;
        var cd = new CourseData();
        cd.loadGroupUsers(gk, (gusers) => {
            var list = [];
            for (var j = 0; j < gusers.length; j++) {
                list.push(gusers[j].key);
            }
            cd.loadAllGroupWorkouts(gk, (gworkouts) => {
                var groupenabled = !that.state.groups[gk].info.disabled;
                cd.loadChallenges(gk, false, (chll) => {
                    that.setState({
                        selectedGroup: gk,
                        enabled: groupenabled,
                        selectedGroupUsers: list,
                        selectedGroupWorkoutTimes: gworkouts,
                        groupchallenges: chll,
                        challengestart: "",
                        challengeend: "",
                        type: "index",
                        subtype: "power",
                        challenginformation: ""
                    });
                });
                
            });
        });
    }

    selectUser(uk) {
        this.setState({
            selectedUser: uk
        });
    }

    selectWorkout(wk) {
        this.setState({
            selectedWorkout: wk
        });
    }

    assignUser() {
        var that = this;
        var ud = new UserData();
        var cd = new CourseData();
        ud.loadUser(that.state.selectedUser, (u) => {
            cd.assignUserToGroup(u, that.state.selectedGroup, (str) => {
                var list = that.state.selectedGroupUsers;
                list.push(that.state.selectedUser);
                that.setState({
                    selectedUser: "",
                    selectedGroupUsers: list
                });
            });
        });
    }

    assignWorkout() {
        if (this.state.start === "") {
            this.showError("Start nicht gesetzt");
            return;
        }
        if (this.state.end === "") {
            this.showError("Ende nicht gesetzt");
            return;
        }
        if (this.state.start >= this.state.end) {
            this.showError("Fehler: Start > Ende");
            return;
        }
        var wtkeys = Object.keys(this.state.selectedGroupWorkoutTimes);
        for (var i = 0; i < wtkeys.length; i++) {
            if (this.state.selectedGroupWorkoutTimes[wtkeys[i]].workout === this.state.selectedWorkout &&
                ((this.state.start.getTime() < this.state.selectedGroupWorkoutTimes[wtkeys[i]].interval.start &&
                    this.state.end.getTime() >= this.state.selectedGroupWorkoutTimes[wtkeys[i]].interval.start) ||
                    (this.state.start.getTime() < this.state.selectedGroupWorkoutTimes[wtkeys[i]].interval.end &&
                        this.state.end.getTime() >= this.state.selectedGroupWorkoutTimes[wtkeys[i]].interval.end))) {
                this.showError("Workout existiert schon im angegebenen Zeitraum");
                return;
            }
        }
        var cd = new CourseData();
        cd.assignWorkoutToGroup(this.state.selectedWorkout, this.state.selectedGroup, this.state.start.getTime(), this.state.end.getTime());
        cd.loadAllGroupWorkouts(this.state.selectedGroup, (gworkouts) => {
            this.setState({
                selectedGroupWorkoutTimes: gworkouts
            });
        });
    }

    assignChallenge() {
        if (this.state.challengestart === "") {
            this.showError("Start nicht gesetzt");
            return;
        }
        if (this.state.challengeend === "") {
            this.showError("Ende nicht gesetzt");
            return;
        }
        if (this.state.challengestart >= this.state.challengeend) {
            this.showError("Fehler: Start > Ende");
            return;
        }
        var cd = new CourseData();
        cd.insertChallenge(this.state.selectedGroup, this.state.type, this.state.subtype, this.state.challengestart.getTime(), this.state.challengeend.getTime(), this.state.challenginformation);
        this.selectGroup(this.state.selectedGroup);
    }

    saveGroup() {
        var cd = new CourseData();
        var group = this.state.groups[this.state.selectedGroup].info;
        group.name = this.state.name;
        group.information = this.state.description;
        group.disabled = !this.state.enabled;

        cd.updateGroup(group, this.state.selectedGroup);
        this.showInfo("Gruppeninformationen erfolgreich aktualisiert!");
        var groups = this.state.groups;
        groups[this.state.selectedGroup].info = group;
        this.setState({
            groups: groups
        });
    }

    showError(text) {
        var that = this;
        this.setState({
            errormessage: text
        });
        setTimeout(() => {
            that.showError("");
        }, 3000);
    }

    showInfo(text) {
        var that = this;
        this.setState({
            infomessage: text
        });
        setTimeout(() => {
            that.showInfo("");
        }, 3000);
    }

    editGroup() {
        if (this.state.selectedGroup !== "") {
            this.setState({
                editMode: true
            });
        }
    }

    cancelEdit() {
        this.setState({
            editMode: false
        });
    }

    datechange(prop, event) {
        var val = new Date(event.target.value);
        if (prop === 'start') {
            this.setState({
                start: val
            });
        }
        if (prop === 'end') {
            this.setState({
                end: val
            });
        }
        if (prop === 'challengestart') {
            this.setState({
                challengestart: val
            });
        }
        if (prop === 'challengeend') {
            this.setState({
                challengeend: val
            });
        }
    }

    changeBool(type) {
        if (type === 'groupenabled') {
            var oldstate = (this.state.enabled === undefined) ? false : this.state.enabled;
            var newstate = !oldstate;
            this.setState({
                enabled: newstate
            });
        }
    }

    comboboxchange(type, e) {
        if (type === "type") {
            this.setState({
                type: e.target.value
            });
        }
        if (type === "subtype") {
            this.setState({
                subtype: e.target.value
            });
        }
    }

    render() {
        const { classes } = this.props;
        var content = (<div className={[classes.floatrelative, classes.infotextbold, classes.fullwidth].join(' ')}>Lade ...</div>);

        if (this.state.groups !== null && this.state.users !== null && this.state.workouts !== null) {
            if (this.state.editMode) {
                content = this.createGroupEditInput();
            }
            else {
                var currentGroupWorkouts = this.createGroupList();
                var inputform = this.createGroupInput();
                content = (<div>
                    <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                        {currentGroupWorkouts}
                        {inputform}
                    </div>
                    <div className={classes.mainspacer} />
                </div>);
            }
        }
        return (

            <div>
                <div className={classes.completeWidthHeaderLabel}>
                    Online Training - Gruppen
                </div>
                <div className={classes.autoheight}>
                    <div className={classes.errorlabel}>{this.state.errormessage}</div>
                    <div className={classes.infolabel}>{this.state.infomessage}</div>
                    <div className={classes.mainspacer} />
                    {content}
                </div>
            </div>
        );
    }

    createGroupList() {
        const { classes } = this.props;
        var that = this;
        if (this.state.groups !== null && this.state.users !== null) {
            var existinggws = this.state.groupkeys.map((gk, idx) => {
                if (gk === this.state.selectedGroup) {
                    return (<div className={classes.listBoxEntrySelected} key={idx}>{this.state.groups[gk].info.name}</div>);
                }
                else {
                    return (<div className={classes.listBoxEntry} key={idx} onClick={(e) => that.selectGroup(gk)}>{this.state.groups[gk].info.name}</div>);
                }
            });

            return (
                <div className={[classes.floatrelative, classes.listArea].join(' ')}>
                    <div className={[classes.label].join(' ')}>Existierende Gruppen:</div>
                    <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                        {existinggws}
                    </div>
                    <div className={classes.mainspacer} />
                    <Button className={classes.buttonContentMore} onClick={() => this.editGroup()}>
                        Gruppe bearbeiten
                    </Button>
                </div>
            );
        }
        return (<div />);
    }

    createGroupInput() {
        const { classes } = this.props;
        var that = this;
        return (<div className={[classes.floatrelative, classes.listArea].join(' ')}>
            <div className={classes.label}>Name</div>
            <input className={[classes.textbox].join(' ')} defaultValue={that.state.name} onChange={(e) => that.textchange('name', e)}></input>
            <div className={classes.label}>Description</div>
            <input className={[classes.textbox].join(' ')} defaultValue={that.state.description} onChange={(e) => that.textchange('description', e)}></input>
            <Button className={classes.buttonContentMore} onClick={() => this.addGroup()}>
                Gruppe erstellen
            </Button>
        </div>);
    }

    createGroupEditInput() {
        const { classes } = this.props;
        var that = this;
        var groupedit = this.state.groups[this.state.selectedGroup];

        var userassign = this.createUserAssign();
        var workoutassign = this.createWorkoutAssign();
        var challengeassign = this.createChallengeAssign();
        return (<div>
            <Button className={classes.buttonContentMore} onClick={() => this.cancelEdit()}>
                Zurück
            </Button>
            <div className={classes.mainspacer} />
            <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                <div className={classes.label}>Name</div>
                <input className={[classes.textbox].join(' ')} defaultValue={groupedit.info.name} onChange={(e) => that.textchange('name', e)}></input>
                <div className={classes.label}>Description</div>
                <input className={[classes.textbox].join(' ')} defaultValue={groupedit.info.description} onChange={(e) => that.textchange('description', e)}></input>
                <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.enabled} className={classes.checkbox}
                            name="groupenabled" onChange={() => that.changeBool('groupenabled')} />}
                        label={<div>Aktiv</div>}
                    />
                </div>
                <div className={classes.mainspacer} />
                <div className={classes.fullwidth}>
                    <Button className={classes.buttonContentMore} onClick={() => this.saveGroup()}>
                        Aktualisieren
                    </Button>
                </div>
            </div>
            <div className={classes.mainspacer} />
            {userassign}
            <div className={classes.mainspacer} />
            {workoutassign}
            <div className={classes.mainspacer} />
            {challengeassign}
        </div>);
    }

    createUserAssign() {
        const { classes } = this.props;
        var that = this;

        var userkeys = Object.keys(this.state.users);
        var assigneduserkeys = [];
        var unassigneduserkeys = [];
        for (var i = 0; i < userkeys.length; i++) {
            var assigned = false;
            for (var j = 0; j < this.state.selectedGroupUsers.length; j++) {
                if (this.state.selectedGroupUsers[j] === userkeys[i]) {
                    assigned = true;
                    assigneduserkeys.push(userkeys[i]);
                }
            }
            if (!assigned) {
                unassigneduserkeys.push(userkeys[i]);
            }
        }
        var assignedusers = assigneduserkeys.map((uk, idx) => {
            return (<div className={classes.listBoxEntry} key={idx}>{this.state.users[uk].Surname} {this.state.users[uk].Name}</div>);
        });
        var unassignedusers = unassigneduserkeys.map((uk, idx) => {
            if (uk === this.state.selectedUser) {
                return (<div className={classes.listBoxEntrySelected} key={idx}>{this.state.users[uk].Surname} {this.state.users[uk].Name}</div>);
            }
            else {
                return (<div className={classes.listBoxEntry} key={idx} onClick={(e) => that.selectUser(uk)}>{this.state.users[uk].Surname} {this.state.users[uk].Name}</div>);
            }
        });
        var assignbutton = (<div></div>);
        if (this.state.selectedUser !== '') {
            assignbutton = (<Button className={classes.buttonContentMore} onClick={() => this.assignUser()}>
                Benutzer hinzufügen
            </Button>);
        }

        return (
            <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                <div className={[classes.floatrelative, classes.halfwidth].join(' ')}>
                    <div className={[classes.listAreaFull].join(' ')}>
                        <div className={[classes.label].join(' ')}>Nicht zugeordnete Benutzer:</div>
                        {assignbutton}
                        <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                            {unassignedusers}
                        </div>
                    </div>
                </div>
                <div className={[classes.floatrelative, classes.halfwidth].join(' ')}>
                    <div className={[classes.listAreaFull].join(' ')}>
                        <div className={[classes.label].join(' ')}>Zugeordnete Benutzer:</div>
                        <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                            {assignedusers}
                        </div>
                    </div>
                </div>
            </div>);
    }

    createWorkoutAssign() {
        const { classes } = this.props;
        var that = this;

        var workoutkeys = Object.keys(this.state.workouts);
        var wtkeys = Object.keys(this.state.selectedGroupWorkoutTimes);
        var assignedworkouts = wtkeys.map((wtkey, idx) => {
            var text = this.state.workouts[this.state.selectedGroupWorkoutTimes[wtkey].workout].name + " (" + new Date(this.state.selectedGroupWorkoutTimes[wtkey].interval.start).toLocaleDateString() + " - " + new Date(this.state.selectedGroupWorkoutTimes[wtkey].interval.end).toLocaleDateString() + ")";
            return (<div className={classes.listBoxEntry} key={idx}>{text}</div>);
        });
        var unassignedworkouts = workoutkeys.map((uk, idx) => {
            if (uk === this.state.selectedWorkout) {
                return (<div className={classes.listBoxEntrySelected} key={idx}>{this.state.workouts[uk].name}</div>);
            }
            else {
                return (<div className={classes.listBoxEntry} key={idx} onClick={(e) => that.selectWorkout(uk)}>{this.state.workouts[uk].name}</div>);
            }
        });
        var assignbutton = (<div></div>);
        if (this.state.selectedWorkout !== '') {
            assignbutton = (<div>
                <TextField
                    id="datetime-start"
                    label="Von"
                    type="date"
                    onChange={(e) => that.datechange('start', e)}
                    defaultValue={this.state.start}
                    className={classes.datebox}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    id="datetime-end"
                    label="Bis"
                    type="date"
                    onChange={(e) => that.datechange('end', e)}
                    defaultValue={this.state.end}
                    className={classes.datebox}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <Button className={classes.buttonContentMore} onClick={() => this.assignWorkout()}>
                    Workout hinzufügen
                </Button>
            </div>);
        }

        return (
            <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                <div className={[classes.floatrelative, classes.halfwidth].join(' ')}>
                    <div className={[classes.listAreaFull].join(' ')}>
                        <div className={[classes.label].join(' ')}>Nicht zugeordnete Workouts:</div>
                        {assignbutton}
                        <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                            {unassignedworkouts}
                        </div>
                    </div>
                </div>
                <div className={[classes.floatrelative, classes.halfwidth].join(' ')}>
                    <div className={[classes.listAreaFull].join(' ')}>
                        <div className={[classes.label].join(' ')}>Zugeordnete Workouts:</div>
                        <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                            {assignedworkouts}
                        </div>
                    </div>
                </div>
            </div>);
    }

    createChallengeAssign() {
        const { classes } = this.props;
        var that = this;
        var timerange = (<div>
            <TextField
                id="datetime-challengestart"
                label="Von"
                type="date"
                onChange={(e) => that.datechange('challengestart', e)}
                defaultValue={this.state.challengestart}
                className={classes.datebox}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                id="datetime-challengeend"
                label="Bis"
                type="date"
                onChange={(e) => that.datechange('challengeend', e)}
                defaultValue={this.state.challengeend}
                className={classes.datebox}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>);

        var type = (<TextField
            id="combotype"
            select
            value={this.state.type}
            className={classes.combobox}
            onChange={(e) => that.comboboxchange("type", e)}
        >
            {this.state.availabletypes.map((option) => (
                <MenuItem className={classes.menuitem} key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>);
        var subtype = (<TextField
            id="combosubtype"
            select
            value={this.state.subtype}
            className={classes.combobox}
            onChange={(e) => that.comboboxchange("subtype", e)}
        >
            {this.state.availablesubtypes.map((option) => (
                <MenuItem className={classes.menuitem} key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>);
        var infotext = (<OutlinedInput aria-label="News Text" multiline className={classes.textboxMulti} placeholder="Information" onChange={(e) => that.textchange('information', e)} />);
        var create = (<Button className={classes.buttonContentMore} onClick={() => this.assignChallenge()}>
            Challenge hinzufügen
        </Button>);
        var chkeys = Object.keys(this.state.groupchallenges);
        var assignedchallenges = chkeys.map((chkey, idx) => {
            var text = this.state.groupchallenges[chkey].type + " - " + this.state.groupchallenges[chkey].subtype + " (" + new Date(this.state.groupchallenges[chkey].start).toLocaleDateString() + " - " + new Date(this.state.groupchallenges[chkey].end).toLocaleDateString() + ")";
            return (<div className={classes.listBoxEntry} key={idx}>{text}</div>);
        });
        return (
            <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                <div className={[classes.floatrelative, classes.halfwidth].join(' ')}>
                    <div className={[classes.listAreaFull].join(' ')}>
                        <div className={[classes.label].join(' ')}>Nicht zugeordnete Workouts:</div>
                        {timerange}
                        <div className={classes.mainspacer} />
                        {type}
                        <div className={classes.mainspacer} />
                        {subtype}
                        <div className={classes.mainspacer} />
                        {infotext}
                        <div className={classes.mainspacer} />
                        {create}
                    </div>
                </div>
                <div className={[classes.floatrelative, classes.halfwidth].join(' ')}>
                    <div className={[classes.listAreaFull].join(' ')}>
                        <div className={[classes.label].join(' ')}>Zugeordnete Challenges:</div>
                        <div className={[classes.fullWidthMargin20Both, classes.listBox].join(' ')}>
                            {assignedchallenges}
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export default withStyles(useStyles)(OnlineTrainingGroups);
