import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import './App.css';
// import { FirebaseMessaging } from "./lib/fcm";
import { FirebaseStorage } from "./lib/database";
import Button from '@material-ui/core/Button';
import Home from './gui/home';
import WebHome from './gui/webhome';
import Checkin from './gui/management/checkin';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Background from './img/logo512.png';
import { RequestMethods } from "./lib/request";
import $ from "jquery";
import { PwaInstaller } from "./lib/installprompt";
import { FirebaseAuth } from "./lib/authentication";
import defaultStyles from './gui/base'
import { AdminData } from './controller/data/admindata'

var sectionStyle = {
  backgroundImage: `url(${Background})`
};

const result = {
  ...defaultStyles
};

const useStyles = theme => (result)

class App extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 1,
      mode: (window.orientation === undefined) ? 'web' : 'mobile'
    }
  }

  async componentDidMount() {
    var that = this;
    this.resize();

    //FirebaseMessaging.OnTokenReceived = this.storeToken;
    //FirebaseMessaging.requestPermission();
    this.timerId = setInterval(() => {
      that.checkIcons();
    }, 1000);
    if (window.orientation === undefined) {
      this.setState({
        mode: 'web'
      });
    }
    else {
      this.setState({
        mode: 'mobile'
      });
    }
    $(window).resize(function () {
      that.resize();
    });
    setTimeout(() => {
      that.resize();
    }, 200);
  }

  resize() {
    if (this.state && this.state.mode === 'web') {
      $("#slideContainer").height($(window).height() - 35);
      var left1 = ($(window).width() / 2) - 80;
      $("#headerlogo").css("left", left1 + "px");
      $("#root").width($(window).width());
    }
    else {
      $("#slideContainer").height($(window).height() - 150);
      var left2 = ($(window).width() / 2) - 80;
      $("#headerlogo").css("left", left2 + "px");
      $("#root").width($(window).width());
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidCatch(error, errorInfo) {
    var that = this;
    var ad = new AdminData();
    var src = that.getIpClient();
    ad.logError(error, src,  (FirebaseAuth.currentUser !== null) ? FirebaseAuth.currentUser.email : "");
    setTimeout(() => {
      that.setState({
        error: error,
        errorInfo: errorInfo
      })
    }, 1000);
  }

  getIpClient() {
    try {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.open( "GET", 'https://api.ipify.org?format=json', false );
      xmlHttp.send( null );
      var ret = JSON.parse(xmlHttp.responseText).ip;      
      return ret;
    } catch (error) {
      return "";
    }
  }

  reloadApp() {
    window.location.reload();
  }


  // storeToken(token) {
  //   if (token !== null && token !== "") {
  //     var ts = Date.now();
  //     var push = {};
  //     push[token] = {
  //       "Timestamp": ts
  //     };
  //     FirebaseStorage.insertupdateObject("pushObjects", push);
  //   }
  //   else {
  //     console.log("No token");
  //   }
  //   if (FirebaseMessaging.messagingSupported && !FirebaseMessaging.permissionBlocked) {
  //     $("#NotificationAllowed").show();
  //     $("#NotificationBlocked").hide();
  //     $("#NotificationNotSupported").hide();
  //   }
  //   else if (FirebaseMessaging.messagingSupported && FirebaseMessaging.permissionBlocked) {
  //     $("#NotificationAllowed").hide();
  //     $("#NotificationBlocked").show();
  //     $("#NotificationNotSupported").hide();
  //   }
  //   else {
  //     $("#NotificationAllowed").hide();
  //     $("#NotificationBlocked").hide();
  //     $("#NotificationNotSupported").show();
  //   }
  //   $("#notificationmessage").html(FirebaseMessaging.currentMessage);
  // }

  checkIcons() {
    // if (FirebaseMessaging.messagingSupported && !FirebaseMessaging.permissionBlocked) {
    //   $("#NotificationAllowed").show();
    //   $("#NotificationBlocked").hide();
    //   $("#NotificationNotSupported").hide();
    // }
    // else if (FirebaseMessaging.messagingSupported && FirebaseMessaging.permissionBlocked) {
    //   $("#NotificationAllowed").hide();
    //   $("#NotificationBlocked").show();
    //   $("#NotificationNotSupported").hide();
    // }
    // else {
    //   $("#NotificationAllowed").hide();
    //   $("#NotificationBlocked").hide();
    //   $("#NotificationNotSupported").show();
    // }
    // $("#notificationmessage").html(FirebaseMessaging.currentMessage);
    // if (PwaInstaller.deferredPrompt != null && !PwaInstaller.IsInstalled) {
    //   $("#InstallButton").show();
    // }
    // else {
    //   $("#InstallButton").hide();
    // }
    if (FirebaseAuth.currentUser !== null && FirebaseAuth.currentUser.emailVerified === true) {
      $("#LogoffButton").show();
      $("#CurrentUserInfo").html(FirebaseAuth.currentUser.email);
    }
    else {
      $("#LogoffButton").hide();
      $("#CurrentUserInfo").html("");
    }
  }

  setCurrentIndex(index) {
    this.setState({
      currentIndex: index
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.errorInfo) {
      return (
        <ThemeProvider theme={theme} >
          <div style={sectionStyle} id="googleinfo">NoLimitZ Fitness: Outdoor Fitness, Sport-Events, Teambuilding von Tobias Meier und Andreas Faltermeieer </div>
          <div style={sectionStyle} id="watermark" />
          <div className={[classes.info, classes.textcenter].join(' ')}>
            Ein Fehler ist aufgetreten, bitte laden Sie die Seite neu.<br />
            Falls der Fehler weiterhin auftritt, schicken Sie bitte eine Email an info@nolimitz-fitness.de
          </div>
          <Button className={classes.buttonContentMore} onClick={() => this.reloadApp()}>
            Neu laden
          </Button>
        </ThemeProvider>
      );
    }
    if (RequestMethods.getQueryVariable("app") === "checkin") {
      return (
        <ThemeProvider theme={theme} >
          <div style={sectionStyle} id="watermark" />
          <Checkin />
        </ThemeProvider>
      );
    }
    else {
      if (this.state && this.state.mode === 'web') {
        return (
          <ThemeProvider theme={theme} >
            <div style={sectionStyle} id="googleinfo">NoLimitZ Fitness: Outdoor Fitness, Sport-Events, Teambuilding von Tobias Meier und Andreas Faltermeieer </div>
            <div style={sectionStyle} id="watermark" />
            <WebHome OnChangeIndex={(index) => this.setCurrentIndex(index)} />
          </ThemeProvider>
        );
      }
      else {
        return (
          <ThemeProvider theme={theme} >
            <div style={sectionStyle} id="googleinfo">NoLimitZ Fitness: Outdoor Fitness, Sport-Events, Teambuilding von Tobias Meier und Andreas Faltermeieer </div>
            <div style={sectionStyle} id="watermark" />
            <Home OnChangeIndex={(index) => this.setCurrentIndex(index)} />
          </ThemeProvider>
        );
      }

      /* return (
        <ThemeProvider theme={theme} >
          <div style={sectionStyle} id="googleinfo">NoLimitZ Fitness: Outdoor Fitness, Sport-Events, Teambuilding von Tobias Meier und Andreas Faltermeieer </div>
          <div style={sectionStyle} id="watermark" />
          <div style={notificationmessage} id="notificationmessage" />
          <Home />
        </ThemeProvider>
      ); */
    }
  }
}

export default withStyles(useStyles)(App);