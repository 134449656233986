import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { FirebaseStorage } from '../../lib/database';
import defaultStyles from '../base';
import { NewsData } from '../../controller/data/newsdata'

const additionalStyles = {

};

const result = {
  ...defaultStyles,
  ...additionalStyles,
};

const useStyles = theme => (result)

class NewsItems extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      news: [],
      expanded: false
    };
  }

  loadNews() {
    var that = this;
    var nd = new NewsData();    
    nd.loadAllNews((news) => {
      var newnews = [];
      news.forEach(function (n) {
        newnews.push(n);
      });
      const newssorter = (a, b) => a.start < b.start ? 1 : -1;
      newnews.sort(newssorter);
      that.setState({
        news: newnews
      });
    });
  }

  componentDidMount() {
    this.loadNews();
  }

  expand() {
    this.setState({
      expanded: true
    });
  }

  render() {
    const { classes } = this.props;
    var that = this;

    var information = that.state.news.map(function (newsitem, i) {
      var ni = newsitem.val();
      var expand = "";    
      var news = (<div></div>);
      if (ni.text.length > 120 && that.state.expanded === false) {
        var newstextlines = ni.text.substring(0, 120) + "...";
        news = (<div className={classes.labelInfo} dangerouslySetInnerHTML={{ __html: newstextlines }}></div>);        
        expand = " weiterlesen "
      }
      else {
        var lines = ni.text.split("\n");
        news = lines.map((d, idx) => {  
            if (d.trim() === '') {        
              return (<div className={[classes.floatrelative, classes.labelInfo, classes.fullwidth].join(' ')}>&nbsp;</div>)          
            }
            else {
              return (<div className={[classes.floatrelative, classes.labelInfo, classes.fullwidth].join(' ')}>{d}</div>)          
            }
        });
      }
      if (new Date(ni.start).getTime() < new Date().getTime() &&
        new Date(ni.end).getTime() > new Date().getTime()) {
        return (<div key={i}>
          <div className={classes.labelStandard}>{ni.title}</div>
          {news}
          <div className={[classes.labelInfoBlue, classes.clickable].join(' ')} onClick={() => that.expand()}>{expand}</div>
        </div>
        )
      }
      else {
        return (<div key={i} />)
      }
    });
    return information;
  }
}

export default withStyles(useStyles)(NewsItems);