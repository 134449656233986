import { FirebaseStorage } from '../../lib/database';
import { NewsData} from './newsdata'

export class DBUpdate {

    updateProfiles() {
        FirebaseStorage.loadAll("users", (u) => {        
            var user = u.val();
            if (!user.hasOwnProperty('onlinetraining')) {              
                user.onlinetraining = {
                    fitnesslevel: 3,
                    pushalertongroupmessage: true,
                    emailalertongroupmessage: false
                };
                console.log("Update user " + u.key + ": "+ user.Name);
                FirebaseStorage.updateObject("users", u.key, user);
            } 
            else {
                console.log("Update not required for user " + u.key + ": "+ user.Name);
            }           
          });
    }

    updateNews() {
        var nd = new NewsData();
        FirebaseStorage.loadAllComplete("news", (news) => {
            var newnews = [];
            news.forEach(function (n) {
                nd.insertNews(n.val());
            });
          });
    }

    updateWorkoutExecutions() {

        FirebaseStorage.loadAllComplete("courses/workoutexecutions", (execs)  => {
            var idx = 0;
            execs.forEach(function (exec) {
                var execution = exec.val();
                var key = exec.key;
                for (var i = 0; i < execution.drills.length; i++) {
                    execution.drills[i].info.excercises = {};
                    execution.drills[i].info.exercises = {};
                    execution.drills[i].info.info = {};
                    execution.drills[i].info.information = [];
                    for (var j = 0; j < execution.drills[i].exercises.length; j++) {
                        execution.drills[i].exercises[j].info.description = [];
                        execution.drills[i].exercises[j].info.images = [];
                        execution.drills[i].exercises[j].info.video = {};
                        execution.drills[i].exercises[j].info.info = {};
                    }
                }
                if (execution.warmup !== undefined && execution.warmup !== null) {
                    for (var i = 0; i < execution.warmup.drills.length; i++) {
                        execution.warmup.drills[i].info.excercises = {};
                        execution.warmup.drills[i].info.exercises = {};
                        execution.warmup.drills[i].info.information = [];
                        for (var j = 0; j < execution.warmup.drills[i].exercises.length; j++) {
                            execution.warmup.drills[i].exercises[j].info.description = [];
                            execution.warmup.drills[i].exercises[j].info.images = [];
                            execution.warmup.drills[i].exercises[j].info.video = {};
                            execution.warmup.drills[i].exercises[j].info.info = {};
                        }
                    }
                }
                if (execution.cooldown !== undefined && execution.cooldown !== null) {
                    for (var i = 0; i < execution.cooldown.drills.length; i++) {
                        execution.cooldown.drills[i].info.excercises = {};
                        execution.cooldown.drills[i].info.exercises = {};
                        execution.cooldown.drills[i].info.information = [];
                        for (var j = 0; j < execution.cooldown.drills[i].exercises.length; j++) {
                            execution.cooldown.drills[i].exercises[j].info.description = [];
                            execution.cooldown.drills[i].exercises[j].info.images = [];
                            execution.cooldown.drills[i].exercises[j].info.video = {};
                            execution.cooldown.drills[i].exercises[j].info.info = {};
                        }
                    }
                }
                FirebaseStorage.updateObject("courses/workoutexecutions", key, execution);
                
                idx++;
            });
        });
    }
} 