import React from "react";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import Vimeo from '@u-wave/react-vimeo';
import defaultStyles from '../base'
import deepmerge from '../../lib/deepmerge'
import $ from "jquery";

const additionalStyles = {
    preparecontainer: {
        width: '100%',
        padding: '0px',
        margin: '0px'
    },
    imageMaxHeight: {
        height: '60px'
    },
    exerciseBlock: {
        width: '30%',
        marginLeft: '2%',
    }
}
const useStyles = theme => (deepmerge(defaultStyles, additionalStyles))

class WorkoutExecutePrepare extends React.Component {

    constructor(props) {
        super(props);
        var levels = [];
        for (var i = 0; i < this.props.workoutinfo.drills.length; i++) {
            levels.push(this.props.workoutinfo.drills[i].executionlevel);
        }
        this.state = {
            levels: levels,
            usePause: false,
            warmup: true,
            cooldown: true
        };

    }

    componentDidMount() {
    }

    close(start) {
        if (start) {
            this.props.close(this.state.levels, this.state.usePause, this.state.warmup, this.state.cooldown);
        }
        else{
            this.props.cancel();
        }
    }

    changeSlider(event, newValue, idx) {
        var levels = this.state.levels;
        levels[idx] = newValue;
        this.setState({
            levels: levels
        });
        ;
    }

    changeBool(type) {        
        if (type === 'usePause') {
            var oldstate = this.state.usePause;
            var newstate = !oldstate;  
            this.setState({
                usePause: newstate
            });                
        }          
        if (type === 'warmup') {
            var oldstate2 = this.state.warmup;
            var newstate2 = !oldstate2;  
            this.setState({
                warmup: newstate2
            });                
        }   
        if (type === 'cooldown') {
            var oldstate3 = this.state.cooldown;
            var newstate3 = !oldstate3;  
            this.setState({
                cooldown: newstate3
            });                
        }  
    }

    onVideoError(errorObj, videoid, i) {
        $('#Video_' + i).hide();        
        $('#VideoError_' + i).show();        
    }

    onVideoLoaded(videoid, i) {
        $('#Video_' + i).show();        
        $('#VideoError_' + i).hide();    
    }

    render() {
        const { classes } = this.props;
        var that = this;
        var introtext = (<div className={[classes.floatrelative, classes.infotext, classes.fullwidth, classes.textcenter].join(' ')}>
            Dein Workout besteht aus {this.props.workoutinfo.drills.length} Übungen mit jeweils {this.props.workoutinfo.drills[0].exercises.length} Durchgängen.
            <br />
            Für jede Übung kannst du hier dein Level individuell anpassen, um das Training optimal einzustellen.
            <br />
        </div>)
        var setlevels = this.props.workoutinfo.drills.map((item, idx) => {
            var img = '';
            for (var i = 0; i < item.exercises.length; i++) {
                if (item.exercises[i].info != null &&
                    item.exercises[i].info.images != null &&
                    item.exercises[i].info.images.length > 0) {
                    img = item.exercises[i].info.images[0];
                    break;
                }
            }
            var ext = " ";
            if (item.info.unit === 'Anzahl') {
                ext = " Stück";
            }
            if (item.info.unit === 'Sekunden') {
                ext = " Sekunden";
            }
            var leveltext = item.info.levels[this.state.levels[idx]] + ext;
            var imgvideo = (<img className={[classes.floatrelative, classes.exerciseBlock, classes.imageMaxHeight].join(' ')} alt="Übungsbild" src={img}></img>);
            if (item.exercises[i].info.video !== "") {
                var divid = "Video_" + i;
                var diverrorid ="VideoError_" + i;
                var videopreviewerror = (<div id={diverrorid} className={[classes.errorlabel, classes.hidden].join(' ')}>Fehler beim Laden des Videos</div>);
                imgvideo = (
                    <div>
                        {videopreviewerror}
                        <div className={[classes.floatrelative, classes.exerciseBlock, classes.imageMaxHeight].join(' ')}>
                            <Vimeo 
                                id={divid}
                                video={item.exercises[i].info.video}                         
                                autoplay
                                background
                                loop
                                responsive 
                                controls={false}
                                muted={true}
                                showPortrait={false}
                                showTitle={false}
                                showByline={false}
                                onError={(errorObj) => { this.onVideoError(errorObj, item.exercises[i].info.video, i) } }
                                onLoaded={() => { this.onVideoLoaded(item.exercises[i].info.video, i) } }/>
                        </div>
                    </div>);
            }            
            return (
                <div key={idx} className={[classes.floatrelative, classes.preparecontainer].join(' ')}>
                    <div className={[classes.floatrelative, classes.exerciseBlock].join(' ')}>{item.info.name}</div>
                    {imgvideo}
                    <div className={[classes.floatrelative, classes.exerciseBlock].join(' ')}>
                        {leveltext}
                        <Slider
                            className={[classes.floatrelative, classes.slider].join(' ')}
                            value={this.state.levels[idx]}
                            onChange={(event, newValue) => that.changeSlider(event, newValue, idx)}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={7}
                        />
                    </div>
                    <div className={classes.mainspacer} />
                </div>
            );
        });
        return (

            <div>
                {introtext}
                <div className={classes.mainspacer} />
                <div className={[classes.floatrelative, classes.fullwidth].join(' ')}>
                    <Button style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonleft].join(' ')} onClick={() => this.close(true)}>
                        Workout starten
                    </Button>
                    <Button style={{ backgroundColor: "#00D2FF", color: '#000000' }} className={[classes.buttonContentDefault, classes.workoutbuttonright].join(' ')} onClick={() => this.close(false)}>
                        Abbrechen
                    </Button>
                </div>
                <div className={classes.mainspacer} />
                <FormControlLabel
                    control={<Checkbox checked={this.state.warmup} className={classes.checkbox}
                        name="warmup" onChange={() => that.changeBool('warmup')} />}
                    label={<div>Warmup hinzufügen</div>}
                />
                <FormControlLabel
                    control={<Checkbox checked={this.state.cooldown} className={classes.checkbox}
                        name="cooldown" onChange={() => that.changeBool('cooldown')} />}
                    label={<div>Cool Down hinzufügen</div>}
                />
                <FormControlLabel
                    control={<Checkbox checked={this.state.usePause} className={classes.checkbox}
                        name="usePause" onChange={() => that.changeBool('usePause')} />}
                    label={<div>Pausen zwischen den Übungen</div>}
                />
                <div className={classes.mainspacer} />
                {setlevels}
            </div>
        );
    }
}

export default withStyles(useStyles)(WorkoutExecutePrepare);